/**
 * A filter to convert a moment to a human readable string of the format "X seconds ago", "Y minutes ago", "4 months ago"
 */
(function () {

    var app = angular.module('saphira');

    app.filter('TimeAgoFilter', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            //assumes time is a moment object
            return function (time) {

                var now = moment();

                if (time && moment.isMoment(time)) {
                    if (time > now) {
                        return 'Now';
                    }
                    else {
                        return time.fromNow();
                    }
                }
                else {
                    return 'Never';
                }
            }
        }]);
})();