/**
 * MWDController. This controller handled all operations with our MWD window that is currently leveraging a modal instance.
 */
(function () {

    var app = angular.module("saphira");

    app.controller("MwdReaderModalController", [
        /* Angular Modules  */  "$uibModalInstance", "$uibModal", "$timeout", "$q",
        /* 3rd Party Modules*/ "uiGridConstants",
        /* Internal Modules */ "DataService", "AuthenticationService", "MWDService", "ClientRequisitesService", "UIBlocker", "HierarchyService","TimeService",

        /* Input            */ "currentSurveySet",
        /* Exteral Modules  */ "SweetAlert",

        function (/* Angular Modules  */ $uibModalInstance, $uibModal, $timeout, $q,
                  /* 3rd Party Modules*/ uiGridConstants,
                  /* Internal Modules */ DataService, AuthenticationService, MWDService, ClientRequisitesService, UIBlocker, HierarchyService, TimeService,
                  /* Input            */ currentSurveySet,
                  /* External Modules */ SweetAlert) {

            var vm = this;
            vm.model = {
                templateGridOptions: {},
                gridOptions: {},
                selectedTemplate: null,
                loadedTemplates: ClientRequisitesService.data['MwdTemplates'],
                selectedMWDFile: null,
                overwriteExisting: true,
                mappings: []
            };

            vm.enableTemplateSelection = false;

            vm.templateChanged = false;

            /**
             * Max file size 30MB in bytes. Very hard to pull a value from Springboot application.properties, plus exposing those values may not be ideal.
             * ng-file-upload does not have knowledge of Springboot max file size, therefore when springboot kills the connection the module throws a 502.
             * This should prevent it. Also prevents large uploads to the server from occurring.             *
             */
            var maxFileSize = 31457280;

            /**
             * This scope variable is for holding the current file choice. These file lists should be obtained by the server, which will provide an ID, so that
             * we can send back and ID to the sever that it provided, and have an almost guarantee that the file exists.
             * @type {Object}
             */
            vm.selection = {
                choice: ""
            };
            vm.displayChoice = "";
            vm.surveySet = currentSurveySet;
            vm.checkPerm = AuthenticationService.checkPermissionMapEntry;
            vm.savedAttachments = [];

            vm.templateGridOptions = {
                onRegisterApi: function (gridApi) {
                    vm.templateGridApi = gridApi;
                    vm.templateGridApi.selection.on.rowSelectionChanged(null, function (row) {
                        vm.model.selectedTemplate = row.entity;
                        vm.previewSelectedTemplate(vm.model.selectedTemplate);
                        vm.filterAttachments(vm.model.selectedTemplate);
                    });
                },

                enableSorting: true,
                enableFiltering: true,
                showTreeExpandNoChildren: false,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                noUnselect: true,
                modifierKeysToMultiSelect: false,
                enableColumnMenus: false,
                appScopeProvider: vm,
                rowTemplate: "<div ng-dblclick='grid.appScope.selectTemplate(row.entity)' ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\" class=\"ui-grid-cell\" ng-class=\"{'ui-grid-row-header-cell': col.isRowHeader }\" ui-grid-cell></div>",

                columnDefs: [
                    {
                        field: 'templateName',
                        sort: {
                            direction: uiGridConstants.ASC
                        },
                        width: 350
                    },
                    {
                        field: 'fileExtension',
                        displayName: 'File Type',
                        width: 110
                    },
                    {
                        field: 'uploadDate',
                        displayName: 'Upload Date'  + " (" + TimeService.getTimeZoneAbbr().server + ")",
                        sortingAlgorithm: function(a, b) {
                            return ((moment(a) > moment(b)) ? 1 : -1);
                        }
                    }
                ]
            };

            vm.selectTemplate = function(template) {
                vm.model.selectedTemplate = template;
                vm.enableTemplateSelection = false;
            };

            vm.gridOptions = {
                onRegisterApi: function (gridApi) {
                    vm.fileGridApi = gridApi;

                    vm.fileGridApi.selection.on.rowSelectionChanged(null, function (row) {
                        vm.model.selectedMWDFile = row.entity.id;
                    });
                },
                enableSorting: true,
                enableFiltering: false,
                showTreeExpandNoChildren: false,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                noUnselect: true,
                modifierKeysToMultiSelect: false,
                enableColumnMenus: false,
                columnDefs: [
                    {
                        field: 'name',
                        width: 350
                    },
                    {
                        field: 'uploadDate',
                        displayName: 'Upload Date'  + " (" + TimeService.getTimeZoneAbbr().server + ")",
                        sort: {
                            direction: uiGridConstants.DESC
                        },
                        sortingAlgorithm: function(a, b) {
                            return ((moment(a) > moment(b)) ? 1 : -1);
                        }
                    }
                ]
            };

            vm.toggleTemplateSelection = function() {
                vm.enableTemplateSelection = !vm.enableTemplateSelection;
            };

            /**
             * This function filters our attachments based on the mwd file type that has been currently selected. Due to a weird quirk in the ng-repeat with the options
             * and the ng-value, it kept on returning our object as a string and it just wasn't worth it to have the function try and parse out the string into a jsobject and
             * then parse it. So instead we just do a basic matching on the chosen value (the name of a filetype) and then we filter on the extension of the chosen file type and
             * make a 'filtered attachments' list which contains only attachments that have the same extension as the chosen file type. yay matching. yay for each loops.
             * @param  {String} model The string-valued name fo the chosen mwd file type that we are matching on
             */
            vm.filterAttachments = function (template) {
                if(!template) {
                    return;
                }

                var filterExtension = template.fileExtension;
                vm.filteredAttachments = vm.attachments.filter(function (element) {
                    var extension = element.name.split('.').pop();
                    return extension.toLowerCase() === filterExtension.toLowerCase();
                });
                vm.gridOptions.data = vm.filteredAttachments;
                vm.model.selectedMWDFile = null;
            };

            /**
             *  THis function simply validates our model and returns true if it's valid, false otherwise
             *
             * @param  {Object} our model object we're validating
             * @return {Boolean} true if it's valid, false otherwise.
             */

            vm.validateMWDForm = function (model) {
                return (model && model.selectedMWDFile && model.selectedTemplate);
            };

            /**
             * This scope variable represents the array of attachments we are currently displaying for this mwd reader
             *
             * @type {Array}
             */
            vm.filteredAttachments = null;

            /**
             * Upload a file to the server
             *
             * @param file A handle for a file selected via ng-file-upload
             */
            vm.uploadNewMWDFile = function (file) {
                if (file === undefined || file === null) {
                    return;
                }

                if (file.size > maxFileSize) {
                    var sizeMessage = "[Uploaded File Size: " + file.size + " bytes, Max File Size: " + maxFileSize + " bytes]";
                    SweetAlert.swal("Error", "File size too large! " + sizeMessage ,"error");
                    return;
                }

                DataService.uploadNewFile(file, vm.surveySet.id, vm.surveySet.commonName).then(function (/*response*/) {
                    vm.initialize();
                }, function (reason) {
                    SweetAlert.swal("Error", "There was an error uploading the file: " + (reason.statusText ? reason.statusText : reason.data.error), "error" );
                });

            };

            /**
             * This function uses our DataService to upload a brand new MWD file template to the server. These templates are not specific to a particular Hierarchy object.
             * @param  {JS File} file The file to be uploaded
             */
            vm.uploadNewMWDFileTemplate = function (file) {
                if (file === undefined || file === null) {
                    return;
                }

                if (file.size > maxFileSize) {
                    var sizeMessage = "[Uploaded File Size: " + file.size + " bytes, Max File Size: " + maxFileSize + " bytes]";
                    SweetAlert.swal("Error", "File size too large! " + sizeMessage ,"error");
                    return;
                }

                DataService.getMwdMappingsFromFile(file).then(function (response) {

                    $uibModal.open({
                        templateUrl: 'html/views/detailedWellbore/workflow/MwdReaderMappingsModal.html',
                        controller: 'MwdReaderMappingsController',
                        controllerAs: 'vm',
                        resolve: {
                            mappings: function () {
                                return response.data;
                            },
                            orderOfMappings: function () {
                                return getOrderOfMappings(currentSurveySet.data.surveyInputType);
                            }
                        }
                    }).result.then(
                        function (confirm) {

                            if (confirm) {
                                DataService.uploadMwdTemplate(file).then(function (/*response*/) {
                                    vm.initialize();
                                }, function (/*reason*/) {
                                    console.error('Error uploading template file.');
                                });
                            }

                        }, function (/*reason*/) {
                            //TODO not sure if this needs logged... the user just decided not to confirm the mappings.
                            console.error('Error confirming mappings.');
                        });

                }, function (reason) {
                    SweetAlert.swal("Error", "There was an error uploading the file: " + (reason.statusText ? reason.statusText : reason.data.error),"error");
                });

            };

            /**
             * getOrderOfMappings:
             *
             *  Helper function to get an array of the mappings in the order we want to display them.
             *
             * @param surveyInputType The type of survey input (STANDARD or BACK_CALCULATED)
             * @returns {*}
             */
            var getOrderOfMappings = function (surveyInputType) {
                switch (surveyInputType) {
                    case 'BACK_CALCULATED':
                        return ['MD', 'INC', 'AZI', 'BTOTAL', 'GTOTAL', 'DIP', 'TOOLFACE', 'DATE', 'TIME'];
                    default:
                    case 'STANDARD':
                        return ['MD', 'INC', 'AZI', 'Bx', 'By', 'Bz', 'Gx', 'Gy', 'Gz', 'DATE', 'TIME'];
                }
            };

            /**
             * This function gets the mappings of the template and displays them.
             * @param templateToPreview
             */
            vm.previewSelectedTemplate = function (templateToPreview) {
                DataService.getMappingsByTemplateName(templateToPreview.templateName).then(function (templateMapping) {
                    vm.model.mappings = [];
                    var mappings = vm.model.mappings;
                    var order = getOrderOfMappings(currentSurveySet.data.surveyInputType);

                    for (var i = 0; i < order.length; i++) {
                        Object.keys(templateMapping).forEach(function (key) {
                            if (order[i] === templateMapping[key]) {

                                mappings.push({
                                    excelColumn: key,
                                    saphiraMapping: templateMapping[key]
                                });
                            }
                        });
                    }
                })
            };

            /**
             * This function deletes a template given the entire template object that is desired to be deleted.
             * @param  {Object} templateToDelete The template object to delete
             */
            vm.deleteSelectedTemplate = function (templateToDelete) {
                if (templateToDelete === undefined || templateToDelete === null) {
                    return;
                }
                SweetAlert.swal({
                    title: 'Are you sure?',
                    text: 'This will delete the template: ' + templateToDelete.templateName,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Yes I'm Sure",
                    closeOnConfirm: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        DataService.deleteMwdTemplate(templateToDelete.attachmentId).then(function (response) {
                            SweetAlert.swal("Success!", "Template: " + templateToDelete.templateName + " deleted Successfully",
                                "success");
                            vm.initialize();
                        }, function (error) {
                            SweetAlert.swal("Error",
                                "There was an error deleting template: " + templateToDelete.templateName +
                                " please check server logs",
                                "error");
                        });
                    }
                });
            };

            vm.downloadSelectedTemplate = function (selectedTemplate) {
                if (selectedTemplate) {
                    DataService.downloadAttachment("MWDTemplates", "noID", selectedTemplate.attachmentId);
                }
                else {
                    SweetAlert.swal({
                        title: 'Failed to find template to download!',
                        text: 'Unable to find the selected template for downloading.',
                        type: 'error'
                    })
                }
            };

            /**
             * This function loads all of our attachments for this surey set. We don't have any way of knowing ahead of time if an attachment is meant
             * for MWD or for just whatever. So if the user decides to attach a non-MWD file here, it's just how it is. lame. The function will return a list
             * of files that each have a name and an ID. The ID should be used to find the file later.
             * @return {Object[]} an object array of files.
             */
            vm.loadAttachments = function () {
                vm.isLoading = true;
                return DataService.getAllAttachmentsByParentID(vm.surveySet.id, vm.surveySet.commonName).then(function (response) {
                    if (response.data !== undefined && response.data.length > 0) {
                        response.data.forEach(function (item) {
                            item.toString = item.name;
                        });
                    }

                    vm.attachments = response.data;
                    angular.copy(response.data, vm.savedAttachments);
                    vm.gridOptions.data = [];
                    vm.isLoading = false;
                    return $q.resolve();
                }, function (reason) {
                    vm.isLoading = false;
                    SweetAlert.swal("Error", "There was an error uploading the file: " + (reason.statusText ? reason.statusText : reason.data.error), "error");
                    return $q.reject();
                });
            };

            /**
             * This function actually tries to perform the MWD extraction from the specified file.
             * @param  {String} selectedTemplate The key of the selected template.
             * @param  {String} fileID           The string-valued ID of the file in the database that we want to get a survey from
             * @return {HTTP Response}                  An HTTP response that represents the success or failure of the MWD processing. if Autodetect was selected, then a 'failure' just means
             *                                          that we werent able to automatically guess the template (and that you probably need to upload a new template...)
             */
            vm.performMWD = function (selectedTemplate, fileID) {
                if (vm.surveySet.data.templateName !== selectedTemplate.templateName) {
                    vm.surveySet.data.mwdFileTemplate = selectedTemplate.templateName;
                    //No need to handle the promise because We're not waiting on anything here.
                    HierarchyService.SurveySet.save(vm.surveySet.data);
                }
                var overwriteExisting = undefined;
                if (vm.model.importAllOrOne) {
                    overwriteExisting = vm.model.overwriteExisting;
                }
                var promise;
                if (overwriteExisting !== undefined) {
                    promise = MWDService.getAllSurveysFromMWD(vm.surveySet, fileID, selectedTemplate.templateName, overwriteExisting);
                } else {
                    promise = MWDService.getSurveyFromMWD(vm.surveySet, fileID, selectedTemplate.templateName, true);
                }
                promise.then(function (response) {
                    $uibModalInstance.close(response.data);
                }, function (reason) {
                    //generic message if we don't know what happened.
                    var msg = 'please review file and try again.';
                    if (reason && reason.data && reason.data.message) {
                        msg = reason.data.message;
                    }
                    SweetAlert.swal("Error", "Error with MWD processing: " + msg, "error");
                }, function (update) {
                    //We would put progress bar notifications here if we hooked it up and got progress notification stuff
                });
            };

            /**
             * Updates a boolean value that states that the template has been changed.
             */
            vm.updateTemplate = function () {
                vm.templateChanged = true;
                vm.fileGridApi.selection.clearSelectedRows();
            };

            vm.selectTemplate = function(templateName) {
                vm.templateGridApi.grid.modifyRows(vm.templateGridOptions.data);
                var selectedRow = null;
                for(var i in vm.templateGridOptions.data) {
                    var row = vm.templateGridOptions.data[i];
                    if(row.templateName === templateName) {
                        selectedRow = row;
                    }
                }
                if(selectedRow) {

                    $timeout(function() {
                        vm.templateGridApi.core.scrollTo(selectedRow);
                    }, 0);

                    vm.model.selectedTemplate = selectedRow;
                    vm.templateGridApi.selection.selectRow(selectedRow);
                }
            };


            /**
             * This function initializes our attachment list and our mwd file type list, both obtained from separate endpoints on the server.
             *
             */
            vm.initialize = function () {
                vm.model.loadedTemplates = [];
                vm.loadAttachments();

                $q.all([vm.loadAttachments(), ClientRequisitesService.init(true)]).finally(function() {
                    vm.model.loadedTemplates = ClientRequisitesService.data['MwdTemplates'];
                    vm.model.selectedTemplate = vm.surveySet.data.mwdFileTemplate;
                    vm.templateGridOptions.data = vm.model.loadedTemplates;
                    vm.selectTemplate(currentSurveySet.data.mwdFileTemplate);
                });
            };
            vm.initialize();

        }
    ]);
})();