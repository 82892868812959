/**
 * The service responsible for basic operations on a trajectory
 */
(function () {

    var app = angular.module('saphira');

    app.factory('TrajectoryService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */  'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var TrajectoryService = {};
            //a list of urls used by the service
            var URLS = {
                getOne: '/saphira/api/repositories/trajectory/getOne',
                update: '/saphira/api/repositories/trajectory/update',
                getManyMeta: '/saphira/api/repositories/trajectory/getManyMeta',
                getManyMetaByParent: '/saphira/api/repositories/trajectory/getManyMetaByParent',
                lastModifiedDate: '/saphira/api/repositories/trajectory/lastModifiedDate',
                updateDefinitiveSurvey: "/saphira/api/repositories/trajectory/{trajectoryId}/definitiveSurveys"
            };
            //the loaded trajectories
            TrajectoryService.Loaded = {};

            /**
             * getManyMeta:
             *
             *      Returns the metas for the trajectories with the corresponding
             *  ids.  Standard authentication rules apply.
             *
             * @param trajectoryIds   An array of trajectory ids
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            TrajectoryService.getManyMeta = function (trajectoryIds) {
                return $http.get(URLS.getManyMeta, {'params': {'id': trajectoryIds}}).then(function (response) {
                    return response.data;
                });
            };

            /**
             * getManyMetaByParent:
             *
             *      Returns the metas for the trajectories with the corresponding
             *  parent id.  Standard authentication rules apply.
             *
             * @param parent   id of the parent
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            TrajectoryService.getManyMetaByParent = function (parentId) {
                return $http.get(URLS.getManyMetaByParent, {'params': {'parentId': parentId}}).then(function (response) {
                    return response.data;
                });
            };

            /**
             * get:
             *
             *      Returns the trajectory with the corresponding id.  If
             *  the trajectory is already loaded, it returns it.  If the
             *  trajectory is not yet loaded, it pulls it from the server
             *  and then stores a reference to it and returns it.
             *  Standard authentication rules apply.
             *
             * @param trajectoryId     The id of the trajectory to return
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            TrajectoryService.get = function (trajectoryId, forceUpdate) {
                UIBlocker.start();
                if (forceUpdate !== true && trajectoryId in TrajectoryService.Loaded) {
                    UIBlocker.stop();
                    return $q.resolve(TrajectoryService.Loaded[trajectoryId]);
                } else {
                    return $http.get(URLS.getOne, {'params': {'id': trajectoryId}}).then(function (response) {
                        TrajectoryService.Loaded[response.data.id] = response.data;

                        UIBlocker.stop();
                        return response.data;
                    }, function (response) {
                        UIBlocker.stop();
                        return $q.reject(response.status);
                    });
                }
            };

            /**
             * save:
             *
             *      Saves the given trajectory to the server.  If the trajectory is
             *  successfully saved, it updates the service's reference to
             *  the trajectory.
             *
             * @param trajectory       The trajectory object to save
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            TrajectoryService.save = function (trajectory) {
                UIBlocker.start();
                return $http.post(URLS.update, trajectory).then(function (response) {
                    TrajectoryService.Loaded[trajectory.id] = trajectory;
                    UIBlocker.stop();
                    return trajectory;
                }, function (response) {
                    UIBlocker.stop();
                    return $q.reject(response);
                });
            };

            /**
             * getLastModifiedDate:
             *
             *      Gets the last modified date for the trajectory with the given
             *  trajectory id.
             *
             * @param trajectoryId     The trajectory id
             *
             * @returns {*}
             */
            TrajectoryService.getLastModifiedDate = function (trajectoryId) {
                return $http.get(URLS.lastModifiedDate, {'params': {'id': trajectoryId}}).then(function (response) {
                    return response.data;
                });
            };

            /**
             * updateIfModified:
             *
             *      Updates the trajectory corresponding to the given trajectory id if
             *  the server has a more recent version.
             *
             * @param trajectoryId     The trajectory id of the trajectory to check for updates on
             * @returns {*}
             */
            TrajectoryService.updateIfModified = function (trajectoryId) {
                if (!(trajectoryId in TrajectoryService.Loaded)) {
                    console.error('Attempted to check for updates on a trajectory that isn\'t loaded');
                    return $q.reject('Trajectory needs to be loaded before checking for updates');
                } else {
                    return TrajectoryService.getLastModifiedDate(trajectoryId).then(function (date) {
                        var local = TrajectoryService.Loaded[trajectoryId];
                        if (date.seconds !== local.lastModifiedDate.seconds ||
                            date.nanos !== local.lastModifiedDate.nanos) {
                            return TrajectoryService.get(trajectoryId, true).then(function () {
                                return true;
                            });
                        }
                    }, function (response) {
                        return $q.reject(response);
                    });
                }
            };

            /**
             * updateDefinitiveSurveys:
             *
             *  Updates the definitive survey data on the trajectory with the given trajectory id with
             * new definitive survey data by replacing the old data.
             * The definitiveSurveySetRanges data should be in this json structure:
             * {
             * "parentSurveySetRanges": [{
             * 	"parentSurveySetId": "",
             * 	"mdStart": {
             * 		"value": 0.0,
             * 		"unit": "METER"
             * 	},
             * 	"mdEnd": {
             * 		"value": 1.0,
             * 		"unit": "METER"
             * 	}
             * }]
             * }
             *
             * @param trajectoryId The trajectory id of the trajectory to update with definitive survey data.
             * @param definitiveSurveySetRanges The list of parentSurveySetId's and their corresponding range data (start MD and and end MD, where start < end)
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            TrajectoryService.updateDefinitiveSurveys = function (trajectoryId, definitiveSurveySetRanges) {
                UIBlocker.start();

                //Copy relevant data to know array as we dont want to delete the list of selectable surveys, but we don't want to delete them either
                var ranges = [];
                definitiveSurveySetRanges.forEach(function(surveyRange) {
                    ranges.push({
                        definitiveValue : surveyRange.definitiveValue,
                        mdEnd : surveyRange.mdEnd,
                        mdStart : surveyRange.mdStart,
                        parentSurveySetId : surveyRange.parentSurveySetId,
                        parentSurveySetName : surveyRange.parentSurveySetName
                    });
                });

                return $http.put(URLS.updateDefinitiveSurvey.replace('{trajectoryId}', trajectoryId),
                    ranges).then(function (response) {
                    // format the http status line for return (eg. '200 OK').
                    UIBlocker.stop();
                    return response.status + " " + response.statusText;
                }, function (response) {
                    UIBlocker.stop();
                    return $q.reject(response);
                });
            };

            return TrajectoryService;
        }]);
})();
