(function () {

    var app = angular.module("saphira");

    app.service("SurveyProgramService", [
        /* Angular Modules  */ "$http", "$q",
        /* 3rd Party Modules*/
        /* Internal Modules */ 'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var SurveyProgramService = {};

            //a list of urls used by the service
            var URLS = {
                getSurveyProgramByWellboreId: '/saphira/api/repositories/surveyProgram/wellbore/',
                addSurveyProgramByWellboreId: '/saphira/api/repositories/surveyProgram/add',
                addSurveyProgramGeomagneticsData: '/saphira/api/repositories/surveyProgram/add/geomagnetics'
            };

            /**
             * getByWellboreId:
             *
             *      Returns the survey program data for a given wellbore
             *
             * @param wellboreId    The id of the wellbore we want survey program data for
             */
            SurveyProgramService.getByWellboreId = function (wellboreId) {
                return $http.get(URLS.getSurveyProgramByWellboreId + wellboreId)
                //If the request succeeds
                    .success(function (data, status, headers, config) {
                        //add the survey program data to the returned object
                        return data;
                    })
                    //If the request has an error
                    .error(function (data, status, headers, config) {
                        return status;
                    });
            };

            /**
             * addByWellboreId:
             *
             *      Adds the survey program data to a given wellboreId
             *
             * @param pojo    contains survey program data
             */
            SurveyProgramService.addByWellboreId = function (pojo) {
                return $http.post(URLS.addSurveyProgramByWellboreId, pojo)
                    //If the request succeeds
                    .success(function (data, status, headers, config) {
                        return data;
                    })
                    //If the request has an error
                    .error(function (data, status, headers, config) {
                        return status;
                    });
            };

            /**
             * addGeomagneticsData:
             *
             *      Adds the GeomagneticsData to survey program data
             *
             * @param pojo    contains survey program data
             */
            SurveyProgramService.addGeomagneticsData = function (pojo) {
                return $http.post(URLS.addSurveyProgramGeomagneticsData, pojo)
                //If the request succeeds
                    .success(function (data, status, headers, config) {
                        return data;
                    })
                    //If the request has an error
                    .error(function (data, status, headers, config) {
                        return status;
                    });
            };

            SurveyProgramService.truncate = function(value, decimalPlace){
                var divisor = Math.pow(10, decimalPlace);
                return Math.floor(value * divisor) / divisor;
            };

            return SurveyProgramService;
        }]);
}());
