/**
 * UploadSurveyFileModalController. This controller handles all of the operations around deleting files, attaching files, etc. etc.
 */
(function() {
    var app = angular.module("saphira");

    app.controller("UploadSurveyFileModalController", [
        /* Angular Modules  */ "$scope", "$uibModalInstance",
        /* 3rd Party Modules*/ "ngProgressFactory", "moment", "uiGridConstants",
        /* Internal Modules */ "DataService", "AuthenticationService","TimeService",
        /* Input            */ "currentSurveySet",

        function(/* Angular Modules  */ $scope, $uibModalInstance,
                 /* 3rd Party Modules*/ ngProgressFactory, moment, uiGridConstants,
                 /* Internal Modules */ DataService, AuthenticationService, TimeService,
                 /* Input            */ currentSurveySet ) {

            $scope.checkPerm = AuthenticationService.checkPermissionMapEntry;
            $scope.currentSurveySetOriginal = currentSurveySet;
            $scope.currentSurveySet = currentSurveySet.data.name;
            $scope.allAttachments = null;
            $scope.surveyAttachments = null;
            $scope.filteredSurveyAttachments = null;
            $scope.nonSurveyAttachments = null;
            $scope.progressbar = ngProgressFactory.createInstance();
            //because of some angular weirdness, this had to have full object scope to work right.. *shrug*
            $scope.selectedAttachments = {
                choices: []
            };
            $scope.errorMessage = null;
            $scope.sizeMessage = null;

            /**
             * Max file size 30MB in bytes. Very hard to pull a value from Springboot application.properties, plus exposing those values may not be ideal.
             * ng-file-upload does not have knowledge of Springboot max file size, therefore when springboot kills the connection the module throws a 502.
             * This should prevent it. Also prevents large uploads to the server from occurring.             *
             */
            var maxFileSize = 31457280;

            var activeTab = null;

            $scope.gridOptions = {
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function(){
                        $scope.selectedAttachments.choices = [];
                        $scope.gridApi.selection.getSelectedRows().forEach(function(row) {
                            $scope.selectedAttachments.choices.push(row.id);
                        })
                    });

                    gridApi.selection.on.rowSelectionChangedBatch($scope, function(){
                        if ($scope.gridApi.selection.getSelectedRows().length > 0) {
                            $scope.selectedAttachments.choices = [];
                            $scope.gridApi.selection.getSelectedRows().forEach(function(row) {
                                $scope.selectedAttachments.choices.push(row.id);
                            })
                        }
                    });
                },
                excessRows: 10,
                enableSorting: true,
                enableFiltering: false,
                showTreeExpandNoChildren: false,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                multiSelect: true,
                noUnselect: false,
                modifierKeysToMultiSelect: true,
                enableColumnMenus: false,
                columnDefs: [
                    {
                        field: 'name',
                        width: 550
                    },
                    {
                        field: 'uploadDate',
                        displayName: 'Upload Date'  + " (" + TimeService.getTimeZoneAbbr().server + ")",
                        sort: {
                            direction: uiGridConstants.DESC
                        },
                        sortingAlgorithm: function(a, b) {
                            return ((moment(a) > moment(b)) ? 1 : -1);
                        }
                    }
                ]
            };

            /**
             * This function deletes a file on the server. Specifically, it calls a wrapper to our serverFunctions Service to do it, but still.
             * Takes in an attachment to delete, which is an object which actually represents the entire attachment, and then wraps the function.
             * calls our refresh function on server response (whether it's good or bad).
             * @param  {JS Object} attachmentToDelete The actual attachment to delete.
             */
            $scope.deleteSingleFile = function(attachmentID) {
                $scope.progressbar.setParent(document.getElementById("file_modal"));
                $scope.progressbar.start();
                var promise = DataService.deleteAttachmentById(currentSurveySet.commonName, currentSurveySet.id, attachmentID);
                promise.then(function(response) {
                    $scope.progressbar.complete();
                    $scope.errorMessage = null;
                    if ($scope.surveyAttachments.length > 0) {
                        $scope.surveyAttachments = $scope.surveyAttachments.filter(function (element) {
                            return element.id !== attachmentID;
                        });
                    }
                    if ($scope.nonSurveyAttachments.length > 0) {
                        $scope.nonSurveyAttachments = $scope.nonSurveyAttachments.filter(function (element) {
                            return element.id !== attachmentID;
                        });
                    }
                    initialize();
                }, function(reason) {
                    $scope.progressbar.complete();
                    $scope.errorMessage = "Error deleting file: " + reason;
                }, function(update) {
                    //add functionality here for progress bar
                });
            };
            /**
             * This function simply iterates through all of the selected attachments and downloads them. easy as that. Uses the DataServices' 'downloadAttachment' to do it.
             * @param  {List<String>} attachmentsToDownload The id's that are currently selected by the user
             */
            $scope.downloadAttachments = function(attachmentsToDownload) {
                if(attachmentsToDownload === null) {
                    return;
                }
                attachmentsToDownload.forEach(function(id) {
                    DataService.downloadAttachment(currentSurveySet.commonName, currentSurveySet.id, id);
                });
            };
            /**
             * This function simply takes in a list of file ID's to delete, and then makes multiple
             * calls to our 'deleteSingleFile' function to do it.
             * @param  {String[]} filesToDelete an array of string ID's tod elete
             */
            $scope.deleteMultipleFiles = function(filesToDelete) { // TODO: Cr0006: Doesn't work on first opening of modals?
                filesToDelete.forEach(function(id) {
                    $scope.deleteSingleFile(id);
                });
            };
            /**
             * This function is for handling the upload of multiple files. This runs a simple wrapper around our single file upload.
             * @param  {Object[]} files our array of file objects
             */
            $scope.uploadNewFileMultiple = function(files, isSurveyFile) {
                files.forEach(function(file) {
                    $scope.uploadNewFile(file, isSurveyFile);
                });
            };
            /**
             * Upload a file to the server
             *
             * @param file A handle for a file selected via ng-file-upload
             */
            $scope.uploadNewFile = function(file, isSurveyFile) {
                if (file.size > maxFileSize) {
                    $scope.errorMessage = "There was an error uploading the file: File size too large!";
                    $scope.sizeMessage = ["Uploaded File Size: " + file.size + " bytes",
                        "Max File Size: " + maxFileSize + " bytes"];
                    return;
                }

                $scope.progressbar.setParent(document.getElementById("file_modal"));
                $scope.progressbar.start();

                var promise = DataService.uploadNewFile(file, currentSurveySet.id, currentSurveySet.commonName, isSurveyFile);
                promise.then(function(response) {
                    $scope.progressbar.complete();
                    $scope.errorMessage = null;
                    $scope.sizeMessage = null;
                    initialize(response);
                    if (currentSurveySet.data.automationActive && isSurveyFile) {
                        $uibModalInstance.close(response);
                    }
                }, function(reason) {
                    $scope.progressbar.complete();
                    $scope.sizeMessage = null;
                    $scope.errorMessage = "There was an error uploading the file: " + (reason.statusText ? reason.statusText : reason.data.error);
                }, function(update) {
                    $scope.progressbar.set(update);
                });
            };

            /**
             * This function handles rendering the uigrid with the correct data depending on selected tab
             * @param tab The index of the currently selected tab
             */
            $scope.tabSelect = function(tab) {
                activeTab = tab;
                $scope.selectedAttachments.choices = [];
                if (tab === 0) {
                    $scope.gridOptions.data = $scope.surveyAttachments;
                }
                else {
                    $scope.gridOptions.data = $scope.nonSurveyAttachments;
                }
            };

            /**
             * This function separates survey files and non-survey files from all attachments in the surveyset
             * @param attachmentList Array of attachments in the surveySet
             * @param findSurveys Boolean, where true finds all survey attachments, false finds all non-survey attachments
             * @returns {Array} Filtered array of attachments of the specified type
             */
            var getFilteredFilesFromAttachment = function(attachmentList, findSurveys) {
                var filteredFilesList = [];

                attachmentList.forEach(function (attachment) {
                    if (findSurveys === true) {
                        if (attachment.isSurveyFile === undefined || attachment.isSurveyFile === true) {
                            filteredFilesList.push(attachment);
                        }
                    }
                    else {
                        if (attachment.isSurveyFile === false) {
                            filteredFilesList.push(attachment);
                        }
                    }

                });
                return filteredFilesList;
            };

            /**
             * This function simply inits the attachments, and once they are uploaded, sets the $scope.attachments variable. We also have to
             * map the 'name' field of the item to a 'toString' field on the object in order to make the select list directive work correctly.
             * @return {[type]} [description]
             */
            var initialize = function() {
                DataService.getAllAttachmentsByParentID(currentSurveySet.id, currentSurveySet.commonName).then(function(response) {
                    response.data.forEach(function(item) {
                        item.toString = item.name;
                    });
                    $scope.allAttachments = response.data;
                    if ($scope.allAttachments.length === 0) {
                        $scope.errorMessage = "No files found, please attach a file to get started";
                    }
                    else {
                        $scope.surveyAttachments = getFilteredFilesFromAttachment($scope.allAttachments, true);
                        $scope.nonSurveyAttachments = getFilteredFilesFromAttachment($scope.allAttachments, false);
                    }
                    $scope.tabSelect(activeTab);

                }, function(reason) {
                    SweetAlert.swal("Error", "Failed to load survey files");
                    console.error(reason);
                    $scope.errorMessage = "Error getting initial file list: " + reason;
                }, function(update) {
                    //add progress bar here in the future maybe.
                });
            };

            initialize();
        }
    ]);
})();