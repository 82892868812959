/**
 * A simple filter that converts resolved state enums to friendlier string values.
 */
(function () {

    var app = angular.module('saphira');

    app.filter('resolvedStateFilter', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (resolution, value) {
                if (resolution == 'RESOLVED') {
                    return 'Resolved';
                }
                else if (resolution == 'UNRESOLVED') {
                    return 'Unresolved';
                }
                else {
                    return 'Manually Set : ' + value;
                }
            };
        }]);
})();
