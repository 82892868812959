/**
 * A dual list in AngularJs that lets you choose a subset of items from a list of available items.
 * Also includes the ability to sort the selected items.
 *
 * Code about the two lists and moving items from one to the other is based on the jsfiddle http://jsfiddle.net/4zt4k5er/
 *
 * The ability to sort the selected list, as well as the restructuring into an AngularJs directive done in house.
 *
 * The directive requires a map of two arrays named "available", and "selected" to be passed to it, like so
 * {
 *      available: [],
 *      selected: []
 * }
 * "Available" should be a list of all options you want the list of available items to contain, "selected" will be populated with the selected items.
 * It's kind of ugly in that regard but angular binding can be rather annoying sometimes.
 */
(function () {

    angular.module('saphira').directive('mvPickList', [function($templateCache) {
        function link(scope, element, attrs){

        }

        function controller($scope){
            $scope.SelectedAvailItems = [];
            $scope.SelectedSelectedListItems = [];
            $scope.SelectedListItems = [];
            $scope.AvailableListItems = [];

            //Bind given map to the internal lists.
            $scope.DefaultListItems = $scope.pickList.available;
            $scope.pickList.selected = $scope.SelectedListItems;

            //Update the lists if the given options are changed
            $scope.$watch('pickList.available', function( newValue, oldValue ){
                $scope.DefaultListItems = $scope.pickList.available;
                angular.copy($scope.DefaultListItems, $scope.AvailableListItems);
                $scope.SelectedListItems.splice(0, $scope.SelectedListItems.length);
                $scope.SelectedAvailItems = [];
                $scope.SelectedSelectedListItems = [];
            }, true);

            var findIndex = function(arr, desired) {
                for(var i in arr) {
                    if(arr.hasOwnProperty(i)) {
                        var value = arr[i];
                        if (value === desired) {
                            return Number(i);
                        }
                    }
                }
                return -1;
            };

            var moveUpArray = function(arr, value) {
                var index = findIndex(arr, value);

                if(index < 0) {
                    console.warn("Attempted to move " + value[$scope.displayAttribute] + " up the list, but it doesn't exist in the list!");
                }

                //Already at beginning of list, nothing to do
                if(index === 0) {
                    return;
                }

                var element = arr[index];
                arr.splice(index, 1);
                arr.splice(index - 1, 0, element);
            };

            var moveDownArray = function(arr, value) {
                var index = findIndex(arr, value);

                if(index < 0) {
                    console.warn("Attempted to move " + value[$scope.displayAttribute] + " down the list, but it doesn't exist in the list!");
                }

                //Already at end of list, nothing to do
                if(index === arr.length - 1) {
                    return;
                }

                var element = arr[index];
                arr.splice(index , 1);
                arr.splice(index + 1, 0, element);
            };

            $scope.btnRight = function () {
                //move selected.
                angular.forEach($scope.SelectedAvailItems, function (value, key) {
                    this.push(value);
                }, $scope.SelectedListItems);

                //remove the ones that were moved.
                angular.forEach($scope.SelectedAvailItems, function (value, key) {
                    for (var i = $scope.AvailableListItems.length - 1; i >= 0; i--) {
                        if ($scope.AvailableListItems[i] === value) {
                            $scope.AvailableListItems.splice(i, 1);
                        }
                    }
                });
                $scope.SelectedAvailItems = [];
            };

            $scope.btnAllRight = function () {
                //move all.
                angular.forEach($scope.AvailableListItems, function (value, key) {
                    this.push(value);
                }, $scope.SelectedListItems);

                //remove the ones that were moved from the source.
                for (var i = $scope.AvailableListItems.length - 1; i >= 0; i--) {
                    $scope.AvailableListItems.splice(i, 1);
                }
            };

            $scope.btnLeft = function () {
                //move selected.
                angular.forEach($scope.SelectedSelectedListItems, function (value, key) {
                    this.push(value);
                }, $scope.AvailableListItems);

                //remove the ones that were moved from the source container.
                angular.forEach($scope.SelectedSelectedListItems, function (value, key) {
                    for (var i = $scope.SelectedListItems.length - 1; i >= 0; i--) {
                        if ($scope.SelectedListItems[i] === value) {
                            $scope.SelectedListItems.splice(i, 1);
                        }
                    }
                });
                $scope.SelectedSelectedListItems = [];
            };

            $scope.btnAllLeft = function () {

                //move over all items
                angular.forEach($scope.SelectedListItems, function (value, key) {
                    this.push(value);
                }, $scope.AvailableListItems);

                //remove the ones that were moved from the source.
                for (var i = $scope.SelectedListItems.length - 1; i >= 0; i--) {
                    $scope.SelectedListItems.splice(i, 1);
                }
                $scope.SelectedSelectedListItems = [];
            };

            $scope.btnUp = function() {
                angular.forEach($scope.SelectedSelectedListItems, function (selected) {
                    moveUpArray($scope.SelectedListItems, selected);
                });
            };

            $scope.btnDown = function() {
                angular.forEach($scope.SelectedSelectedListItems, function (selected) {
                    moveDownArray($scope.SelectedListItems, selected);
                });
            }
        }

        return {
            link: link,
            controller: controller,
            scope: {
                pickList: "=",
                displayAttribute: "=",
            },
            templateUrl: 'html/templates/pickList.html',
            restrict: 'E'
        };
    }]);
})();
