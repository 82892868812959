(function () {

    var app = angular.module('saphira');

    app.factory('StateService', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            var State = {};
            State.wellboreDetailView = {};

            State.routing = {
                pendingPage : null
            };

            State.resetRouting = function() {
                State.routing = {
                    pendingPage : null
                };
            };

            return State;
        }]);
})();
