/**
 * AzimuthPlottingService:
 *
 *      Responsible for: Creating the plot options for various types of plots for survey Azimuth.
 *      @author: cperry
 */
(function () {
    var app = angular.module('saphira');

    app.factory("AzimuthPlottingService", [
        /* Angular Modules  */ "$filter",
        /* 3rd Party Modules*/
        /* Internal Modules */ "PlotStyleService", "PlotHelperService",
        /* Input            */

        function (/* Angular Modules  */ $filter,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ PlotStyleService, PlotHelperService
                  /* Input            */) {

            var service = {};

            /**
             * Creates the plot options for plotting the raw azimuth of a list of surveys
             * @param surveys
             * @param unitSet
             * @param qc
             * @returns {{exporting: *, title: *, xAxis: *, yAxis: *, chart: *, plotOptions: *, credits: {enabled: boolean}, tooltip: *, series: *[]}}
             */
            service.plotRawAzi = function(surveys, unitSet, qc) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);

                var topFailRange = [], bottomFailRange=[], passRange = [], reference = [];
                var delta = {
                    ALL:[],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getRawMinMax(surveys,qc, unitSet.angleUnit);
                var min = minMax.min;
                var max = minMax.max;

                for(var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var del = PlotHelperService.angleDif(surveys[i].aziCalc.value, surveys[i].aziRep.value, unitSet.angleUnit);

                    topFailRange.push([md, qc, max]);
                    bottomFailRange.push([md, min, qc * -1.0]);
                    passRange.push([md, qc * -1.0, qc]);
                    reference.push([md, 0]);
                    delta[surveys[i].type].push([md, del]);
                    delta["ALL"].push([md, del]);
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Azimuth (Calculated - Reported)'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("Residual (" +
                        $filter('angleUnit')(unitSet.angleUnit) + ")", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, unitSet.angleUnit),

                    series: [
                        PlotStyleService.createFailRegion(topFailRange),
                        PlotStyleService.createPassRegion(passRange),
                        PlotStyleService.createFailRegion(bottomFailRange),
                        PlotStyleService.createReferenceSeries("Reference", reference),
                        PlotStyleService.createAllMeasuredSeries("Surveys", delta["ALL"]),
                        PlotStyleService.createMeasuredSeries("Standard", delta["STANDARD"]),
                        PlotStyleService.createPoorMeasuredSeries("Poor", delta["POOR"]),
                        PlotStyleService.createBadSeries("Bad", delta["BAD"]),
                        PlotStyleService.createCheckshotMeasuredSeries("Checkshot", delta["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerMeasuredSeries("Accelerometer", delta["ACC_CHECK"]),
                    ]
                };

            };

            /**
             * Creates the plot options for plotting the MSA corrected azimuth of a list of surveys
             * @param surveys
             * @param unitSet
             * @param qc
             * @returns {{exporting: *, title: *, xAxis: *, yAxis: *, chart: *, plotOptions: *, credits: {enabled: boolean}, tooltip: *, series: *[]}}
             */
            service.plotMsaAzi = function(surveys, unitSet, qc) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);
                surveys = PlotHelperService.getSurveysByCorrection(surveys, ['MULTISTATION']);

                var topFailRange = [], bottomFailRange=[], passRange = [], reference = [];
                var delta = {
                    ALL:[],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getMsaMinMax(surveys,qc, unitSet.angleUnit);
                var min = minMax.min;
                var max = minMax.max;

                for(var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var del = PlotHelperService.angleDif(surveys[i].correctedDatas.MULTISTATION.azimuth.value, surveys[i].aziRep.value, unitSet.angleUnit);

                    topFailRange.push([md, qc, max]);
                    bottomFailRange.push([md, min, qc * -1.0]);
                    passRange.push([md, qc * -1.0, qc]);
                    reference.push([md, 0]);
                    delta[surveys[i].type].push([md, del]);
                    delta["ALL"].push([md, del]);
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Azimuth (Corrected - Reported)'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("Residual (" +
                        $filter('angleUnit')(unitSet.angleUnit) + ")", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, unitSet.angleUnit),

                    series: [
                        PlotStyleService.createFailRegion(topFailRange),
                        PlotStyleService.createPassRegion(passRange),
                        PlotStyleService.createFailRegion(bottomFailRange),
                        PlotStyleService.createReferenceSeries("Reference", reference),
                        PlotStyleService.createAllCorrectedSeries("Surveys", delta["ALL"]),
                        PlotStyleService.createCorrectedSeries("Standard", delta["STANDARD"]),
                        PlotStyleService.createPoorCorrectedSeries("Poor", delta["POOR"]),
                        PlotStyleService.createBadSeries("Bad", delta["BAD"]),
                        PlotStyleService.createCheckshotCorrectedSeries("Checkshot", delta["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerCorrectedSeries("Accelerometer", delta["ACC_CHECK"])
                    ]
                };
            };

            /**
             * Creates the plot options for plotting the MSA corrected azimuth and the post-processed MSA corrected
             * azimuth of a list of surveys
             * @param surveys
             * @param postSurveys
             * @param unitSet
             * @param qc
             * @returns {{exporting: *, title: *, xAxis: *, yAxis: *, chart: *, plotOptions: *, credits: {enabled: boolean}, tooltip: *, series: *[]}}
             */
            service.plotMsaAziWithPost = function(surveys, postSurveys, unitSet, qc) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);
                surveys = PlotHelperService.getSurveysByCorrection(surveys, ['MULTISTATION']);
                postSurveys = PlotHelperService.filterOutInterpolatedSurveys(postSurveys);
                postSurveys = PlotHelperService.getSurveysByCorrection(postSurveys, ['MULTISTATION']);

                var topFailRange = [], bottomFailRange=[], passRange = [], reference = [];
                var delta = {
                    ALL: [],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };
                var postDelta = {
                    ALL:[],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getMsaPostMinMax(surveys, postSurveys, qc, unitSet.angleUnit);
                var min = minMax.min;
                var max = minMax.max;

                for(var i = 0; i < postSurveys.length; i++) {
                    var md = postSurveys[i].md.value;
                    var del = PlotHelperService.angleDif(postSurveys[i].correctedDatas.MULTISTATION.azimuth.value, postSurveys[i].aziRep.value, unitSet.angleUnit);

                    topFailRange.push([md, qc, max]);
                    bottomFailRange.push([md, min, qc * -1.0]);
                    passRange.push([md, qc * -1.0, qc]);
                    reference.push([md, 0]);
                    postDelta[postSurveys[i].type].push([md, del]);
                    postDelta["ALL"].push([md, del]);
                }

                for(var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var del = PlotHelperService.angleDif(surveys[i].correctedDatas.MULTISTATION.azimuth.value, surveys[i].aziRep.value, unitSet.angleUnit);
                    delta[surveys[i].type].push([md, del]);
                    delta["ALL"].push([md, del]);
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Azimuth (Corrected - Reported)'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("Residual (" +
                        $filter('angleUnit')(unitSet.angleUnit) + ")", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, unitSet.angleUnit),

                    series: [
                        PlotStyleService.createFailRegion(topFailRange),
                        PlotStyleService.createPassRegion(passRange),
                        PlotStyleService.createFailRegion(bottomFailRange),
                        PlotStyleService.createReferenceSeries("Reference", reference),
                        PlotStyleService.createAllMeasuredSeries("Surveys (Post)", postDelta["ALL"]),
                        PlotStyleService.createMeasuredSeries("Standard (Post)", postDelta["STANDARD"]),
                        PlotStyleService.createPoorMeasuredSeries("Poor (Post)", postDelta["POOR"]),
                        PlotStyleService.createBadSeries("Bad (Post)", postDelta["BAD"]),
                        PlotStyleService.createCheckshotMeasuredSeries("Checkshot (Post)", postDelta["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerMeasuredSeries("Accelerometer (Post)", postDelta["ACC_CHECK"]),
                        PlotStyleService.createAllCorrectedSeries("Surveys", delta["ALL"]),
                        PlotStyleService.createCorrectedSeries("Standard", delta["STANDARD"]),
                        PlotStyleService.createPoorCorrectedSeries("Poor", delta["POOR"]),
                        PlotStyleService.createBadSeries("Bad", delta["BAD"]),
                        PlotStyleService.createCheckshotCorrectedSeries("Checkshot", delta["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerCorrectedSeries("Accelerometer", delta["ACC_CHECK"])
                    ]
                };
            };

            var getRawMinMax = function(surveys, qc, angleUnit) {
                var min = qc * -1.0;
                var max = qc;

                for(var i = 0; i < surveys.length; i++) {
                    var delta = PlotHelperService.angleDif(surveys[i].aziCalc.value, surveys[i].aziRep.value, angleUnit);
                    min = min < delta ? min : delta;
                    max = max > delta ? max : delta;
                }

                max += Math.abs((max - min) * 0.05);
                min -= Math.abs((max - min) * 0.05);

                return {
                    min: min,
                    max: max
                }
            };

            var getMsaMinMax = function(surveys, qc, angleUnit) {
                var min = qc * -1.0;
                var max = qc;

                for(var i = 0; i < surveys.length; i++) {
                    var delta = PlotHelperService.angleDif(surveys[i].correctedDatas.MULTISTATION.azimuth.value, surveys[i].aziRep.value, angleUnit);
                    min = min < delta ? min : delta;
                    max = max > delta ? max : delta;
                }

                max += Math.abs((max - min) * 0.05);
                min -= Math.abs((max - min) * 0.05);

                return {
                    min: min,
                    max: max
                }
            };

            var getMsaPostMinMax = function(surveys, postSurveys, qc, angleUnit) {
                var min = qc * -1.0;
                var max = qc;

                for(var i = 0; i < surveys.length; i++) {
                    var delta = PlotHelperService.angleDif(surveys[i].correctedDatas.MULTISTATION.azimuth.value, surveys[i].aziRep.value, angleUnit);
                    min = min < delta ? min : delta;
                    max = max > delta ? max : delta;
                }

                for(var i = 0; i < postSurveys.length; i++) {
                    var delta = PlotHelperService.angleDif(postSurveys[i].correctedDatas.MULTISTATION.azimuth.value, postSurveys[i].aziRep.value, angleUnit);
                    min = min < delta ? min : delta;
                    max = max > delta ? max : delta;
                }

                max += Math.abs((max - min) * 0.05);
                min -= Math.abs((max - min) * 0.05);

                return {
                    min: min,
                    max: max
                }
            };

            return service;
        }]);
})();