/**
 * This controller handles everything in our 'account' page. the primary purpose of this page is to set themes, etc. etc.
 */
(function () {

    var app = angular.module("saphira");

    app.controller("AccountController", [
        /* Angular Modules  */ "$scope", "$filter", "$timeout", "$state",
        /* 3rd Party Modules*/ "SweetAlert", "Idle",
        /* Internal Modules */ "AuthenticationService", "ThemeService", "StateService",
        /* Input            */

        function (/* Angular Modules   */ $scope, $filter, $timeout, $state,
                  /* 3rd Party Modules */ SweetAlert, Idle,
                  /* Internal Modules  */ AuthenticationService, ThemeService, StateService
                  /* Input             */) {

            $scope.$on('IdleStart', function() {
                if(AuthenticationService.currentUser().isRigUser) {
                    AuthenticationService.updateDelayTimeInSession("idleStart");
                }
            });

            $scope.$on('IdleEnd', function() {
                if(AuthenticationService.currentUser().isRigUser) {
                    AuthenticationService.updateDelayTimeInSession("idleEnd");
                }
            });

            /**
             * This just initializes some of our themes, checks for the swaldrop special, etc.
             * @return {[type]} [description]
             */
            var initialize = function () {
                document.title = "Saphira Account";
                AuthenticationService.getSessionStatus().then(function(loggedIn) {
                    //check if the user is logged in
                    if (!loggedIn) {
                        //if not, set a pending page and send them to log in
                        StateService.routing.pendingPage = "permissions";
                        $state.go("login");
                    }
                    else {
                        $scope.acct = AuthenticationService.currentUser();
                        $scope.isRigUser = AuthenticationService.currentUser().isRigUser;
                        $scope.selection = ThemeService.defaultTheme;
                        $scope.loadingFinished = true;
                    }
                },function(response) {
                    SweetAlert.swal("Error", "Failed to authenticate", "error");
                    $state.go("login");
                });
            };
            initialize();
            $scope.templateUrl = 'html/views/account/SurveyTypesColors.html';
        }
    ]);
})();
