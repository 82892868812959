/**
 * ActivityLogModalController:
 *
 *      Responsible for:
 */
(function () {

    var app = angular.module('saphira');

    app.controller('ActivityLogModalController', [
        /* Angular Modules  */ '$scope', '$http', '$q', '$uibModalInstance',
        /* 3rd Party Modules*/ 'SweetAlert',
        /* Internal Modules */ 'ActivityLogService', 'StateService', 'DataService', "TimeService",
        /* Input            */ "wellName",

        function (/* Angular Modules  */ $scope, $http, $q, $uibModalInstance,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ ActivityLogService, StateService, DataService, TimeService,
                  /* Input            */ wellName) {

            //the currently loaded activity logs
            $scope.activityLogs = [];

            $scope.state = StateService;

            $scope.timeZone = TimeService.getTimeZoneAbbr();

            $scope.displaying = {
                level: {
                    Company: false,
                    Field: false,
                    Pad: false,
                    Well: false,
                    Wellbore: true,
                    Trajectory: StateService.wellboreDetailView.selectedTrajectoryId !== null,
                    SurveySet: StateService.wellboreDetailView.selectedSurveySetId !== null
                },
                criticality: {
                    ERROR: true,
                    WARN: true,
                    INFO: false,
                    DEBUG: false,
                    TRACE: false,
                    AUDIT: false
                }
            };

            $scope.page = {size: 15, index: 1, numPages: 1, totalItems: 1};


            //the action for closing the modals
            $scope.closeModalCancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.getLogs = function (reset) {
                if (reset === true) {
                    $scope.page.size = 15;
                    $scope.page.index = 1;
                }

                ActivityLogService.getFiltered(constructRequestPojo()).then(function (request) {
                    $scope.activityLogs = request.data.messages;
                    $scope.page.numPages = request.data.numPages; //Getting an extra page for some reason?
                    $scope.page.totalItems = request.data.numPages * $scope.page.size;

                    for (var i = 0; i < $scope.activityLogs.length; i++) {
                        $scope.activityLogs[i].timeFormatted =
                            moment($scope.activityLogs[i].time.seconds * 1000).format("YYYY/M/D h:mm:ss a");
                    }
                }, function (status) {
                    SweetAlert.swal("Error", "Error getting activity logs" + status, 'error');
                });
            };

            $scope.exportLogs = function () {
                ActivityLogService.getAllFiltered(constructRequestPojo()).then(function (request) {
                    var logs = request.data;

                    if (logs === null || logs === undefined) {
                        SweetAlert.swal("Error exporting activity logs", "No activity logs to export!", 'error');
                        return;
                    }

                    // Convert the logs object into an array
                    var logsArray = Object.keys(logs).map(function (key) {
                        var timeFormatted = moment(logs[key].time.seconds * 1000).format("YYYY/M/D h:mm:ss a");
                        // Put quotes around the message to prevent csv from seeing commas, and remove newline/carriage returns
                        var message = ("\"" + logs[key].message + "\"").replace(/[\n\r]/g, ' ');

                        return [timeFormatted, logs[key].objRef.type, logs[key].criticality, logs[key].activityType.toString(), message];
                    });

                    // Construct file name
                    var objRefString = "[";
                    var critString = "";
                    request.config.data.objRefs.forEach(function (objRef) {
                        objRefString += objRef.type + "_";
                    });
                    request.config.data.criticalities.forEach(function (criticality) {
                        critString += criticality + "_";
                    });
                    critString = critString.slice(0, -1) + "]_";
                    var today = new Date();
                    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + "-" + today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();

                    var fileName = "ActivityLogs_" + wellName + "_" + objRefString + critString + date + ".csv";

                    // Create the csv, inject array data, and create invisible download link
                    var csvContent = "data:text/csv;charset=utf-8,";
                    csvContent += "TIME (" + $scope.timeZone.client + "),LEVEL,CRITICALITY,ACTIVITY TYPE,MESSAGE\n\n";
                    logsArray.forEach(function (infoArray, index){
                        var dataString = infoArray.join(",");
                        csvContent += index < logsArray.length ? dataString + "\n" : dataString;
                    });
                    DataService.downloadData(fileName, csvContent);
                });
            };

            var constructRequestPojo = function () {
                var pojo = {
                    objRefs: [],
                    criticalities: [],
                    pageSize: $scope.page.size,
                    pageIndex: $scope.page.index - 1
                }; //Bootstrap starts index at 1, but server starts at 0

                if ($scope.displaying.level.Company) pojo.objRefs.push(
                    {id: StateService.wellboreDetailView.selectedCompanyId, type: "Company"});
                if ($scope.displaying.level.Field) pojo.objRefs.push({id: StateService.wellboreDetailView.selectedFieldId, type: "Field"});
                if ($scope.displaying.level.Pad) pojo.objRefs.push({id: StateService.wellboreDetailView.selectedPadId, type: "Pad"});
                if ($scope.displaying.level.Well) pojo.objRefs.push({id: StateService.wellboreDetailView.selectedWellId, type: "Well"});
                if ($scope.displaying.level.Wellbore) pojo.objRefs.push(
                    {id: StateService.wellboreDetailView.selectedWellboreId, type: "Wellbore"});
                if ($scope.displaying.level.Trajectory) pojo.objRefs.push(
                    {id: StateService.wellboreDetailView.selectedTrajectoryId, type: "Trajectory"});
                if ($scope.displaying.level.SurveySet) pojo.objRefs.push(
                    {id: StateService.wellboreDetailView.selectedSurveySetId, type: "SurveySet"});

                if ($scope.displaying.criticality.ERROR) pojo.criticalities.push("ERROR");
                if ($scope.displaying.criticality.WARN) pojo.criticalities.push("WARN");
                if ($scope.displaying.criticality.INFO) pojo.criticalities.push("INFO");
                if ($scope.displaying.criticality.DEBUG) pojo.criticalities.push("DEBUG");
                if ($scope.displaying.criticality.TRACE) pojo.criticalities.push("TRACE");
                if ($scope.displaying.criticality.AUDIT) pojo.criticalities.push("AUDIT");


                return pojo;
            };

            //initialize the controller by loading up the activity logs
            var init = function () {
                $scope.getLogs();
            }();

        }]);
})();