(function() {

    var app = angular.module('saphira');
    app.directive('mvMasterChatBox', ['$document', '$window','$templateCache','moment', function($document, $window, $templateCache, moment) {
    return {
            restrict: 'E',      //Enforce the directive to be used as an element

            replace: true,      //Force the directive html to replace the tag

            scope: {
                messages: '=',  // (Required) The list of messages to display in the chat box
                options: '=',   // (Required) Some configuration options
                onAddMessage: '&'   // (Required) Function called when a message is added
            },

            template: $templateCache.get('html/views/masterChat/masterChatBox.html'), //The template url

            link: function(scope, elem, attrs) {
                var createCssClass = function(className, field, value, otherStyle) {
                    var headerStyleClass = document.createElement('style');
                    headerStyleClass.type = 'text/css';
                    headerStyleClass.innerHTML = '.' + className + ' { ' + field + ': ' + value + '; ' + otherStyle + '}';
                    document.getElementsByTagName('head')[0].appendChild(headerStyleClass);
                };

                var classNames = {
                    mainUserBox: 'mvChatBox_MainUserBox',
                    otherUserBox: 'mvChatBox_OtherUserBox'
                };
                createCssClass(classNames.mainUserBox, 'background-color', scope.options.userColor, 'float: left;');
                createCssClass(classNames.otherUserBox, 'background-color', scope.options.otherColor, 'float: right;');
            },
            controller: ['$scope', '$element', function($scope, $element) {
              
                var scrollElement = $('.mvChatBox_Scrollable')[0];
                $scope.scrollToBottom = function() {
                    scrollElement.scrollTop = scrollElement.scrollHeight;
                };

                var scrollbarIsAtBottom = function() {
                    return scrollElement.scrollTop === (scrollElement.scrollHeight - scrollElement.offsetHeight);
                };

                var init = function() {
                    $scope.$watch('messages', function() {
                        $scope.messages.forEach(function(message){
                            message.momentTime = moment.unix(message.time.seconds);
                        });
                        if (scrollbarIsAtBottom()) {
                            setTimeout(function() {
                                $scope.scrollToBottom();
                            }, 10);
                        }
                    }, true);
                    setTimeout(function() {
                        $scope.scrollToBottom();
                    }, 10);
                };
                init();
            }]
        }

    }]);
})();