/**
 * Survey type filters. Converts between survey types and abbreviations
 */
(function () {

    var app = angular.module('saphira');

    //TODO extract to ClientRequisites and have this info is pulled from the server
    var surveyTypes = {
        "STANDARD": {
            definitive: true,
            interpolated: false,
            acronym: "D-S"
        },
        "POOR": {
            definitive: true,
            interpolated: false,
            acronym: "D-P"
        },
        "DEFINITIVE_INTERPOLATED": {
            definitive: true,
            interpolated: true,
            acronym: "D-I"
        },
        "DEFINITIVE_REPORTED": {
            definitive: true,
            interpolated: true,
            acronym: "D-R"
        },
        "BAD": {
            definitive: false,
            interpolated: false,
            acronym: "X-B"
        },
        "ACC_CHECK": {
            definitive: false,
            interpolated: false,
            acronym: "X-A"
        },
        "CHECKSHOT": {
            definitive: false,
            interpolated: false,
            acronym: "X-C"
        },
        "INTERPOLATED": {
            definitive: false,
            interpolated: false,
            acronym: "X-I"
        }
    };

    app.filter('surveyTypeFilter', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (string) {
                if(surveyTypes[string]) {
                    return surveyTypes[string].acronym;
                }
                else {
                    return "Unsupported Type";
                }
            }
        }]);

    app.filter('surveyTypeAbvFilter', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (string) {

                for(var type in surveyTypes) {
                    if(surveyTypes.hasOwnProperty(type) && surveyTypes[type].acronym === string) {
                        return type;
                    }
                }
                return "Unsupported Type";
            }
        }
    ]);

    app.filter('definitiveSurveys', [
        /* Angular Modules  */
        /* 3rd Party Modules*/ "lodash",
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/ lodash
                  /* Internal Modules */
                  /* Input            */) {

            return function (surveys, includeInterpolated) {
                if(surveys && surveys.length > 0) {
                    return surveys.filter(function(survey) {
                       if(includeInterpolated) {
                           return surveyTypes[survey.type] && surveyTypes[survey.type].definitive;
                       }
                       else {
                           return  surveyTypes[survey.type] && surveyTypes[survey.type].definitive && !surveyTypes[survey.type].interpolated;
                       }
                    });
                }
                else {
                    return surveys;
                }
            }
        }
    ]);
})();
