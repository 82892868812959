/**
 * Data Validation controller
 * Handles the displaying of validation errors
 */

(function () {

    var app = angular.module("saphira");

    app.controller("SurveyValidationModalController", [
        /* Angular Modules  */ "$scope", "$uibModalInstance",
        /* 3rd Party Modules*/
        /* Internal Modules */ 'ClientRequisitesService',
        /* Input            */ 'validatedSurvey',

        function (/* Angular Modules  */ $scope, $uibModalInstance,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ ClientRequisitesService,
                  /* Input            */ validatedSurvey) {

            var init = function () {
                ClientRequisitesService.init().then(function () {
                    $scope.errors = [];
                    angular.forEach(validatedSurvey.validationErrors, function (errorCode) {
                        $scope.errors.push("Check " + errorCode + ": " + ClientRequisitesService.data.ValidationErrors[errorCode]);
                    });
                });
            };
            init();

            /**
             * This closes the modals, accepting and overriding any errors
             */
            $scope.closeModal = function () {
                $uibModalInstance.close('Accept');
            };

            /**
             * This dismisses the modals, so the user may discard the survey
             */
            $scope.cancelModal = function () {
                $uibModalInstance.dismiss('Reject');
            };

        }]);

})();