/**
 * This service is for handling various data transmissions to and from the server. Generally this is more general things like CRUD operations on a file, or getting enums, or
 * w/e
 */
(function () {

    var app = angular.module('saphira');

    app.factory('DataService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/ 'Upload',
        /* Internal Modules */ 'UIBlocker', 'StateService',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/  Upload,
                  /* Internal Modules */ UIBlocker, StateService
                  /* Input            */) {

            var DataService = {};
            var URLS = {
                downloadAttachment: "/saphira/api/attachments/search/findById",
                report: "/saphira/api/utils/report",
                multiReport: "/saphira/api/utils/multiReport",
                multiReportUpdate: "/saphira/api/utils/multiReport/update",
                correctedSurveyReport : "/saphira/api/utils/correctedsurveyreport"
            };
            /**
             * The function that obtains all the well metadata for all the
             * wells associated with the current account.
             *
             * @param wellIds
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            DataService.uploadNewFile = function (file, parentID, parentCommonName, isSurveyFile) {
                var deferred = $q.defer();
                UIBlocker.start();
                Upload.upload({
                    url: '/saphira/api/attachments',
                    method: 'POST',
                    fields: {
                        parentId: parentID,
                        parentObject: parentCommonName,
                        isSurveyFile: isSurveyFile
                    },
                    file: file,
                    fileFormDataName: 'file'
                }).then(function (response) {
                    UIBlocker.stop();
                    swal("Success!", "Upload of file: " + file.name + " succeeded!", "success");
                    deferred.resolve(response);
                }, function (response) {
                    UIBlocker.stop();
                    deferred.reject(response);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };


            DataService.getMwdMappingsFromFile = function (file) {
                var deferred = $q.defer();
                UIBlocker.start();
                Upload.upload({
                    url: '/saphira/api/mwd/getMappingsFromTemplate',
                    method: 'POST',
                    data: {templateFile: file, fileFormDataName: 'file'}
                }).then(function (response) {
                    UIBlocker.stop();
                    deferred.resolve(response);
                }, function (response) {
                    UIBlocker.stop();
                    deferred.reject(response);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };

            DataService.getMappingsByTemplateName = function (templateName) {
                var deferred = $q.defer();
                UIBlocker.start();
                var params = {
                    templateName: templateName
                };
                $http.get("/saphira/api/mwd/getMappingsByTemplateName", {params: params}).then(
                    function (data, status, headers, config) {
                        UIBlocker.stop();
                        deferred.resolve(data.data);
                    },
                    function (data, status, headers, config) {
                        UIBlocker.stop();
                        deferred.reject(data);
                    }
                );
                return deferred.promise;
            };


            DataService.uploadMwdTemplate = function (file) {
                var deferred = $q.defer();
                UIBlocker.start();
                Upload.upload({
                    url: '/saphira/api/mwd/saveNewTemplate',
                    method: 'POST',
                    data: {templateFile: file, fileFormDataName: 'file'}
                }).then(function (response) {
                    UIBlocker.stop();
                    swal("Success!", "Upload of file: " + file.name + " succeeded!", "success");
                    deferred.resolve(response);
                }, function (response) {
                    UIBlocker.stop();
                    deferred.reject(response);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };


            /**
             * This function deletes a specified template given the template id. uses the endpoint
             * /saphira/api/mwd/templates
             * @param  {String} templateId The attachmentId of the template to be deleted
             * @return {Promise}              Returns a promise that resolves when the HTTP request is finished.
             */
            DataService.deleteMwdTemplate = function (templateId) {
                var deferred = $q.defer();
                if (templateId === undefined || templateId === null || templateId === '') {
                    deferred.reject("No template name provided");
                }
                UIBlocker.start();
                var params = {
                    templateId: templateId
                };
                $http.delete("/saphira/api/mwd/templates", {
                    params: params
                }).then(function (data, status, headers, config) {
                    UIBlocker.stop();
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    UIBlocker.stop();
                    deferred.reject(data);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };


            /**
             * This service function is for deleting a particular attachment with a given ID and parent object.
             * @param  {Object} parentObject This is the fully qualified parent object who's child the fileID is. (aka: surveySet, trajectory, well, etc.);
             * @return {Promise}              Returns a promise that resolves when the HTTP request is finished.
             */
            DataService.deleteAttachmentById = function (parentObjectType, parentObjectId, fileID) {
                var deferred = $q.defer();
                var params = {
                    parentId: parentObjectId,
                    parentObject: parentObjectType,
                    id: fileID
                };
                UIBlocker.start();
                $http.delete("/saphira/api/attachments", {
                    params: params
                }).then(function (data, status, headers, config) {
                    UIBlocker.stop();
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    UIBlocker.stop();
                    deferred.reject(data);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };
            /**
             * This service function is for getting all attachments, given a parent id and a parent common name (aka: surveySet, Trajectory, etc.);
             * @param  {String} parentID The id of the parent object
             * @param {String} parentCommonname The common string-valued name (a la: surveySet, trajectory, w/e) of the parent object
             * @return {Promise}              Returns a promise that resolves when the HTTP request is finished.
             */
            DataService.getAllAttachmentsByParentID = function (parentID, parentCommonName) {
                var deferred = $q.defer();
                var params = {
                    parentId: parentID,
                    parentObject: parentCommonName
                };
                UIBlocker.start();
                $http.get("/saphira/api/attachments/search/findByParent", {
                    params: params
                }).then(function (data, status, headers, config) {
                    UIBlocker.stop();
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    UIBlocker.stop();
                    deferred.reject(status);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };


            /**
             * This function downloads an attachment from the server, and then 'auto clicks' it (forcing a download) it only downloads it if we get a 200 OK from the server
             * when accessing the file ID as a url
             * @param  {Sting} parentObject This is the parent object common name of the file (aka: SurveySet, Trajectory, etc.)
             * @param  {String} parentId     This is the id of the parent object, string-valued
             * @param  {String} fileId       This is the string-valued id of the actual file to be downloaded. should correspond to a fs.file object
             * @return {JS Promise}              A promise which represents the http transaction for downloading the attachment.
             */
            DataService.downloadAttachment = function (parentObject, parentId, fileId) {
                var params = {
                    parentId: parentId,
                    parentObject: parentObject,
                    id: fileId
                };
                UIBlocker.start();
                return $http.get(URLS.downloadAttachment, {
                    params: params
                }).then(function (data) {
                    var anchor = angular.element('<a/>');
                    anchor.attr({
                        href: URLS.downloadAttachment + "?id=" + params.id + "&parentId=" + params.parentId + "&parentObject=" + parentObject,
                        target: '_blank',
                        download: data.name
                    });
                    anchor[0].dispatchEvent(new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': false
                    }));
                    UIBlocker.stop();
                }, function (error) {
                    UIBlocker.stop();
                    throw error; // Stay on the failed end of the promise chain.
                });
            };

            /**
             * getAnalysis
             *
             *  Gets the survey set analysis for the given surveyset id. Appends the given comment to the analysis.
             *
             * @param surveySetId
             * @param comment
             */
            DataService.getAnalysis = function (surveySetId, comment) {
                var params = {
                    surveySetId: surveySetId,
                    comment: comment
                };

                UIBlocker.start();
                return $http.get(URLS.report, {params: params}).then(function (analysis) {
                    UIBlocker.stop();
                    return analysis.data;
                }, function (error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };

            DataService.getMultiAnalysis = function(surveySets) {
                var params = {
                    surveySetIds: surveySets
                };

                UIBlocker.start();
                return $http.get(URLS.multiReport, {params: params}).then(function (analysis) {
                    UIBlocker.stop();
                    return analysis.data;
                }, function (error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };

            DataService.updateComments = function(commentList) {

                UIBlocker.start();
                return $http.post(URLS.multiReportUpdate, commentList).then(function (response) {
                    UIBlocker.stop();
                    return response;
                }, function (error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };

            DataService.getCorrectedSurveyReport = function(trajectoryId) {
                var params = {
                    trajectoryId : trajectoryId
                };

                var anchor = angular.element('<a/>');
                anchor.attr({
                    href: URLS.correctedSurveyReport + "?trajectoryId=" + params.trajectoryId,
                    target: '_blank'
                });
                anchor[0].dispatchEvent(new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                }));

            };

            /**
             * This function creates an invisible link and forces a download to the client of any data sent to it.
             * @param  {String} fileName     The name of the file, including extension
             * @param  {String} content      This is the data to be included within the file. Requires proper data header ie: data:text/csv;charset=utf-8,
             */
            DataService.downloadData = function (fileName, content) {
                var encodedUri = encodeURI(content);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", fileName);
                document.body.appendChild(link); // Required for FF
                link.click();
            };


            /**
             * Simply a wrapper to our function call 'getAllusersFromCrowd' does exactly what you'd imagine.. gets all the users.
             * @return {The HTTP request promise} just a standard $http.get request
             */
            DataService.getAllUsers = function () {
                return $http.get("/saphira/api/utils/getAllUsersFromCrowd");
            };

            /**
             * Simply a wrapper to our function call 'getAllGroupsFromCrowd' does exactly what you'd imagine.. gets all the groups.
             * @return {The HTTP request promise} just a standard $http.get request
             */
            DataService.getAllGroups = function () {
                return $http.get("/saphira/api/utils/getAllGroupsFromCrowd");
            };

            /**
             * This function is for creating a brand new object on the server. it assumes you've already constructed it, and are just wanting to add the thing.
             * @param  {JS object} objectToCreate the object to create
             */
            DataService.createObject = function (objectToCreate) {
                if (objectToCreate === undefined || objectToCreate.commonName === undefined || objectToCreate.commonName === "") {
                    return;
                }
                var json = {};
                json[(objectToCreate.commonName === "SurveySet" ? "surveySet" : objectToCreate.commonName.toLowerCase())] = objectToCreate;
                var url = "/saphira/api/repositories/" + (objectToCreate.commonName === "SurveySet" ? "surveySet" : objectToCreate.commonName.toLowerCase()) + "/add";
                return $http.post(url, objectToCreate);
            };

            DataService.getObject = function (objectType, objectId) {
                if (objectType === undefined || objectId === undefined) {
                    return $q.reject();
                }
                var url = "/saphira/api/repositories/" + (objectType === "SurveySet" ? "surveySet" : objectType.toLowerCase()) + "/getOne";
                return $http.get(url, {"params": {"id": objectId}}).then(function (response) {
                    return response;
                }, function (error) {
                    return $q.reject(error);
                });
            };

            /**
             * This function is for updating an object on the server. it assumes you've already constructed it, and are just wanting to update the thing.
             * @param  {JS object} objectToUpdate the object to update
             */
            DataService.updateObject = function (objectToUpdate) {
                if (objectToUpdate === undefined || objectToUpdate.commonName === undefined || objectToUpdate.commonName === "") {
                    return;
                }
                var json = {};
                json[(objectToUpdate.commonName === "SurveySet" ? "surveySet" : objectToUpdate.commonName.toLowerCase())] = objectToUpdate;
                var url = "/saphira/api/repositories/" + (objectToUpdate.commonName === "SurveySet" ? "surveySet" : objectToUpdate.commonName.toLowerCase()) + "/update";
                return $http.post(url, json);
            };

            /**
             * This method is for deleting an object by common name and ID. pretty straightforward.
             * @param  {String} commonName the common name of the object to delete.
             * @param  {String} id         The string ID of the object to delete
             * @return {JS promise}            the promise relating to the actual HTTP request.
             */
            DataService.deleteObject = function (commonName, id) {
                if (commonName === undefined || commonName === "") {
                    return;
                }
                var params = {
                    id: id
                };
                var url = "/saphira/api/repositories/" + (commonName === "SurveySet" ? "surveySet" : commonName.toLowerCase()) + "/delete";
                return $http.delete(url, {
                    params: params
                });
            };

            /**
             * This function simply wraps our endpoint on the WellController that adds shift notes to a well.
             * @param  {String} wellId     The ID of the well
             * @param  {String} shiftNotes The shiftNotes  (String values) to be saved)
             * @return {Http Promise}            The $http request returns a promise.
             */
            DataService.updateShiftNotes = function (wellId, shiftNotes) {
                //because we're not sending json, we need to set a custom header... Meh.
                var req = {
                    method: 'POST',
                    url: '/saphira/api/repositories/well/' + wellId + '/shiftNotes',
                    headers: {
                        'Content-Type': "text/plain"
                    },
                    data: shiftNotes
                };
                return $http(req);
            };

            /**
             * This function simply wraps our endpoint on the WellController that adds rig contacts to a well.
             * @param  {String} wellId      The ID of the well
             * @param  {String} rigContacts    The rigContacts  (String values) to be saved
             * @return {Http Promise}       The $http request returns a promise.
             */
            DataService.updateRigContacts = function (wellId, rigContacts) {
                var req = {
                    method: 'POST',
                    url: '/saphira/api/repositories/well/' + wellId + '/rigContacts',
                    headers: {
                        'Content-Type': "text/plain"
                    },
                    data: rigContacts
                };
                return $http(req);
            };

            /**
             * This is kind of a funny function. It iterates through all of our objects, and then does a 'getAll' call to each one of them. We keep an internal counter, and don't resolve
             * out the promise until we've gotten all the data from all of the objects.
             */
            DataService.getAllDataUserCanSeeFromServer = function () {
                var deferred = $q.defer();
                var numberOfDatatypes = 7;
                var i = 0;
                var wholeList = {};
                var API_PREFIX = "/saphira/api/repositories/";
                var dataTypeList = ["company", "field", "pad", "well", "wellbore", "trajectory", "surveySet"];
                dataTypeList.forEach(function (dataType) {
                    if (i == -1) {
                        return;
                    }
                    $http.get(API_PREFIX + dataType + "/getAll").then(function (response) {
                        wholeList[dataType] = response.data;
                        i++;
                        if (i === numberOfDatatypes) {
                            deferred.resolve(wholeList);
                        }
                        ;
                    }, function (error) {
                        deferred.reject(error);
                        i = -1;
                        return;
                    });
                });
                return deferred.promise
            };

            return DataService;
        }]);
})();
