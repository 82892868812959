/**
 * Unit filters. Changes Enum names to more legible names
 */
(function () {
    var app = angular.module('saphira');

    app.filter('orderSurveysByMd', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (items, reverse) {
                var filtered = [];
                angular.forEach(items, function (item) {
                    filtered.push(item);
                });
                filtered.sort(function (a, b) {
                    return (a.md.value > b.md.value ? 1 : -1);
                });
                if (reverse) filtered.reverse();
                return filtered;
            }
        }]);

})();
