(function () {

    var app = angular.module('saphira');

    app.controller('MultiReportModalController', [
        /* Angular Modules  */ '$scope', '$uibModal', '$uibModalInstance', '$timeout', "$q",
        /* 3rd Party Modules*/ 'SweetAlert',
        /* Internal Modules */ 'HighchartsPlottingService','DataService',
        /* Input            */ 'analyses', 'reportParams', 'acSigma', 'header',

        function (/* Angular Modules  */ $scope, $uibModal, $uibModalInstance, $timeout, $q,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ HighchartsPlottingService,DataService,
                  /* Input            */ analyses, reportParams, acSigma, header) {

            //Assign the messages to be displayed in the modals

            $scope.analyses = analyses;
            $scope.reportParams = reportParams;
            $scope.header = header;
            $scope.currentSurvey = null;
            $scope.unitSet = analyses && analyses[0] ? analyses[0].unitSet : null; //The only differences would be gravity and magnetic units and we don't handle those here.

            var defaultGraphsToNull = function() {
                $scope.surCorrPieGraph = null;

                $scope.surCorrFilePieGraph = null;

                for (var i in analyses) {
                    if (analyses.hasOwnProperty(i)) {
                        var analysis = analyses[i];

                        analysis.sigmaAllGraph = null;
                        analysis.bTotalAllGraph = null;
                        analysis.gTotalAllGraph = null;
                        analysis.dipAllGraph = null;

                        analysis.incCorrGraph = null;
                        analysis.aziCorrGraph = null;

                        analysis.surCorrGraph = null;

                        analysis.surCorrFileGraph = null
                    }
                }
            };

            var getImgFromChart = function (chart) {
                var imageWidth = 1000;
                var imageHeight = imageWidth * chart.chartHeight / chart.chartWidth;

                var svg = chart.getSVG({
                    exporting: {
                        sourceWidth: chart.chartWidth,
                        sourceHeight: chart.chartHeight
                    }
                });

                var canvas = document.createElement('canvas');
                canvas.height = imageHeight;
                canvas.width = imageWidth;

                var image = new Image;
                image.onload = function () {
                    canvas.getContext('2d').drawImage(this, 0, 0, imageWidth, imageHeight);
                };
                image.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)));
                return image;
            };

            var getPrintSection = function () {
                var $printSection = null;
                $printSection = document.getElementById("printSection");

                if (!$printSection) {
                    $printSection = document.createElement("div");
                    $printSection.id = "printSection";
                    document.body.appendChild($printSection);
                }
                return $printSection;
            };

            var printElement = function (elem, append, delimiter) {
                var domClone = elem.cloneNode(true);

                var $printSection = getPrintSection();

                if (append !== true) {
                    $printSection.innerHTML = "";
                }
                else {
                    if (typeof(delimiter) === "string") {
                        $printSection.innerHTML += delimiter;
                    }
                    else if (typeof(delimiter) === "object") {
                        $printSection.appendChild(delimiter);
                    }
                }

                $printSection.appendChild(domClone);
            };

            var clearPrint = function () {
                var $printSection = getPrintSection();
                $printSection.innerHTML = "";
            };

            $scope.saveEdits = function (analyses) {

                $scope.editMode = false;
                $scope.analysesBackup = angular.copy(analyses);

                var commentList=[];
                for (var i in $scope.analyses) {
                    commentList.push({"surveySetId":$scope.analyses[i].surveySet.id, "message": $scope.analyses[i].comment});
                }

                DataService.updateComments(commentList).then(
                    function (responseString) {
                        SweetAlert.swal("Success!", "Edits saved successfully!!", "success");
                    }, function (error) {
                        SweetAlert.swal("Error", "Error saving edits", "error");
                    }
                );

            }

            $scope.cancelEdits = function (){

                $scope.editMode = false;
                $scope.analyses = $scope.analysesBackup;

            }

            $scope.editComment = function(){
                $scope.editMode = true;
                $scope.analysesBackup = angular.copy($scope.analyses);
            }

            $scope.printReport = function () {

                printElement(document.getElementById("printableMultiSurveyReport"));

                for (var i in analyses) {
                    if (analyses.hasOwnProperty(i)) {
                        var analysis = analyses[i];
                        if (reportParams.qcPlots || reportParams.correctedPlots) {
                            var lineBreak = document.createElement("hr");
                            var plotSectionTitle = document.createElement("div");
                            plotSectionTitle.style.cssText = "text-align: center; font-size: xx-large";
                            plotSectionTitle.innerHTML = analysis.surveySet.name;
                            printElement(lineBreak, true);
                            printElement(plotSectionTitle, true);
                        }

                        if (reportParams.qcPlots) {
                            var sigmaPlot = getImgFromChart(analysis.sigmaAllGraph.chart);
                            var bTotalPlot = getImgFromChart(analysis.bTotalAllGraph.chart);
                            var gTotalPlot = getImgFromChart(analysis.gTotalAllGraph.chart);
                            var dipPlot = getImgFromChart(analysis.dipAllGraph.chart);
                            printElement(sigmaPlot, true, "<br/>");
                            printElement(bTotalPlot, true);
                            printElement(gTotalPlot, true);
                            printElement(dipPlot, true);
                        }

                        if (reportParams.correctedPlots) {
                            var incPlot = getImgFromChart(analysis.incCorrGraph.chart);
                            var aziPlot = getImgFromChart(analysis.aziCorrGraph.chart);
                            printElement(incPlot, true, "<br/>");
                            printElement(aziPlot, true);
                        }

                        if (reportParams.surveyCorrectionTimeFromSurveyCreation) {
                            var surCorrPlot = getImgFromChart(analysis.surCorrGraph.chart);
                            printElement(surCorrPlot, true);
                        }

                        if (reportParams.surveyCorrectionTimeFromFileUpload) {
                            var surCorrFilePlot = getImgFromChart(analysis.surCorrFileGraph.chart);
                            printElement(surCorrFilePlot, true);
                        }
                    }
                }

                if (reportParams.surveyCorrectionTimeFromSurveyCreation) {
                    var surCorrPiePlot = getImgFromChart($scope.surCorrPieGraph.chart);
                    printElement(surCorrPiePlot, true);
                }

                if (reportParams.surveyCorrectionTimeFromFileUpload) {
                    var surCorrFilePiePlot = getImgFromChart($scope.surCorrFilePieGraph.chart);
                    printElement(surCorrFilePiePlot, true);
                }

                $timeout(function () {
                    window.print();
                    clearPrint();
                }, 100);
            };

            $scope.qcPlotsMade = function (analysis) {
                return (analysis.sigmaAllGraph !== null && analysis.bTotalAllGraph !== null
                    && analysis.gTotalAllGraph !== null && analysis.dipAllGraph !== null);
            };

            $scope.diffPlotsMade = function (analysis) {
                return (analysis.incCorrGraph !== null && analysis.aziCorrGraph !== null);
            };

            $scope.surCorrPlotsMade = function (analysis) {
                return (analysis.surCorrGraph !== null);
            };

            $scope.surCorrPiePlotsMade = function () {
                return ($scope.surCorrPieGraph !== null);
            };

            $scope.surCorrFilePlotsMade = function (analysis) {
                return (analysis.surCorrFileGraph !== null);
            };

            $scope.surCorrFilePiePlotsMade = function () {
                return ($scope.surCorrFilePieGraph !== null);
            };

            $scope.close = function () {
                $uibModalInstance.close('close');
            };

            var createQcPlots = function() {
                for (var i in analyses) {
                    if (analyses.hasOwnProperty(i) && analyses[0].surveys.length > 0) {
                        var analysis = analyses[i];
                        if (analysis.sigmaAllGraph === null) {
                            analysis.sigmaAllGraph = {chart: {}, options: {}};
                            analysis.sigmaAllGraph.options = HighchartsPlottingService.Sigma.plotMultiSigma(analysis.surveys, analysis.unitSet, acSigma);
                        }
                        if (analysis.bTotalAllGraph === null) {
                            analysis.bTotalAllGraph = {chart: {}, options: {}};
                            analysis.bTotalAllGraph.options = HighchartsPlottingService.BTotal.plotMultiBTotal(analysis.surveys, analysis.unitSet, acSigma);
                        }
                        if (analysis.dipAllGraph === null) {
                            analysis.dipAllGraph = {chart: {}, options: {}};
                            analysis.dipAllGraph.options = HighchartsPlottingService.Dip.plotMultiDip(analysis.surveys, analysis.unitSet, acSigma);
                        }
                        if (analysis.gTotalAllGraph === null) {
                            analysis.gTotalAllGraph = {chart: {}, options: {}};
                            analysis.gTotalAllGraph.options = HighchartsPlottingService.GTotal.plotMultiGTotal(analysis.surveys, analysis.unitSet, acSigma);
                        }
                    }
                }
            };

            var createCorrectedPlots = function() {
                for (var i in analyses) {
                    if (analyses.hasOwnProperty(i) && analyses[0].surveys.length > 0) {
                        var analysis = analyses[i];
                        if (analysis.incCorrGraph === null) {
                            analysis.incCorrGraph = {chart: {}, options: {}};
                            analysis.incCorrGraph.options = HighchartsPlottingService.Inc.plotMsaInc(analysis.surveys, analysis.unitSet, analysis.surveySet.msaDeltaInc);
                        }
                        if (analysis.aziCorrGraph === null) {
                            analysis.aziCorrGraph = {chart: {}, options: {}};
                            analysis.aziCorrGraph.options = HighchartsPlottingService.Azi.plotMsaAzi(analysis.surveys, analysis.unitSet, analysis.surveySet.msaDeltaAzi);
                        }
                    }
                }
            };

            var getCurrentSurvey = function() {
                var surveys = [];

                //Add all the surveys to a single list we can iterate through.
                for (var i in analyses) {
                    if (analyses.hasOwnProperty(i)) {
                        surveys = surveys.concat(analyses[i].surveys);
                    }
                }

                //Grab the deepest survey with corrections
                $scope.currentSurvey = surveys.length > 0 ? surveys[0] : null;
                for (var j = 0; j < surveys.length; j++) {
                    if (surveys[j].correctedSixAxisDatas && surveys[j].correctedSixAxisDatas.MULTISTATION && surveys[j].md.value > $scope.currentSurvey.md.value) {
                        $scope.currentSurvey = surveys[j];
                    }
                }
            };

            var createSurrCorrTimePlots = function() {
                for (var i in analyses) {
                    if (analyses.hasOwnProperty(i) && analyses[0].surveys.length > 0) {
                        var analysis = analyses[i];
                        if (analysis.surCorrGraph === null) {
                            analysis.surCorrGraph = {chart: {}, options: {}};
                            analysis.surCorrGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotSurveyCorrectionTimes(analysis.surveys, analysis.surveySet.unitSet, analysis.surveySet.name, "receivedTime");
                        }
                    }
                }

                if ($scope.surCorrPieGraph === null) {
                    $scope.surCorrPieGraph = {chart: {}, options: {}};
                    $scope.surCorrPieGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotMultiPieSurveyCorrectionTimes(analyses, "receivedTime");
                }
            };

            var createSurrCorrTimeFilePlots = function() {
                for (var i in analyses) {
                    if (analyses.hasOwnProperty(i) && analyses[0].surveys.length > 0) {
                        var analysis = analyses[i];
                        if (analysis.surCorrFileGraph === null) {
                            analysis.surCorrFileGraph = {chart: {}, options: {}};
                            analysis.surCorrFileGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotSurveyCorrectionTimes(analysis.surveys, analysis.surveySet.unitSet, analysis.surveySet.name, "fileUploadTime");
                        }
                    }
                }

                if ($scope.surCorrFilePieGraph === null) {
                    $scope.surCorrFilePieGraph = {chart: {}, options: {}};
                    $scope.surCorrFilePieGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotMultiPieSurveyCorrectionTimes(analyses, "fileUploadTime");
                }
            };

            var init = function () {
                defaultGraphsToNull();

                if (reportParams.qcPlots) {
                    createQcPlots();
                }

                if(reportParams.correctedPlots) {
                    createCorrectedPlots();
                }

                if (reportParams.recentSurvey) {
                    getCurrentSurvey();
                }

                if (reportParams.surveyCorrectionTimeFromSurveyCreation) {
                    createSurrCorrTimePlots();
                }

                if (reportParams.surveyCorrectionTimeFromFileUpload) {
                    createSurrCorrTimeFilePlots();
                }
            };
            init();
        }
    ]);
})();
