/**
 * This controller is for handling everything to do about our 'chat' window. yay.
 */
(function () {

    var app = angular.module("saphira");

    app.controller("3dTrajectoryModalController", [
        /* Angular Modules  */ '$scope', '$uibModalInstance',
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */ 'data', 'rawData',
        function (/* Angular Modules  */ $scope, $uibModalInstance,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ data, rawData
                  /* Input            */) {

            var init = function () {
                var plotData = [];
                var plotData2 = [];
                if (data !== undefined && data !== null) {
                    data.forEach(function (survey) {
                        plotData.push([parseFloat(survey.east.value), -1 * parseFloat(survey.tvd.value),
                            parseFloat(survey.north.value)]);
                    });
                }
                if (rawData !== undefined && rawData !== null) {
                    rawData.forEach(function (survey) {
                        plotData2.push([parseFloat(survey.east.value), -1 * parseFloat(survey.tvd.value),
                            parseFloat(survey.north.value)]);
                    });
                }

                angular.element(document).ready(function () {

                    Highcharts.chart('3dPlot', {
                        title: {
                            text: '3D Plot of Definitive Trajectory'
                        },
                        plotOptions: {
                            scatter: {
                                marker: {
                                    radius: 1,
                                    enabled: true
                                }
                            }
                        },
                        yAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value * -1;
                                }
                            }
                        },
                        scrollbar: {
                            enabled: true
                        },
                        chart: {
                            margin: 100,
                            type: 'scatter',
                            options3d: {
                                enabled: true,
                                alpha: 10,
                                beta: 30,
                                depth: 250,
                                viewDistance: 5,
                                fitToPlot: false,
                                frame: {
                                    bottom: {size: 1, color: 'rgba(0,0,0,0.02)'},
                                    back: {size: 1, color: 'rgba(0,0,0,0.04)'},
                                    side: {size: 1, color: 'rgba(0,0,0,0.06)'}
                                },
                                drag: {
                                    enabled: true
                                }
                            }
                        },
                        credits: {
                            enabled: false
                        },
                        series: [{
                            lineWidth: 3,
                            name: "minimum curvature - definitive",
                            data: plotData
                        },
                            {
                                lineWidth: 3,
                                name: 'minimum curvature - raw (reported)',
                                data: plotData2,
                                color: '#ff0000'
                            }]
                    });
                });
            };
            init();
        }
    ]);
})();