(function () {

    var app = angular.module('saphira');

    app.controller('AutomationModalController', [
        /* Angular Modules  */ '$scope', '$http', '$q', '$uibModalInstance',
        /* 3rd Party Modules*/ 'SweetAlert',
        /* Internal Modules */ 'SurveySetService', 'ActivityLogService', 'StateService', 'ClientRequisitesService',
        /* Input            */

        function (/* Angular Modules  */ $scope, $http, $q, $uibModalInstance,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ SurveySetService, ActivityLogService, StateService, ClientRequisitesService
                  /* Input            */) {

            //the survey set
            $scope.surveySet = {
                data: {},
                edit: false
            };

            $scope.model = {
                loadedTemplates: ClientRequisitesService.data['MwdTemplates']
            };

            var changesMade = false;

            $scope.copyOverMetaData = function () {
                $scope.editMetaData = _.cloneDeep($scope.surveySet.data);
            };

            $scope.saveMetaDataChanges = function () {
                //Clear min/max if value is cleared (due to server unable to parse an empty string as a number)
                var correctionParameters = $scope.editMetaData.correctionParameters;
                if(correctionParameters.msaComputationRange) {
                    if(correctionParameters.msaComputationRange.min && correctionParameters.msaComputationRange.min.value === "") {
                        correctionParameters.msaComputationRange.min = null;
                    }
                    if(correctionParameters.msaComputationRange.max && correctionParameters.msaComputationRange.max.value === "") {
                        correctionParameters.msaComputationRange.max = null;
                    }
                }

                SurveySetService.save($scope.editMetaData, false, true).then(
                    function () {
                        updateSurveySet();
                        changesMade = true;
                    },
                    function () {
                        console.error('failed to save meta data changes');
                    }
                )
            };

            $scope.disableAutomationAndClose = function () {
                toggleAutomationAndClose(false);
            };

            $scope.enableAutomationAndClose = function () {
                toggleAutomationAndClose(true);
            };

            var toggleAutomationAndClose = function (enabled) {
                changesMade = true;
                $scope.copyOverMetaData();
                $scope.editMetaData.automationActive = enabled;
                $scope.editMetaData.setUnhandledMsaFailure = null;
                SurveySetService.save($scope.editMetaData).then(
                    function () {
                        swal('Automation has been ' + (enabled ? 'started' : 'stopped') + '!');
                        $uibModalInstance.close(changesMade);
                    },
                    function () {
                        console.error('error setting automation active to: ' + enabled);
                    }
                );
            };

            //the action for closing the modals
            $scope.closeModalCancel = function () {
                $uibModalInstance.close(changesMade);
            };

            var updateSurveySet = function () {
                var getSurveySetRequest = SurveySetService.get(StateService.wellboreDetailView.selectedSurveySetId);
                getSurveySetRequest.then(
                    function (result) {
                        $scope.surveySet.data = result;
                    },
                    function () {
                        console.error('failed to read the survey set: ', getSurveySetRequest);
                    }
                );
            };

            //initialize the controller by loading up the selected survey set
            var init = function () {
                updateSurveySet();
                ClientRequisitesService.init().then(function() {
                    $scope.loadedEnums = ClientRequisitesService.data;
                })
            };
            init();

        }]);
})();
