/**
 * The service responsible for basic operations on a wellbore
 */
(function () {

    var app = angular.module('saphira');

    app.factory('WellboreService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */  'UIBlocker', 'TimeService',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker, TimeService
                  /* Input            */) {

            var WellboreService = {};

            //a list of urls used by the service
            var URLS = {
                getOne: '/saphira/api/repositories/wellbore/getOne',
                update: '/saphira/api/repositories/wellbore/update',
                getManyMeta: '/saphira/api/repositories/wellbore/getManyMeta',
                getManyMetaByParent: '/saphira/api/repositories/wellbore/getManyMetaByParent',
                lastModifiedDate: '/saphira/api/repositories/wellbore/lastModifiedDate',
                getStatuses: '/saphira/api/repositories/wellbore/getStatuses'
            };

            //the loaded wellbores
            WellboreService.Loaded = {};

            /**
             * getManyMeta:
             *
             *      Returns the metas for the wellbores with the corresponding
             *  ids.  Standard authentication rules apply.
             *
             * @param wellboreIds   An array of wellbore ids
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellboreService.getManyMeta = function (wellboreIds) {
                UIBlocker.start();
                return $http.get(URLS.getManyMeta, {'params': {'id': wellboreIds}}).then(function (response) {
                    UIBlocker.stop();
                    return response.data;
                }, function (status) {
                    UIBlocker.stop();
                    return $q.reject(status);
                });
            };

            /**
             * getManyMetaByParent:
             *
             *      Returns the metas for the wellbores with the corresponding
             *  parent well id.  Standard authentication rules apply.
             *
             * @param parentId   Id of the parent well
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellboreService.getManyMetaByParent = function (parentId) {
                UIBlocker.start();
                return $http.get(URLS.getManyMetaByParent, {params: {'parentId': parentId}}).then(function (response) {
                    UIBlocker.stop();
                    return response.data;
                }, function (status) {
                    UIBlocker.stop();
                    return $q.reject(status);
                });
            };


            /**
             * get:
             *
             *      Returns the wellbore with the corresponding id.  If
             *  the wellbore is already loaded, it returns it.  If the
             *  wellbore is not yet loaded, it pulls it from the server
             *  and then stores a reference to it and returns it.
             *  Standard authentication rules apply.
             *
             * @param wellboreId     The id of the wellbore to return
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellboreService.get = function (wellboreId, forceUpdate) {
                if (forceUpdate !== true && wellboreId in WellboreService.Loaded) {
                    return $q.resolve(WellboreService.Loaded[wellboreId]);
                } else {
                    UIBlocker.start();
                    return $http.get(URLS.getOne, {'params': {'id': wellboreId}}).then(function (response) {
                        WellboreService.Loaded[response.data.id] = response.data;
                        UIBlocker.stop();
                        return response.data;
                    }, function (status) {
                        UIBlocker.stop();
                        return $q.reject(status);
                    });
                }
            };

            /**
             * save:
             *
             *      Saves the given wellbore to the server.  If the wellbore is
             *  successfully saved, it updates the service's reference to
             *  the wellbore.
             *
             * @param wellbore       The wellbore object to save
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellboreService.save = function (wellbore) {
                UIBlocker.start();
                return $http.post(URLS.update, wellbore).then(function (response) {
                    WellboreService.Loaded[wellbore.id] = wellbore;
                    UIBlocker.stop();
                    return wellbore;
                }, function (status) {
                    UIBlocker.stop();
                    return $q.reject(status);
                });
            };

            /**
             * getLastModifiedDate:
             *
             *      Gets the last modified date for the wellbore with the given
             *  wellbore id.
             *
             * @param wellboreId     The wellbore id
             *
             * @returns {*}
             */
            WellboreService.getLastModifiedDate = function (wellboreId) {
                return $http.get(URLS.lastModifiedDate, {'params': {'id': wellboreId}}).then(function (response) {
                    return response.data;
                });
            };

            /**
             * getStatuses:
             *
             *  Makes a GET request to the server to get the list of wellbore statuses.
             *
             */
            WellboreService.getStatuses = function(latestUpdate) {
                return $http.get(URLS.getStatuses, {'params': {'latestDate': latestUpdate ? TimeService.GetUTCTimestampFromInstant(latestUpdate) : latestUpdate }}).then(function(response) {
                    return response.data;
                });
            };

            /**
             * updateIfModified:
             *
             *      Updates the wellbore corresponding to the given wellbore id if
             *  the server has a more recent version. Returns a boolean indicating if a wellbore was updated or not
             *
             * @param wellboreId     The wellbore id of the wellbore to check for updates on
             * @returns {*}
             */
            WellboreService.updateIfModified = function (wellboreId) {
                if (!(wellboreId in WellboreService.Loaded)) {
                    console.error('Attempted to check for updates on a wellbore that isn\'t loaded');
                    return $q.reject('Wellbore needs to be loaded before checking for updates');
                } else {
                    return WellboreService.getLastModifiedDate(wellboreId).then(function (date) {
                        var local = WellboreService.Loaded[wellboreId];

                        if (date.seconds !== local.lastModifiedDate.seconds || date.nanos !== local.lastModifiedDate.nanos) {
                            return WellboreService.get(wellboreId, true).then(function () {
                                return true;
                            });
                        } else {
                            return false;
                        }
                    });
                }
            };

            return WellboreService;
        }]);
})();
