/**
 * This controller is responsible for the wellbore list view and the navigation of the wellbore hierarchy.
 *
 * It contains 5 filters to filter wellbores by their status, company, field, pad, and well.
 *
 * THe init() function calls the WellboreSelectionService to grab the wellbores for the current account and loads them
 * into the table.
 *
 * selectWellbore will open the chosen wellbore in a detail view
 */
(function () {

    var app = angular.module('saphira');

    app.controller('WellboreSelectionController', [
        /* Angular Modules  */ '$scope', '$filter', "$state", '$q', '$window',
        /* 3rd Party Modules*/ 'uiGridConstants', 'SweetAlert', 'lodash', 'Idle',
        /* Internal Modules */ 'WellboreSelectionService', 'StateService', 'ClientRequisitesService', 'UIBlocker', "AuthenticationService", "TimeService",
                                'CaptureAndSendAnalyticsService', 'Constants',
        /* Input            */

        function (/* Angular Modules  */ $scope, $filter, $state, $q, $window,
                  /* 3rd Party Modules*/ uiGridConstants, SweetAlert, lodash, Idle,
                  /* Internal Modules */ WellboreSelectionService, StateService, ClientRequisitesService, UIBlocker, AuthenticationService, TimeService,
                                        CaptureAndSendAnalyticsService, Constants
                  /* Input            */) {
            $scope.$on('IdleStart', function() {
                if(AuthenticationService.currentUser().isRigUser) {
                    AuthenticationService.updateDelayTimeInSession("idleStart");
                }
            });

            $scope.$on('IdleEnd', function() {
                if(AuthenticationService.currentUser().isRigUser) {
                    AuthenticationService.updateDelayTimeInSession("idleEnd");
                }
            });

            $scope.radio = {};
            $scope.selected = null;

            var companyColumn = {
                field: 'companyName',
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };
            var fieldColumn = {
                field: 'fieldName',
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };
            var padColumn = {
                field: 'padName',
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };
            var wellColumn = {
                field: 'wellName',
                enableHiding : false,
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []},
                sort: {
                    direction: uiGridConstants.ASC
                }
            };
            var wellboreColumn = {
                field: 'wellboreName',
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };
            var rigColumn = {
                field: 'rigName',
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };
            var uniqueWellIDColumn = {
                displayName: 'Unique Well ID',
                field: 'uniqueWellID',
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };
            var latestSurveyColumn = {
                field: 'latestSurveyTime',
                type: 'date',
                filterHeaderTemplate: '<div class="row" style="padding-left:10px"><div class="col-sm-6" ng-repeat="colFilter in col.filters" style="margin-top:3px;"><input type="date" ' +
                'ng-model="colFilter.term" style="font-size:11px;width:115px"/></div></div>',
                filters: [
                    {
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                    }],
                cellFilter: 'date:"MMM dd, yyyy hh:mm:ss a"',
                sortingAlgorithm: TimeService.SortByDate()
            };

            $scope.gridOptions = {
                enableFiltering: true,
                enableGridMenu: true,
                columnDefs: [
                    wellColumn, rigColumn, wellboreColumn, padColumn, fieldColumn, companyColumn, latestSurveyColumn, uniqueWellIDColumn
                ],
                data: null,
                multiSelect: false,
                modifierKeysToMultiSelect: false,
                noUnselect: true,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                appScopeProvider: $scope,
                rowTemplate: "<div ng-dblclick=\"grid.appScope.viewWellbore(row.entity)\" ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\" class=\"ui-grid-cell\" ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader }\" ui-grid-cell></div>",
                onRegisterApi: function (gridApi) {
                    //set gridApi on scope
                    $scope.gridApi = gridApi;

                    $scope.gridApi.core.on.filterChanged($scope, function () {
                        WellboreSelectionService.reconstructFilterOptions($scope.gridOptions.data, companyColumn,
                            fieldColumn, padColumn, wellColumn, rigColumn, wellboreColumn);
                    });

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        $scope.selected = row.entity;
                    });
                }
            };

            /**
             * Initialization function fo WellboreSelectionController
             * Initializes all scope fields and calls relevant service functions to prepare the view
             */
            $scope.init = function () {
                document.title = "Saphira Wellbore Select";
                AuthenticationService.getSessionStatus().then(function(loggedIn) {
                    //check if the user is logged in
                    if (!loggedIn) {
                        //if not, set a pending page and send them to log in
                        StateService.routing.pendingPage = "wellboreSelection";
                        $state.go("login");
                    }
                    else {
                        if (AuthenticationService === null || AuthenticationService === undefined ||
                            AuthenticationService.currentUser() === null ||
                            AuthenticationService.currentUser() === undefined) {
                            $state.go("login");
                            return;
                        }

                        UIBlocker.start();

                        ClientRequisitesService.init().then(function () {
                            if (AuthenticationService.currentUser().isRigUser) {
                                $scope.statuses =
                                    lodash.filter(ClientRequisitesService.data.WellboreStatuses, function (status) {
                                        return (status.includes("ACTIVE") || status.includes("REALTIME")); //Only get enums that
                                        // include
                                        // "ACTIVE"
                                    })
                            }
                            else {
                                $scope.statuses = ClientRequisitesService.data.WellboreStatuses;
                            }

                            //switch Active - Batch Process with Active - Realtime such that Active - Realtime is first
                            if ($scope.statuses[0] !== "ACTIVE_REALTIME") {
                                var temp = $scope.statuses[0];
                                $scope.statuses[0] = $scope.statuses[1];
                                $scope.statuses[1] = temp;
                            }

                            $scope.radio = {model: $scope.statuses[0]}; //default to first status in the list of statuses
                            loadSelections($scope.radio.model).then(function () {
                                UIBlocker.stop();
                            }, function (error) {
                                UIBlocker.stop();
                            });
                        }, function (error) {
                            SweetAlert.swal("Error", "Failed to load initialization data. Please refresh page to try again.", "error");
                        });
                    }
                },function(response) {
                    SweetAlert.swal("Error", "Failed to authenticate", "error");
                    $state.go("login");
                });
            };
            $scope.init(); //Initialize the controller

            var loadSelections = function (status) {
                // Capture analytics when tabs clicked in wellbore-selection
                const eventData = {
                    'tab_clicked_name': status
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_SELECTION_TAB_CLICKED, eventData);
                var getColumnFilters = WellboreSelectionService.getColumnFilters;
                status = status === undefined ? null : status;
                UIBlocker.start();
                return WellboreSelectionService.getSelections(status).then(function (data) {
                    $scope.selections = data;
                    $scope.gridOptions.data = data;
                    companyColumn.filter.selectOptions = getColumnFilters($scope.gridOptions.data, 'company');
                    WellboreSelectionService.reconstructFilterOptions($scope.gridOptions.data, companyColumn,
                        fieldColumn, padColumn, wellColumn, rigColumn, wellboreColumn);
                    UIBlocker.stop();
                    StateService.loggedIn = true;
                }, function (status) {
                    UIBlocker.stop();
                    SweetAlert.swal("Error loading wellbores", "I'm sorry, there was an error loading the" +
                        " wellbores: " + status, "error");
                });
            };

            $scope.$watch(function () {
                return $scope.radio.model;
            }, function (newValue, oldValue) {
                if (newValue !== oldValue && oldValue !== undefined) {
                    loadSelections(newValue);
                }
            });

            /**
             * Filter wellbores by well
             * @param sel
             */
            $scope.viewWellbore = function (sel) {
                // Capture analytics when View Wellbore button is clicked by selecting a well in wellbore-selection
                const eventData = {
                    'well_name': sel.wellName,
                    'button_name': 'View Wellbore'
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_SELECTION_BUTTON_CLICKED, eventData);
                //view
                $window.open("/#/wellboreDetail/" + sel.wellboreId);
            };
        }
    ]);
})();
