/**
 * A simple filter that converts 'true' to 'On' and 'false' to 'Off'.
 */
(function () {

    var app = angular.module('saphira');

    app.filter('BooleanOnOffFilter', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (value) {
                if (value) {
                    return 'On';
                }
                else {
                    return 'Off';
                }
            }
        }]);
})();