(function () {

    var app = angular.module('saphira');

    app.directive('mvDistanceUnitSelect', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return {

                restrict: 'E',      //Enforce the directive to be used as an element

                replace: true,      //Force the directive html to replace the tag

                template: '<select>' +
                '<option value="FOOT">{{ "FOOT" | distanceUnit}}</option>' +
                '<option value="KILOMETER">{{ "KILOMETER" | distanceUnit}}</option>' +
                '<option value="METER">{{ "METER" | distanceUnit }}</option>' +
                '<option value="MILE">{{ "MILE" | distanceUnit}}</option>' +
                '<option value="FOOT_US">{{ "FOOT_US" | distanceUnit}}</option>' +
                '<option value="YARD">{{ "YARD" | distanceUnit}}</option>' +
                '</select>'

            };

        }]);

})();
