(function () {

    var app = angular.module('saphira');

    app.directive('mvGravityUnitSelect', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return {

                restrict: 'E',      //Enforce the directive to be used as an element

                replace: true,      //Force the directive html to replace the tag

                template: '<select>' +
                '<option value="FT_PER_SEC_SQ">Ft Per Sec Sq</option>' +
                '<option value="G">G</option>' +
                '<option value="G_98">G 98</option>' +
                '<option value="GAL">Gal</option>' +
                '<option value="MILLI_G">Milli G</option>' +
                '<option value="MILLI_GAL">Milli Gal</option>' +
                '<option value="M_PER_SEC_SQ">M Per Sec Sq</option>' +
                '</select>'

            };

        }]);

})();