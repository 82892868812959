(function () {
    var app = angular.module("saphira");

    app.controller("MoveFieldModalController", [
        /* Angular Modules*/   "$scope", "$uibModalInstance",
        /* 3rd Party Modules*/ "SweetAlert", "lodash",
        /* Internal Modules*/  "UIBlocker", "FieldService",
        /* Input */            "modalData",

        function (/* Angular Modules */   $scope, $uibModalInstance,
                  /* 3rd Party Modules */ SweetAlert, lodash,
                  /* Internal Modules */  UIBlocker, FieldService,
                  /* Input */             modalData) {

            var field = modalData.field;
            $scope.currentCompany = modalData.company;
            $scope.selectedIndex = null;
            $scope.selectableCompanies = _.filter(modalData.companies, function(company) {return company.id !== $scope.currentCompany.id});

            /**
             * moveField
             *  Moves the field to a different company
             */
            $scope.moveField = function() {
                if($scope.selectedIndex) {
                    var selectedCompany =  $scope.selectableCompanies[$scope.selectedIndex];
                    SweetAlert.swal({
                        title: "Are you sure?",
                        text: field.name + " will be moved from " + $scope.currentCompany.name + " to " + selectedCompany.name + ".",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#419641",
                        confirmButtonText: "Yes! Move " + field.name + ".",
                        closeOnConfirm: true,
                        showLoaderOnConfirm: true
                    }, function (proceed) {
                        if (proceed) {
                            field.parentId = selectedCompany.id;
                            FieldService.save(field).then(function (success) {
                                $uibModalInstance.close("close");
                            }, function (error) {
                                SweetAlert.swal("Error", error, "error");
                            });
                        }
                    });
                }
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss("cancel");
            };


        }
    ]);
})();
