(function () {

    var app = angular.module('saphira');

/*
 * NOTE: This is a temporary directive to be replaced by mvParameterResolution soon.
 */
    app.directive('mvCorrectionParameterSelect', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return {

                restrict: 'E',      //Enforce the directive to be used as an element

                replace: true,      //Force the directive html to replace the tag

                template: '<select>' +
                '<option value="UNRESOLVED">Unresolved</option>' +
                '<option value="RESOLVED">Resolved</option>' +
                '<!--<option value="MANUAL">Manually Set</option>-->' +
                '</select>'

            };

        }]);

})();
