/**
 * This service is for querying mviw for IFR information
 * @return {[type]} [description]
 */
(function () {

    var app = angular.module('saphira');

    app.factory('MviwIfrService', [
        /* Angular Modules  */ '$http',
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */ $http
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            var MviwIfrService = {};
            var URLS = {
                getCubeIds: "/saphira/api/ifr/cube/ids"
            };

            /**
             * This retrieves the list of IFR Cube Ids from Matron
             *
             * Returns a promise to the httpQuery get()
             */
            MviwIfrService.getCubeIds = function () {
                return $http.get(URLS.getCubeIds);
            };

            return MviwIfrService;
        }]);
})();
