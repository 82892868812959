(function () {

    var app = angular.module('saphira');
    app.directive('resize', function ($window) {
        return function (scope, element) {
            var w = $window;
            var footer = document.getElementsByClassName("footer");
            var header = document.getElementById("header");
            scope.getWindowDimensions = function () {
                return {'h': w.innerHeight, 'w': w.innerWidth};
            };
            scope.$watch(scope.getWindowDimensions, function (newValue, oldValue) {
                var footer_height = angular.element(footer)[0].offsetHeight;
                var header_height = angular.element(header)[0].offsetHeight;
                var margins = 65; //TODO:: add jquery to calculate
                var new_height = newValue.h - footer_height - header_height - margins;
                if(new_height < 400)
                    return false;
                element.css('min-height', (new_height) + 'px');
            }, true);

            angular.element(w).bind('resize', function () {
                scope.$apply();
            });
        }
    })
})();