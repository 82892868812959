/**
 * Attribute value filter. Returns items that have the given value for the given attribute
 */

(function () {

    var app = angular.module('saphira');

    app.filter('attributeValue', [
        /* Angular Modules  */
        /* 3rd Party Modules*/ 'lodash',
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */ _
                  /* Input            */) {

            return function (items, attribute, value) {
                var newItems = [];
                angular.forEach(items, function (item) {
                    if (_.isEqual(item[attribute], value)) {
                        newItems.push(item);
                    }
                });
                return newItems;
            }
        }]);
})();
