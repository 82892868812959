/**
 * A service that provides helper functions for getting random data.  Useful for developing new features.
 */
(function () {

    var app = angular.module('saphira');

    app.service('RandomDataService', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */
        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            var service = {};

            /**
             * getRandomIntInRange:
             *
             * @param min
             * @param max
             * @returns (number) random integer between min and max.
             */
            service.getRandomIntInRange = function(min, max) {
                return Math.floor(Math.random() * (max - min + 1) + min);
            };

            /**
             * getRandomLoremIpsum:
             *
             *      Generates a random string made up of words from a word bank of lorem ipsum related words.
             *
             * @param numWords      The number of desired words in the string.
             * @returns {string}    A randomly generated string made up of words from a word bank of lorem ipsum.
             */
            service.getRandomLoremIpsum = function(numWords) {
                var words = ['Lorem','ipsum','dolor','sit','amet','consectetur','adipiscing','elit','Curabitur','vestibulum','laoreet','lacus','Donec','consequat','odio','nec','malesuada','dapibus','dui','diam','facilisis','diam','at','hendrerit','nulla','tellus','at','diam','Morbi','sit','amet','enim','semper','iaculis','metus','non','bibendum','ipsum','Vivamus','et','finibus','sapien','In','eu','cursus','tellus','Mauris','sed','tincidunt','nisi','Praesent','semper','ipsum','ut','ipsum','vulputate','aliquam','accumsan','orci','rhoncus','Phasellus','fermentum','iaculis','lorem','vel','tempus','Quisque','condimentum','lectus','eu','odio','maximus','ac','dignissim','lacus','imperdiet','Orci','varius','natoque','penatibus','et','magnis','dis','parturient','montes','nascetur','ridiculus','mus'];
                var string = '';
                for (var i = 0; i < numWords; i++) {
                    string += words[service.getRandomIntInRange(0, words.length)] + " ";
                }
                return string;
            };

            /**
             * getRandomTime:
             *
             * @returns {Moment} A random time in the last 600 minutes in the moment format.
             */
            service.getRandomTime = function() {
                return moment().subtract(service.getRandomIntInRange(0, 600), 'minutes');
            };

            /**
             * getRandomSurveySetState:
             *
             * @returns {string} A random survey set state.
             */
            service.getRandomSurveySetState = function() {
                return ['WAITING_SURVEY_SUBMIT', 'WAITING_CORRECTION', 'WAITING_VALIDATION', 'UNKNOWN'][service.getRandomIntInRange(0, 3)];
            };

            /**
             * getRandomBoolean:
             *
             *      Flips a coin and returns true or false based on result.
             *
             * @returns {boolean} True or false.
             */
            service.getRandomBoolean = function() {
                return [true, false][service.getRandomIntInRange(0, 1)];
            };

            return service;

        }]);
})();