(function () {

    var app = angular.module('saphira');

    app.controller('DefinitiveSurveySelectionController', [
        /* Angular Modules  */ '$scope',
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */ $scope
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            /**
             * Add's an entry into the array's used to display and record UI SurveySet Range selection information.  Ultimately this will add a row to the UI.
             */
            $scope.addSurveySetRangeSelectionField = function () {
                $scope.definitiveSurveyData.selectedSurveySetRangeData.push({});
            };

            /**
             * Remove's an entry from the array's used to display and record UI SurveySet Range selection informtion.  Ultimately this will remove a row from the UI.
             */
            $scope.removeLastSurveySetRangeSelectionField = function () {
                $scope.definitiveSurveyData.selectedSurveySetRangeData.pop();
            };

        }
    ]);
})();
