/**
 * Plots Service
 * Handles the creation of highcharts plot data from surveys (and other variables)
 */
(function () {
    var app = angular.module('saphira');

    app.factory('HighchartsPlottingService', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */ "SigmaPlottingService", "DipPlottingService", "GTotalPlottingService", "BTotalPlottingService", "InclinationPlottingService", "AzimuthPlottingService", "SurveyCorrectionTimesPlottingService",
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */ SigmaPlottingService, DipPlottingService, GTotalPlottingService, BTotalPlottingService, InclinationPlottingService, AzimuthPlottingService, SurveyCorrectionTimesPlottingService
                  /* Input            */) {

            var Service = {};

            Service.Sigma = SigmaPlottingService;
            Service.GTotal = GTotalPlottingService;
            Service.BTotal = BTotalPlottingService;
            Service.Dip = DipPlottingService;
            Service.Inc = InclinationPlottingService;
            Service.Azi = AzimuthPlottingService;
            Service.SurveyCorrectionTimes = SurveyCorrectionTimesPlottingService;

            return Service;
        }]);
})();

