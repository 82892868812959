/**
 */
(function () {
    var app = angular.module("saphira");

    app.controller("CreateSurveySetsModalController", [
        /* Angular Modules*/   "$scope", "$uibModalInstance",
        /* 3rd Party Modules*/ "SweetAlert",
        /* Internal Modules*/  "SurveySetService",
        /* Input */            "modalData", "$filter", "UIBlocker", "$timeout",

        function (/* Angular Modules */   $scope, $uibModalInstance,
                  /* 3rd Party Modules */ SweetAlert,
                  /* Internal Modules */  SurveySetService,
                  /* Input */             modalData, $filter, UIBlocker, $timeout) {
            var wellboreChildren = modalData.wellbore.children;
            $scope.surveys = $filter('filter')(wellboreChildren, {commonName: "SurveySet"});
            $scope.setSurveySetsSelection = function(){
                var selectedIdx = false;
                $scope.surveys.forEach(function (obj, idx) {
                    obj.wellboreName = 'wellboreName';
                    if (obj.id == modalData.selectedSurveySet.id) {
                        selectedIdx = idx;                      
                    }
                });
                if(selectedIdx){
                    $scope.surveys.splice(selectedIdx, 1);
                }
            };
            $scope.setSurveySetsSelection();
            $scope.selectedSurveySet = modalData.selectedSurveySet;
            var nameColumn = {
                name: 'Survey Set Name',
                field: "name"
            };
            var wellboreNameColumn = {
                field: "wellboreName",
                name: modalData.wellbore.name
            };

            $scope.gridOptions = {
                enableGridMenu: true,
                enableFiltering: true,
                columnDefs: [
                    nameColumn, wellboreNameColumn
                ],
                data: $scope.surveys,
                multiSelect: true,
                modifierKeysToMultiSelect: false,
                enableRowSelection: true,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: false,
                appScopeProvider: $scope,
                rowTemplate: "<div ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by $index\" " +
                "class=\"ui-grid-cell\" ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader }\" ui-grid-cell></div>",
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                }
            };

            $scope.getSelectedSurveySetsIds = function () {
                var surveySetsIds = [$scope.selectedSurveySet.id];
                $scope.gridApi.selection.getSelectedRows().forEach(function (obj) {
                    if (surveySetsIds.indexOf(obj.id) == -1) {
                        surveySetsIds.push(obj.id);
                    }
                });
                return surveySetsIds;
            };

            $scope.createSurveySets = function () {
                $scope.surveySetForm.$setSubmitted();
                $scope.error = false;
                var selected = $scope.getSelectedSurveySetsIds();
                if ($scope.surveySetForm.$invalid) {
                    $scope.error = 'Name is required';
                    return false;
                }
                UIBlocker.start();
                SurveySetService.createSurveySets($scope.name, selected).then(function (response) {
                    UIBlocker.stop();
                    SweetAlert.swal("Success!", "Copy of survey set was successfully created!!", "success");
                    $uibModalInstance.close(response);
                }, function (error) {
                    UIBlocker.stop();
                    SweetAlert.swal("Error!",
                        "There was a problem creating the copy of survey set.",
                        "error");
                });
            };
        }
    ]);
})();
