/**
 * This service is for dealing with all things 'theme'
 * @return {Factory} our angular constructed theme service factory thing.
 */
(function () {

    var app = angular.module('saphira');

    app.factory('ThemeService', ["DefaultThemesService", "$filter", "$window",

        function (DefaultThemesService, $filter) {
            var ThemeService = {};
            ThemeService.defaultThemes = DefaultThemesService.themes;
            var themeDefault = $filter('filter')(ThemeService.defaultThemes, {name: "Default"}, true)[0];
            ThemeService.defaultTheme = themeDefault || ThemeService.defaultThemes[0];
            ThemeService.numberOfSupportedThemeOptions = Object.keys(ThemeService.defaultTheme.colors).length;
            ThemeService.themes = JSON.parse(localStorage.getItem('themes')) || ThemeService.defaultThemes;

            ThemeService.initColors = function () {
                if (ThemeService.themes !== undefined && ThemeService.themes !== null) {
                    var selectedTheme = ThemeService.defaultTheme;
                    if (selectedTheme.colors === undefined) {
                        selectedTheme = ThemeService.defaultTheme;
                    }
                    ThemeService.createAndApplyThemes(selectedTheme.colors);
                }
            };

            /**
             * This function is the primary function for this service. It takes in a json object of colors, and adds them to the DOM. it removes old colors anytime it encounters a dupe.
             * It currently sets the theme to be saved to a cookie that expires every 6 months. This gets refreshed anytime you open the page, however.
             * @param  {JS Object} colors the colors to be saved.
             */
            ThemeService.createAndApplyThemes = function (colors) {

                removeStyle('.theme-class');
                var darker, lighter;
                if (colors.mainBackgroundColor !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.mainBackgroundColor, -0.2);
                    createCss("mv-background", ".mv-background, .modal-content, .panel-body", "background-color:" + colors.mainBackgroundColor + "!important");
                    createCss("mv-highcharts-bg", ".mv-highcharts-bg, .highcharts-background", "fill:" + ThemeService.ColorLuminance(colors.mainBackgroundColor, 0.9) + "!important");
                    createCss("mv-sub-bg", ".mv-sub-bg, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus",
                        "background: " + ThemeService.ColorLuminance(colors.mainBackgroundColor, -0.08) + ";");
                    createCss("panelHeadingBg", ".mv-panel-head-bg, .panel-default > .panel-heading",
                        "background-image: linear-gradient(to bottom, " + ThemeService.ColorLuminance(colors.mainBackgroundColor, -0.08) + " 0%, " + darker + " 100%)");
                    createCss("mv-border-color", ".mv-border-color, .modal-footer, .pagination > li > a, .ui-grid, hr, .table-bordered, .company-tabset, .nav-tabs > .active > a, " +
                        ".nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus, .nav-tabs, .panel-default, .panel-default > .panel-heading + .panel-collapse > .panel-body",
                        "border-color:" + darker + " !important");
                    createCss("mv-border-btm-color", ".mv-border-btm-color, .nav > li > a", "border-bottom-color:" + darker + " !important");
                }
                if (colors.mainTextColor !== undefined) {
                    createCss("mv-main-text", ".mv-main-text, .highcharts-menu-item, a.mv-main-text:hover", "color:" + colors.mainTextColor + " !important");
                }

                if (colors.headerBackgroundColor !== undefined) {
                    createCss("mv-header-background", ".mv-header-background, .modal-header", "background-color:" + colors.headerBackgroundColor);
                    createCss("mv-header-border", ".mv-header-border, .modal-header", "border-color:" + ThemeService.ColorLuminance(colors.headerBackgroundColor, -0.2));
                }
                if (colors.headerTextColor !== undefined) {
                    createCss("mv-header-text", ".mv-header-text, .modal-header, .modal-header .close", "color:" + colors.headerTextColor + " !important");
                }
                if (colors.tableHeaderBackgroundColor !== undefined) {
                    createCss("mv-table-header-bg-border", ".mv-table-header-bg, .mv-table-header-background  > tbody > tr > td.active, " +
                        ".mv-table-header-background  > thead > tr > td.active," +
                        ".mv-table-header-background  > tbody > tr > th.active, .mv-table-header-background  > thead > tr > th.active," +
                        ".ui-grid-header-cell-wrapper, .ui-grid-header, .ui-grid-header-cell, .ui-grid-top-panel",
                        "border-color:" + ThemeService.ColorLuminance(colors.tableHeaderBackgroundColor, -0.2) + "; " +
                        "background: " + colors.tableHeaderBackgroundColor);
                }
                if (colors.tableHeaderTextColor !== undefined) {
                    createCss("table-header-text", ".table-header-text, .ui-grid-header-cell-wrapper, .mv-table-header-text > tbody > tr > td.active," +
                        ".mv-table-header-text > thead > tr > td.active, .mv-table-header-text > tbody > tr > th.active," +
                        ".mv-table-header-text > thead > tr > th.active", "color:" + colors.tableHeaderTextColor + " !important");
                }
                if (colors.tableRowColor !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.tableRowColor, -0.2);
                    createCss("mv-table-background", ".mv-table-background", "background-color:" + colors.tableRowColor + " !important");
                    createCss("tableRowOddColor", ".table-row-odd, .ui-grid-canvas, .ui-grid-row:nth-child(odd) .ui-grid-cell", "background-color:" + colors.tableRowColor);
                    createCss("tableRowEvenColor", ".table-row-even, .ui-grid-row:nth-child(even) .ui-grid-cell", "background-color:" + ThemeService.ColorLuminance(colors.tableRowColor, -0.05));
                    createCss("tableRowBorderColor", ".table-row-border, .table-bordered > tbody > tr > td, .ui-grid-row .ui-grid-cell", "border-color:" + darker);
                    createCss("tableRowSelectedColor", ".table-selected-row, .ui-grid-row.ui-grid-row-selected>[ui-grid-row]>.ui-grid-cell",
                        "background-color:" + darker + '!important');
                }
                if (colors.tableEditedColor !== undefined) {
                    createCss("tableRowEvenEditedColor", ".mv-table-edited-row-even, .editTable .ui-grid-row:nth-child(odd) .ui-grid-cell",
                        "background-color:" + ThemeService.ColorLuminance(colors.tableEditedColor, -0.1) + '!important');
                    createCss("tableRowOddEditedColor", ".mv-table-edited-row-odd, .editTable .ui-grid-row:nth-child(even) .ui-grid-cell",
                        "background-color:" + colors.tableEditedColor + '!important');
                }
                if (colors.tableTextColor !== undefined) {
                    createCss("mv-table-text", ".mv-table-text, .ui-grid-cell", "color:" + colors.tableTextColor + " !important");
                }
                if (colors.iconsColor !== undefined) {
                    createCss("highchartsBtnSymbol", ".mv-highcharts-button-symbol, .highcharts-button-symbol", "stroke:" + colors.iconsColor + " !important");
                    createCss("iconsColor", ".mv-icons-color, .glyphicon", "color:" + colors.iconsColor + " !important");
                }
                if (colors.editIconColor !== undefined) {
                    createCss("editIconColor", ".mv-icons-color-edit, .glyphicon.glyphicon-pencil", "color:" + colors.editIconColor + " !important");
                }
                if (colors.saveIconColor !== undefined) {
                    createCss("saveIconColor", ".mv-icons-color-save, .glyphicon.glyphicon-ok", "color:" + colors.saveIconColor + " !important");
                }
                if (colors.discardIconColor !== undefined) {
                    createCss("discardIconColor", ".mv-icons-color-discard, .glyphicon.glyphicon-remove", "color:" + colors.discardIconColor + " !important");
                }
                if (colors.btnBgColor !== undefined) {
                    createCss("btnBgColor", ".mv-btn-bg, .btn-primary", " background-image: linear-gradient(to bottom, " +
                        ThemeService.ColorLuminance(colors.btnBgColor, 0.35) + " 0%," + colors.btnBgColor + " 100%) !important; " +
                        "border-color: " + colors.btnBgColor + " !important");
                    createCss("btnHoverColor", ".mv-btn-bg:hover, .btn-primary:hover, .btn-primary:focus", " background-color: " +
                        colors.btnBgColor + " !important");
                    createCss("btnActiveColor", ".mv-active-btn-bg, .statusButtons label.active, .btn-primary.active, .pagination > .active > a, " +
                        ".pagination > .active > a:hover, .pagination > .active > a:focus", "background-color:" +
                        ThemeService.ColorLuminance(colors.btnBgColor, -0.2) + " !important; background-image: none!important");
                }
                if (colors.btnSuccessBgColor !== undefined) {
                    createCss("btnSuccessBgColor", ".mv-btn-success-bg, .btn-success", " background-image: linear-gradient(to bottom, " +
                        ThemeService.ColorLuminance(colors.btnSuccessBgColor, 0.35) + " 0%," + colors.btnSuccessBgColor + " 100%) !important; " +
                        "border-color: " + colors.btnSuccessBgColor + " !important");
                    createCss("btnSuccessHoverColor", ".mv-btn-success-bg:hover, .btn-success:hover, .btn-success:focus", " background-color: " +
                        colors.btnSuccessBgColor + " !important");
                }
                if (colors.btnWarningBgColor !== undefined) {
                    createCss("btnWarningBgColor", ".mv-btn-warning-bg, .btn-warning", " background-image: linear-gradient(to bottom, " +
                        ThemeService.ColorLuminance(colors.btnWarningBgColor, 0.35) + " 0%," + colors.btnWarningBgColor + " 100%) !important; " +
                        "border-color: " + colors.btnWarningBgColor + " !important");
                    createCss("btnWarningHoverColor", ".mv-btn-warning-bg:hover, .btn-warning:hover, .btn-warning:focus", " background-color: " +
                        colors.btnWarningBgColor + " !important");
                }
                if (colors.btnDangerBgColor !== undefined) {
                    createCss("btnDangerBgColor", ".mv-btn-danger-bg, .btn-danger", " background-image: linear-gradient(to bottom, " +
                        ThemeService.ColorLuminance(colors.btnDangerBgColor, 0.35) + " 0%," + colors.btnDangerBgColor + " 100%) !important; " +
                        "border-color: " + colors.btnDangerBgColor + " !important");
                    createCss("btnDangerHoverColor", ".mv-btn-danger-bg:hover, .btn-danger:hover, .btn-danger:focus", " background-color: " +
                        colors.btnDangerBgColor + " !important");
                }
                if (colors.btnColor !== undefined) {
                    createCss("btnColor", ".mv-btn-color, .btn-primary, .pagination > .active > a, .pagination > .active > a:hover," +
                        " .pagination > .active > a:focus", "color:" + colors.btnColor + " !important");
                }
                if (colors.inputsBg !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.inputsBg, -0.1);
                    createCss("inputsBg", ".mv-input-bg, input, select, textarea, .dropdown-menu, .highcharts-menu",
                        "background : " + colors.inputsBg + " !important");
                    createCss("inputsBgInteraction", ".mv-input-darker-bg, .dropdown-menu > li > a:hover, .dropdown-menu .divider, " +
                        ".highcharts-menu hr, .highcharts-menu-item:hover", 'background : ' + darker + " !important;" +
                        "border-color:" + darker + "!important;");
                    createCss("inputsBorders", ".mv-input-border, .form-control, .color-picker-wrapper .input-group .input-group-addon, " +
                        "select.ui-grid-filter-select", "border-color : " + ThemeService.ColorLuminance(colors.inputsBg, -0.2));
                }
                if (colors.inputsTextColor !== undefined) {
                    lighter = ThemeService.ColorLuminance(colors.inputsTextColor, 0.5);
                    createCss("mv-inputs-text", ".form-control, .mv-inputs-text, input, select, textarea", "color:" + colors.inputsTextColor);
                    createCss("mv-main-text-webkit-placeholder", "::-webkit-input-placeholder", "color:" + lighter + " !important");
                    createCss("mv-main-text-mozz-placeholder", "::-moz-placeholder", "color:" + lighter + " !important");
                    createCss("mv-main-text-ms-placeholder", ":-ms-input-placeholder", "color:" + lighter + " !important");
                    createCss("mv-main-text-moz-placeholder", ":-moz-placeholder", "color:" + lighter + " !important");
                }
                if (colors.defaultBtnBg !== undefined) {
                    lighter = ThemeService.ColorLuminance(colors.defaultBtnBg, 0.35);
                    darker = ThemeService.ColorLuminance(colors.defaultBtnBg, -0.2);
                    createCss("defaultBtnBg", ".mv-default-btn-bg-border, .btn-default", " background-image: linear-gradient(to bottom, " + lighter + " 0%,"
                        + colors.defaultBtnBg + " 100%) !important; " + "border-color: " + ThemeService.ColorLuminance(colors.defaultBtnBg, -0.1) + " !important;" +
                        "text-shadow: 0 1px 0 " + lighter + ";");
                    createCss("highchartsBtnNormal", ".highcharts-button-box", "fill:" + colors.defaultBtnBg + "!important;");
                    createCss("defaultBtnBgHover", ".btn-default:hover, .btn-default:focus", " background-color: " + colors.defaultBtnBg + " !important");
                    createCss("defaultBtnActive", ".btn-default.active", " background-color: " + colors.defaultActiveBtnBg + " !important; color: white !important; background-image: none !important;");

                }
                if (colors.linksColor !== undefined) {
                    createCss("linksColor", ".mv-links-color, a", "color: " + colors.linksColor + " !important");
                    createCss("linksHover", ".mv-links-color:hover, a:hover, a:focus", "color: " + ThemeService.ColorLuminance(colors.linksColor, -0.3) + " !important");
                    createCss("mv-lighter-links", ".mv-other-links-color, .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover," +
                        " .nav-tabs > li.active > a:focus", "color: " + ThemeService.ColorLuminance(colors.linksColor, 0.3) + " !important");
                }
                if (colors.autoActive !== undefined) {
                    createCss("activeAuto", ".mv-active-auto, .active-auto", "color: " + colors.autoActive + " !important");
                }
                if (colors.autoInactive !== undefined) {
                    createCss("inactiveAuto", ".mv-inactive-auto, .inactive-auto", "color: " + colors.autoInactive + " !important");
                }
                if (colors.connectDown !== undefined) {
                    createCss("connectDown", ".mv-connect-down, .serverStatusDown", "color: " + colors.connectDown + " !important");
                }
                if (colors.connectPoor !== undefined) {
                    createCss("connectPoor", ".mv-connect-poor, .serverStatusPoor", "color: " + colors.connectPoor + " !important");
                }
                if (colors.connectBad !== undefined) {
                    createCss("connectBad", ".mv-connect-bad, .serverStatusBad", "color: " + colors.connectBad + " !important");
                }
                if (colors.connectGood !== undefined) {
                    createCss("connectGood", ".mv-connect-good, .serverStatusGood", "color: " + colors.connectGood + " !important");
                }
                if (colors.currentUserBg !== undefined) {
                    createCss("currentUserBg", ".mv-current-user-bg, .mvChatBox_MainUserBox", " background-color:" + colors.currentUserBg + " !important");
                }
                if (colors.otherUserBg !== undefined) {
                    createCss("otherUserBg", ".mv-other-user-bg, .mvChatBox_OtherUserBox ", " background-color: " + colors.otherUserBg + " !important");
                }
                if (colors.footerTextColor !== undefined) {
                    createCss("footerTextColor", ".mv-footer-text", "color: " + colors.footerTextColor + " !important");
                }
                if (colors.footerBg !== undefined) {
                    createCss("footerBg", ".mv-footer-bg", " background-color: " + colors.footerBg + " !important");
                }
                if (colors.applicationBg !== undefined) {
                    createCss("applicationBg", ".mv-window-background", " background-color: " + colors.applicationBg + " !important");
                }
                if (colors.surveyTypeDP !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.surveyTypeDP, -0.2);
                    createCss("surveyType-POOR", ".survey-type-POOR  .ui-grid-cell", " background: " + colors.surveyTypeDP + " !important");
                    createCss("surveyType-POOR-selected", ".ui-grid-row-selected .survey-type-POOR .ui-grid-cell", " background: " + darker + " !important");
                }
                if (colors.surveyTypeXB !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.surveyTypeXB, -0.2);
                    createCss("surveyType-BAD", ".survey-type-BAD  .ui-grid-cell", " background: " + colors.surveyTypeXB + " !important");
                    createCss("surveyType-BAD-selected", ".ui-grid-row-selected .survey-type-BAD .ui-grid-cell", " background: " + darker + " !important");
                }
                if (colors.surveyTypeDS !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.surveyTypeDS, -0.2);
                    createCss("surveyType-STANDARD", ".survey-type-STANDARD  .ui-grid-cell", " background: " + colors.surveyTypeDS + " !important");
                    createCss("surveyType-STANDARD-selected", ".ui-grid-row-selected .survey-type-STANDARD .ui-grid-cell", " background: " + darker + " !important");
                }
                if (colors.surveyTypeXA !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.surveyTypeXA, -0.2);
                    createCss("surveyType-ACC_CHECK", ".survey-type-ACC_CHECK  .ui-grid-cell", " background: " + colors.surveyTypeXA + " !important");
                    createCss("surveyType-ACC_CHECK-selected", ".ui-grid-row-selected .survey-type-ACC_CHECK .ui-grid-cell", " background: " + darker + " !important");
                }
                if (colors.surveyTypeXC !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.surveyTypeXC, -0.2);
                    createCss("surveyType-CHECKSHOT", ".survey-type-CHECKSHOT  .ui-grid-cell", " background: " + colors.surveyTypeXC + " !important");
                    createCss("surveyType-CHECKSHOT-selected", ".ui-grid-row-selected .survey-type-CHECKSHOT .ui-grid-cell", " background: " + darker + " !important");
                }
                if (colors.surveyTypeXI !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.surveyTypeXI, -0.2);
                    createCss("surveyType-INTERPOLATED", ".survey-type-INTERPOLATED  .ui-grid-cell", " background: " + colors.surveyTypeXI + " !important");
                    createCss("surveyType-INTERPOLATED-selected", ".ui-grid-row-selected .survey-type-INTERPOLATED .ui-grid-cell", " background: " + darker + " !important");
                }
                if (colors.surveyTypeDI !== undefined) {
                    darker = ThemeService.ColorLuminance(colors.surveyTypeDI, -0.2);
                    createCss("surveyType-DEFINITIVE_INTERPOLATED", ".survey-type-DEFINITIVE_INTERPOLATED  .ui-grid-cell", " background: " + colors.surveyTypeXI + " !important");
                    createCss("surveyType-DEFINITIVE_INTERPOLATED-selected", ".ui-grid-row-selected .survey-type-DEFINITIVE_INTERPOLATED .ui-grid-cell", " background: " + darker + " !important");
                }
            };

            /**
             @return string
             **/
            ThemeService.ColorLuminance = function (hex, lum) {
                hex = String(hex).replace(/[^0-9a-f]/gi, '');
                if (hex.length < 6) {
                    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                }
                hex = hex == '000000' ? '111111' : hex;
                lum = lum || 0;

                var rgb = "#", c, i;
                for (i = 0; i < 3; i++) {
                    c = parseInt(hex.substr(i * 2, 2), 16);
                    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
                    rgb += ("00" + c).substr(c.length);
                }

                return rgb;
            };

            var removeStyle = function (styleSel) {
                var styleEl = $(styleSel);
                if (styleEl.length > 0) {
                    styleEl.remove();
                }
            };

            var createCss = function (styleId, sel, cssValue) {
                removeStyle('#' + styleId);
                var style = $('<style id=' + styleId + ' class="theme-class">' + sel + ' { ' + cssValue + '; }</style>');
                $('html > head').append(style);
            };
            return ThemeService;
        }]);
})();