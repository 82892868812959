/**
 * FileTemplateController:
 *
 *      Responsible for:
 */
(function () {
    var app = angular.module("saphira");

    app.controller("FileTemplateController", [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */
                  ) {

            /**
             * Initialization for the controller
             */
            var initialize = function () {

            };
            initialize();
        }
    ]);
})();
