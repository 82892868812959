(function () {

    var app = angular.module('saphira');

    app.controller('MsaValidationModalController', [
        /* Angular Modules  */ '$scope', '$filter', '$uibModalInstance', '$q',
        /* 3rd Party Modules*/ 'SweetAlert', 'lodash', 'uiGridConstants',
        /* Internal Modules */ 'HierarchyService', 'StateService', 'InputValidationService', 'ClientRequisitesService', 'CorrectionService', 'HighchartsPlottingService',
                                'UIBlocker', 'EventService', 'TableConfigService', 'SurveyService',
        /* Input            */ 'manualCorrectionValues', 'correctionResolutions', 'rawSurveys', 'correctionAssessment', 'surveySetId', 'params', 'unitSet', 'acSigma', 'qcDeltaInc',
                                'qcDeltaAzi', 'msaQCDeltaInc', 'msaQCDeltaAzi', 'fromAutomation', 'refresh',

        function (/* Angular Modules  */ $scope, $filter, $uibModalInstance, $q,
                  /* 3rd Party Modules*/ SweetAlert, lodash, uiGridConstants,
                  /* Internal Modules */ HierarchyService, StateService, InputValidationService, ClientRequisitesService, CorrectionService, HighchartsPlottingService,
                                        UIBlocker, EventService, TableConfigService, SurveyService,
                  /* Input            */ manualCorrectionValues, correctionResolutions, rawSurveys, correctionAssessment, surveySetId, params, unitSet, acSigma, qcDeltaInc,
                                            qcDeltaAzi, msaQCDeltaInc, msaQCDeltaAzi, fromAutomation, refresh) {

            $scope.helpTooltip = '<div>Zoom: click-drag&#013;' +
                'Pan: shift-click-drag&#013;' +
                'Restore zoom level: double-click</div>';

            /* Debug stuff */
            $scope.correctionPermission = true; //TODO: Hook this up to authentication
            $scope.hasCorrection = false;

            $scope.surveys = [];
            $scope.postProcessedSurveys = [];
            $scope.msaErrors = [];
            $scope.surveyValidationErrors = [];

            $scope.surveysToUpdate = [];

            $scope.surveySet = {};

            $scope.manualCorrectionValues = manualCorrectionValues;

            var minMd = null;
            var maxMd = null;

            $scope.isNewFileFlagPresent = EventService.isNewFileFlagPresent;
            $scope.clearSurveySetFlags = function() {
                EventService.clearSurveySetFlags().then(function()  {
                    refresh();
                });
            };

            var getContextUnits = function () {
                return {
                    depth: unitSet.depthUnit,
                    distance: unitSet.distanceUnit,
                    angle: unitSet.angleUnit,
                    magnetic: unitSet.magneticUnit,
                    gravity: unitSet.gravityUnit
                }
            };

            var checkForOneDefinitiveSurvey = function (data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].type === 'STANDARD' || data[i].type === 'CHECKSHOT') {
                        return true;
                    }
                }
                return false;
            };

            var checkForSurveyUpdates = function (rowEntity, colDef, newValue, oldValue) {
                var deferred = $q.defer();
                var surveyIndex = -1;

                // Value is the same, do nothing and return
                if (newValue === oldValue) {
                    return deferred.promise;
                }

                // Loop to check if the row data is already in the surveysToUpdate list
                for (var i = 0; i < $scope.surveysToUpdate.length; i++) {
                    if ($scope.surveysToUpdate[i].id === rowEntity.id) {
                        surveyIndex = i;
                        break;
                    }
                }

                // Value is new and row data is not in the list, add to list, recompute, and return
                if (surveyIndex === -1) {
                    $scope.surveysToUpdate.push(rowEntity);
                    $scope.recomputeCorrection(false).then(function(result) {
                        deferred.resolve();
                    }, function(error) {
                        $scope.surveysToUpdate.splice(surveyIndex, 1);
                        rowEntity[colDef.name] = oldValue;
                        deferred.reject();
                    });
                    return deferred.promise;
                }

                // Value is new and row data is in list, loop to check if we're setting the value back to the original
                for (var j = 0; j < rawSurveys.length; j++) {
                    // If we're setting back to original, remove row data from list, recompute, and return
                    if (rawSurveys[j].id === $scope.surveysToUpdate[surveyIndex].id && rawSurveys[j].type === $scope.surveysToUpdate[surveyIndex].type) {
                        $scope.surveysToUpdate.splice(surveyIndex, 1);
                        $scope.recomputeCorrection(false).then(function(result) {
                            deferred.resolve();
                        }, function(error) {
                            $scope.surveysToUpdate.push(rowEntity);
                            rowEntity[colDef.name] = oldValue;
                            deferred.reject();
                        });
                        return deferred.promise;
                    }
                }

                // Value is new, row data is in the list, but not setting back to original value, just recompute
                $scope.recomputeCorrection(false).then(function(result) {
                    deferred.resolve();
                }, function(error) {
                    rowEntity[colDef.name] = oldValue;
                    deferred.reject();
                });
                return deferred.promise;
            };

            var insertChangeBooleanIntoSurveys = function () {
                $scope.surveys.forEach(function(survey) {
                    survey.changedData = $scope.surveysToUpdate.includes(survey);
                });
                $scope.gridOptions.data = $scope.surveys;
            };

            $scope.gridOptions = {
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    gridApi.edit.on.afterCellEdit($scope, function(rowEntity, colDef, newValue, oldValue) {
                        if (checkForOneDefinitiveSurvey($scope.gridOptions.data) === false) {
                            rowEntity[colDef.name] = oldValue;
                            newValue = oldValue;
                            SweetAlert.swal({
                                title: "Cannot Set Value",
                                text: "There must be at least one D-S or X-C type survey!",
                                type: "warning",
                                showCancelButton: false,
                                confirmButtonColor: "#2D9635",
                                confirmButtonText: "Okay"
                            })
                        }
                        checkForSurveyUpdates(rowEntity, colDef, newValue, oldValue).then(function() {
                            insertChangeBooleanIntoSurveys();
                        });
                    });
                },
                enableSorting: true,
                enableFiltering: false,
                showTreeExpandNoChildren: false,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                noUnselect: true,
                modifierKeysToMultiSelect: false,
                enableColumnMenus: false,
                columnDefs: TableConfigService.getMSAValidationSurveyTableDefs(getContextUnits(), uiGridConstants),
                rowTemplate: '<div ng-class="{\'ui-grid-row-bg-orange\':row.entity.changedData==true }">' +
                    '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"' +
                    'class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>'
            };


            /* Colors */
            var colors = {
                reference: 'black',
                reference2: 'green',
                measured: 'blue',
                corrected: 'firebrick',
                pass: 'green',
                warning: 'orange',
                fail: 'red',
                qc: 'green',
                qcRaw: 'blue',
                qcCorrected: 'red'
            };

            //Construct Reported/Calc plots
            var sigmaData = {raw: [], corrected: [], all: []}, bTotalData = {raw: [], corrected: [], all: []},
                gTotalData = {raw: [], corrected: [], all: []}, dipData = {raw: [], corrected: [], all: []},
                incData = {raw: [], corrected: [], postProcessed: [], all: []}, aziData = {
                    raw: [],
                    corrected: [],
                    postProcessed: [],
                    all: []
                };

            /* Graphs */
            $scope.sigmaRawGraph = null;
            $scope.bTotalRawGraph = null;
            $scope.gTotalRawGraph = null;
            $scope.dipRawGraph = null;

            $scope.sigmaMsaGraph = null;
            $scope.bTotalMsaGraph = null;
            $scope.gTotalMsaGraph = null;
            $scope.dipMsaGraph = null;

            $scope.sigmaAllGraph = null;
            $scope.bTotalAllGraph = null;
            $scope.gTotalAllGraph = null;
            $scope.dipAllGraph = null;

            $scope.incCalcGraph = null;
            $scope.aziCalcGraph = null;

            $scope.incCorrGraph = null;
            $scope.aziCorrGraph = null;

            $scope.modalClose = function () {
                if ($scope.surveysToUpdate.length > 0) {
                    SweetAlert.swal({
                        title: "Are you sure?",
                        text: $scope.surveysToUpdate.length + " survey(s) will be updated from the Corrected Surveys tab!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#2D9635",
                        confirmButtonText: "Proceed",
                        closeOnConfirm: true,
                        showLoaderOnConfirm: false
                    }, function (proceed) {
                        if (proceed) {
                            // Save the updated surveys
                            SurveyService.overrideUpdates($scope.surveysToUpdate, surveySetId, $scope.surveySet.toolType).then(function() {
                                $uibModalInstance.close('Exit'); //TODO should we pass something?
                            });
                        }
                    })
                }
                else {
                    $uibModalInstance.close('Exit'); //TODO should we pass something?
                }
            };

            $scope.modalCancel = function () {
                $uibModalInstance.dismiss('Reject'); //TODO Should we pass something back?
            };

            var constructRawPlots = function () {
                if ($scope.sigmaRawGraph === null) {
                    $scope.sigmaRawGraph = {chart: {}, options: {}};
                    $scope.sigmaRawGraph.options = HighchartsPlottingService.Sigma.plotRawSigma($scope.surveys, unitSet, acSigma);
                }
                if ($scope.bTotalRawGraph === null) {
                    $scope.bTotalRawGraph = {chart: {}, options: {}};
                    $scope.bTotalRawGraph.options = HighchartsPlottingService.BTotal.plotRawBTotal($scope.surveys, unitSet, acSigma);
                }
                if ($scope.gTotalRawGraph === null) {
                    $scope.gTotalRawGraph = {chart: {}, options: {}};
                    $scope.gTotalRawGraph.options = HighchartsPlottingService.GTotal.plotRawGTotal($scope.surveys, unitSet, acSigma);
                }
                if ($scope.dipRawGraph === null) {
                    $scope.dipRawGraph = {chart: {}, options: {}};
                    $scope.dipRawGraph.options = HighchartsPlottingService.Dip.plotRawDip($scope.surveys, unitSet, acSigma);
                }
            };

            var constructCorrectedPlots = function () {
                if ($scope.sigmaMsaGraph === null) {
                    $scope.sigmaMsaGraph = {chart: {}, options: {}};
                    $scope.sigmaMsaGraph.options = HighchartsPlottingService.Sigma.plotMsaSigma($scope.surveys, unitSet, acSigma);
                }
                if ($scope.bTotalMsaGraph === null) {
                    $scope.bTotalMsaGraph = {chart: {}, options: {}};
                    $scope.bTotalMsaGraph.options = HighchartsPlottingService.BTotal.plotMsaBTotal($scope.surveys, unitSet, acSigma);
                }
                if ($scope.gTotalMsaGraph === null) {
                    $scope.gTotalMsaGraph = {chart: {}, options: {}};
                    $scope.gTotalMsaGraph.options = HighchartsPlottingService.GTotal.plotMsaGTotal($scope.surveys, unitSet, acSigma);
                }
                if ($scope.dipMsaGraph === null) {
                    $scope.dipMsaGraph = {chart: {}, options: {}};
                    $scope.dipMsaGraph.options = HighchartsPlottingService.Dip.plotMsaDip($scope.surveys, unitSet, acSigma);
                }
            };

            var constructAllPlots = function () {

                if ($scope.sigmaAllGraph === null) {
                    $scope.sigmaAllGraph = {chart: {}, options: {}};
                    $scope.sigmaAllGraph.options = HighchartsPlottingService.Sigma.plotMultiSigma($scope.surveys, unitSet, acSigma);
                }
                if ($scope.bTotalAllGraph === null) {
                    $scope.bTotalAllGraph = {chart: {}, options: {}};
                    $scope.bTotalAllGraph.options = HighchartsPlottingService.BTotal.plotMultiBTotal($scope.surveys, unitSet, acSigma);
                }
                if ($scope.dipAllGraph === null) {
                    $scope.dipAllGraph = {chart: {}, options: {}};
                    $scope.dipAllGraph.options = HighchartsPlottingService.Dip.plotMultiDip($scope.surveys, unitSet, acSigma);
                }
                if ($scope.gTotalAllGraph === null) {
                    $scope.gTotalAllGraph = {chart: {}, options: {}};
                    $scope.gTotalAllGraph.options = HighchartsPlottingService.GTotal.plotMultiGTotal($scope.surveys, unitSet, acSigma);
                }
            };

            var constructIncAziCalcPlots = function () {
                if ($scope.aziCalcGraph === null) {
                    $scope.aziCalcGraph = {chart: {}, options: {}};
                    $scope.aziCalcGraph.options =
                        HighchartsPlottingService.Azi.plotRawAzi($scope.surveys, unitSet, qcDeltaAzi.value);
                }
                if ($scope.incCalcGraph === null) {
                    $scope.incCalcGraph = {chart: {}, options: {}};
                    $scope.incCalcGraph.options = HighchartsPlottingService.Inc.plotRawInc($scope.surveys, unitSet, qcDeltaInc.value);
                }
            };

            var constructIncAziCorrPlots = function () {
                if ($scope.incCorrGraph === null) {
                    $scope.incCorrGraph = {chart: {}, options: {}};
                    $scope.incCorrGraph.options = HighchartsPlottingService.Inc.plotMsaIncWithPost($scope.surveys, $scope.postProcessedSurveys, unitSet, msaQCDeltaInc);
                }
                if ($scope.aziCorrGraph === null) {
                    $scope.aziCorrGraph = {chart: {}, options: {}};
                    $scope.aziCorrGraph.options = HighchartsPlottingService.Azi.plotMsaAziWithPost($scope.surveys, $scope.postProcessedSurveys, unitSet, msaQCDeltaAzi);
                }
            };

            $scope.rawPlotsMade = function () {
                return ($scope.sigmaRawGraph !== null && $scope.bTotalRawGraph !== null
                && $scope.gTotalRawGraph !== null && $scope.dipRawGraph !== null);
            };

            $scope.corrPlotsMade = function () {
                return ($scope.sigmaMsaGraph !== null && $scope.bTotalMsaGraph !== null
                && $scope.gTotalMsaGraph !== null && $scope.dipMsaGraph !== null);
            };

            $scope.allPlotsMade = function () {
                return ($scope.sigmaAllGraph !== null && $scope.bTotalAllGraph !== null
                && $scope.gTotalAllGraph !== null && $scope.dipAllGraph !== null);
            };

            $scope.incAziCalcPlotsMade = function () {
                return ($scope.incCalcGraph !== null && $scope.aziCalcGraph !== null);
            };

            $scope.incAziCorrPlotsMade = function () {
                return ($scope.incCorrGraph !== null & $scope.aziCorrGraph !== null);
            };

            var clearGraphs = function () {
                /* Data */
                sigmaData = {raw: [], corrected: [], all: []};
                bTotalData = {raw: [], corrected: [], all: []};
                gTotalData = {raw: [], corrected: [], all: []};
                dipData = {raw: [], corrected: [], all: []};
                incData = {raw: [], corrected: [], all: []};
                aziData = {raw: [], corrected: [], all: []};

                /* Graphs */
                $scope.sigmaRawGraph = null;
                $scope.bTotalRawGraph = null;
                $scope.gTotalRawGraph = null;
                $scope.dipRawGraph = null;

                $scope.sigmaMsaGraph = null;
                $scope.bTotalMsaGraph = null;
                $scope.gTotalMsaGraph = null;
                $scope.dipMsaGraph = null;

                $scope.sigmaAllGraph = null;
                $scope.bTotalAllGraph = null;
                $scope.gTotalAllGraph = null;
                $scope.dipAllGraph = null;

                $scope.incCalcGraph = null;
                $scope.aziCalcGraph = null;

                $scope.incCorrGraph = null;
                $scope.aziCorrGraph = null;
            };

            /**
             * exportGraphs:
             *
             *      Exports all graphs as a png
             *
             * @param type
             */
            $scope.exportGraphs = function (type) {
                var options = Highcharts.getOptions().exporting;
                var svg;
                var name;

                switch (type) {
                    case 'raw':
                        name = "Reported Graphs";
                        svg = Highcharts.getSVG([$scope.sigmaRawGraph.chart, $scope.bTotalRawGraph.chart, $scope.dipRawGraph.chart, $scope.gTotalRawGraph.chart]);
                        break;
                    case 'corrected':
                        name = "Corrected Graphs";
                        svg = Highcharts.getSVG([$scope.sigmaMsaGraph.chart, $scope.bTotalMsaGraph.chart, $scope.dipMsaGraph.chart, $scope.gTotalMsaGraph.chart]);
                        break;
                    case 'all':
                        name = "Reported vs Corrected Graphs";
                        svg = Highcharts.getSVG([$scope.sigmaAllGraph.chart, $scope.bTotalAllGraph.chart, $scope.dipAllGraph.chart, $scope.gTotalAllGraph.chart]);
                        break;
                    case 'calcIncAzi':
                        name = "Calculated Delta Graphs";
                        svg = Highcharts.getSVG([$scope.aziCalcGraph.chart, $scope.incCalcGraph.chart]);
                        break;
                    case 'corrIncAzi':
                        name = "Corrected Delta Graphs";
                        svg = Highcharts.getSVG([$scope.aziCorrGraph.chart, $scope.incCorrGraph.chart]);
                        break;
                    default:
                        console.error("Unknown type for export all");
                        return;
                }

                // Post to export server
                Highcharts.post(options.url, {
                    filename: name,
                    type: options.type,
                    width: options.width,
                    svg: svg
                });
            };

            /**
             * Calls the necessary functions to redraw the graphs whenever they're displayed in the tab content
             * @param tab
             */
            $scope.tabSelect = function (tab) {
                switch (tab) {
                    case 'qcRaw':
                        constructRawPlots();
                        break;
                    case 'qcCorr':
                        if (!$scope.hasCorrection)
                            break;
                        constructCorrectedPlots();
                        break;
                    case 'qcAll':
                        if (!$scope.hasCorrection)
                            break;
                        constructAllPlots();
                        break;
                    case 'incAziCalc':
                        constructIncAziCalcPlots();
                        break;
                    case 'incAziCorr':
                        if (!$scope.hasCorrection)
                            break;
                        constructIncAziCorrPlots();
                        break;
                    default:
                        break;
                }
            };

            //FIXME this is woefully slow
            var mergeSurveys = function (raw, corrected) {
                for (var i = 0; i < $scope.surveys.length; i++) {
                    for (var j = 0; j < corrected.length; j++) {
                        if ($scope.surveys[i].id === corrected[j].id) {
                            $scope.surveys[i] = corrected[j];
                        }
                    }
                }

                // Update the surveys in surveysToUpdate in case any were part of corrected
                corrected.forEach(function(surveyCorrected) {
                    for (var i = 0; i < $scope.surveysToUpdate.length; i++) {
                        if ($scope.surveysToUpdate[i].id === surveyCorrected.id) {
                            var type = $scope.surveysToUpdate[i].type;
                            $scope.surveysToUpdate[i] = surveyCorrected;
                            $scope.surveysToUpdate[i].type = type;
                            $scope.surveysToUpdate[i].changedData = true;
                            break;
                        }
                    }
                });

                $scope.surveys = $filter('orderSurveysByMd')($scope.surveys);
                $scope.gridOptions.data = $scope.surveys;
            };

            var setCorrectionData = function (correction, errorCodes, correctedSurveys) {
                if (correction !== undefined) {
                    $scope.correction = correction;
                    $scope.correction.msaComputationRange = manualCorrectionValues.msaComputationRange;
                    $scope.changesMade = false;
                    $scope.hasCorrection = true;
                }

                if (errorCodes !== undefined) {
                    $scope.msaErrors = [];
                    angular.forEach(errorCodes, function (errorCode) {
                        $scope.msaErrors.push("Check " + errorCode + ": " + ClientRequisitesService.data.ValidationErrors[errorCode]);
                    });
                }

                //add survey validation errors
                $scope.surveyValidationErrors = [];
                angular.forEach(correctedSurveys[correctedSurveys.length - 1].validationErrors, function (errorCode) {
                    $scope.surveyValidationErrors.push("Check " + errorCode + ": " + ClientRequisitesService.data.ValidationErrors[errorCode]);
                });

                if (correctedSurveys !== undefined) {
                    mergeSurveys(rawSurveys, correctedSurveys);
                }
            };

            $scope.noteChangesMade = function () {
                $scope.changesMade = true;
            };

            var isSurveyinComputeRange = function (survey) {
                var min = lodash.get($scope, "manualCorrectionValues.msaComputationRange.min.value", minMd);
                var max = lodash.get($scope, "manualCorrectionValues.msaComputationRange.max.value", maxMd);

                // If min or max is null or undefined, it will use the entire md range
                min = (min !== null && min !== undefined) ? min : minMd;
                max = (max !== null && min !== undefined) ? max : maxMd;

                return (survey.md.value >= min && survey.md.value <= max);
            };

            var numberofSurveysToCompute = function () {
                var numSurveys = 0;

                //if we are only computing msa from a range
                if ($scope.correctionResolutions.msaComputeFromRange) {

                    //count surveys based on which are in the compute range
                    for (var i = 0; i < $scope.surveys.length; i++) {
                        var survey = $scope.surveys[i];
                        if (isSurveyinComputeRange(survey)) {
                            numSurveys++;
                        }
                    }
                }
                //otherwise, all surveys are used for computing msa
                else {
                    numSurveys = $scope.surveys.length;
                }

                return numSurveys;
            };

            var isSurveyComputeType = function (survey) {
                return !(survey.type === "POOR" || survey.type === "BAD" || survey.type === "INTERPOLATED");
            };

            var isValidComputeRange = function () {
                var set = manualCorrectionValues;
                var min = set.msaComputationRange.min;
                var max = set.msaComputationRange.max;
                return !($scope.correctionResolutions.msaComputeFromRange &&
                (!min || !max || (!min.value && min.value !== 0) || (!max.value && max.value !== 0)));

            };

            $scope.isSurveyComputedFrom = function (survey) {
                return (!$scope.correctionResolutions.msaComputeFromRange || isSurveyinComputeRange(survey)) && isSurveyComputeType(survey);
            };

            $scope.correctSurveys = function () {

                CorrectionService.applyCorrection(surveySet.id, $scope.params.selector, $scope.params.mdMin, $scope.params.mdMax, CorrectionService.correction, true, $scope.correctionResolutions.msaComputeFromRange, $scope.correctionResolutions.msaComputationRange).then(function () {
                    SweetAlert.swal("Success", "MSA computed successfully.", "success");
                    $scope.modalClose();
                }, function (error) {
                    //failed to apply correction
                    SweetAlert.swal("Error", "Unknown error when applying correction to surveys.", "error");
                });
            };

            $scope.recomputeCorrection = function (showSuccessAlert) {
                var deferred = $q.defer();
                var set = $scope.correction;

                if (set.msaComputeFromRange && !isValidComputeRange()) {
                    swal("Invalid range");
                    return;
                }

                if (numberofSurveysToCompute() === 0) {
                    swal("No surveys to correct from!");
                    return;
                }

                if ($scope.changesMade) {
                    CorrectionService.correction = $scope.correction;
                }

                var correction = CorrectionService.correction;
                correction.gDamp = manualCorrectionValues.gDamp;
                correction.bDamp = manualCorrectionValues.bDamp;
                correction.msaIncOffset = manualCorrectionValues.msaIncOffset;
                correction.msaAziOffset = manualCorrectionValues.msaAziOffset;
                correction.msaComputationRange = manualCorrectionValues.msaComputationRange;

                CorrectionService.computeMSA($scope.surveySet, params.selector, params.mdMin, params.mdMax, correction,
                    $scope.correctionResolutions, $scope.surveysToUpdate).then(function (correctionData) {
                    if (!correctionData) {
                        return;
                    }
                    CorrectionService.applyCorrection(surveySetId, params.selector, params.mdMin, params.mdMax, correctionData,
                        manualCorrectionValues.msaIncOffset, manualCorrectionValues.msaAziOffset, false, $scope.correctionResolutions.msaComputeFromRange,
                        manualCorrectionValues.msaComputationRange).then(function (data) {
                        if (data.standard !== undefined && data.postProcessed !== undefined && data.standard.correctionAssessment !== undefined) {
                            // Actually save the correction only if it was successfully reapplied.
                            $scope.correction = correctionData;
                            CorrectionService.correction = $scope.correction;

                            setCorrectionData(data.standard.correctionAssessment.correction, data.standard.correctionAssessment.errorCodes, data.standard.surveys);
                            $scope.postProcessedSurveys = data.postProcessed.surveys;
                            clearGraphs();
                            if (showSuccessAlert === null || showSuccessAlert === undefined || showSuccessAlert === true) {
                                swal("Success!", "Correction has been recalculated!", "success");
                            }
                            deferred.resolve();
                        }
                        else {
                            swal("Error", "Unknown error when recorrecting.  Please check the correction parameters and try again.", "error");
                            deferred.reject();
                        }
                    }, function (error) {
                        swal("Error", "Unknown error when recorrecting.  Please check the correction parameters and try again.", "error");
                        deferred.reject();
                    });

                }, function (error) {
                    var errorMessage = error && error.data && error.data.message ? error.data.message : "";
                    swal("Error", "Error when recorrecting.  Please check the correction parameters and try again. \n\n" + errorMessage, "error");
                    deferred.reject();
                });
                return deferred.promise;
            };

            $scope.saveCorrectionParameters = function () {
                $scope.surveySet.correctionParameters.bxBias = $scope.correctionResolutions.bxBiasType;
                $scope.surveySet.correctionParameters.byBias = $scope.correctionResolutions.byBiasType;
                $scope.surveySet.correctionParameters.bzBias = $scope.correctionResolutions.bzBiasType;
                $scope.surveySet.correctionParameters.gxBias = $scope.correctionResolutions.gxBiasType;
                $scope.surveySet.correctionParameters.gyBias = $scope.correctionResolutions.gyBiasType;
                $scope.surveySet.correctionParameters.gzBias = $scope.correctionResolutions.gzBiasType;
                $scope.surveySet.correctionParameters.bxScale = $scope.correctionResolutions.bxScaleType;
                $scope.surveySet.correctionParameters.byScale = $scope.correctionResolutions.byScaleType;
                $scope.surveySet.correctionParameters.bzScale = $scope.correctionResolutions.bzScaleType;
                $scope.surveySet.correctionParameters.gxScale = $scope.correctionResolutions.gxScaleType;
                $scope.surveySet.correctionParameters.gyScale = $scope.correctionResolutions.gyScaleType;
                $scope.surveySet.correctionParameters.gzScale = $scope.correctionResolutions.gzScaleType;
                if (!$scope.surveySet.correctionParameters) {
                    $scope.surveySet.correctionParameters = {};
                }
                $scope.surveySet.correctionParameters.isMagneticMisalignmentManual = $scope.correctionResolutions.isMagneticMisalignManual;
                $scope.surveySet.correctionParameters.isGravityMisalignmentManual = $scope.correctionResolutions.isGravityMisalignManual;
                $scope.surveySet.correctionParameters.msaComputeFromRange = $scope.correctionResolutions.msaComputeFromRange;

                if ($scope.surveySet.correctionParameters.bxBias === "MANUAL") {
                    $scope.surveySet.correctionParameters.bxBiasValue = $scope.correction.magneticBias.data[0];
                }
                if ($scope.surveySet.correctionParameters.byBias === "MANUAL") {
                    $scope.surveySet.correctionParameters.byBiasValue = $scope.correction.magneticBias.data[1];
                }
                if ($scope.surveySet.correctionParameters.bzBias === "MANUAL") {
                    $scope.surveySet.correctionParameters.bzBiasValue = $scope.correction.magneticBias.data[2];
                }
                if ($scope.surveySet.correctionParameters.gxBias === "MANUAL") {
                    $scope.surveySet.correctionParameters.gxBiasValue = $scope.correction.gravityBias.data[0];
                }
                if ($scope.surveySet.correctionParameters.gyBias === "MANUAL") {
                    $scope.surveySet.correctionParameters.gyBiasValue = $scope.correction.gravityBias.data[1];
                }
                if ($scope.surveySet.correctionParameters.gzBias === "MANUAL") {
                    $scope.surveySet.correctionParameters.gzBiasValue = $scope.correction.gravityBias.data[2];
                }
                if ($scope.surveySet.correctionParameters.bxScale === "MANUAL") {
                    $scope.surveySet.correctionParameters.bxScaleValue = $scope.correction.magneticScales.data[0];
                }
                if ($scope.surveySet.correctionParameters.byScale === "MANUAL") {
                    $scope.surveySet.correctionParameters.byScaleValue = $scope.correction.magneticScales.data[1];
                }
                if ($scope.surveySet.correctionParameters.bzScale === "MANUAL") {
                    $scope.surveySet.correctionParameters.bzScaleValue = $scope.correction.magneticScales.data[2];
                }
                if ($scope.surveySet.correctionParameters.gxScale === "MANUAL") {
                    $scope.surveySet.correctionParameters.gxScaleValue = $scope.correction.gravityScales.data[0];
                }
                if ($scope.surveySet.correctionParameters.gyScale === "MANUAL") {
                    $scope.surveySet.correctionParameters.gyScaleValue = $scope.correction.gravityScales.data[1];
                }
                if ($scope.surveySet.correctionParameters.gzScale === "MANUAL") {
                    $scope.surveySet.correctionParameters.gzScaleValue = $scope.correction.gravityScales.data[2];
                }
                if ($scope.surveySet.correctionParameters.isMagneticMisalignmentManual) {
                    $scope.surveySet.correctionParameters.magneticMisalignQ0Value = $scope.correction.magneticMisalign.q0;
                    $scope.surveySet.correctionParameters.magneticMisalignQ1Value = $scope.correction.magneticMisalign.q1;
                    $scope.surveySet.correctionParameters.magneticMisalignQ2Value = $scope.correction.magneticMisalign.q2;
                    $scope.surveySet.correctionParameters.magneticMisalignQ3Value = $scope.correction.magneticMisalign.q3;
                }
                if ($scope.surveySet.correctionParameters.isGravityMisalignmentManual) {
                    $scope.surveySet.correctionParameters.gravityMisalignQ0Value = $scope.correction.gravityMisalign.q0;
                    $scope.surveySet.correctionParameters.gravityMisalignQ1Value = $scope.correction.gravityMisalign.q1;
                    $scope.surveySet.correctionParameters.gravityMisalignQ2Value = $scope.correction.gravityMisalign.q2;
                    $scope.surveySet.correctionParameters.gravityMisalignQ3Value = $scope.correction.gravityMisalign.q3;
                }

                // Save these values as they were set on the MSA Parameter Modal
                $scope.surveySet.correctionParameters.msaComputationRange = {
                    min: null,
                    max: null
                };
                if ($scope.surveySet.correctionParameters.msaComputeFromRange) {
                    if (manualCorrectionValues.msaComputationRange.min.value === undefined
                        || manualCorrectionValues.msaComputationRange.min.value === null) {
                        $scope.surveySet.correctionParameters.msaComputationRange.min = null;
                    } else {
                        $scope.surveySet.correctionParameters.msaComputationRange.min = manualCorrectionValues.msaComputationRange.min;
                    }
                    if (manualCorrectionValues.msaComputationRange.max.value === undefined
                        || manualCorrectionValues.msaComputationRange.max.value === null) {
                        $scope.surveySet.correctionParameters.msaComputationRange.max = null;
                    } else {
                        $scope.surveySet.correctionParameters.msaComputationRange.max = manualCorrectionValues.msaComputationRange.max;
                    }
                }
                else {
                    $scope.surveySet.correctionParameters.msaComputationRange.min = null;
                    $scope.surveySet.correctionParameters.msaComputationRange.max = null;
                }
                $scope.surveySet.correctionParameters.gDamp = manualCorrectionValues.gDamp;
                $scope.surveySet.correctionParameters.bDamp = manualCorrectionValues.bDamp;
                $scope.surveySet.correctionParameters.msaIncOffset = manualCorrectionValues.msaIncOffset;
                $scope.surveySet.correctionParameters.msaAziOffset = manualCorrectionValues.msaAziOffset;

                UIBlocker.start();
                HierarchyService.SurveySet.save($scope.surveySet, false, true).then(
                    function (updatedSurveySet) {
                        $scope.surveySet = updatedSurveySet;
                        UIBlocker.stop();
                        swal("Success!", "Successfully updated the correction parameters for the current SurveySet.", "success");
                    },
                    function (error) {
                        UIBlocker.stop();
                        swal("Error", "Error while saving correction parameters on the current SurveySet. \n" + error, "error");
                    });
            };

            var init = function () {

                var clonedSurveys = lodash.cloneDeep(rawSurveys);

                for (var i = 0; i < clonedSurveys.length; i++) {
                    if (clonedSurveys[i].type !== 'INTERPOLATED' && clonedSurveys[i].type !== 'DEFINITIVE_INTERPOLATED') {
                        $scope.surveys.push(clonedSurveys[i]);
                    }
                }

                var surveySet = HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId];

                $scope.correctionResolutions = correctionResolutions;

                $scope.surveySet = surveySet;
                $scope.unitSet = surveySet.unitSet;

                var correction;
                if (fromAutomation !== undefined && fromAutomation === true) {
                    correction = surveySet.unhandledMsaFailure.correction;
                }
                else {
                    correction = CorrectionService.correction;
                }

                if (rawSurveys && rawSurveys.length > 0) {
                    minMd = rawSurveys[0].md.value;
                    maxMd = rawSurveys[rawSurveys.length - 1].md.value
                }

                var errorCodes = correctionAssessment.standard.correctionAssessment.errorCodes;
                var preSurveys = correctionAssessment.standard.surveys;
                var postSurveys = correctionAssessment.postProcessed.surveys;

                setCorrectionData(correction, errorCodes, preSurveys);

                $scope.postProcessedSurveys = postSurveys;

                $scope.gridOptions.data = $scope.surveys;
            };
            init();
        }]);

})();
