/**
 * Unit filters. Changes Enum names to more legible names
 */
(function () {
    var app = angular.module('saphira');

    // number of decimal places to display for various units.
    var decimalMap = {
        // distance units
        METER: 2,
        KILOMETER: 1,
        FOOT: 2,
        FOOT_US: 2,
        YARD: 2,
        MILE: 1,

        // angle units
        DEGREE: 2,
        RADIAN: 2,
        GRADIAN: 2,

        // gravity units
        M_PER_SEC_SQ: 3,
        FT_PER_SEC_SQ: 3,
        G: 4,
        MILLI_G: 2,
        G_98: 1,
        GAL: 4,
        MILLI_GAL: 1,

        // magnetic units
        NANOTESLA: 0,
        MICROTESLA: 4,
        MILLITESLA: 0,
        TESLA: 0,
        GAUSS: 5,
        GAMMA: 0
    };



    /**
     * addCommas:
     *
     *      Helper function that adds in commas to a number for readability.
     *
     * @param number
     * @returns {*}
     */
    var addCommas = function (number) {
        //check if we have a decimal
        var split = number.split(".");
        //if we have a decimal
        if (split.length === 2) {
            //only add commas to the first half and return the second half
            return split[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + '.' + split[1];
        }
        else {
            //add commas throughout
            return split[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }
    };

    /**
     * removeSignOnZero:
     *
     *      If the value is zero, remove any negative signs. (converts -0.00 to 0.00)
     *
     * @param number
     */
    var removeSignOnZero = function (number) {

        //if the first value is a negative sign
        if (number[0] === '-') {

            //if any values are not 0, return the number, negative sign is valid.
            for (var i = 1; i < number.length; i++) {
                if (number[i] !== '0' && number[i] !== '.') {
                    return number;
                }
            }

            //if all values are 0, remove the negative sign
            return  number.slice(1);
        }
        //first value was not a negative sign, nothing to see here.
        else {
            return number;
        }
    };

    /**
     * formatNumber:
     *
     *      Adds commas and removes the negative sign for zero values.
     *
     * @param number
     */
    var formatNumber = function (number) {
        return removeSignOnZero(addCommas(number));
    };


    /**
     * isValidFloat:
     *
     *      A stricter float filter than parseFloat().
     *
     * @param value
     * @returns {*}
     */
    var isValidFloat = function (value) {
        return !isNaN(parseFloat(value));
    };

    /**
     * distanceDecimal:
     *
     *      Returns the distance as a string with an amount of decimal places based on its unit.
     *      Also formats the string with commas for readability.
     */
    app.filter('distanceDecimal', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (value, unit) {
                if (!isValidFloat(value)) {
                    return "N/A"
                }
                value = parseFloat(value);
                if(typeof decimalMap[unit] !== "undefined") {
                    return formatNumber(value.toFixed(decimalMap[unit]));
                } else {
                    return "Unsupported Unit: " + unit;
                }
            };
        }]);

    /**
     * distanceUnit:
     *
     *      Returns a readable form of the given unit.
     */
    app.filter('distanceUnit', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (unit, full) {
                var string = "";
                switch (unit) {
                    case "METER":
                        string = (full === true ? 'Meters' : 'meters');
                        break;
                    case "KILOMETER":
                        string = (full === true ? "Kilometers" : "kilometers");
                        break;
                    case "FOOT":
                        string = (full === true ? "Feet" : "feet");
                        break;
                    case "FOOT_US":
                        string = (full === true ? "US Survey Feet" : "US Surv. Ft");
                        break;
                    case "YARD":
                        string = (full === true ? "Yards" : "yards");
                        break;
                    case "MILE":
                        string = (full === true ? "Miles" : "miles");
                        break;
                    default:
                        string = unit;
                        break;
                }
                return string;
            }
        }]);

    /**
     * angleDecimal:
     *
     *      Returns the angle as a string with an amount of decimal places based on its unit.
     *      Also formats the string with commas for readability.
     */
    app.filter('angleDecimal', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (value, unit) {
                if (!isValidFloat(value)) {
                    return "N/A"
                }
                value = parseFloat(value);
                if(typeof decimalMap[unit] !== "undefined") {
                    return formatNumber(value.toFixed(decimalMap[unit]));
                } else {
                    return "Unsupported Unit: " + unit;
                }
            }
        }]);

    /**
     * angleUnit:
     *
     *      Returns a readable form of the given unit.
     */
    app.filter('angleUnit', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (unit, full) {
                var string = "";
                switch (unit) {
                    case "DEGREE":
                        string = (full === true ? "Degrees" : decodeURI('%C2%B0'));
                        break;
                    case "RADIAN":
                        string = (full === true ? "Radians" : "rad");
                        break;
                    case "GRADIAN":
                        string = (full === true ? "Gradians" : "grad");
                        break;
                    default:
                        string = unit;
                        break;
                }
                return string;
            }
        }]);

    /**
     * gravityDecimal:
     *
     *      Returns the gravity value as a string with an amount of decimal places based on its unit.
     *      Also formats the string with commas for readability.
     */
    app.filter('gravityDecimal', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (value, unit) {
                if (!isValidFloat(value)) {
                    return "N/A"
                }
                value = parseFloat(value);
                if(typeof decimalMap[unit] !== "undefined") {
                    return formatNumber(value.toFixed(decimalMap[unit]));
                } else {
                    return "Unsupported Unit: " + unit;
                }
            }
        }]);

    /**
     * gravityUnit:
     *
     *      Returns a readable form of the given unit.
     */
    app.filter('gravityUnit', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (unit, full) {
                var string = "";
                switch (unit) {
                    case "M_PER_SEC_SQ":
                        string = (full === true ? "Meters per Second Sq." : "m/s" + decodeURI('%C2%B2'));
                        break;
                    case "FT_PER_SEC_SQ":
                        string = (full === true ? "Feet per Second Sq." : "f/s" + decodeURI('%C2%B2'));
                        break;
                    case "G":
                        string = "G";
                        break;
                    case "MILLI_G":
                        string = "mG";
                        break;
                    case "G_98":
                        string = "G (9.8)";
                        break;
                    case "GAL":
                        string = "Gal";
                        break;
                    case "MILLI_GAL":
                        string = "mGal";
                        break;
                    default:
                        string = unit;
                        break;
                }
                return string;
            }
        }]);

    /**
     * magneticDecimal:
     *
     *      Returns the magnetic value as a string with an amount of decimal places based on its unit.
     *      Also formats the string with commas for readability.
     */
    app.filter('magneticDecimal', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (value, unit) {
                if (!isValidFloat(value)) {
                    return "N/A"
                }
                value = parseFloat(value);
                if(typeof decimalMap[unit] !== "undefined") {
                    return formatNumber(value.toFixed(decimalMap[unit]));
                } else {
                    return "Unsupported Unit: " + unit;
                }
            };
        }]);

    /**
     * magneticUnit:
     *
     *      Returns a readable form of the given unit.
     */
    app.filter('magneticUnit', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (unit, full) {
                var string = "";
                switch (unit) {
                    case "NANOTESLA":
                        string = (full === true ? "Nanotesla" : "nT");
                        break;
                    case "MICROTESLA":
                        string = (full === true ? "Microtesla" : decodeURI('%C2%B5') + "T");
                        break;
                    case "MILLITESLA":
                        string = (full === true ? "Millitesla" : "mT");
                        break;
                    case "TESLA":
                        string = (full === true ? "Tesla" : "T");
                        break;
                    case "GAUSS":
                        string = (full === true ? "Gauss" : "Gs");
                        break;
                    case "GAMMA":
                        string = (full === true ? "Gamma" : decodeURI('%CE%B3'));
                        break;
                    default:
                        string = unit;
                        break;
                }
                return string;
            };
        }]);

    /**
     *  measure:
     *
     *      Returns a string representation of the measure with proper decimal places and formatting.
     */
    app.filter('measure', [
        /* Angular Modules  */ "$filter",
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */ $filter
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (measure, displayUnit) {
                //default: display units
                if (displayUnit === undefined) {
                    displayUnit = true;
                }

                var value, unit;

                if (measure === undefined || measure.unit === undefined) {
                    return "Unsupported Input for Measure Filter";
                }

                switch (measure.unit) {
                    case "METER":
                    case "KILOMETER":
                    case "FOOT":
                    case "FOOT_US":
                    case "YARD":
                    case "MILE":
                        value = $filter('distanceDecimal')(measure.value, measure.unit);
                        unit = " " + $filter('distanceUnit')(measure.unit);
                        break;
                    case "DEGREE":
                        value = $filter('angleDecimal')(measure.value, measure.unit);
                        unit = $filter('angleUnit')(measure.unit);
                        break;
                    case "RADIAN":
                    case "GRADIAN":
                        value = $filter('angleDecimal')(measure.value, measure.unit);
                        unit = " " + $filter('angleUnit')(measure.unit);
                        break;
                    case "M_PER_SEC_SQ":
                    case "FT_PER_SEC_SQ":
                    case "G":
                    case "MILLI_G":
                    case "G_98":
                    case "GAL":
                    case "MILLI_GAL":
                        value = $filter('gravityDecimal')(measure.value, measure.unit);
                        unit = " " + $filter('gravityUnit')(measure.unit);
                        break;
                    case "NANOTESLA":
                    case "MICROTESLA":
                    case "MILLITESLA":
                    case "TESLA":
                    case "GAUSS":
                    case "GAMMA":
                        value = $filter('magneticDecimal')(measure.value, measure.unit);
                        unit = " " + $filter('magneticUnit')(measure.unit);
                        break;
                    default:
                        return "Unsupported Unit: " + measure.unit;
                }
                //if we are displaying units
                if (displayUnit) {
                    return value + unit;
                }
                //if we aren't displaying units, just give the value
                else {
                    return value;
                }
            }
        }]);

})();
