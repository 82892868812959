/**
 * The service responsible for basic operations on a pad
 */
(function () {

    var app = angular.module('saphira');

    app.factory('PadService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */  'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var PadService = {};

            //a list of urls used by the service
            var URLS = {
                getOne: '/saphira/api/repositories/pad/getOne',
                getOneMeta: '/saphira/api/repositories/pad/getOneMeta',
                getAll: '/saphira/api/repositories/pad/getAll',
                update: '/saphira/api/repositories/pad/update',
                lastModifiedDate: '/saphira/api/repositories/pad/lastModifiedDate'
            };

            //the loaded pads
            PadService.Loaded = {};
            PadService.LoadedMeta = {};

            /**
             * get:
             *
             *      Returns the pad with the corresponding id.  If
             *  the pad is already loaded, it returns it.  If the
             *  pad is not yet loaded, it pulls it from the server
             *  and then stores a reference to it and returns it.
             *  Standard authentication rules apply.
             *
             * @param padId     The id of the pad to return
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            PadService.get = function (padId, forceUpdate) {
                if (forceUpdate !== true && padId in PadService.Loaded) {
                    return $q.resolve(PadService.Loaded[padId]);
                } else {
                    return $http.get(URLS.getOne, {"params": {"id": padId}}).then(function (result) {
                        PadService.Loaded[result.data.id] = result.data;
                        return result.data;
                    }, function (status) {
                        UIBlocker.stop();
                        return $q.reject(status);
                    });
                }

            };

            PadService.getOneMeta = function (padId, forceUpdate) {
                if (forceUpdate !== true && padId in PadService.LoadedMeta) {
                    return $q.resolve(PadService.LoadedMeta[padId]);
                } else {
                    return $http.get(URLS.getOneMeta, {"params": {"id": padId}}).then(function (result) {
                        PadService.LoadedMeta[result.data.id] = result.data;
                        return result.data;
                    }, function (status) {
                        UIBlocker.stop();
                        return $q.reject(status);
                    });
                }
            };

            PadService.getAll = function() {
                UIBlocker.start();
                return $http.get(URLS.getAll).then(function(response) {
                    UIBlocker.stop();
                    return response.data;
                }, function(error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };


            /**
             * save:
             *
             *      Saves the given pad to the server.  If the pad is
             *  successfully saved, it updates the service's reference to
             *  the pad.
             *
             * @param pad       The pad object to save
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            PadService.save = function (pad) {
                return $http.post(URLS.update, pad).then(function (result) {
                    PadService.Loaded[pad.id] = pad;
                    return pad;
                }, function (status) {
                    UIBlocker.stop();
                    return $q.reject(status);
                });
            };

            /**
             * getLastModifiedDate:
             *
             *      Gets the last modified date for the pad with the given
             *  pad id.
             *
             * @param padId     The pad id
             *
             * @returns {*}
             */
            PadService.getLastModifiedDate = function (padId) {
                return $http.get(URLS.lastModifiedDate, {"params": {"id": padId}}).then(function (result) {
                    return result.data;
                });
            };

            /**
             * updateIfModified:
             *
             *      Updates the pad corresponding to the given pad id if
             *  the server has a more recent version.
             *
             * @param padId     The pad id of the pad to check for updates on
             * @returns {*}
             */
            PadService.updateIfModified = function (padId) {
                if (!(padId in PadService.Loaded)) {
                    console.error('Attempted to check for updates on a pad that isn\'t loaded!');
                    return $q.reject('Pad must be loaded before checking for updates.');
                } else {
                    return PadService.getLastModifiedDate(padId).then(function (date) {
                        var local = PadService.Loaded[padId];

                        if (date.seconds !== local.lastModifiedDate.seconds || date.nanos !== local.lastModifiedDate.nanos) {
                            return PadService.get(padId, true);
                        } else {
                            return local;
                        }
                    });
                }
            };

            return PadService;
        }]);
})();
