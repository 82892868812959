(function () {

    var app = angular.module('saphira');

    app.controller('ShiftNotesModalController', [
        /* Angular Modules  */ '$scope', '$rootScope', '$uibModalInstance', '$q', '$interval',
        /* 3rd Party Modules*/ 'SweetAlert',
        /* Internal Modules */ 'HierarchyService', 'StateService', 'DataService',
        /* Input            */ 'currentWell',

        function (/* Angular Modules  */ $scope, $rootScope, $uibModalInstance, $q, $interval,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ HierarchyService, StateService, DataService,
                  /* Input            */ currentWell) {

            //Assign the messages to be displayed in the modals
            var timeout = 15000;
            var dialog = null;
            $scope.currentWell = currentWell;
            $scope.notes = {
                current: currentWell.shiftNotes,
                edited: ""
            };
            $scope.editNotes = false;


            /**
             * This function enables the 'edit' view of our shift notes window.
             */
            $scope.enableEdit = function () {
                if ($scope.notes.current !== null) {
                    $scope.notes.edited = $scope.notes.current.slice(0);
                }
                else {
                    $scope.notes.edited = "";
                }
                $scope.editNotes = true;
            };

            /**
             * This function actually saves the shift notes to the server. Uses our DataService wrapper for it
             */
            $scope.saveEdit = function () {
                $scope.currentWell.shiftNotes = $scope.notes.edited;
                DataService.updateShiftNotes($scope.currentWell.id, $scope.notes.edited).then(function () {
                    $scope.notes.current = $scope.notes.edited;
                    $scope.notes.edited = "";
                }, function (reason) {
                    SweetAlert.swal("error", "unable to save shift notes, reason: " + reason.data, "error");
                }).then(function () {
                    $scope.editNotes = false;
                });
            };

            /**
             * This function simply discards the previous edits. easy enough.
             * @return {[type]} [description]
             */
            $scope.discardEdit = function () {
                $scope.editNotes = false;
            };


            /**
             * closeModalCancel:
             *      This dismisses the modals so that the user can go back to change values before resubmitting.
             */
            $scope.closeModalCancel = function () {
                $uibModalInstance.dismiss('cancel');
            };


            /**
             *  startRefreshLoop:
             *
             *      This starts the fresh loop that calls the refresh function based
             *  on the refresh rate.
             */
            var startRefreshLoop = function () {
                $rootScope.refreshId = $interval(function () {
                    refresh();
                }, timeout);
            };

            /**
             *  refreshWellReference:
             *
             *      Re-references the locally selected well to the well stored in the service
             */
            var refreshWellReference = function () {
                $scope.currentWell = HierarchyService.Well.Loaded[StateService.wellboreDetailView.selectedWellId];
                $scope.notes.current = $scope.currentWell.shiftNotes;
            };

            /**
             * refresh:
             *
             *      The refresh function that is called to make sure that the page is kept
             *  up to date.
             */
            var refresh = function () {
                //check if the well was updated
                HierarchyService.Well.updateIfModified(StateService.wellboreDetailView.selectedWellId).then(function () {
                    refreshWellReference();
                });
            };

            /**
             *  init:
             *
             *      Some setup for the controller/view required when moving to this view.
             */
            var init = function () {
                //start the refresh loop
                startRefreshLoop();
            };
            init();

            /**
             * This cleans up our refresh timer, to make sure it destroys itself.
             */
            $scope.$on(
                "$destroy",
                function (event) {
                    $interval.cancel($rootScope.refreshId);
                }
            );

            /**
             * This prevents the modal from closing if currently being edited.
             */
            $scope.$on('modal.closing', function(event) {
                if ($scope.editNotes === true) {
                    event.preventDefault();
                }
            });
        }
    ]);

})();