/**
 *
 */
(function () {
    var app = angular.module("saphira");

    app.controller("CreateObjectModalController", [
        /* Angular Modules*/   "$q", "$scope", "$uibModalInstance",
        /* 3rd Party Modules*/ "SweetAlert", "lodash",
        /* Internal Modules*/  "DataService", "ClientRequisitesService", "UIBlocker",
        /* Input */            "newObject",

        function (/* Angular Modules */   $q, $scope, $uibModalInstance,
                  /* 3rd Party Modules */ SweetAlert, lodash,
                  /* Internal Modules */  DataService, ClientRequisitesService, UIBlocker,
                  /* Input */             newObject) {

            $scope.newObject = newObject;
            $scope.errorMessage = "";
            $scope.numberList = [];


            $scope.createManyNewObjects = function(objectToCreate) {
                if (objectToCreate.name === "" || objectToCreate.name === undefined) {
                    $scope.errorMessage = "I'm sorry, you need to at least give the object a name to continue";
                    return;
                }

                var countString = '';
                var numberToCreate = parseInt(objectToCreate.numberToCreate);
                var objectList = [];

                UIBlocker.start();

                if (objectToCreate.numberToCreate === null || objectToCreate.numberToCreate === undefined || objectToCreate.numberToCreate === "1") {
                    objectList.push($scope.createNewObject(objectToCreate).then(function (response) {
                        return response;
                    }))
                }
                else {
                    countString = ' 01-' + (objectToCreate.numberToCreate > 9 ? objectToCreate.numberToCreate : '0' + objectToCreate.numberToCreate);
                    for (var i = 1; i < numberToCreate + 1; i++) {
                        var cloneObject = lodash.cloneDeep(objectToCreate);
                        if (i < 10) {
                            cloneObject.name += '0';
                        }
                        cloneObject.name += i;
                        objectList.push($scope.createNewObject(cloneObject).then(function(response) {
                            return response;
                        }))
                    }
                }

                $q.all(objectList).then(function(results) {
                    UIBlocker.stop();
                    SweetAlert.swal("Success!!",
                        objectToCreate.commonName + " " + objectToCreate.name + countString + " successfully created!!", "success");
                    $uibModalInstance.close(results);
                }, function(error) {
                    UIBlocker.stop();
                    if (error.data.message !== undefined) {
                        SweetAlert.swal(
                            "Error saving " + objectToCreate.commonName + "!",
                            "There was an error saving the " + objectToCreate.commonName + ": " + error.data.message,
                            "error"
                        );
                    } else {
                        SweetAlert.swal("Uh-oh!",
                            "There was a problem retrieving the " + objectToCreate.commonName + " " + objectToCreate.name +
                            " : " + error.data + " Please check your inputs and try again (they should be non-empty, etc.)",
                            "error");
                        $scope.errorMessage =
                            "There was a problem retrieving the " + objectToCreate.commonName + " " + error.data;
                    }
                })
            };

            $scope.createNewObject = function (objectToCreate) {
                var deferred = $q.defer();
                DataService.createObject(objectToCreate).then(function (response) {
                    DataService.getObject(objectToCreate.commonName, response.data).then(function(response){
                        deferred.resolve(response.data);
                    }, function(error) {
                        deferred.reject(error);
                    });
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            };

            /**
             * This function loads all of the tool codes given a particular tool code set.
             * @param  {String} toolCodeSet The string-valued enum member of our tool code sets we got.
             * @param  {boolean} correction  Tells whether or not we are getting tool codes for our correction, or for regular stuff
             */
            $scope.loadToolCodes = function (toolCodeSet, correction) {
                if (correction === true) {
                    $scope.correctionToolCodes = ClientRequisitesService.data.ToolCodes[toolCodeSet];
                } else {
                    $scope.toolCodes = ClientRequisitesService.data.ToolCodes[toolCodeSet];
                }
            };

            /**
             * Simply initializes some enums
             */
            var initialize = function () {
                $scope.refModels = ClientRequisitesService.data['ReferenceTypes'];
                $scope.toolCodeSets = ClientRequisitesService.data['ToolCodes'].toolCodeSets;
                $scope.northReferences = ClientRequisitesService.data['NorthReferences'];
                $scope.sensorOrientations = ClientRequisitesService.data['KnownComponentOrientations'];
                $scope.wellboreStatuses = ClientRequisitesService.data['WellboreStatuses'];
                $scope.surveySetStatuses = ClientRequisitesService.data["SurveySetStatuses"];
                for (var i = 0;i < 20;i++) {
                    $scope.numberList.push(i+1);
                }

                newObject.numberToCreate = "1";

                switch (newObject.commonName) {
                    case "Company":
                        newObject.sigmaLevel = 2;
                        break;
                    case "Field":
                        newObject.verticalReferenceDatum = "Mean Sea Level";
                        break;
                    case "Pad":
                        break;
                    case "Well":
                        break;
                    case "Wellbore":
                        newObject.status = "PRE_OPERATIONS";
                        newObject.magneticModelType = "MVHD";
                        break;
                    case "Trajectory":
                        break;
                    case "SurveySet":
                        newObject.toolCodeDatabase = "OWSG_Rev_2";
                        $scope.loadToolCodes(newObject.toolCodeDatabase);
                        newObject.toolCode = "MWD_IFR1";
                        newObject.correctionToolCodeDatabase = "OWSG_Rev_2";
                        $scope.loadToolCodes(newObject.correctionToolCodeDatabase, true);
                        newObject.correctionToolCode = "MWD_IFR1_MS";
                        newObject.status = "EMPTY";
                        newObject.qcCourseLength = {
                            value: 110,
                            unit: "FOOT_US"
                        };
                        newObject.qcDogLegSeverity = 5;
                        newObject.qcDeltaInc = {
                            value: 0.1,
                            unit: "DEGREE"
                        };
                        newObject.qcDeltaAzi = {
                            value: 0.1,
                            unit: "DEGREE"
                        };
                        newObject.msaDeltaInc = 0.1;
                        newObject.msaDeltaAzi = 0.5;
                        break;
                    default:

                }
            };
            initialize();
        }
    ]);
})();
