(function () {

    var app = angular.module('saphira');

    app.directive('mapDraw', [
        /* Angular Modules  */
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {
            return {
                restrict : 'E',
                template : '<div id="googleMap" style="width:100%; height: 350px"></div>',
                replace : true,
                link : function(scope,element,attrs) {
                    var lat = scope.$parent.selected.well.data.latitude.value;
                    var lng = scope.$parent.selected.well.data.longitude.value;
                    var latLng = new google.maps.LatLng(lat, lng);
                    var mapProp = {
                        center : latLng,
                        zoom : 9,
                        mapTypeId : google.maps.MapTypeId.ROADMAP
                    };
                    var mapObj = new google.maps.Map(element[0],mapProp);
                    var marker = new google.maps.Marker({
                        position : latLng,
                        map : mapObj
                    });
                    window.setTimeout(function () {
                        google.maps.event.trigger(mapObj, 'resize');
                        mapObj.setCenter(new google.maps.LatLng(lat, lng));
                    }, 0);
                    marker.setMap(mapObj);
                }
            };

        }]);

})();