(function () {

    var app = angular.module('saphira');

    app.filter('wellboreStatusFilter', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (string) {
                switch(string) {
                    case "ACTIVE_BATCH_PROCESS":
                        return "Active - Batch Process";
                    case "ACTIVE_REALTIME":
                        return "Active - Realtime";
                    case "PRE_OPERATIONS":
                        return "Pre-Operations";
                    case "COMPLETED":
                        return "Completed";
                    default:
                        return "Unknown status";
                }
            }
        }]);
})();