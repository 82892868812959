/**
 * GTotalPlottingService:
 *
 *      Responsible for: Creating the plot options for various types of plots for survey G Total.
 *      @author: cperry
 */
(function () {
    var app = angular.module('saphira');

    app.factory("GTotalPlottingService", [
        /* Angular Modules  */ "$filter", "$q",
        /* 3rd Party Modules*/
        /* Internal Modules */ "PlotStyleService", "PlotHelperService",
        /* Input            */

        function (/* Angular Modules  */ $filter, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ PlotStyleService, PlotHelperService
                  /* Input            */) {

            var service = {};

            /**
             * Creates the plot options for plotting the raw G Total of a list of surveys
             * @param surveys
             * @param unitSet
             * @param acSigma
             * @returns {{exporting: *, title: *, xAxis: *, yAxis: *, chart: *, plotOptions: *, credits: {enabled: boolean}, tooltip: *, series: *[]}}
             */
            service.plotRawGTotal = function (surveys, unitSet, acSigma) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);

                var topRedRange = [], topYellowRange = [], greenRange = [], bottomYellowRange = [],
                    bottomRedRange = [], reference = [];
                var measured = {
                    ALL:[],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getRawMinMax(surveys, acSigma);
                var min = minMax.min, max = minMax.max;

                //Get reference and fac series
                for (var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var ref = surveys[i].referenceData.gTotal.value;
                    var facMin = surveys[i].facData.gTotalFacMin.value * acSigma;
                    var facMax = surveys[i].facData.gTotalFacMax.value * acSigma;
                    var meas = surveys[i].gTotalCalc.value;

                    topRedRange.push([md, ref + facMax, max]);
                    topYellowRange.push([md, ref + facMin, ref + facMax]);
                    greenRange.push([md, ref - facMin, ref + facMin]);
                    bottomYellowRange.push([md, ref - facMin, ref - facMax]);
                    bottomRedRange.push([md, ref - facMax, min]);
                    reference.push([md, ref]);
                    measured[surveys[i].type].push([md, meas]);
                    measured["ALL"].push([md, meas]);
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Total Gravity Field'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("G Total (" +
                        $filter('gravityUnit')(unitSet.gravityUnit) + ")", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(unitSet.depthUnit, unitSet.gravityUnit),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, unitSet.gravityUnit),

                    series: [
                        PlotStyleService.createFailRegion(topRedRange),
                        PlotStyleService.createWarnRegion(topYellowRange),
                        PlotStyleService.createPassRegion(greenRange),
                        PlotStyleService.createWarnRegion(bottomYellowRange),
                        PlotStyleService.createFailRegion(bottomRedRange),
                        PlotStyleService.createReferenceSeries("Reference Total Gravity Field", reference),
                        PlotStyleService.createAllMeasuredSeries("Measured Surveys", measured["ALL"]),
                        PlotStyleService.createMeasuredSeries("Measured Standard", measured["STANDARD"]),
                        PlotStyleService.createPoorMeasuredSeries("Measured Poor", measured["POOR"]),
                        PlotStyleService.createBadSeries("Measured Bad", measured["BAD"]),
                        PlotStyleService.createCheckshotMeasuredSeries("Measured Checkshot", measured["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerMeasuredSeries("Measured Accelerometer", measured["ACC_CHECK"])
                    ]
                };
            };

            /**
             * Creates the plot options for plotting the MSA corrected G Total of a list of surveys
             * @param surveys
             * @param unitSet
             * @param acSigma
             * @returns {{exporting: *, title: *, xAxis: *, yAxis: *, chart: *, plotOptions: *, credits: {enabled: boolean}, tooltip: *, series: *[]}}
             */
            service.plotMsaGTotal = function (surveys, unitSet, acSigma) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);
                surveys = PlotHelperService.getSurveysByCorrection(surveys, ['MULTISTATION']);

                var topRedRange = [], topYellowRange = [], greenRange = [], bottomYellowRange = [],
                    bottomRedRange = [], reference = [];
                var measured = {
                    ALL:[],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getMsaMinMax(surveys, acSigma);
                var min = minMax.min, max = minMax.max;

                for (var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var ref = surveys[i].referenceData.gTotal.value;
                    var facMin = surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMin.value * acSigma;
                    var facMax = surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value * acSigma;
                    var meas = surveys[i].correctedDatas.MULTISTATION.gTotal.value;

                    topRedRange.push([md, ref + facMax, max]);
                    topYellowRange.push([md, ref + facMin, ref + facMax]);
                    greenRange.push([md, ref - facMin, ref + facMin]);
                    bottomYellowRange.push([md, ref - facMin, ref - facMax]);
                    bottomRedRange.push([md, ref - facMax, min]);
                    reference.push([md, ref]);
                    measured[surveys[i].type].push([md, meas]);
                    measured["ALL"].push([md, meas]);

                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Corrected Total Gravity Field'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis(
                        "G Total (" + $filter('gravityUnit')(unitSet.gravityUnit) + ")", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(unitSet.depthUnit, unitSet.gravityUnit),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, unitSet.gravityUnit),

                    series: [
                        PlotStyleService.createFailRegion(topRedRange),
                        PlotStyleService.createWarnRegion(topYellowRange),
                        PlotStyleService.createPassRegion(greenRange),
                        PlotStyleService.createWarnRegion(bottomYellowRange),
                        PlotStyleService.createFailRegion(bottomRedRange),
                        PlotStyleService.createReferenceSeries("Reference Total Gravity Field", reference),
                        PlotStyleService.createAllCorrectedSeries("Corrected Surveys", measured["ALL"]),
                        PlotStyleService.createCorrectedSeries("Corrected Standard", measured["STANDARD"]),
                        PlotStyleService.createPoorCorrectedSeries("Corrected Poor", measured["POOR"]),
                        PlotStyleService.createBadSeries("Corrected Bad", measured["BAD"]),
                        PlotStyleService.createCheckshotCorrectedSeries("Corrected Checkshot", measured["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerCorrectedSeries("Corrected Accelerometer", measured["ACC_CHECK"])
                    ]
                };
            };

            /**
             * Creates the plot options for plotting the MSA corrected and post-processed MSA Corrected G Total of a
             * list of surveys
             * @param surveys
             * @param unitSet
             * @param acSigma
             * @returns {{exporting: *, title: *, xAxis: *, yAxis: *, chart: *, plotOptions: *, credits: {enabled: boolean}, tooltip: *, series: *[]}}
             */
            service.plotMultiGTotal = function (surveys, unitSet, acSigma) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);

                var topRedRange = [], topYellowRange = [], greenRange = [], bottomYellowRange = [],
                    bottomRedRange = [], reference = [];
                var measured = {
                    ALL:[],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };
                var corrected = {
                    ALL:[],
                    STANDARD: [],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getMultiMinMax(surveys, acSigma);
                var min = minMax.min, max = minMax.max;

                //Get reference and fac series
                for (var i = 0; i < surveys.length; i++) {
                    var isCorrected = surveys[i].correctedDatas.MULTISTATION !== undefined;
                    var md = surveys[i].md.value;
                    var ref = surveys[i].referenceData.gTotal.value;
                    var facRaw = surveys[i].facData.gTotalFacMax.value * acSigma;
                    var facCorr = null;
                    var corr = null;

                        var meas = surveys[i].gTotalCalc.value;
                        measured[surveys[i].type].push([md, meas]);
                        measured["ALL"].push([md, meas]);
                        reference.push([md, ref]);
                        topRedRange.push([md, ref + facRaw, max]);
                        bottomRedRange.push([md, ref - facRaw, min]);

                        if(isCorrected) {
                            if (surveys[i].correctedDatas.MULTISTATION && surveys[i].correctedDatas.MULTISTATION.gTotal) {
                                corr = surveys[i].correctedDatas.MULTISTATION.gTotal.value;
                                corrected[surveys[i].type].push([md, corr]);
                                corrected["ALL"].push([md, corr]);
                            }
                            facCorr = surveys[i].facData.gTotalFacMin.value * acSigma;
                            topYellowRange.push([md, ref + facCorr, ref + facRaw]);
                            greenRange.push([md, ref - facCorr, ref + facCorr]);
                            bottomYellowRange.push([md, ref - facCorr, ref - facRaw]);
                        }
                        else {
                            if(i > 0 && surveys[i-1].correctedDatas.MULTISTATION !== undefined) {
                                var prevMd = surveys[i -1].md.value;
                                var prevRef = surveys[i-1].referenceData.gTotal.value;
                                var prevFacRaw = surveys[i-1].facData.gTotalFacMax.value * acSigma;
                                topYellowRange.push([prevMd, prevRef , prevRef + prevFacRaw]);
                                bottomYellowRange.push([prevMd, prevRef , prevRef - prevFacRaw]);
                            }
                                topYellowRange.push([md, ref , ref + facRaw]);
                                bottomYellowRange.push([md, ref , ref - facRaw]);
                            if(i < surveys.length - 1 && surveys[i+1].correctedDatas.MULTISTATION !== undefined) {
                                var nextMd = surveys[i +1].md.value;
                                var nextRef = surveys[i+1].referenceData.gTotal.value;
                                var nextFacRaw = surveys[i+1].facData.gTotalFacMax.value * acSigma;
                                topYellowRange.push([nextMd, nextRef , nextRef + nextFacRaw]);
                                bottomYellowRange.push([nextMd, nextRef , nextRef - nextFacRaw]);
                            }
                        }

                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Total Gravity Field'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("G Total (" +
                        $filter('gravityUnit')(unitSet.gravityUnit) + ")", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(unitSet.depthUnit, unitSet.gravityUnit),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, unitSet.gravityUnit),

                    series: [
                        PlotStyleService.createFailRegion(topRedRange),
                        PlotStyleService.createWarnRegion(topYellowRange),
                        PlotStyleService.createPassRegion(greenRange),
                        PlotStyleService.createWarnRegion(bottomYellowRange),
                        PlotStyleService.createFailRegion(bottomRedRange),
                        PlotStyleService.createReferenceSeries("Reference G Total", reference),
                        PlotStyleService.createAllMeasuredSeries("Measured Surveys", measured["ALL"]),
                        PlotStyleService.createMeasuredSeries("Measured Standard", measured["STANDARD"]),
                        PlotStyleService.createPoorMeasuredSeries("Measured Poor", measured["POOR"]),
                        PlotStyleService.createBadSeries("Measured Bad", measured["BAD"]),
                        PlotStyleService.createCheckshotMeasuredSeries("Measured Checkshot", measured["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerMeasuredSeries("Measured Accelerometer", measured["ACC_CHECK"]),
                        PlotStyleService.createAllCorrectedSeries("Corrected Surveys", corrected["ALL"]),
                        PlotStyleService.createCorrectedSeries("Corrected Standard", corrected["STANDARD"]),
                        PlotStyleService.createPoorCorrectedSeries("Corrected Poor", corrected["POOR"]),
                        PlotStyleService.createBadSeries("Corrected Bad", corrected["BAD"]),
                        PlotStyleService.createCheckshotCorrectedSeries("Corrected Checkshot", corrected["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerCorrectedSeries("Corrected Accelerometer", corrected["ACC_CHECK"])
                    ]
                };
            };

            /**
             * Returns the min and max values for the Raw G Total plot.
             *
             * @param surveys
             * @param acSigma
             * @returns {{}}
             */
            var getRawMinMax = function (surveys, acSigma) {
                var refVals = [], calcVals = [], facVals = [];

                for (var i = 0; i < surveys.length; i++) {
                    refVals.push(surveys[i].referenceData.gTotal.value);
                    calcVals.push(surveys[i].gTotalCalc.value);
                    facVals.push(surveys[i].facData.gTotalFacMax.value);
                }

                return PlotHelperService.getMinMax(refVals, calcVals, facVals, acSigma);
            };

            /**
             * Returns the min and max values for the MSA G Total plot.
             *
             * @param surveys
             * @param acSigma
             * @returns {{}}
             */
            var getMsaMinMax = function (surveys, acSigma) {
                var refVals = [], calcVals = [], facVals = [];

                for (var i = 0; i < surveys.length; i++) {
                    refVals.push(surveys[i].referenceData.gTotal.value);
                    calcVals.push(surveys[i].correctedDatas.MULTISTATION.gTotal.value);
                    facVals.push(surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value);
                }

                return PlotHelperService.getMinMax(refVals, calcVals, facVals, acSigma);
            };

            /**
             * Returns the min and max values for the Multiseries G Total plot.
             *
             * @param surveys
             * @param acSigma
             * @returns {{}}
             */
            var getMultiMinMax = function (surveys, acSigma) {
                var min, max;
                rMinMax = getRawMinMax(surveys, acSigma);
                min = rMinMax.min;
                max = rMinMax.max;
                surveys = PlotHelperService.getSurveysByCorrection(surveys, ['MULTISTATION']);
                cMinMax = getMsaMinMax(surveys, acSigma);
                min = min < cMinMax.min ? min : cMinMax.min;
                max = max > cMinMax.max ? max : cMinMax.max;

                return {
                    min: min,
                    max: max
                };
            };

            return service;

        }
    ]);
})();
