/**
 * TimeService:
 *
 *      This service provides helper functions for time (whether instants or moments)
 *
 */

(function () {

    var app = angular.module('saphira');

    app.service('TimeService', [
        /* Angular Modules  */
        /* 3rd Party Modules*/ 'lodash',
        /* Internal Modules */
        /* Input            */
        function (/* Angular Modules  */
                  /* 3rd Party Modules*/ lodash
                  /* Internal Modules */
                  /* Input            */) {

            var service = {};

            /**
             * GetMostRecentNotification:
             *
             *    Returns the most recent notification as given from the survey set.
             *
             * @param notifications     Notifications from the survey set
             * @returns {*}             An object containing the notification and its timestamp
             */
            service.GetMostRecentNotification = function(notifications) {

                //if we don't have any notifications
                if (Object.keys(notifications).length === 0) {
                    return null;
                }

                //store the most recent notification
                var mostRecentNotification = null;

                //go over all notifications
                for (var notification in notifications) {

                    //get the moment time
                    var time = service.GetMomentFromInstant(notifications[notification].timeCreated);

                    //if we haven't stored a most recent notification yet
                    if (mostRecentNotification == null) {

                        //start with this default
                        mostRecentNotification = {
                            type: notification,
                            time: time
                        }
                    }
                    //if we have a most recent notification to compare against
                    else {

                        //check if this one is after it
                        if (time.isAfter(mostRecentNotification.time)) {

                            //and update most recent notification if so
                            mostRecentNotification = {
                                type: notification,
                                time: time
                            }
                        }
                    }
                }

                //return the most recent notification
                return mostRecentNotification;
            };

            /**
             *  Converts the given instant (server) to a moment (client)
             */
            service.GetMomentFromInstant = function(instant) {
                //convert the nanos to seconds, add to seconds, and multiply by 1000 to get milliseconds,
                //required by the moment constructor.
                return moment(1000.0 * (instant.seconds + (instant.nanos / 1000000000.0)));

            };

            /**
             *  Converts the given instant (server) to a moment (client)
             */
            service.GetUTCTimestampFromInstant = function(instant) {
                //convert the nanos to seconds, add to seconds, and multiply by 1000 to get milliseconds,
                //required by the moment constructor.
                return moment.utc(1000.0 * (instant.seconds + (instant.nanos / 1000000000.0))).format("YYYY[-]MM[-]DD[T]HH[:]mm[:]ss.SSS[Z]");

            };

            /**
             * A nice friendly sort function for ui-grid to sort moments.
             *
             * @returns {Function}
             * @constructor
             */
            service.SortByMoment = function() {
                return function (aMoment, bMoment) {
                    if(aMoment && bMoment) {
                        if(aMoment.isBefore(bMoment)) {
                            return 1;
                        }
                        else if(bMoment.isBefore(aMoment)) {
                            return -1;
                        }
                        else {
                            return 0;
                        }
                    }
                    else if(aMoment) {
                        return -1;
                    }
                    else if(bMoment) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                }
            };

            /**
             * A nice friendly sort function for ui-grid to sort date.
             *
             * @returns {Function}
             * @constructor
             */
            service.SortByDate = function () {
                return function (aDate, bDate) {
                    if (aDate === null && bDate === null) return 0;
                    if (aDate === "" || aDate === null) return -1;
                    if (bDate === "" || bDate === null) return 1;
                    if (aDate === bDate) return 0;
                    return aDate < bDate ? -1 : 1;
                }
            };


            /**
             *   Timezone Abbreviation
             *
             * @returns {*}     An object containing the timezone for Server and Client
             */
            service.getTimeZoneAbbr = function () {
               var tz = {};
               tz.server = "UTC";

               var tzClient = moment.tz(moment.tz.guess()).zoneAbbr();
               tz.client = ( !_.isNull(tzClient)  && !_.isUndefined(tzClient)) ? tzClient : "Local TimeZone";
               return tz;
            }


            return service;

        }]);
})();