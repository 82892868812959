/**
 * This controller is responsible for configuring and preparing the Springboot Actuator endpoint data for the Diagnostics view.
 */

(function () {
    var app = angular.module("saphira");

    app.controller("DiagnosticsController", [
        /* Angular Modules  */ '$scope', '$http', '$state',
        /* 3rd Party Modules*/
        /* Internal Modules */ 'HealthService', 'AuthenticationService', 'StateService',
        /* Input            */

        function (/* Angular Modules  */ $scope, $http, $state,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ HealthService,  AuthenticationService, StateService
                  /* Input            */) {

            /**
             * Provides memory, heap, uptime, http counters, thread status to the view
             */
            HealthService.getMetrics().then(function(data) {
                $scope.metrics = data;

                // Memory converted from kilobytes to gigabytes
                $scope.memUsed = (($scope.metrics.mem - $scope.metrics["mem.free"]) / 1024 / 1024).toFixed(2);
                $scope.memTotal = ($scope.metrics.mem / 1024 / 1024).toFixed(2);

                // Heap converted from kilobytes to gigabytes
                $scope.heapUsed = (($scope.metrics["heap.used"]) / 1024 / 1024).toFixed(2);
                $scope.heapCommitted = ($scope.metrics["heap.committed"] / 1024 / 1024).toFixed(2);
                $scope.heapMax = ($scope.metrics.heap / 1024 / 1024).toFixed(2);
                $scope.heapInit = ($scope.metrics["heap.init"] / 1024 / 1024).toFixed(2);

                // Uptime converted from milliseconds to hours
                $scope.instanceUptime = ($scope.metrics["instance.uptime"] / 1000 / 60 / 60).toFixed(2);
                $scope.systemUptime = ($scope.metrics.uptime / 1000 / 60 / 60).toFixed(2);

                $scope.counters200 = [];
                angular.forEach($scope.metrics, function(value, key) {
                    if (key.includes('counter.status.200')) {
                        $scope.counters200.push({
                            endpoint : key,
                            count: value
                        });
                    }
                });

                $scope.counters4xx = [];
                angular.forEach($scope.metrics, function(value, key) {
                    if (key.includes('counter.status.4')) {
                        $scope.counters4xx.push({
                            endpoint : key,
                            count: value
                        });
                    }
                });
            });

            /**
             * Provides mongo status and disk status to the view
             */
            HealthService.getHealth().then(function(data) {
                $scope.health = data;

                // Convert disk space from bytes to gigabytes
                $scope.diskUsed = (($scope.health.details.diskSpace.details.total - $scope.health.details.diskSpace.details.free) / 1024 / 1024 / 1024).toFixed(2);
                $scope.diskTotal = ($scope.health.details.diskSpace.details.total  / 1024 / 1024 / 1024).toFixed(2);
            });

            /**
             * Simply provides audit data on login success/failures
             */
            HealthService.getAuditEvents().then(function(data) {
                $scope.auditEvents = data.events.reverse();
            });

            /**
             * Provides the view the Saphira log data.
             * Parses out the data into an array object using the date format as an index
             */
            HealthService.getLogFile().then(function(data) {
                var logfile = data;
                var datePattern = /\d{4}-\d{2}-\d{2}/g; //Date format in YYYY-MM-DD
                var logDateIndexes = [];

                //Finds every date pattern in the log file and saves the index of each
                while (datePattern.test(logfile) === true) {
                    logDateIndexes.push({
                        dateIndex : datePattern.lastIndex - 10
                    });
                }

                $scope.logData = [];

                //Pulls out the log date and log info based on the date indexes we've found
                for (var i = 0; i < logDateIndexes.length; i++) {
                    $scope.logData.push({
                        logDate : logfile.substring(logDateIndexes[i].dateIndex, logDateIndexes[i].dateIndex+23),
                        logInfo : logfile.substring(logDateIndexes[i].dateIndex+24, logDateIndexes[i+1] ? logDateIndexes[i+1].dateIndex-1 : logfile.length - 1)
                    });
                }
                //Let's reverse the array so we're presenting the latest information first
                $scope.logData.reverse();
            });

            /**
             * Basic initialization for the controller and login checks
             */
            var initialize = function () {
                document.title = "Saphira Diagnostics";
                AuthenticationService.getSessionStatus().then(function (loggedIn) {
                    //check if the user is logged in
                    if (!loggedIn) {
                        //if not, set a pending page and send them to log in
                        StateService.routing.pendingPage = "wellboreSelection";
                        $state.go("login");
                    }
                    else {
                        if (AuthenticationService === null || AuthenticationService === undefined ||
                            AuthenticationService.currentUser() === null ||
                            AuthenticationService.currentUser() === undefined) {
                            $state.go("login");
                            return;
                        }
                    }
                }, function (/*response*/) {
                    SweetAlert.swal("Error", "Failed to authenticate", "error");
                    $state.go("login");
                });
            };
            initialize();
        }
    ]);
})();
