/**
 * Data Validation controller
 * Handles the displaying of validation errors
 */

(function () {

    var app = angular.module("saphira");

    app.controller("SurveySetValidationTolerancesModalController", [
        /* Angular Modules  */ '$scope', '$http', '$q', '$uibModalInstance',
        /* 3rd Party Modules*/ 'SweetAlert',
        /* Internal Modules */ 'SurveySetService', 'ActivityLogService', 'StateService', 'ClientRequisitesService', 'InputValidationService',
        /* Input            */

        function (/* Angular Modules  */ $scope, $http, $q, $uibModalInstance,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ SurveySetService, ActivityLogService, StateService, ClientRequisitesService, InputValidationService
                  /* Input            */) {

            //the survey set
            $scope.surveySet = {
                data: {},
                edit: false
            };

            $scope.InputValidationService = InputValidationService;


            var changesMade = false;

            $scope.copyOverMetaData = function () {
                $scope.editMetaData = _.cloneDeep($scope.surveySet.data);
            };

            var updateSurveySet = function () {
                var getSurveySetRequest = SurveySetService.get(StateService.wellboreDetailView.selectedSurveySetId);
                getSurveySetRequest.then(
                    function (result) {
                        $scope.surveySet.data = result;
                    },
                    function () {
                        console.error('failed to read the survey set: ', getSurveySetRequest);
                    }
                );
            };

            $scope.saveMetaDataChanges = function () {

                if (InputValidationService.validatedInputsReturnErrors(['sstCourseLength', 'sstDeltaInc', 'sstDeltaAzi', 'msavtAzi', 'msavtInc'])) {
                    return;
                }

                $scope.surveySet.validationTolerancesTableEdit = false;

                //Save the surveyset
                SurveySetService.save($scope.editMetaData, false, true).then(
                    function () {
                        updateSurveySet();
                        changesMade = true;
                    },
                    function () {
                        console.error('failed to save meta data changes');
                    }
                )
            };

            //the action for closing the modals
            $scope.closeModalCancel = function () {
                $uibModalInstance.close(changesMade);
            };

            //initialize the controller by loading up the selected survey set
            var init = function () {
                updateSurveySet();
            };
            init();

        }]);

})();