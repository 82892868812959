/**
 * Simple service that groups up all the table config services and provides table definitions using them. A quick
 * place to change the ordering of columns in tables.
 */
(function () {
    var app = angular.module('saphira');

    app.factory('TableConfigService',
        [/* Angular Modules  */
            /* 3rd Party Modules*/
            /* Internal Modules */ 'SurveySetRawDataTableService', 'DefinitiveSurveyTableService',
            /* Input            */

            function (/* Angular Modules  */
                      /* 3rd Party Modules*/
                      /* Internal Modules */ SurveySetRawDataTableService, DefinitiveSurveyTableService
                      /* Input            */) {

                var TableService = {
                    SurveySetRawDataTable: SurveySetRawDataTableService,
                    DefinitiveSurveyTable: DefinitiveSurveyTableService
                };

                /**
                 * Combines the given table columns into one array.
                 *
                 * @param columnArrays  A list of arrays of columns
                 *
                 * @returns {Array}
                 */
                var combineColumns = function (columnArrays) {

                    var combinedColumns = [];

                    columnArrays.forEach(function (columns) {
                        columns.forEach(function (column) {
                            combinedColumns.push(column);
                        });
                    });

                    return combinedColumns;
                };

                var makeColumnsNonEditable = function(columns) {
                    columns.forEach(function(column) {
                        column.enableCellEdit = false;
                    });
                    return columns;
                };

                /**
                 * @returns A list of the tabs determining which subsets of survey data can be viewed.
                 */
                TableService.getRawSurveyTabs = function () {
                    return [
                        {title: 'All Values'},
                        {title: 'Survey Comparison', hideFromCustomer: true},
                        {title: 'Survey Corrections', hideFromCustomer: true},
                        {title: 'Reference Values', hideFromCustomer: true},
                        {title: 'Six-Axis Data'},
                        {title: 'Minimum Curvature', hideFromCustomer: true},
                        {title: 'FAC', hideFromCustomer: true},
                        {title: 'Tracking ID', hideFromCustomer: true}
                    ];
                };

                /**
                 * @param surveyInputType   The survey input type (STANDARD or BACK_CALCULATED)
                 * @returns                 The columns used for survey input
                 */
                var getReportedColumns = function (surveyInputType) {
                    switch (surveyInputType) {
                        case 'BACK_CALCULATED':
                            return combineColumns([
                                SurveySetRawDataTableService.getReportedBackCalculated(),
                                SurveySetRawDataTableService.getCalculatedSixAxis()
                            ]);
                        case 'STANDARD':
                        default:
                            return SurveySetRawDataTableService.getRawSixAxis();
                    }
                };

                /**
                 * @returns The columns that are displayed at the beginning of each tab.
                 */
                var getDefaultColumns = function () {
                    return combineColumns([
                        makeColumnsNonEditable(SurveySetRawDataTableService.getDateAndTime()),
                        SurveySetRawDataTableService.getType(),
                        SurveySetRawDataTableService.getMd(),
                        SurveySetRawDataTableService.getMsaIncAzi(),
                        SurveySetRawDataTableService.getRepIncAzi(),
                        SurveySetRawDataTableService.getCalcIncAzi()
                    ]);
                };

                /**
                 * @param subset            The tab
                 * @param surveyInputType   What type of input we have for surveys
                 * @returns                 The columns specific to the tab
                 */
                var getTabSpecificColumns = function (subset, surveyInputType) {
                    switch (subset) {
                        case 'Survey Comparison':
                            return combineColumns([
                                SurveySetRawDataTableService.getDeltaIncAzi()
                            ]);
                        case 'Survey Corrections':
                            return combineColumns([
                                SurveySetRawDataTableService.getMsaCorrection(),
                                SurveySetRawDataTableService.getMsaComputeFromMdRange(),
                                SurveySetRawDataTableService.getCorrectionMisalignments(),
                                SurveySetRawDataTableService.getDeltaCorrectedReportedIncAzi()
                            ]);
                        case 'Reference Values':
                            return combineColumns([
                                SurveySetRawDataTableService.getReferenceValues(),
                                SurveySetRawDataTableService.getCalculatedValues()
                            ]);
                        case 'Six-Axis Data':
                            return combineColumns([
                                getReportedColumns(surveyInputType),
                                SurveySetRawDataTableService.getMsaSixAxis()
                            ]);
                        case 'Minimum Curvature':
                            return combineColumns([
                                SurveySetRawDataTableService.getMinCurve()
                            ]);
                        case 'FAC':
                            return combineColumns([
                                SurveySetRawDataTableService.getFacRanges(),
                                SurveySetRawDataTableService.getMsaFacValues()
                            ]);
                        case 'Tracking ID':
                            return combineColumns([
                                SurveySetRawDataTableService.getMisc()
                            ]);
                        case 'All Values':
                        default:
                            return combineColumns([
                                makeColumnsNonEditable(SurveySetRawDataTableService.getCalculatedToolFaces()),
                                SurveySetRawDataTableService.getReferenceValues(),
                                SurveySetRawDataTableService.getCalculatedValues(),
                                getReportedColumns(surveyInputType),
                                SurveySetRawDataTableService.getMsaSixAxis(),
                                SurveySetRawDataTableService.getMsaCorrection(),
                                SurveySetRawDataTableService.getMsaComputeFromMdRange(),
                                SurveySetRawDataTableService.getFacRanges(),
                                SurveySetRawDataTableService.getMsaFacValues(),
                                SurveySetRawDataTableService.getMinCurve(),
                                SurveySetRawDataTableService.getMisc()
                            ]);
                    }
                };

                /**
                 * Returns the column definitions for the raw survey table.
                 *
                 * @param units             The units we are using.
                 * @param isCustomer        Whether or not we are a customer user (rig users don't see certain columns)
                 * @param uiGridConstants   Required for sorting techniques on some columns.
                 *
                 * @returns {Array}         An array of columns that define the table.
                 */
                TableService.getRawSurveyTableDefs = function (units, isCustomer, surveyInputType, uiGridConstants, subset) {

                    SurveySetRawDataTableService.prep(units, isCustomer, uiGridConstants);

                    return combineColumns([
                        getDefaultColumns(),
                        getTabSpecificColumns(subset, surveyInputType)
                    ]);

                };

                /**
                 * Returns the column definitions for the MSA Validation survey table.
                 *
                 * @param units             The units we are using.
                 * @param isCustomer        Whether or not we are a customer user (rig users don't see certain columns)
                 * @param uiGridConstants   Required for sorting techniques on some columns.
                 *
                 * @returns {Array}         An array of columns that define the table.
                 */
                TableService.getMSAValidationSurveyTableDefs = function (units, uiGridConstants) {

                    SurveySetRawDataTableService.prep(units, false, uiGridConstants);

                    return combineColumns([
                        makeColumnsNonEditable(SurveySetRawDataTableService.getMd()),
                        SurveySetRawDataTableService.getTypeMsaValidationModal(),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getUnorientedMsaSixAxis()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getRepInc()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getCalcInc()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getMsaInc()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getRepAzi()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getCalcAzi()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getMsaAzi()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getCalculatedValuesGTotal()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getCalculatedValuesBTotal()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getFacRanges()),
                        makeColumnsNonEditable(SurveySetRawDataTableService.getChangeDataValidationModal())
                    ]);

                };

                /**
                 * Returns the column definitions for the definitive survey table.
                 *
                 * @param units             The units we are using.
                 * @param uiGridConstants   Requird for sorting techniques on some columns.
                 *
                 * @returns {Array}         An array of columns to define the table.
                 */
                TableService.getDefinitiveSurveyTableDefs = function (units, uiGridConstants) {

                    var dataTableService = DefinitiveSurveyTableService;

                    dataTableService.prep(units, uiGridConstants);

                    return combineColumns([
                        dataTableService.getMd(),
                        dataTableService.getPostedIncAzi(),
                        dataTableService.getMinCurve()
                    ]);
                };

                return TableService;

            }]);
})();
