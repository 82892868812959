(function () {

    var app = angular.module('saphira');

    app.factory('MasterChatService', ['$http', '$q',
        function ($http, $q) {
            var MasterChatService = {};
            MasterChatService.getPageMessages = function (page, pageSize) {
                var params = {
                    page: page - 1,
                    pageSize: pageSize
                };
                return $http.get("/saphira/api/repositories/chat/allWells/paginated", {
                    'params': params
                }).then(function (response) {
                    return response.data;
                }, function (response) {
                    return $q.reject(response);
                });
            };

            MasterChatService.getMessages = function (time1, time2) {
             //   console.log(time1, time2);
                var params = {
                        'time1': time1.format("YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]"),
                        'time2': time2.format("YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]")
                    };

                return $http.get("/saphira/api/repositories/chat/allWells/betweenTimeStamps", {
                    'params': params
                }).then(function (response) {
                    return response.data;
                }, function (response) {
                    return $q.reject(response);
                });
            };

            MasterChatService.getLatestTime = function() {
                return $http.get("/saphira/api/repositories/chat/allWells/latestTime")
                    .then(function (response) {
                    if(response.data !== null && response.data != "") {
                     //   response.data.time = moment.utc(response.data.time.seconds * 1000.0);
                        return response.data;
                    }
                    else {
                        return null;
                    }
                }, function (response) {
                    return $q.reject(response);
                });
            };
            return MasterChatService;
        }]);
})();