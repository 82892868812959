(function () {
    var app = angular.module('saphira');
    app.directive('numberinputpad', [
        function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    element.addClass('input-number–noSpinners')
                    element.on('focus', function () {
                        angular.element(this).on('mousewheel', function (e) {
                            e.preventDefault();
                        });
                    });
                    element.on('blur', function () {
                        angular.element(this).off('mousewheel');
                    });

                }
            };
        }]
    );
})
();


