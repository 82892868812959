/**
 * SnapshotController.js: This controller is for handling all of our functinality inside of the 'view snapshots' modal. It is agnostic as to which object called it, as in the future we will support
 * holding snapshots of multiple types of objects, at various levels of the hierarchy (trajectory, well, wellbore, etc).
 */
(function () {
    var app = angular.module("saphira");

    app.controller("SnapshotModalController", [
        /* Angular Modules  */ "$scope", "$uibModalInstance", "$uibModal",
        /* 3rd Party Modules*/ "moment", "SweetAlert",
        /* Internal Modules */ "SnapshotService", "ngProgressFactory", "AuthenticationService", "UIBlocker",
        /* Input            */ "currentObject",

        function (/* Angular Modules  */ $scope, $uibModalInstance, $uibModal,
                  /* 3rd Party Modules*/ moment, SweetAlert,
                  /* Internal Modules */ SnapshotService, ngProgressFactory, AuthenticationService, UIBlocker,
                  /* Input            */ currentObject) {

            $scope.checkPerm = AuthenticationService.checkPermissionMapEntry;
            $scope.currentObject = currentObject;
            $scope.snapshots = null;
            $scope.filteredSnapshots = null;
            $scope.progressbar = ngProgressFactory.createInstance();
            //because of some angular weirdness, this had to have full object scope to work right.. *shrug*
            $scope.selectedSnapshots = {
                choices: []
            };
            $scope.errorMessage = null;

            /**
             * This function simply deletes a single snapshot. Uses the snapshot service in order to delete them by snapshot (attachment)
             * @param  {String} snapshotID The ID of the snapshot that we want to delete
             * @return {[type]}            [description]
             */
            $scope.deleteSingleSnapshot = function (snapshotID) {
                $scope.progressbar.setParent(document.getElementById("file_modal"));
                $scope.progressbar.start();
                var promise = SnapshotService.deleteSnapshotForObject(currentObject.commonName, currentObject.id, snapshotID);
                promise.then(function (response) {
                    $scope.progressbar.complete();
                    $scope.errorMessage = null;
                    $scope.snapshots = $scope.snapshots.filter(function (element) {
                        return element.id !== snapshotID;
                    });
                    $scope.filteredSnapshots = $scope.filteredSnapshots.filter(function (element) {
                        return element.id !== snapshotID;
                    });
                }, function (reason) {
                    $scope.progressbar.complete();
                    $scope.errorMessage = "Error deleting file: " + JSON.stringify(reason);
                }, function (update) {
                    //add functionality here for progress bar
                });
            };


            /**
             * This function actually imports the snapshot file into the survey set, and performs 'import' operations based on user preference.
             * @param  {string} snapshotID This is the actual ID of the snapshot that we're importing.
             */
            $scope.restoreSurveySetFromSnapshot = function (snapshotID) {
                if (snapshotID === undefined || snapshotID.length === 0) {
                    $scope.errorMessage = "Please choose a snapshot to import first";
                    return;
                }
                var chooseImportOptionsModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/workflow/importSurveyFile/ImportSurveyFileOptionsModal.html',
                    controller: 'ImportSurveyFileOptionsModalController',
                    size: 'md'
                });

                //no 'error' side of this is needed because we control this modals, and we never throw it to the error loop.
                chooseImportOptionsModal.result.then(function (option) {
                    if (option === undefined || option === null || option.length === 0) {
                        $scope.errorMessage = "Restore cancelled. Please choose an import option to finish restoring next time.";
                        return;
                    }
                    UIBlocker.start();
                    SnapshotService.restoreSurveySetFromSnapshot(currentObject.id, option, snapshotID).then(function (response) {
                        //There is a success SWAL on the well Detail controller now when we close this modals (made more sense that way);
                        $uibModalInstance.close(true);
                        UIBlocker.stop();
                    }, function (error) {
                        $scope.errorMessage = (error.data.message !== undefined) ? error.data.message : error.data;
                        SweetAlert.swal("Error restoring snapshot", "There was an error restoring the snapshot: " + $scope.errorMessage, "error");
                        UIBlocker.stop();
                    });
                });

            };

            /**
             * This funtion uploads the given snapshot and then uses our 'restoreSurveySetFromSnapshot' functionality to actually restore it. It sets a special flag on the attachment on the server to make it so that the
             * server knows to delete this whenever we first 'use' it, since it should be a temp upload
             * @param  {Multipart File} snapshotUpload This is the actual full file upload object
             */
            $scope.uploadAndRestore = function (snapshotUpload) {
                UIBlocker.start();
                SnapshotService.uploadSnapshotFromFile(currentObject.commonName, currentObject.id, snapshotUpload).then(function (response) {
                    UIBlocker.stop();
                    $scope.restoreSurveySetFromSnapshot(response.data.id);
                }, function (error) {
                    $scope.errorMessage = (error.data.message !== undefined) ? error.data.message : error.data;
                    UIBlocker.stop();
                });
            };

            /**
             * This function deletes multiple snapshots. Simply take in a list of snapshot ID's, and then deletes them. easy peazy.
             * @param  {List<String>} snapshotsToDelete the list of snapshot ID's to delete.
             */
            $scope.deleteMultipleSnapshots = function (snapshotsToDelete) {
                snapshotsToDelete.forEach(function (id) {
                    $scope.deleteSingleSnapshot(id);
                });
            };

            /**
             * This function downloads multiple snapshots based off of a list of id's passed into it. nothing too fancy, just iterates through all of the snapshots currently
             * selected, and then calls the snapshotServices downloadSnapshot function to actually download them.
             * @param  {List<String>} snapshotsToDownload A list of string snapshot ID's to download.
             */
            $scope.downloadSnapshots = function (snapshotsToDownload) {

                snapshotsToDownload.forEach(function (id) {
                    SnapshotService.downloadSnapshot(currentObject.commonName, currentObject.id, id);
                });
            };

            /**
             * This function creates a new snapshot, and then re-initializes the view so that the updated survey appears immediately. Wraps the SnapshotService in order to create it
             * and download it immediately after creation.
             * @param  {JS Object} currentObject The current object that we are taking a snapshot of (eg a survey set, trajectory, w/e. )
             */
            $scope.createNewSnapshot = function (currentObject) {
                var promise = SnapshotService.createSnapshot(currentObject);
                promise.then(function (response) {
                    initialize();
                    SnapshotService.downloadSnapshot(currentObject.commonName, currentObject.id, response.data);
                    if ($scope.snapshots.length > 0) {
                        $scope.errorMessage = "";
                    }
                }, function (error) {
                    $scope.errorMessage = "Error creating snapshot: " + error.data;
                    if ($scope.snapshots.length > 0) {
                        $scope.errorMessage = "";
                    }
                });
            };

            /**
             * This function simply inits the snapshots, and once they are uploaded, sets the $scope.snapshots variable. We also have to
             * map the 'name' field of the item to a 'toString' field on the object in order to make the select list directive work correctly.
             */
            var initialize = function () {
                var promise = SnapshotService.getSnapshotsForObject($scope.currentObject.id, $scope.currentObject.commonName);
                $scope.selectedSnapshots = {
                    choices: []
                };
                promise.then(function (response) {
                    response.data.forEach(function (item) {
                        item.toString = item.name;
                    });
                    $scope.snapshots = response.data;
                    if ($scope.snapshots.length === 0) {
                        $scope.errorMessage = "No snapshots found, please create one in order to view here.";
                    } else {
                        $scope.filteredSnapshots = getLastestDatesFromAttachment(3, response.data);
                    }
                }, function (reason) {
                    $scope.errorMessage = "Error getting initial snapshots list: " + reason;
                }, function (update) {
                    //add progress bar here in the future maybe.
                });
            };
            /**
             * This function finds the 'latest' 3 attachments out of a given list of attachments. It does this leveraging Moment.js and a copy of the passed in array.
             * @param  {Integer} numberOfDatesToGet the integer-valued number of attachments to find the 'latest' of (aka: 3 = 3 latest attachments)
             * @param  {JS Array} attachmentList     This is the array of attachments we're finding the latest of
             * @return {JS array}                    A filtered array containing n = numberOfDatesToGet or n = attachmentList.size() if numberOfDatesToGet > attachmentList.size()
             */
            var getLastestDatesFromAttachment = function (numberOfDatesToGet, attachmentList) {
                var innerAttachmentList = attachmentList.slice();
                var attachmentReturnList = [];
                var dateOne = null, dateTwo = null;
                var x = 0;
                var index = 0;
                if (numberOfDatesToGet > attachmentList.length) {
                    numberOfDatesToGet = attachmentList.length;
                }
                for (i = 0; i < numberOfDatesToGet; i++) {
                    innerAttachmentList.forEach(function (attachment) {
                        dateOne = moment(attachment.uploadDate);
                        if (dateTwo === null) {
                            dateTwo = moment(attachment.uploadDate);
                        } else {
                            if (dateOne > dateTwo) {
                                dateTwo = dateOne;
                                index = x;
                            }
                        }
                        x++;
                    });
                    attachmentReturnList.push(innerAttachmentList[index]);
                    dateTwo = null;
                    dateOne = null;
                    innerAttachmentList.splice(index, 1);
                    x = 0;
                    index = 0;
                }
                return attachmentReturnList;
            };
            initialize();
        }
    ]);
})();