/**
 * The service responsible for basic operations on a company
 */
(function () {

    var app = angular.module('saphira');

    app.factory('CompanyService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */ 'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var CompanyService = {};

            //a list of urls used by the service
            var URLS = {
                getOne: '/saphira/api/repositories/company/getOne',
                getOneMeta: '/saphira/api/repositories/company/getOneMeta',
                getAll: '/saphira/api/repositories/company/getAll',
                getAllUnderCompany: '/saphira/api/repositories/company/getAllUnderCompany',
                update: '/saphira/api/repositories/company/update',
                lastModifiedDate: '/saphira/api/repositories/company/lastModifiedDate'
            };

            //the loaded companies
            CompanyService.Loaded = {};
            CompanyService.LoadedMeta = {};

            /**
             * get:
             *
             *      Returns the company with the corresponding id.  If
             *  the company is already loaded, it returns it.  If the
             *  company is not yet loaded, it pulls it from the server
             *  and then stores a reference to it and returns it.
             *  Standard authentication rules apply.
             *
             * @param companyId     The id of the company to return
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            CompanyService.get = function (companyId, forceUpdate) {
                UIBlocker.start();
                if (forceUpdate !== true && companyId in CompanyService.Loaded) {
                    UIBlocker.stop();
                    return $q.resolve(CompanyService.Loaded[companyId]);
                } else {
                    return $http.get(URLS.getOne, {"params": {"id": companyId}}).then(function (result) {
                        CompanyService.Loaded[result.data.id] = result.data;
                        UIBlocker.stop();
                        return result.data;
                    }, function (status) {
                        UIBlocker.stop();
                        return $q.reject(status);
                    });
                }
            };

            CompanyService.getAll = function() {
                UIBlocker.start();
                return $http.get(URLS.getAll).then(function(response) {
                    UIBlocker.stop();
                    return response.data;
                }, function(error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };

            CompanyService.getAllUnderCompany = function(companyId) {
                UIBlocker.start();
                return $http.get(URLS.getAllUnderCompany, {"params": {"id": companyId}}).then(function(response) {
                    UIBlocker.stop();
                    return response.data;
                }, function(error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };

            /**
             * get:
             *
             *      Returns the company with the corresponding id.  If
             *  the company is already loaded, it returns it.  If the
             *  company is not yet loaded, it pulls it from the server
             *  and then stores a reference to it and returns it.
             *  Standard authentication rules apply.
             *
             * @param companyId     The id of the company to return
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            CompanyService.getOneMeta = function (companyId, forceUpdate) {
                UIBlocker.start();
                if (forceUpdate !== true && companyId in CompanyService.LoadedMeta) {
                    UIBlocker.stop();
                    return $q.resolve(CompanyService.LoadedMeta[companyId]);
                } else {
                    return $http.get(URLS.getOneMeta, {"params": {"id": companyId}}).then(function (result) {
                        CompanyService.LoadedMeta[result.data.id] = result.data;
                        UIBlocker.stop();
                        return result.data;
                    }, function (status) {
                        UIBlocker.stop();
                        return $q.reject(status);
                    });
                }
            };


            /**
             * save:
             *
             *      Saves the given company to the server.  If the company is
             *  successfully saved, it updates the service's reference to
             *  the company.
             *
             * @param company       The company object to save
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            CompanyService.save = function (company) {
                UIBlocker.start();
                return $http.post(URLS.update, company).then(function (result) {
                    CompanyService.Loaded[company.id] = company;
                    UIBlocker.stop();
                    return company;
                }, function (status) {
                    UIBlocker.stop();
                    return $q.reject(status);
                });
            };

            /**
             * getLastModifiedDate:
             *
             *      Gets the last modified date for the company with the given
             *  company id.
             *
             * @param companyId     The company id
             *
             * @returns {*}
             */
            CompanyService.getLastModifiedDate = function (companyId) {
                return $http.get(URLS.lastModifiedDate, {"params": {"id": companyId}}).then(function (result) {
                    return result.data;
                });
            };

            /**
             * updateIfModified:
             *
             *      Updates the company corresponding to the given company id if
             *  the server has a more recent version.
             *
             * @param companyId     The company id of the company to check for updates on
             * @returns {*}
             */
            CompanyService.updateIfModified = function (companyId) {
                if (!(companyId in CompanyService.Loaded)) {
                    console.error('Attempted to check for updates on a company that isn\'t loaded!');
                    return $q.reject('Company must be loaded before checking for updates.');
                } else {
                    return CompanyService.getLastModifiedDate(companyId).then(function (date) {
                        var local = CompanyService.Loaded[companyId];

                        if (date.seconds !== local.lastModifiedDate.seconds || date.nanos !== local.lastModifiedDate.nanos) {
                            return CompanyService.get(companyId, true);
                        } else {
                            return local;
                        }
                    });
                }
            };


            return CompanyService;
        }]);
})();
