(function () {

    var app = angular.module('saphira');

    app.factory('EventService', [
        /* Angular Modules  */ "$q",
        /* 3rd Party Modules*/ "lodash",
        /* Internal Modules */ "HierarchyService", "StateService", "AuthenticationService",
        /* Input            */

        function (/* Angular Modules  */ $q,
                  /* 3rd Party Modules*/ lodash,
                  /* Internal Modules */ HierarchyService, StateService, AuthenticationService
                  /* Input            */) {

            var Service = {};

            Service.isNewChatFlagPresent = function () {

                if (AuthenticationService.isReadOnlyUser()) {
                    return false;
                }

                var wellId = lodash.get(StateService, "wellboreDetailView.selectedWellId", null);
                if (wellId) {
                    var well = lodash.get(HierarchyService, "Well.Loaded[" + wellId + "]", null);
                    if (well) {
                        return (!AuthenticationService.isRigUser() && well.hasNewChat);
                    }
                    return false;
                }
                return false;
            };

            Service.setNewChatFlag = function (flag) {
                return HierarchyService.Well.setNewChatFlag(lodash.get(StateService, "wellboreDetailView.selectedWellId", null), flag);
            };

            Service.setNewChatFlagByWellId = function (wellId, flag) {
                return HierarchyService.Well.setNewChatFlag(wellId, flag);
            };

            /**
             * @returns True if: user has permission to see this flag and if the flag is set to true
             */
            Service.isNewFileFlagPresent = function () {

                if (AuthenticationService.isReadOnlyUser()) {
                    return false;
                }

                var surveySetId = lodash.get(StateService, "wellboreDetailView.selectedSurveySetId", null);
                if (surveySetId) {
                    var surveySet = lodash.get(HierarchyService, "SurveySet.Loaded[" + surveySetId + "]", null);
                    if (surveySet) {
                        return (!AuthenticationService.isRigUser() && (surveySet.hasNewFile || surveySet.hasNewSuccessfulSurvey || surveySet.hasNewUncorrectedSurvey ||surveySet.unhandledMsaFailure));
                    }
                    return false;
                }
                return false;
            };

            /**
             * Sets the new file flag on the survey set to the given value.
             * @param flag
             * @returns {*|Document.promise|k.promise|{then, catch, finally}}
             */
            Service.setNewFileFlag = function (flag) {
                return HierarchyService.SurveySet.setNewFileFlag(lodash.get(StateService, "wellboreDetailView.selectedSurveySetId", null), flag);
            };

            Service.clearSurveySetFlags = function() {
                return HierarchyService.SurveySet.clearFlags(lodash.get(StateService, "wellboreDetailView.selectedSurveySetId", null));
            };

            return Service;
        }]);
})();
