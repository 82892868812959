(function () {

    var app = angular.module("saphira");

    app.controller("ReadOnlyMwdMappingsModalController", [
        /* Angular Modules  */  "$uibModalInstance",
        /* Internal Modules */
        /* External Modules */ "SweetAlert",
        /* Input            */ "templateName", "mappings", "extension",

        function (/* Angular Modules  */ $uibModalInstance,
                  /* Internal Modules */
                  /* External Modules */ SweetAlert,
                  /* Input            */ templateName, mappings, extension) {
            var vm = this;

            vm.init = function () {
                vm.templateName = templateName;
                vm.mappings = mappings;
                vm.extension = extension;
            };

            vm.close = function () {
                $uibModalInstance.close();
            };

            vm.init();
        }]);
})();