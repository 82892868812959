(function () {

    var app = angular.module("saphira");

    app.service("ActivityLogService", [
        /* Angular Modules  */ "$http", "$q",
        /* 3rd Party Modules*/
        /* Internal Modules */ 'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var ActivityLogService = {};

            //a list of urls used by the service
            var URLS = {
                getActivityLogMessagesByWellId: '/saphira/api/repositories/activityLogs/wells/',
                getFilteredActivityLog: '/saphira/api/repositories/activityLogs/getFiltered/',
                getAllFilteredActivityLog: '/saphira/api/repositories/activityLogs/getAllFiltered/',
                addActivityLogMessageByObjectId: '/saphira/api/repositories/activityLogs/add/'
            };

            /**
             * getAllByWellId:
             *
             *      Returns all of the activity logs for a given well
             *
             * @param wellId    The id of the well we want activity logs for
             */
            ActivityLogService.getAllByWellId = function (wellId) {
                //make a promise
                var deferred = $q.defer();

                //make the request for the activity logs
                UIBlocker.start();
                $http.get(URLS.getActivityLogMessagesByWellId + wellId)

                //if we found (and have permission to read) the activity logs on the well
                    .success(function (data, status, headers, config) {
                        //add the activity logs to the returned object
                        deferred.result = data;

                        UIBlocker.stop();
                        //resolve the promise
                        deferred.resolve();
                    })

                    //if we couldnt find (or dont have permission to read) the activity logs on the well
                    .error(function (data, status, headers, config) {
                        //reject the promise with the status
                        UIBlocker.stop();
                        deferred.reject(status);
                    });

                //return the promise
                return deferred;
            };

            /**
             * getFiltered:
             *
             *      Returns paged activity logs for desired objects and criticalities
             *
             * @param pojo
             * @returns {*}
             */
            ActivityLogService.getFiltered = function (pojo) {
                return $http.post(URLS.getFilteredActivityLog, pojo)
                //If the request succeeds
                    .success(function (data, status, headers, config) {
                        //add the activity logs to the returned object
                        return data;
                    })
                    //If the request has an error
                    .error(function (data, status, headers, config) {
                        return status;
                    });
            };

            /**
             * getAllFiltered:
             *
             *      Returns all activity logs for desired objects and criticalities
             *
             * @param pojo
             * @returns {*}
             */
            ActivityLogService.getAllFiltered = function (pojo) {
                return $http.post(URLS.getAllFilteredActivityLog, pojo)
                //If the request succeeds
                    .success(function (data, status, headers, config) {
                        //add the activity logs to the returned object
                        return data;
                    })
                    //If the request has an error
                    .error(function (data, status, headers, config) {
                        return status;
                    });
            };

            /**
             * addByObjectId:
             *
             *      Adds an activity log to a given objectId with matching objectTypeName
             *
             * @param pojo    contains objectId, objectTypeName, level, activityType, message
             */
            ActivityLogService.addByObjectId = function (pojo) {
                return $http.post(URLS.addActivityLogMessageByObjectId, pojo)
                    //If the request succeeds
                    .success(function (data, status, headers, config) {
                        //add the activity logs to the returned object
                        return data;
                    })
                    //If the request has an error
                    .error(function (data, status, headers, config) {
                        return status;
                    });
            };

            return ActivityLogService;
        }]);
}());