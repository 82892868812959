(function () {

    var app = angular.module('saphira');

    app.controller('ReportParametersModalController', [
        /* Angular Modules  */ '$scope', '$uibModal',  '$uibModalInstance',
        /* 3rd Party Modules*/
        /* Internal Modules */ "DataService",
        /* Input            */ 'surveySet', 'surveys', 'acSigma', 'header',

        function (/* Angular Modules  */ $scope, $uibModal, $uibModalInstance,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ DataService,
                  /* Input            */ surveySet, surveys, acSigma, header) {

            //Assign the messages to be displayed in the modals

            $scope.reportParams = {
                header: false,
                recentSurvey: false,
                textReport: false,
                customComment: "",
                qcPlots: false,
                correctedPlots: false,
                surveyCorrectionTimeFromFileUpload: false,
                surveyCorrectionTimeFromSurveyCreation: false
            };


            $scope.makeReport = function() {

                if($scope.reportParams.textReport) {
                    DataService.getAnalysis(surveySet.id, $scope.reportParams.customComment).then(function(analysis) {
                        openReport(analysis);
                    }, function(error) {
                        console.error(error);
                    });
                }
                else {
                    openReport(null);
                }

            };

            $scope.checkNoOptionsSelected = function() {
                var options =  $scope.reportParams;
                return !(options.header || options.recentSurvey || options.textReport || options.qcPlots || options.correctedPlots || options.surveyCorrectionTimeFromFileUpload || options.surveyCorrectionTimeFromSurveyCreation);
            };

            var openReport = function(analysis) {
                var reportModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/ReportModal.html',
                    controller: 'ReportModalController',
                    backdrop: 'static',
                    windowClass: 'reportModal',
                    resolve: {
                        surveySet: function () {
                            return surveySet;
                        },
                        surveys : function() {
                            return surveys;
                        },
                        acSigma: function() {
                            return acSigma;
                        },
                        header : function() {
                            return header;
                        },
                        reportParams: function() {
                            return $scope.reportParams;
                        },
                        analysis: function() {
                            return analysis;
                        }
                    }
                });
            };


            /**
             * closeModalCancel:
             *      This dismisses the modals so that the user can go back to change values before resubmitting.
             */
            $scope.closeModalCancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }
    ]);

})();
