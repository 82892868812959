// The AuthenticationService provides resources for the client to log in and log out.
// In practice this consists of accessing the /login endpoint with HTTP basic authentication.
(function () {

    var app = angular.module("saphira");

    app.service("AuthenticationService", [
        /* Angular Modules  */ "$q", "$http", "$window", "$state",
        /* 3rd Party Modules*/ 'lodash',
        /* Internal Modules */ 'StateService', 'UIBlocker', 'ClientRequisitesService',
        /* Input            */

        function (/* Angular Modules  */ $q, $http, $window, $state,
                  /* 3rd Party Modules*/ lodash,
                  /* Internal Modules */ StateService, UIBlocker, ClientRequisitesService
                  /* Input            */) {

            var currentUser = null; // The current user's details including permitted roles.
            /**
             * Maps a CrowdUserDetails object.
             * @param {object} data The CrowdUserDetails object in JSON form.
             */
            function mapData(data) {
                data.fullId = data.principal.name + '_' + data.principal.directoryId;
                data.principal.attributes.forEach(function (el) {
                    if (el.values.length > 1) {
                        data[el.name] = el.values;
                    } else {
                        data[el.name] = el.values[0];
                    }
                });
                data.roles = [];
                data.isSuperAdmin = false;
                data.isReadOnly = false;
                data.isRigUser = false;
                data.isOperator = false;
                data.isCustomer = false;
                data.authorities.forEach(function (el) {
                    if (el.role === "ROLE_MAGVAR_DEVELOPER" || el.role === "ROLE_MAGVAR_SUPERADMIN" ||
                        el.role === "ROLE_SURCON_SUPERADMIN" || el.role === "ROLE_Contractor") {
                        data.isSuperAdmin = true;
                    }
                    if (el.role === "ROLE_ReadOnly") {
                        data.isReadOnly = true;
                    }
                    if(el.role === "ROLE_Rig") {
                        data.isRigUser = true;
                        data.isCustomer = true;
                    }
                    if(el.role === "ROLE_Operators") {
                        data.isOperator = true;
                        data.isCustomer = true;
                    }
                    data.roles.push(el.role);
                });

                return data;
            }

            // The login function accesses /login with the provided username and password. If successful, store the login.
            function login(user) {
                var params = { // Set up the headers with the authorization according to the HTTP standard.
                    "headers": {
                        "Authorization": "Basic " + $window.btoa(user.username + ":" + user.password)
                    }
                };
                return $http.get("/saphira/api/login", params).then(function (data) {
                    currentUser = mapData(data.data); // If successful, the user's details are stored.
                    // return getPerms().then(function () {
                    //     return currentUser;
                    // });
                    return currentUser;
                }, function (error) {
                    // If unsuccessful, make an effort to interpret the error code.
                    switch (error.status) {
                        case 401: // 401 Unauthorized means the user failed to authenticate.
                            throw error.data.message ? error.data.message : "Username or password incorrect.";
                        case 404: // 404 Not Found means what it says on the tin.
                        case 503: // 503 Service Unavailable is Apache's way of saying the proxy to the application server failed.
                            throw "Login server unavailable.";
                        default:
                            throw error.data.message;
                    }
                });
            }

            /**
             * This method gets a single permission from the server given a particular object (which is permissionable)
             * @param  {JS Object} ObjectForPerms The actual object we're checking the permissions of
             * @return {Map<String, Boolean>}                A map of strings to booleans on the resolve loop of the promise we return
             */
            var getSinglePerm = function (commonName, id, username, roles, directoryID) {
                if (roles === undefined || roles.length == 0) {
                    roles.push("NONE"); //otherwise this wouldn't send any groups over, which suuuucks. because we get 400'ed from the server.
                }
                var params = {
                    "id": id,
                    "commonName": commonName,
                    "username": username,
                    "groups": roles,
                    "directoryID": directoryID
                }
                return $http.get("/saphira/api/getSinglePermission", {
                    params: params
                });
            };
            /**
             * This method gets a single permission from the server given a particular object (which is permissionable)
             * @param  {JS Object} ObjectForPerms The actual object we're checking the permissions of
             * @return {Map<String, Boolean>}                A map of strings to booleans on the resolve loop of the promise we return
             */
            var getSinglePermForGroup = function (commonName, id, groupName) {
                var params = {
                    "id": id,
                    "commonName": commonName,
                    "groupName": groupName
                };
                return $http.get("/saphira/api/getSinglePermissionForGroup", {
                    params: params
                });
            };


            /**
             * This function is for submitting permission modifications to the server on a given object for a user or group.
             * It determines programmatically whether or not you are planning on adding or removing a
             * permission for the given object, based on whether or not the 'perms' property is false or true. It returns
             * an object that has an 'isSuccess' boolean property which is true if the permission modification was
             * successful or false if it was not.
             *
             * @param  {String} commonName The common Name of the object we're modifying the permissions of.
             * @param  {String} id         The ID of the object we're modifying the permissions of.
             * @param  {JS Object} perms   An object of the form:
             [{ name: "READ", isSelected: false}, {name: "UPDATE", isSelected: true}] etc. for submitting the modifications
             * @param  {String} entity     The ID of the entity (user or group) we're modifying permissions for.
             * @return {JS Object}         An object with boolean property 'isSuccess' if the permission modification was successful or not.
             */
            var submitPermissionModification = function (commonName, id, perms, entity, type, applyToChildren) {
                var deferred = $q.defer();

                var returnObject = {};
                var i = 0;
                var selectedPermissions = [];
                perms.forEach(function (perm) {
                    if (perm.isSelected) {
                        selectedPermissions.push(perm.name);
                    }
                });

                var body = {
                    "id" : id,
                    "entity": entity,
                    "type": type,
                    "permissions": selectedPermissions,
                    "applyToChildren": applyToChildren
                };

                $http.post("/saphira/api/repositories/" +
                    (commonName !== "SurveySet" ? commonName.toLowerCase() : "surveySet") + "/modifyPermissions",
                    body).then(function (response) {
                    returnObject.isSuccess = response.data;
                    deferred.resolve(returnObject);
                    return;
                }, function (error) {
                    deferred.reject(error.data);
                    return;
                });
                return deferred.promise;
            };

            /**
             * This function checks to see if the permission map in the object contains a particular entry. Takes in the
             * object we are checking permissions for, and the permission name. The permission name has to match the
             * enum on the server, ex: CREATE , READ , DELETE , etc. for a full list, see the 'Permissions' enum on the server.
             * @param  {Object} object     This is the actual datatype object, (eg: some specific survey set, trajectory, w/e)
             * @param  {String} permission The string-valued permission option, should match one of our permissions enums on the server.
             * @return {Boolean}            true if the user has permissions, false otherwise.
             */
            function checkPermissionMapEntry(object, permission, optionalItem) {

                if (currentUser === undefined || currentUser === null) {
                    return false;
                }

                //Make sure we're checking an actual hierarchy object with a permission map
                if (object !== undefined && object !== null && object.permMap !== undefined &&
                    object.permMap !== null) {

                    if (currentUser.isSuperAdmin) {
                        //Superadmins have full permissions
                        return true;
                    }

                    if (currentUser.isReadOnly) {
                        //readonly users have a subset of permissions
                        return lodash.includes(ClientRequisitesService.data.ReadOnlyPermissions, permission);
                    }

                    //Check if the user or any of their roles have the permission for the object
                    if (!lodash.isEmpty(object.permMap)) {
                        if (lodash.includes(object.permMap[currentUser.fullId], permission)) {
                            return true;
                        }
                    }
                }

                return false;
            }

            /**
             * Just a wrapper for the 'checkPermissionMapEntry' for when the user doesn't have the full object.
             * @param  {String} objectCommonName This is the common name of the object, eg: 'Well', or 'Wellbore', case matters.
             * @param  {String} objectID         This is the actual id of the object we're checking for, string-valued.
             * @param  {[type]} permission       the actual permissionable item we're checking. A la: 'CREATE', or 'READ'. must match one of our enums exactly.
             * @return {Boolean}                  true if the user has permissions for the object, false otherwise.
             */
            function checkPermissionsMapEntryManual(objectCommonName, objectID, permission) {
                var object = {
                    commonName: objectCommonName,
                    id: objectID
                };
                return checkPermissionMapEntry(object, permission);
            }

            /**
             * This function just makes a quick call to the login server to see if the user has a saved session cookie. If they do, this will return true, if not, false.
             * @return {boolean} True if the user has a session cookie saved, false otherwise.
             */
            function checkSessionLogin() {
                return $http.get("/saphira/api/login").then(function (data) {
                    currentUser = mapData(data.data); // If successful, the user's details are stored, and Angular's HTTP headers are updated to perpetuate the login.
                    // return getPerms().then(function () {
                    //     return currentUser;
                    // });
                    return currentUser;
                }, function (error) {
                    return false;
                });
            }

            // The loggedIn function returns true iff the user is currently logged in.
            function loggedIn() {
                return currentUser !== null;
            }

            // The currentUser function returns the current user's data. This can be used to inspect roles or get the username, for instance.
            function currentUserFn() {
                return currentUser;
            }

            // The logout function clears out the authorization header and current user data and returns to the login page.
            function logout() {
                //This tries to log out the user, and then transitions to the login state. Regardless we'll go to login, because
                //if we're here, someone wanted to logout. We send appropriate responses to the state via' state params.
                $http.post("/saphira/api/logout").then(function (data) {
                    StateService.loggedIn = false;
                    $state.go("login", {
                        'reason': data.data.reason,
                        'error': false
                    });
                }, function (error) {
                    StateService.loggedIn = false;
                    $state.go("login", {
                        'reason': error.data.reason,
                        'error': true
                    });
                });
                currentUser = null;
                return $q.resolve(true);
            }

            function getSessionStatus() {
                return checkSessionLogin().then(function (status) {
                    return status !== false;
                }, function(response) {
                    $q.reject(response);
                });
            }

            /**
             * Returns true if the current user is a rig user (has role ROLE_Rig)
             * @returns {boolean}
             */
            function isRigUser() {
                return  currentUser && currentUser.isRigUser;
            }

            /**
             * Returns true if the current user is an operator (has role ROLE_Operators)
             * @returns {boolean}
             */
            function isOperator() {
                return currentUser && currentUser.isOperator;
            }

            /**
             * Returns true if the current user is a customer (Currently either a rig or an operator)
             * @returns {boolean|*}
             */
            function isCustomer() {
                return currentUser && currentUser.isCustomer;
            }

            /**
             * Returns true if the current user is a rig user (has role ROLE_Rig)
             * @returns {boolean}
             */
            function isReadOnlyUser() {
                return currentUser && currentUser.isReadOnly;
            }

            function isSuperAdmin() {
                return currentUser && currentUser.isSuperAdmin;
            }

            /**
             * Updates the delay time of the rig user
             * @param {String} userName
             * @param {String} idleStatus
             */
            function updateDelayTimeInSession(idleStatus) {
                var req = {
                    method: 'POST',
                    url: '/saphira/api/updateDelayTimeInSession',
                    headers: {
                        'Content-Type': "text/plain"
                    },
                    data: idleStatus
                };
                $http(req);
            }

            /**
             * Get the session of the rig user
             * @param {String} userName
             */
            function getUserSession(username) {
                var params = {
                    "rigUsername": username
                };
                return $http.get("/saphira/api/getRigUserStatus", {
                    params: params
                }).then(function (data) {
                    return (data.data);
                }, function (error) {
                    return error;
                });
            }

            return {
                "login": login,
                "loggedIn": loggedIn,
                "getSessionStatus" : getSessionStatus,
                "currentUser": currentUserFn,
                "logout": logout,
                "checkSessionLogin": checkSessionLogin,
                "checkPermissionMapEntry": checkPermissionMapEntry,
                "checkPermissionsMapEntryManual": checkPermissionsMapEntryManual,
                "getSinglePerm": getSinglePerm,
                "getSinglePermForGroup": getSinglePermForGroup,
                "submitPermissionModification": submitPermissionModification,
                "isRigUser": isRigUser,
                "isOperator": isOperator,
                "isCustomer" : isCustomer,
                "isReadOnlyUser": isReadOnlyUser,
                "isSuperAdmin": isSuperAdmin,
                "updateDelayTimeInSession": updateDelayTimeInSession,
                "getUserSession": getUserSession
            };
        }]);
})();
