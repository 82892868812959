(function () {
    var app = angular.module("saphira");

    app.controller("AdminController", [
        /* Angular Modules  */ "$q", "$scope", "$uibModal", "$state", "$stateParams", "$filter",
        /* 3rd Party Modules*/ "SweetAlert", "lodash", 'uiGridConstants',
        /* Internal Modules */ "AuthenticationService", "HierarchyService", "DataService", "UIBlocker",
        "ClientRequisitesService", "StateService", "CompanySnapshotService",
        /* Input            */

        function (/* Angular Modules  */ $q, $scope, $uibModal, $state, $stateParams, $filter,
                  /* 3rd Party Modules*/ SweetAlert, lodash, uiGridConstants,
                  /* Internal Modules */ AuthenticationService, HierarchyService, DataService, UIBlocker,
                  ClientRequisitesService, StateService, CompanySnapshotService
                  /* Input            */) {

            $scope.companies = [];
            $scope.fields = [];
            $scope.pads = [];
            $scope.wells = [];
            $scope.wellbores = [];
            $scope.trajectories = [];
            $scope.surveySets = [];
            $scope.isWhitelisted = false;
            $scope.modify = {
                lastModifiedDate: new Date()
            };
            $scope.filter = {
                permissionableObjectsFilterValue: ""
            };
            $scope.showPerms = false;
            $scope.checkPerm = AuthenticationService.checkPermissionMapEntry;

            $scope.isReadOnlyUser = AuthenticationService.isReadOnlyUser;

            $scope.permissionList = {
                applyToChildren: false
            };

            $scope.hierarchyTree = {
                data: [],
                options: {}
            };

            $scope.enableUserSelection = false;

            $scope.viewPermissionsDescriptions = function () {
                var viewPermissionsDescriptionsModal = $uibModal.open({
                    templateUrl: 'html/views/admin/PermissionsDescriptionModal.html',
                    controller: 'PermissionsDescriptionModalController',
                    backdrop: 'static'
                });
            };

            $scope.dirtyFlag = false;

            /**
             * This function simply sets the dirty flag to false.
             * dirty flag is set true when ever there is a change in permissions of any user
             */
            $scope.setDirtyFlag= function(){
                $scope.dirtyFlag = true;
            }

            /**
             * This function simply wraps an authentication service function for submitting permissions modifications to the server. It wraps it in some nice Swals for ease of user use.
             * @param  {String} commonName The common Name of the object we're modifying the permissions of.
             * @param  {String} id         The ID of the object we're adding permissions of
             * @param  {JS Object} perms      An object of the form { READ: false, UPDATE: true} etc. for submitting the modifications
             * @param  {String} entity     the actual JS string-valued entity we're adding the permission of.
             * @return {JS Object}            an object that has the success of each permission
             */
            $scope.submitPermissionModification = function (commonName, id, perms, entity, type) {
                SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Permission modifications will take place immediately!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, submit them!",
                    closeOnConfirm: false
                }, function (proceed) {
                    // For permissions on groups we need to differentiate them from users
                    // in the permissions map by prepending the group id with 'ROLE_'

                    if ($scope.permissionableObjectTypeSelectOptions.selectedOption.name === "Groups") {
                        entity = "ROLE_" + entity;
                    }
                    if (proceed) {
                        AuthenticationService.submitPermissionModification(commonName, id, perms, entity, type,
                            $scope.permissionList.applyToChildren)
                            .then(function (response) {
                                SweetAlert.swal("Success!", "Permission modification submitted successfully!",
                                    "success");
                                $scope.permissionableObjectsSelectionChange($scope.selectedRow);
                                $scope.dirtyFlag = false;
                            }, function (error) {
                                SweetAlert.swal("Error",
                                    "There was an error submitting the permissions modification: " + error,
                                    "error");
                            });
                    }
                });
            };
            /**
             * This function checks to see if the user has a 'isWhitelisted' flag set on them. if they do, we disable the ability to choose permissions and some other nice stuff
             * @param  {String} userToCheck The string-valued username to check
             * @return {Boolean}             True if they're whitelisted, false otherwise.
             */
            $scope.checkWhitelist = function (userToCheck) {
                if ($scope.users === undefined) {
                    return false;
                }
                $scope.users.forEach(function (user) {
                    if (user.username === userToCheck) {
                        if (user.isWhitelisted) {
                            $scope.isWhitelisted = true;
                            return true;
                        } else {
                            $scope.isWhitelisted = false;
                            return false;
                        }
                    }
                });
            };
            /**
             * Because we have a strange interaction with the 'companies' permission, we have to make sure the user has the 'MODIFY_PERMISSIONS' permission on every single company in order
             * to do it. This is because it's a pretty sensitive permission.
             * @return {boolean} true if the user has the permission on every company, false otherwise.
             */
            $scope.checkPermissionForPermissions = function () {
                $scope.companies.forEach(function (company) {
                    if (AuthenticationService.checkPermissionMapEntry(company, 'MODIFY_PERMISSIONS')) {
                        $scope.showPerms = true;
                        return true;
                    } else {
                        $scope.showPerms = false;
                        return false;
                    }
                });
            };

            var attemptToAddCreateCompanySnapshot = function (options) {
                var selected = $scope.hierarchyTree.selected;
                if (selected.commonName === 'Company') {
                    options.push([
                        function ($itemScope, $event) {
                            return "Create Company Snapshot"
                        }, function ($itemScope) {

                            CompanySnapshotService.getCompanySnapshot(selected.id);

                        }, function ($itemScope, $event) {
                            return true;
                        }]);
                }
            };

            $scope.importCompany = function (companySnapshot) {
                CompanySnapshotService.restoreFromCompanySnapshot(companySnapshot).then(function () {
                    $scope.refresh();
                }, function (error) {
                    swal("Error!", "Upload of Company Snapshot: " + companySnapshot.name + " failed!\n\n" + error.data.message, "error");
                });
            };

            var hierarchyNameSort = function (hierarchyObject) {
                return hierarchyObject.sort(function (a, b) {
                    var x = a.name.toLowerCase();
                    var y = b.name.toLowerCase();
                    if (x < y) {
                        return -1;
                    }
                    if (x > y) {
                        return 1;
                    }
                    return 0;
                });
            };


            var loadCompanyTree = function (node) {
                UIBlocker.start();
                return HierarchyService.Company.getAllUnderCompany(node.id).then(function (response) {
                        var fields = hierarchyNameSort(response.Field);
                        var pads = hierarchyNameSort(response.Pad);
                        var wells = hierarchyNameSort(response.Well);
                        var wellbores = hierarchyNameSort(response.Wellbore);
                        var trajectories = hierarchyNameSort(response.Trajectory);
                        var surveySets = hierarchyNameSort(response.SurveySet);

                        $scope.buildCompanyTree(node, fields, pads, wells, wellbores, trajectories, surveySets);
                        return $q.resolve();
                    }, function (error) {
                        return $q.reject(error);
                    }
                ).finally(function () {
                    UIBlocker.stop();
                });
            };

            var getRootCompany = function (scope) {
                if (scope.node.commonName === "Company") {
                    return scope.node;
                }
                else {
                    return getRootCompany(scope.$parent);
                }
            };

            var attemptToCopySurveySet = function (options) {
                var selected = $scope.hierarchyTree.selected;
                if (selected.commonName === 'SurveySet') {
                    options.push([
                        function ($itemScope, $event) {
                            if ($itemScope.node !== undefined &&
                                $scope.hierarchyTree.selected.id === $itemScope.node.id &&
                                $scope.hierarchyTree.selected.commonName === 'SurveySet') {
                                return "Create copy of survey set";
                            } else {
                                return "";
                            }
                        }, function ($itemScope) {
                            var modalData = {wellbore: $itemScope.$parent.node, selectedSurveySet: $itemScope.node};
                            var createSurveySetsModal = $uibModal.open({
                                templateUrl: 'html/views/admin/CreateSurveySetsModal.html',
                                controller: 'CreateSurveySetsModalController',
                                backdrop: 'static',
                                resolve: {
                                    modalData: function () {
                                        return modalData;
                                    }
                                }
                            });
                            createSurveySetsModal.result.then(function (data) {
                                if (data) {
                                    loadCompanyTree(getRootCompany($itemScope)).then(function () {
                                        UIBlocker.stop();
                                    }, function () {
                                        UIBlocker.stop();
                                    });
                                }
                            });
                            $scope.hierarchyTree.selected = $itemScope.node;
                        }, function ($itemScope, $event) {
                            if ($scope.hierarchyTree.selected !== null &&
                                $scope.hierarchyTree.selected !== undefined) {
                                return $scope.checkPerm($scope.hierarchyTree.selected, 'CREATE')
                            } else {
                                return false;
                            }
                        }]);
                }
            };

            var reloadRootTree = function () {
                var requests = [];
                $scope.hierarchyTree.expandedNodes = [];
                requests.push(HierarchyService.Company.getAll());
                requests.push(HierarchyService.Field.getAll());
                return $q.all(requests).then(function (responses) {
                    $scope.companies = $filter('orderBy')(responses[0], 'name');
                    var fields = $filter('orderBy')(responses[1], 'name');
                    $scope.buildInitialTree($scope.companies, fields);
                    return $q.resolve();
                }, function (errors) {
                    return $q.reject(errors);
                });
            };

            var attemptToMoveField = function (options) {
                var selected = $scope.hierarchyTree.selected;
                if (selected.commonName === 'Field') {
                    options.push([
                        function ($itemScope, $event) {
                            if ($itemScope.node !== undefined &&
                                $scope.hierarchyTree.selected.id === $itemScope.node.id &&
                                $scope.hierarchyTree.selected.commonName === 'Field') {
                                return "Move field to different company";
                            } else {
                                return "";
                            }
                        }, function ($itemScope) {
                            var modalData = {
                                field: $itemScope.node,
                                company: $itemScope.$parent.node,
                                companies: $scope.companies
                            };
                            var moveFieldModal = $uibModal.open({
                                templateUrl: 'html/views/admin/MoveFieldModal.html',
                                controller: 'MoveFieldModalController',
                                backdrop: 'static',
                                resolve: {
                                    modalData: function () {
                                        return modalData;
                                    }
                                }
                            });
                            moveFieldModal.result.then(function (data) {
                                if (data) {
                                    reloadRootTree().then(function () {
                                        UIBlocker.stop();
                                    }, function () {
                                        UIBlocker.stop();
                                    });
                                }
                            });
                            $scope.hierarchyTree.selected = $itemScope.node;
                        }, function ($itemScope, $event) {
                            if ($scope.hierarchyTree.selected !== null &&
                                $scope.hierarchyTree.selected !== undefined) {
                                return $scope.checkPerm($scope.hierarchyTree.selected, 'UPDATE')
                            } else {
                                return false;
                            }
                        }]);
                }
            };

            /**
             * This function simply returns the common name of the child based on the common name that is passed in. Useful for if we have no children, but still need to know the common name
             * of the would-be children for later.
             * @param  {String} commonName The common name we're getting the child common name for
             * @return {String}            the common name of the child in our hierarchy, empty string if no child exists.
             */
            var findChildNameForCommon = function (commonName) {
                switch (commonName) {
                    case "Company":
                        return "Field";
                    case "Field":
                        return "Pad";
                    case "Pad":
                        return "Well";
                    case "Well":
                        return "Wellbore";
                    case "Wellbore":
                        return "Trajectory";
                    case "Trajectory":
                        return "SurveySet";
                    default:
                        return "";
                }
            };

            var attemptToAddStandardChildOption = function (options) {
                var selected = $scope.hierarchyTree.selected;
                if (selected.commonName !== 'SurveySet' && selected.commonName !== 'Trajectory' &&
                    selected.commonName !== 'Wellbore' && selected.children !== undefined) {
                    options.push([
                        function ($itemScope, $event) {
                            if ($itemScope.node !== undefined &&
                                $scope.hierarchyTree.selected.id === $itemScope.node.id) {
                                var childCommonName;
                                if ($scope.hierarchyTree.selected.children.length === 0) {
                                    childCommonName =
                                        findChildNameForCommon($scope.hierarchyTree.selected.commonName);
                                } else {
                                    childCommonName = $scope.hierarchyTree.selected.children[0].commonName;
                                }
                                return "Create New " + childCommonName + " for " + $itemScope.node.name;
                            } else {
                                return null;
                            }
                        }, function ($itemScope) {
                            var newObject = {
                                commonName: findChildNameForCommon($scope.hierarchyTree.selected.commonName)
                            };
                            newObject["parentId"] = $scope.hierarchyTree.selected.id;
                            var createObjectModal = $uibModal.open({
                                templateUrl: 'html/views/admin/CreateObjectModal.html',
                                controller: 'CreateObjectModalController',
                                backdrop: 'static',
                                resolve: {
                                    newObject: function () {
                                        return newObject;
                                    }
                                }
                            });
                            createObjectModal.result.then(function (newObjectList) {
                                if (newObjectList && !newObjectList.isEmpty) {
                                    angular.forEach(newObjectList, function (newObject) {
                                        newObject.children = [];
                                        $itemScope.node.children.push(newObject);
                                    });
                                }
                            });
                        }, function ($itemScope, $event) {
                            if ($scope.hierarchyTree.selected !== null &&
                                $scope.hierarchyTree.selected !== undefined &&
                                $scope.hierarchyTree.selected.commonName !== 'SurveySet' &&
                                $scope.hierarchyTree.selected.commonName !== 'Trajectory' &&
                                $scope.hierarchyTree.selected.commonName !== 'Wellbore') {
                                return $scope.checkPerm($scope.hierarchyTree.selected, 'CREATE');
                            } else {
                                return false;
                            }
                        }]);
                }
            };

            var attemptToAddViewWellbore = function (options) {
                var selected = $scope.hierarchyTree.selected;
                if (selected.commonName === 'Wellbore') {
                    options.push([
                        function ($itemScope, $event) {
                            if ($itemScope.node !== undefined &&
                                $scope.hierarchyTree.selected.id === $itemScope.node.id) {
                                return "View Wellbore: " + $itemScope.node.name;
                            } else {
                                return null;
                            }
                        }, function ($itemScope) {
                            $state.go("wellboreDetail", {wellboreId: $itemScope.node.id});

                        }, function ($itemScope, $event) {
                            if ($scope.hierarchyTree.selected !== null &&
                                $scope.hierarchyTree.selected !== undefined &&
                                $scope.hierarchyTree.selected.commonName === 'Wellbore') {
                                return $scope.checkPerm($scope.hierarchyTree.selected, 'READ');
                            } else {
                                return false;
                            }
                        }]);
                }
            };

            var attemptToAddTrajectoryOption = function (options) {
                var selected = $scope.hierarchyTree.selected;
                if (selected.commonName === 'Wellbore') {
                    options.push([
                        function ($itemScope, $event) {
                            if ($itemScope.node !== undefined &&
                                $scope.hierarchyTree.selected.id === $itemScope.node.id) {
                                return "Create New Trajectory for " + $itemScope.node.name;
                            } else {
                                return null;
                            }
                        }, function ($itemScope) {
                            var newObject = {
                                commonName: "Trajectory"
                            };
                            newObject["parentId"] = $scope.hierarchyTree.selected.id;
                            var createObjectModal = $uibModal.open({
                                templateUrl: 'html/views/admin/CreateObjectModal.html',
                                controller: 'CreateObjectModalController',
                                backdrop: 'static',
                                resolve: {
                                    newObject: function () {
                                        return newObject;
                                    }
                                }
                            });
                            createObjectModal.result.then(function (newObjectList) {
                                if (newObjectList  && !newObjectList.isEmpty) {
                                    angular.forEach(newObjectList, function (newObject) {
                                        $itemScope.node.children.push(newObject);
                                    });
                                }
                            });
                        }, function ($itemScope, $event) {
                            if ($scope.hierarchyTree.selected !== null &&
                                $scope.hierarchyTree.selected !== undefined &&
                                $scope.hierarchyTree.selected.commonName === 'Wellbore') {
                                return $scope.checkPerm($scope.hierarchyTree.selected, 'CREATE');
                            } else {
                                return false;
                            }
                        }]);
                }
            };

            var attemptToAddSurveySetOption = function (options) {
                var selected = $scope.hierarchyTree.selected;
                if (selected.commonName === 'Wellbore') {
                    options.push([
                        function ($itemScope, $event) {
                            if ($itemScope.node !== undefined && $scope.hierarchyTree.selected !== null &&
                                $scope.hierarchyTree.selected !== undefined &&
                                $scope.hierarchyTree.selected.id === $itemScope.node.id &&
                                $scope.hierarchyTree.selected.commonName === 'Wellbore') {
                                return "Create New SurveySet for " + $itemScope.node.name;
                            } else {
                                return null;
                            }
                        }, function ($itemScope) {
                            var newObject = {
                                commonName: "SurveySet"
                            };
                            newObject["parentId"] = $scope.hierarchyTree.selected.id;
                            var createObjectModal = $uibModal.open({
                                templateUrl: 'html/views/admin/CreateObjectModal.html',
                                controller: 'CreateObjectModalController',
                                backdrop: 'static',
                                resolve: {
                                    newObject: function () {
                                        return newObject;
                                    }
                                }
                            });
                            createObjectModal.result.then(function (newObjectList) {
                                if (newObjectList  && !newObjectList.isEmpty) {
                                    angular.forEach(newObjectList, function (newObject) {
                                        $itemScope.node.children.push(newObject);
                                    });
                                }
                            });
                        }, function ($itemScope, $event) {
                            if ($scope.hierarchyTree.selected !== null &&
                                $scope.hierarchyTree.selected !== undefined &&
                                $scope.hierarchyTree.selected.commonName === 'Wellbore') {
                                return $scope.checkPerm($scope.hierarchyTree.selected, 'CREATE');
                            } else {
                                return false;
                            }
                        }]);
                }
            };

            /**
             * This function recursively counds all of the children underneath the hierarchy of the passed in node.
             * @param  {JS Object} node the node of whos children we're counting
             * @return {integer}      the number of children +1 (for the object itself)
             */
            var countChildren = function (node) {
                var count = 0;
                if (node.children === undefined || node.children.length === 0) {
                    return 1;
                }
                node.children.forEach(function (child) {
                    count += countChildren(child);
                });
                return count + 1;
            };

            /**
             * This function simply deletes the node using our data service, and then has a SWAL as a result. the hierarchy of the object is deleted on the server side.
             * We do count the top-level children first to be able to display a number of things deleted to the MagVar user. but.. yea.
             * @param  {JS Object} nodeToDelete the top level object we're deleting.
             */
            var deleteWithSwal = function (scope) {

                var nodeToDelete = scope.node;
                var count = countChildren(nodeToDelete);
                DataService.deleteObject(nodeToDelete.commonName, nodeToDelete.id).then(function (result) {
                    SweetAlert.swal("Success!", "Successfully deleted all " + count + " objects :( ", "success");
                    if (nodeToDelete.commonName === "Company") {
                        UIBlocker.start();
                        reloadRootTree().then(function () {
                            UIBlocker.stop();
                        }, function () {
                            UIBlocker.stop();
                        });
                    }
                    else {
                        //remove the element from the parent's array of children
                        scope.$parent.node.children.splice(scope.$parent.node.children.indexOf(scope.node), 1);
                    }
                }, function (error) {

                    //Quick fix to replace Apache timeout error with something human readable. Going forward we'll want to prevent this entirely.
                    if(error.status ===502) {
                        error.data = "Request Time Out: The process took longer than expected - Ensure that your data was deleted (this may take several minutes, and you'll need to refresh).";
                    }

                    SweetAlert.swal("Error", error.data, "error");
                });
            };

            /**
             * These are the options that define our context menu when you right click any object in the tree list. $itemScope.node will give you the current node of the tree
             * that we are concerned with.
             * @type {Array}
             */
            $scope.fullMenuOptions = function () {

                var options = [];

                if ($scope.hierarchyTree.selected !== null && $scope.hierarchyTree.selected !== undefined) {

                    //Add standard child creation
                    attemptToAddStandardChildOption(options);

                    //Add trajectory creation
                    attemptToAddTrajectoryOption(options);

                    //Add survey set creation
                    attemptToAddSurveySetOption(options);

                    //Add view Wellbore
                    attemptToAddViewWellbore(options);

                    //copy SurveySet
                    attemptToCopySurveySet(options);

                    //Add create company snapshot
                    attemptToAddCreateCompanySnapshot(options);

                    //move field
                    attemptToMoveField(options);

                    //add deletion
                    options.push([
                        function ($itemScope, $event) {
                            if ($itemScope.node !== undefined &&
                                $scope.hierarchyTree.selected.id === $itemScope.node.id) {
                                return "Delete " + $itemScope.node.name;
                            }
                            else {
                                return null;
                            }
                        }, function ($itemScope) {
                            SweetAlert.swal({
                                title: "Are you sure?",
                                text: "Deletion is both permanent and immediate",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Yes! delete " + $itemScope.node.commonName + " " +
                                $itemScope.node.name + "!",
                                closeOnConfirm: false,
                                showLoaderOnConfirm: true
                            }, function (proceed) {
                                if (proceed) {
                                    if ($itemScope.node.commonName === "Company") {
                                        SweetAlert.swal({
                                            title: "Are you really sure?",
                                            text: "You're trying to delete a company, this will delete the ENTIRE hierarchy underneath this company. To give you an idea of impact, this will delete the " +
                                            $itemScope.node.children.length + " fields below this company.",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#DD6B55",
                                            confirmButtonText: "Yes I'm really sure! delete " +
                                            $itemScope.node.commonName +
                                            " " + $itemScope.node.name + "!",
                                            closeOnConfirm: false
                                        }, function (proceed) {
                                            if (proceed) {
                                                deleteWithSwal($itemScope);
                                            }
                                        });
                                    } else {
                                        deleteWithSwal($itemScope);
                                    }
                                }
                            });
                        }, function ($itemScope, $event) {
                            if ($scope.hierarchyTree.selected !== null && $scope.hierarchyTree.selected !== undefined) {
                                return $scope.checkPerm($scope.hierarchyTree.selected, 'DELETE');
                            } else {
                                return false;
                            }
                        }]);
                }

                return options;
            };

            /**
             * This function adds a company=
             * @param {JS Object} node The company to be added.
             */
            $scope.addCompany = function () {
                var newObject = {
                    commonName: "Company"
                };
                var createObjectModal = $uibModal.open({
                    templateUrl: 'html/views/admin/CreateObjectModal.html',
                    controller: 'CreateObjectModalController',
                    backdrop: 'static',
                    resolve: {
                        newObject: function () {
                            return newObject;
                        }
                    }
                });
                createObjectModal.result.then(function (closedBecauseOfSuccess) {
                    if (closedBecauseOfSuccess) {
                        UIBlocker.start();
                        reloadRootTree().then(function () {
                            UIBlocker.stop();
                        }, function () {
                            UIBlocker.stop();
                        });
                    }
                });
            };


            /**
             * This watch is so that we can set the date of our '$scope.modify.lastModifiedDate' object, as well as refresh the permissions when a user selects something from the data tree.
             */
            $scope.$watch('hierarchyTree.selected', function () {
                if ($scope.hierarchyTree.selected !== undefined &&
                    $scope.hierarchyTree.selected.lastModifiedDate !== undefined) {
                    $scope.permissionableObjectsSelectionChange($scope.selectedRow);
                    var d = new Date(0);
                    d.setUTCSeconds($scope.hierarchyTree.selected.lastModifiedDate.seconds);
                    $scope.modify.lastModifiedDate = d;
                }
            });

            /**
             * This function simply refreshes the permissions list we have with the list from the server, nuking any selections the user has made without submitting them.
             */
            $scope.discardChanges = function () {
                $scope.permissionableObjectsSelectionChange($scope.selectedRow);
                $scope.dirtyFlag = false;
            };

            /**
             * This function allows us to dynamically set what context menu options appear on the screen. an empty array makes it so that nothing appears.
             * @param  {JS object} node This is the thing the user just right clicked.
             * @return {JS Array}      the array of menu options for the particular object.
             */
            $scope.menuOptions = function (node) {
                if (node === undefined || node === null || $scope.hierarchyTree.selected === undefined) {
                    return [];
                }
                var options = [];
                if (node.id === $scope.hierarchyTree.selected.id) {
                    return $scope.fullMenuOptions();
                } else {
                    return [];
                }
            };

            /**
             * These are the actual options we use to initialize the tree with. the 'injectClasses' is how you inject various classes into the tree for certain styling/etc/whatever.
             * @type {Object}
             */
            $scope.hierarchyTree.options = {
                menuOptions: $scope.menuOptions,
                nodeChildren: "children",
                dirSelectable: true,
                templateUrl: 'html/views/admin/DataTreeTemplate.html',
                injectClasses: {
                    ul: "scroll",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"
                }
            };

            /**
             * Similar to the above, this function simply collapses every item in the tree view
             */
            $scope.collapseAll = function () {
                $scope.hierarchyTree.expandedNodes = [];
            };

            /**
             * This function just sets the selection for our selected node.
             * @param  {JS object} sel the node that is selected.
             */
            $scope.showSelected = function (sel) {
                $scope.selectedNode = sel;
            };

            $scope.printNode = function (node) {
                console.log(node);
            };

            $scope.buildInitialTree = function (companies, fields) {
                $scope.hierarchyTree.data = []; //clear out initial tree
                //build companies, this is our top level, so we actually add this one to the data tree.
                companies.forEach(function (company) {
                    company.children = [];
                    fields.forEach(function (field) {
                        if (company.id === field.parentId) {
                            company.children.push(field);
                        }
                    });
                    $scope.hierarchyTree.data.push(company);
                });
            };

            $scope.buildCompanyTree = function (parentNode, fields, pads, wells, wellbores, trajectories, surveySets) {

                wellbores.forEach(function (wellbore) {
                    wellbore.children = [];
                    trajectories.forEach(function (trajectory) {
                        if (wellbore.id === trajectory.parentId) {
                            wellbore.children.push(trajectory);
                        }
                    });
                    surveySets.forEach(function (surveySet) {
                        if (wellbore.id === surveySet.parentId) {
                            wellbore.children.push(surveySet);
                        }
                    });
                });

                //Build out our wells' children.
                wells.forEach(function (well) {
                    well.children = [];
                    wellbores.forEach(function (wellbore) {
                        if (well.id === wellbore.parentId) {
                            well.children.push(wellbore);
                        }
                    });
                });

                //Build out our pads' children.
                pads.forEach(function (pad) {
                    pad.children = [];
                    wells.forEach(function (well) {
                        if (pad.id === well.parentId) {
                            pad.children.push(well);
                        }
                    });
                });

                //Build out our fields' children.
                fields.forEach(function (field) {
                    field.children = [];
                    pads.forEach(function (pad) {
                        if (field.id === pad.parentId) {
                            field.children.push(pad);
                        }
                    });
                });
                parentNode.children = fields;
            };




            $scope.showToggle =
                function (node, expanded, $parentNode) {
                    if ($parentNode == null) {
                        //company
                        if (expanded) {
                            //load everything under company's fields
                            loadCompanyTree(node);
                        }
                        else {
                            //unload everything under company's fields
                            node.children.forEach(function (field) {
                                field.children = []; //Just let the GC deal with it
                            });
                        }
                    } else {
                        //all else
                    }
                };

            /**
             * We separate out the crud operations to make them more visible, which is what this is for.
             * @type {Array}
             */
            $scope.crudPermissions = ["CREATE", "READ", "UPDATE", "DELETE"];
            /**
             * This function simply checks to see if the give permission is a 'crud' operation.
             * @param  {String} permission this is the actual permission string that we're checking
             * @return {Boolean}            true if it's part of our crudPermissions array, false otherwise.
             */
            $scope.checkCrud = function (permission) {
                return lodash.includes($scope.crudPermissions, permission);
            };
            $scope.permissionsListDisplayData = [];
            $scope.groupData = [];
            $scope.nameData = [];
            /**
             * Returns an array of group objects containing their name and ID
             */
            $scope.getGroupData = function () {
                return DataService.getAllGroups().then(function (data) {
                    var newList = [];
                    data.data.forEach(function (dataPiece) {
                        newList.push({
                            name: dataPiece
                        });
                    });
                    $scope.groupData = newList;
                    $scope.permissionableObjectsGridOptions.data = $scope.groupData;

                    return $q.resolve();
                }, function (error) {
                    if (error.status === 403) {
                        return $q.reject();
                    }
                    SweetAlert.swal("Error getting Data", "We were unable to get the group list from the server",
                        "error");
                    return $q.reject();
                });
            };

            /**
             * Returns an array of user objects containing their name and ID
             */
            $scope.getNameData = function () {
                return DataService.getAllUsers().then(function (data) {
                    $scope.users = data.data;
                    var newList = [];
                    data.data.forEach(function (username) {
                        newList.push({
                            name: username
                        });
                    });
                    $scope.nameData = newList;
                    $scope.enableUserSelection = true;
                    $scope.permissionableObjectsGridOptions.data = $scope.nameData;
                    return $q.resolve();
                }, function (error) {
                    if (error.status === 403) {
                        return $q.reject();
                    }
                    SweetAlert.swal("Error getting Data", "We were unable to get the group list from the server",
                        "error");
                    return $q.reject();
                });
            };

            /**
             * Really just a getter for the '$scope.nameData' function because grid api requires a retrieval function here.
             * @return {JS Object Array} our array of user objects from the server.
             */
            var getNameArray = function () {
                return $scope.nameData;
            };

            /**
             * Really just a getter for the '$scope.groupData' function because grid api requires a retrieval function here.
             * @return {JS Object Array} our array of group objects from the server.
             */
            var getGroupArray = function () {
                return $scope.groupData;
            };

            /**
             * This object sets the selection option for the various drop-down menu options
             * @type {Object}
             */
            $scope.permissionableObjectTypeSelectOptions = {
                availableOptions: [{
                    name: "Users",
                    retrievalFunction: getNameArray
                }],
                selectedOption: {
                    name: "Users",
                    retrievalFunction: getNameArray
                }
            };

            var clearPermissions = function () {
                for (var i = 0; i < $scope.permissionsListDisplayData.length; i++) {
                    $scope.permissionsListDisplayData[i].isSelected = false;
                }
            };

            var setPermission = function (name, state) {
                var permission = lodash.find($scope.permissionsListDisplayData, function(permission) {
                    return permission.name === name;
                });
                if (permission !== undefined) {
                    permission.isSelected = state;
                }
                else {
                    console.error("Unknown permission: " + name);
                }
            };

            var addPermissions = function (names) {
                for (var i = 0; i < names.length; i++) {
                    setPermission(names[i], true);
                }
                $scope.setDirtyFlag();
            };

            $scope.setDefaultPermissions = function (group) {
                clearPermissions(); //Set all permissions to false
                switch (group) {
                    case "RIG" :
                        addPermissions(
                            ["CREATE", "READ", "ATTACHMENT", "API_SURVEY_READ", "CHAT", "EXPORT_TRAJECTORY", "METADATA", "NOTIFICATIONS"]);
                        break;
                    case "OPERATOR":
                        addPermissions(["READ", "ATTACHMENT", "API_SURVEY_READ", "EXPORT_TRAJECTORY", "EXPORT_MINIMAL", "METADATA", "QC_CORR", 
                        "QC_INCAZI_CALC", "QC_INCAZI_CORR", "QC_PLOTS", "QC_RAW", "QC_TOL"]);
                        break;
                    case "NONE": //does nothing, as well, since we cleared them already.
                        $scope.setDirtyFlag();
                        break;
                    default:
                    //do nothing for the time being
                }
            };

            /**
             * This function detects when a new row has been selected by a user for our 'permissionable' objects. It then goes through and either refreshes the permissions for the group or
             * the user, for the selected object on the data view.
             * @param  {JS Object} row The selected row of the permissionable objects. the 'entity' property will give you access to the things you'll need (name, uniqueID, etc.)
             */
            $scope.permissionableObjectsSelectionChange = function (row) {
                if (row === undefined || $scope.hierarchyTree.selected === undefined) {
                    return;
                }
                if ($scope.permissionableObjectTypeSelectOptions.selectedOption.name === "Groups") {
                    AuthenticationService.getSinglePermForGroup($scope.hierarchyTree.selected.commonName,
                        $scope.hierarchyTree.selected.id, "ROLE_" + row.entity.name).then(function (response) {
                        $scope.permissionsListDisplayData = [];
                        for (var propertyName in response.data) {
                            $scope.permissionsListDisplayData.push({
                                name: propertyName,
                                isSelected: response.data[propertyName]
                            });
                        }
                        $scope.checkWhitelist(row.entity.name);
                    }, function (error) {
                        SweetAlert.swal("Unable to get permissions",
                            "We were unable to retrieve the list of permissions for the group " + row.entity.name +
                            " on object " + $scope.hierarchyTree.selected.name + " sorry!", "error");
                    });
                } else {
                    var authorities = [];
                    var directoryID = "";
                    $scope.users.forEach(function (user) {
                        if (user.username === row.entity.name) {
                            user.authorities.forEach(function (group) {
                                authorities.push("ROLE_" + group);
                            });
                            directoryID = user.directoryId;
                        }
                    });
                    AuthenticationService.getSinglePerm($scope.hierarchyTree.selected.commonName,
                        $scope.hierarchyTree.selected.id, row.entity.name, authorities, directoryID)
                        .then(function (response) {
                            $scope.permissionsListDisplayData = [];
                            for (var propertyName in response.data) {
                                $scope.permissionsListDisplayData.push({
                                    name: propertyName,
                                    isSelected: response.data[propertyName]
                                });
                                $scope.checkWhitelist(row.entity.name);
                                $scope.dirtyFlag = false;
                            }
                        }, function (error) {
                            SweetAlert.swal("Unable to get permissions",
                                "We were unable to retrieve the list of permissions for the user " +
                                row.entity.name + " on object " + $scope.hierarchyTree.selected.name + " sorry!",
                                "error");
                        });
                 }
            };

            var name = {
                field: 'name',
                enableHiding : false,
                enableColumnMenu :false,
                sort: {
                    direction: uiGridConstants.ASC
                }
            };

            /**
             * This object just sets the optiosn for our grid API, nothing too fancy here.
             * @type {Object}
             */
            $scope.permissionableObjectsGridOptions = {
                // Initialize the grid
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    // assign the function to use for filtering the data grid across columns
                    $scope.gridApi.grid.registerRowsProcessor($scope.filterPermissionableObjectsGrid, 200);
                    // initialize the datagrid with the currently selected permissionable object type data
                    $scope.permissionableObjectsGridOptions.data =
                        $scope.permissionableObjectTypeSelectOptions.selectedOption.retrievalFunction();
                    // assign the function to call when the grids row selection changes.
                    $scope.gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        $scope.permissionableObjectsSelectionChange(row);
                        $scope.selectedRow = row;
                    });
                },
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                modifierKeysToMultiSelect: false,
                noUnselect: false,
                columnDefs:[name]
            };

            /**
             * This function refreshes the permissionable objects data grid when the filter has been changed.
             */
            $scope.filterPermissionableObjects = function () {
                $scope.gridApi.grid.refresh();
            };

            /**
             * This method is called whenever the filter input is changed, it is given all renderableRows in the permissionableObjects grid
             * and filters them by both their name and uniqueId fields.  The filter can be any legal RegExp.  After filtering the rows the renderableRows
             * are returned to be displayed in the grid.
             *
             * modified from tutorial at: http://ui-grid.info/docs/#/tutorial/321_singleFilter
             */
            $scope.filterPermissionableObjectsGrid = function (renderableRows) {
                // TODO: Should we put in a slight delay to prevent rapid typing to cause fields to be constantly searched?
                var fieldMatcher = new RegExp($scope.filter.permissionableObjectsFilterValue);
                renderableRows.forEach(function (row) {
                    var isRowMatching = false;
                    ["name"].forEach(function (field) {
                        if (row.entity[field].match(new RegExp(fieldMatcher, "i"))) {
                            isRowMatching = true;
                        }
                    });
                    if (!isRowMatching) {
                        row.visible = false;
                    }
                });
                return renderableRows;
            };

            /**
             * This method switches the data that is displayed in the permissionable objects data grid based on the current selected ooption in the
             * permissionable objects drop down.
             */
            $scope.permissionableObjectsTypeChange = function () {
                $scope.permissionableObjectsGridOptions.data =
                    $scope.permissionableObjectTypeSelectOptions.selectedOption.retrievalFunction();
            };


            $scope.refresh = function () {
                UIBlocker.start();
                reloadRootTree().finally(function () {
                    UIBlocker.stop();
                });
            };

            /**
             * This function initializes all of our data in the tree. This could potentially be a lot of data, sooo... yea.
             */
            $scope.initialize = function () {
                document.title = "Saphira Admin Console";
                AuthenticationService.getSessionStatus().then(function (loggedIn) {
                    //check if the user is logged in
                    if (!loggedIn) {
                        //if not, set a pending page and send them to log in
                        StateService.routing.pendingPage = "permissions";
                        $state.go("login");
                    }
                    else {
                        if(AuthenticationService.isCustomer()) {
                            $state.go("wellboreSelection");
                        }
                        UIBlocker.start();
                        ClientRequisitesService.init().then(function () {

                            var requests = [];
                            requests.push($scope.getNameData());
                            requests.push(reloadRootTree());

                            $q.all(requests).finally(function () {
                                UIBlocker.stop();
                            });
                        });
                    }
                }, function (response) {
                    SweetAlert.swal("Error", "Failed to authenticate", "error");
                    $state.go("login");
                });

            };
            $scope.initialize();
        }
    ]);
})();
