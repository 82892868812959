(function () {

    var app = angular.module('saphira');

    app.directive('mvAngleUnitSelect', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return {

                restrict: 'E',      //Enforce the directive to be used as an element

                replace: true,      //Force the directive html to replace the tag

                template: '<select>' +
                '<option value="DEGREE">Degree</option>' +
                '<option value="GRADIAN">Gradian</option>' +
                '<option value="RADIAN">Radian</option>' +
                '</select>'

            };

        }]);

})();