/**
 * MWDController. This controller handled all operations with our MWD window that is currently leveraging a modal instance.
 */
(function () {

    var app = angular.module("saphira");

    app.controller("MwdReaderMappingsController", [
        /* Angular Modules  */  "$uibModalInstance",
        /* Internal Modules */  "ClientRequisitesService",
        /* External Modules */ "SweetAlert",
        /* Input            */ "mappings", "orderOfMappings",

        function (/* Angular Modules  */ $uibModalInstance,
                  /* Internal Modules */ ClientRequisitesService,
                  /* External Modules */ SweetAlert,
                  /* Input            */ mappings, orderOfMappings) {
            var vm = this;

            vm.init = function () {

                vm.model = {
                    mappings: []
                };

                for (var i = 0; i < orderOfMappings.length; i++) {
                    vm.model.mappings.push({
                        key: orderOfMappings[i],
                        value: mappings[orderOfMappings[i]]
                    });
                }
            };

            vm.close = function (confirm) {
                $uibModalInstance.close(confirm);
            };

            vm.init();
        }]);
})();