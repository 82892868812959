(function () {

    var app = angular.module('saphira');

    app.directive('mvSurveyTypeSelect', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */ "AuthenticationService",
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */ AuthenticationService
                  /* Input            */) {

            return {

                restrict: 'E',      //Enforce the directive to be used as an element

                replace: true,      //Force the directive html to replace the tag

                link: function(scope, element, attrs) {
                    scope.isRigUser = function() {
                        return AuthenticationService.isRigUser();
                    }
                },

                template: '<select>' +
                '<option value="STANDARD">D-S</option>' +
                '<option value="POOR">D-P</option>' +
                '<option ng-if="!isRigUser()" value="DEFINITIVE_INTERPOLATED">D-I</option>' +
                '<option ng-if="!isRigUser()" value="DEFINITIVE_REPORTED">D-R</option>' +
                '<option value="ACC_CHECK">X-A</option>' +
                '<option value="BAD">X-B</option>' +
                '<option value="CHECKSHOT">X-C</option>' +
                '<option ng-if="!isRigUser()" value="INTERPOLATED">X-I</option>' +
                '</select>'

            };

        }]);

})();
