/**
 * WellboreStatusController:
 *
 *      Responsible for:
 */
(function () {
    var app = angular.module("saphira");

    app.controller("WellboreStatusController", [
        /* Angular Modules  */ '$scope', '$state', '$filter', '$rootScope', '$interval', '$window', '$location', '$timeout',
        /* 3rd Party Modules*/ 'uiGridConstants', 'SweetAlert', 'lodash',
        /* Internal Modules */ 'WellboreStatusService', 'AuthenticationService', 'StateService', 'EventService',
        'WellboreService',
        /* Input            */

        function (/* Angular Modules  */ $scope, $state, $filter, $rootScope, $interval, $window, $location, $timeout,
                  /* 3rd Party Modules*/ uiGridConstants, SweetAlert, lodash,
                  /* Internal Modules */ WellboreStatusService, AuthenticationService, StateService, EventService,
                  WellboreService
                  /* Input            */) {

            // A default refresh timer of 10 seconds
            var refreshTimer = 10000;
            var refreshPending = false;

            // A counter to keep track of re-pulling everything in order to clear out any wellbores that have completed.
            var fullRefreshCounterMax = 120; //10 minutes at a 5 second refresh
            var fullRefreshCounter = 0;

            var refreshesPerMinute = (60000 / refreshTimer);

            var latestToggle = 1;

            var latestUpdate = null;

            // Configuration for the ui grid
            var getGridOptions = function (data) {
                return {
                    enableGridMenu: true,
                    enableFiltering: true,
                    columnDefs: WellboreStatusService.getColumns(),
                    data: data,
                    multiSelect: false,
                    modifierKeysToMultiSelect: false,
                    noUnselect: true,
                    enableRowSelection: true,
                    enableRowHeaderSelection: false,
                    appScopeProvider: $scope,
                    rowTemplate: "<div ng-dblclick='grid.appScope.viewWellbore(row.entity)' ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\" class=\"ui-grid-cell\" ng-class=\"{'ui-grid-row-header-cell': col.isRowHeader }\" ui-grid-cell></div>",
                    onRegisterApi: function (gridApi) {
                        //set gridApi on scope
                        $scope.gridApi = gridApi;

                        $scope.gridApi.core.on.filterChanged($scope, function () {
                            // WellboreSelectionService.reconstructFilterOptions($scope.gridOptions.data, companyColumn,
                            //     fieldColumn, padColumn, wellColumn, rigColumn, wellboreColumn);
                        });

                        gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                            $scope.selected = row.entity;
                        });

                        gridApi.core.on.rowsRendered($scope, function () {
                            if ($scope.selIds && $scope.selIds.length > 0) {
                                $scope.selIds.forEach(function (selId) {
                                    $scope.gridOptions.data.forEach(function (row) {
                                        if (selId === row.wellboreId) {
                                            $scope.gridApi.selection.selectRow(row);
                                        }
                                    });
                                });
                            }
                        });
                    }
                };
            };
            $scope.gridOptions = getGridOptions(null);


            /**
             *  Update all the timestamps in the table
             *  This is terrible but gridui doesn't come with this functionality for SOME reason
             */
            var refreshTimestamps = function () {
                $scope.gridOptions.data.forEach(function (row) {
                    if (row.latestActivity)
                        row.latestActivity.add(latestToggle, 'milliseconds');
                    if (row.lastFile)
                        row.lastFile.add(latestToggle, 'milliseconds');
                    if (row.lastCorr)
                        row.lastCorr.add(latestToggle, 'milliseconds');
                    if (row.lastChat)
                        row.lastChat.add(latestToggle, 'milliseconds');
                });
                latestToggle *= -1;
            };

            // Navigates to the wellbore that is selected in a new tab
            $scope.viewWellbore = function (row) {
                $window.open("/#/wellboreDetail/" + row.wellboreId);
            };

            $scope.filterShowNewFiles = function () {
                $scope.gridApi.grid.clearAllFilters();
                $scope.gridApi.grid.getColumn('hasNewFile').filters[0] = {
                    term: true
                };
            };

            $scope.filterShowNewChats = function () {
                $scope.gridApi.grid.clearAllFilters();
                $scope.gridApi.grid.getColumn('hasNewChat').filters[0] = {
                    term: true
                };
            };

            $scope.filterClearNewFiles = function () {
                $scope.gridApi.grid.clearAllFilters();
            };

            /**
             * getWellboreStatuses:
             *
             *      Gets the list of wellbore statuses from the server, formats it, and places it into the table.
             *
             */
            var getWellboreStatuses = function () {

                //Check if it's time to do a full refresh. We send null to the server in that case
                var timestamp = fullRefreshCounter < fullRefreshCounterMax ? latestUpdate : null;

                //get the statuses from the server
                return WellboreService.getStatuses(timestamp).then(function (statuses) {
                    //get our new timestamp
                    latestUpdate = statuses.latest;

                    //format the data for ui-grid
                    var data = WellboreStatusService.formatDataForTable(statuses.statuses);

                    //Keep track of which wellbores are selected
                    $scope.selIds = [];
                    $scope.gridApi.selection.getSelectedRows().forEach(function (selRow) {
                        $scope.selIds.push(selRow.wellboreId);
                    });

                    //if we have no data displayed yet, display all data (initial call to server)
                    if ($scope.gridOptions.data.length === 0 || timestamp === null) {
                        fullRefreshCounter = 0;
                        $scope.gridOptions.data = data;
                    }
                    //else, if we have previous data and there is a delta update
                    else if (data.length > 0) {

                        //only update the new data and retain the selection
                        for (var i = 0; i < $scope.gridOptions.data.length; i++) {
                            for (var j = 0; j < data.length; j++) {
                                if ($scope.gridOptions.data[i].wellboreId === data[j].wellboreId) {
                                    $scope.gridOptions.data[i] = data[j];
                                }
                            }
                        }
                    }

                    updateWellboresWithNewFilesCount($scope.gridOptions.data);

                    updateWellboresWithNewChatsCount($scope.gridOptions.data);

                    if (fullRefreshCounter % refreshesPerMinute === 0)
                        refreshTimestamps();
                });
            };

            var updateWellboresWithNewChatsCount = function (data) {
                $scope.numWellboresWithNewChats = 0;
                for (var i = 0; i < data.length; i++) {
                    if (data[i].hasNewChat) {
                        $scope.numWellboresWithNewChats++;
                    }
                }
            };

            var updateWellboresWithNewFilesCount = function (data) {
                $scope.numWellboresWithNewFiles = 0;
                for (var i = 0; i < data.length; i++) {
                    if (data[i].hasNewFile) {
                        $scope.numWellboresWithNewFiles++;
                    }
                }
                $scope.totalWellbores = $scope.gridOptions.data.length;
            };

            /**
             * refresh:
             *
             *      Called whenever we want to refresh the data..  Has a check for pending so that we don't make a call
             *      while waiting for a previous response on this request.
             */
            var refresh = function () {
                refreshPending = true;
                fullRefreshCounter++;
                return getWellboreStatuses().finally(function () {
                    refreshPending = false;
                });
            };

            /**
             * startRefreshLoop:
             *
             *      Called to kick off the refresh loop.
             */
            var startRefreshLoop = function () {
                $rootScope.refreshId = $interval(function () {
                    if (!refreshPending) {
                        refresh();
                    }
                }, refreshTimer);
            };

            // Stops the refresh loop when we leave this view.
            $scope.$on("$destroy", function (/*event*/) {
                $interval.cancel($rootScope.refreshId);
            });

            /**
             * Initialization for the controller
             */
            var initialize = function () {
                document.title = "Saphira Status Overview";
                AuthenticationService.getSessionStatus().then(function (loggedIn) {
                    //check if the user is logged in
                    if (!loggedIn) {
                        //if not, set a pending page and send them to log in
                        StateService.routing.pendingPage = "wellboreSelection";
                        $state.go("login");
                    }
                    else {
                        if(AuthenticationService.isCustomer()) {
                            $state.go("wellboreSelection");
                        }
                        if (AuthenticationService === null || AuthenticationService === undefined ||
                            AuthenticationService.currentUser() === null ||
                            AuthenticationService.currentUser() === undefined) {
                            $state.go("login");
                            return;
                        }

                        //load data and then start the refresh loop
                        refresh().then(function () {
                            startRefreshLoop();
                        })

                    }
                }, function (/*response*/) {
                    SweetAlert.swal("Error", "Failed to authenticate", "error");
                    $state.go("login");
                });
            };
            initialize();

            $scope.clearChatFlag = function(data) {
                WellboreService.getManyMeta(data.wellboreId).then(function (value){
                    EventService.setNewChatFlagByWellId(value["0"].parentId, false).then(function () {
                        refresh();
                    });
                });
            };
        }
    ]);
})();
