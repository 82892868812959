/**
 * The service responsible for basic operations on a well
 */
(function () {
    var app = angular.module('saphira');

    app.factory('WellService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */  'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var WellService = {};

            //a list of urls used by the service
            var URLS = {
                getOne: '/saphira/api/repositories/well/getOne',
                getOneMeta: '/saphira/api/repositories/well/getOneMeta',
                update: '/saphira/api/repositories/well/update',
                lastModifiedDate: '/saphira/api/repositories/well/lastModifiedDate',
                setNewChatFlag: '/saphira/api/repositories/well/setNewChatFlag'
            };

            //the loaded wells
            WellService.Loaded = {};
            WellService.LoadedMeta = {};

            /**
             * get:
             *
             *      Returns the well with the corresponding id.  If
             *  the well is already loaded, it returns it.  If the
             *  well is not yet loaded, it pulls it from the server
             *  and then stores a reference to it and returns it.
             *  Standard authentication rules apply.
             *
             * @param wellId     The id of the well to return
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellService.get = function (wellId, forceUpdate) {
                if (forceUpdate !== true && wellId in WellService.Loaded) {
                    return $q.resolve(WellService.Loaded[wellId]);
                } else {
                    UIBlocker.start();
                    return $http.get(URLS.getOne, {'params': {'id': wellId}}).then(function (response) {
                        WellService.Loaded[response.data.id] = response.data;
                        UIBlocker.stop();
                        return response.data;
                    }, function (status) {
                        UIBlocker.stop();
                        return $q.reject(status);
                    });
                }
            };

            WellService.setNewChatFlag = function (wellId, flag) {
                var params = {
                    wellId: wellId,
                    flag: flag
                };

                UIBlocker.start();
                return $http.post(URLS.setNewChatFlag, null, {params: params}).then(function (response) {
                    UIBlocker.stop();
                    return response;
                }, function (response) {
                    UIBlocker.stop();
                    return response;
                });
            };

            WellService.getOneMeta = function (wellId, forceUpdate) {
                if (forceUpdate !== true && wellId in WellService.LoadedMeta) {
                    return $q.resolve(WellService.LoadedMeta[wellId]);
                } else {
                    UIBlocker.start();
                    return $http.get(URLS.getOneMeta, {'params': {'id': wellId}}).then(function (response) {
                        WellService.LoadedMeta[response.data.id] = response.data;
                        UIBlocker.stop();
                        return response.data;
                    }, function (status) {
                        UIBlocker.stop();
                        return $q.reject(status);
                    });
                }
            };

            /**
             * save:
             *
             *      Saves the given well to the server.  If the well is
             *  successfully saved, it updates the service's reference to
             *  the well.
             *
             * @param well       The well object to save
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellService.save = function (well) {
                UIBlocker.start();
                return $http.post(URLS.update, well).then(function (response) {
                    WellService.Loaded[well.id] = well;
                    UIBlocker.stop();
                    return well;
                }, function (status) {
                    UIBlocker.stop();
                    return $q.reject(status);
                });
            };

            /**
             * getLastModifiedDate:
             *
             *      Gets the last modified date for the well with the given
             *  well id.
             *
             * @param wellId     The well id
             *
             * @returns {*}
             */
            WellService.getLastModifiedDate = function (wellId) {
                return $http.get(URLS.lastModifiedDate, {'params': {'id': wellId}}).then(function (response) {
                    return response.data;
                });
            };

            /**
             * updateIfModified:
             *
             *      Updates the well corresponding to the given well id if
             *  the server has a more recent version.
             *
             * @param wellId     The well id of the well to check for updates on
             * @returns {*}
             */
            WellService.updateIfModified = function (wellId) {
                if (!(wellId in WellService.Loaded)) {
                    console.error('Attempted to check for updates on a well that isn\'t loaded');
                    return $q.reject('Well needs to be loaded before checking for updates');
                } else {
                    return WellService.getLastModifiedDate(wellId).then(function (date) {
                        var local = WellService.Loaded[wellId];

                        if (date.seconds !== local.lastModifiedDate.seconds || date.nanos !== local.lastModifiedDate.nanos) {
                            return WellService.get(wellId, true);
                        } else {
                            return local;
                        }
                    });
                }
            };

            return WellService;
        }]);
})();
