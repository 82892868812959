/**
 * The service responsible for obtaining the wells associated with the current account.
 *
 * Will look up all the well ids associated with the current account and grab all the wellboreselection metadata
 * using that list of well ids. The metadata is then stored in wellboreSelections.
 */

(function () {

    var app = angular.module('saphira');

    app.factory('WellboreSelectionService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/ 'lodash',
        /* Internal Modules */ 'HierarchyService', 'StateService', 'AuthenticationService',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/ lodash,
                  /* Internal Modules */ HierarchyService, StateService, AuthenticationService
                  /* Input            */) {

            var WellboreSelectionService = {};
            var checkPerm = AuthenticationService.checkPermissionsMapEntryManual;

            var flattenTreeArrayForWellbore = function (tree) {
                var selections = [];

                for (var w = 0; w < tree.length; w++) {
                    var well = tree[w].root;
                    var wellbores = tree[w].children;
                    for (var wb = 0; wb < wellbores.length; wb++) {
                        var wellbore = wellbores[wb].root;
                        var selection = {
                            wellName: well.name,
                            wellId: well.id,
                            wellboreName: wellbore.name,
                            uniqueWellID: wellbore.uniqueWellID !== undefined && wellbore.uniqueWellID !== "" ? wellbore.uniqueWellID : "N/A",
                            wellboreId: wellbore.id
                        };
                        selections.push(selection);
                    }
                }
                return selections;
            };

            /**
             * The function that obtains all the well metadata for all the
             * wells associated with the current account.
             *
             * @param wellIds
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellboreSelectionService.getSelections = function (status) {
                return $http.get("/saphira/api/controllers/wellbore/getSelections", {"params": {"status": status}}).then(
                    function (data, status, headers, config) {
                        return flattenTreeArray(data.data); //Data comes in as a tree and we need to flatten it for now
                    },
                    function (data, status, headers, config) {
                        return status;
                    }
                );
            };

            /**
             * The function that obtains all the well metadata for the
             * given well id associated with the current account.
             *
             * @param wellId
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellboreSelectionService.getSelectionsByWell = function (wellId) {
                return $http.get("/saphira/api/controllers/wellbore/getByWell", {"params": {"wellId": wellId}}).then(
                    function (data, status, headers, config) {
                        return flattenTreeArrayForWellbore(data.data); //Data comes in as a tree and we need to flatten it for now
                    },
                    function (data, status, headers, config) {
                        return status;
                    }
                );
            };

            /**
             * The function that obtains all the well metadata for all the
             * wells under the pad given the padId associated with the current account.
             *
             * @param padId
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            WellboreSelectionService.getSelectionsByPad = function (padId) {
                return $http.get("/saphira/api/controllers/wellbore/getByPad", {"params": {"padId": padId}}).then(
                    function (data, status, headers, config) {
                        return flattenTreeArrayForWellbore(data.data); //Data comes in as a tree and we need to flatten it for now
                    },
                    function (data, status, headers, config) {
                        return status;
                    }
                );
            };


            /**
             * Returns a list of all possible filter for the given field for the given array of objects
             * @param data
             * @param column
             * @returns {Array}
             */
            WellboreSelectionService.getColumnFilters = function (data, column) {
                var filters = [];
                var entities = lodash.uniq(data, function (e) {
                    return e[column + "Name"];
                });

                for (var i = 0; i < entities.length; i++) {
                    filters.push({value: entities[i][column + "Name"], label: entities[i][column + "Name"]});
                }

                return filters;
            };

            /**
             * Reconstructs the filter options for the grid table
             * @param data
             * @param companyColumn
             * @param fieldColumn
             * @param padColumn
             * @param wellColumn
             * @param rigColumn
             * @param wellboreColumn
             */
            WellboreSelectionService.reconstructFilterOptions = function (data, companyColumn, fieldColumn, padColumn, wellColumn, rigColumn, wellboreColumn) {

                var getColumnFilters = WellboreSelectionService.getColumnFilters;

                var companyFilter = companyColumn.filter.term;
                var fieldFilter = fieldColumn.filter.term;
                var padFilter = padColumn.filter.term;
                var wellFilter = wellColumn.filter.term;
                var rigFilter = wellColumn.filter.term;
                var wellboreFilter = wellboreColumn.filter.term;

                var filteredFields = [];
                var filteredPads = [];
                var filteredWells = [];
                var filteredRigs = [];
                var filteredWellbores = [];

                //field
                if (companyFilter !== null && companyFilter !== undefined) {
                    filteredFields = lodash.filter(data, function (o) {
                        return o.companyName === companyFilter
                    });
                    fieldColumn.filter.selectOptions = getColumnFilters(filteredFields, 'field');
                }
                else {
                    filteredFields = data;
                    fieldColumn.filter.selectOptions = getColumnFilters(filteredFields, 'field');
                }
                if (!lodash.some(fieldColumn.filter.selectOptions, function (temp) {
                        return temp.value == fieldFilter
                    })) {
                    fieldFilter = null;
                }

                //pad
                if (fieldFilter !== null && fieldFilter !== undefined) {
                    filteredPads = lodash.filter(filteredFields, function (o) {
                        return o.fieldName === fieldFilter;
                    });
                    padColumn.filter.selectOptions = getColumnFilters(filteredPads, 'pad');
                }
                else {
                    filteredPads = filteredFields;
                    padColumn.filter.selectOptions = getColumnFilters(filteredPads, 'pad');
                }
                if (!lodash.some(padColumn.filter.selectOptions, function (temp) {
                        return temp.value == padFilter
                    })) {
                    padFilter = null;
                }

                //well
                if (padFilter !== null && padFilter !== undefined) {
                    filteredWells = lodash.filter(filteredPads, function (o) {
                        return o.padName === padFilter;
                    });
                    rigColumn.filter.selectOptions = getColumnFilters(filteredWells, 'well');
                }
                else {
                    filteredWells = filteredPads;
                    wellColumn.filter.selectOptions = getColumnFilters(filteredWells, 'well');
                }
                if (!lodash.some(wellColumn.filter.selectOptions, function (temp) {
                        return temp.value == wellFilter
                    })) {
                    wellFilter = null;
                }

                //rig
                if (padFilter !== null && padFilter !== undefined) {
                    filteredRigs = lodash.filter(filteredPads, function (o) {
                        return o.padName === padFilter;
                    });
                    rigColumn.filter.selectOptions = getColumnFilters(filteredRigs, 'rig');
                }
                else {
                    filteredRigs = filteredPads;
                    rigColumn.filter.selectOptions = getColumnFilters(filteredRigs, 'rig');
                }
                if (!lodash.some(rigColumn.filter.selectOptions, function (temp) {
                        return temp.value == rigFilter
                    })) {
                    rigFilter = null;
                }

                //wellbore
                if (wellFilter !== null && wellFilter !== undefined) {
                    filteredWellbores = lodash.filter(filteredWells, function (o) {
                        return o.wellName === wellFilter;
                    });
                    wellboreColumn.filter.selectOptions = getColumnFilters(filteredWellbores, 'wellbore');
                }
                else {
                    filteredWellbores = filteredWells;
                    wellboreColumn.filter.selectOptions = getColumnFilters(filteredWellbores, 'wellbore');
                }
                if (!lodash.some(wellboreColumn.filter.selectOptions, function (temp) {
                        return temp.value == wellboreFilter
                    })) {
                    wellboreFilter = null;
                }
            };

            /**
             * Reconstructs the filter options for the grid table
             * @param data
             * @param wellColumn
             * @param wellboreColumn
             */
            WellboreSelectionService.reconstructFilterOptions = function (data, wellColumn, wellboreColumn) {

                var getColumnFilters = WellboreSelectionService.getColumnFilters;

                var wellFilter = wellColumn.filter.term;
                var wellboreFilter = wellboreColumn.filter.term;


                var filteredWells = [];
                var filteredWellbores = [];

                //wellbore
                if (wellFilter !== null && wellFilter !== undefined) {
                    filteredWellbores = lodash.filter(filteredWells, function (o) {
                        return o.wellName === wellFilter;
                    });
                    wellboreColumn.filter.selectOptions = getColumnFilters(filteredWellbores, 'wellbore');
                }
                else {
                    filteredWellbores = filteredWells;
                    wellboreColumn.filter.selectOptions = getColumnFilters(filteredWellbores, 'wellbore');
                }
                if (!lodash.some(wellboreColumn.filter.selectOptions, function (temp) {
                    return temp.value === wellboreFilter
                })) {
                    wellboreFilter = null;
                }
            };


            /**
             * Loads and selects a wellbore from the given id, then its parent Well, Pad, Field, and Company.
             * @param wellboreId
             * @returns {deferred.promise|{then, catch, finally}|{then}}
             */
            WellboreSelectionService.loadAndSelectWellboreAndParents = function(wellboreId, qcPlotsPermission) {

                var deferred = $q.defer();

                //first, select and load the wellbore
                StateService.wellboreDetailView.selectedWellboreId = wellboreId;
                HierarchyService.loadObjectOrObjectMeta("Wellbore", StateService.wellboreDetailView.selectedWellboreId).then(function (wellbore) {

                    //select and load the parent well
                    StateService.wellboreDetailView.selectedWellId = wellbore.parentId;
                    HierarchyService.loadObjectOrObjectMeta("Well", StateService.wellboreDetailView.selectedWellId).then(function (well) {

                        //Return here if a rig user and doesnt have qc plots permission, otherwise continue and grab the rest.
                        if(AuthenticationService.currentUser().isRigUser && !qcPlotsPermission) {
                            //successfully loaded everything
                            deferred.resolve();
                        }
                        else {
                            //select and load the parent pad
                            StateService.wellboreDetailView.selectedPadId = well.parentId;
                            HierarchyService.loadObjectOrObjectMeta("Pad", StateService.wellboreDetailView.selectedPadId).then(function (pad) {

                                //select and load the parent field
                                StateService.wellboreDetailView.selectedFieldId = pad.parentId;
                                HierarchyService.loadObjectOrObjectMeta("Field", StateService.wellboreDetailView.selectedFieldId).then(function (field) {

                                    //select and load the parent company
                                    StateService.wellboreDetailView.selectedCompanyId = field.parentId;
                                    HierarchyService.loadObjectOrObjectMeta("Company", StateService.wellboreDetailView.selectedCompanyId).then(function (company) {
                                        //successfully loaded everything
                                        deferred.resolve();

                                    }, function (response) {
                                        //failed to load the company
                                        deferred.reject("Failed to load the Company");
                                    })

                                }, function (response) {
                                    //failed to load the field
                                    deferred.reject("Failed to load the Field");
                                })

                            }, function (response) {
                                //failed to load the pad
                                deferred.reject("Failed to load the Pad");
                            })
                        }

                    }, function (response) {
                        //failed to load the well
                        deferred.reject("Failed to load the Well");
                    })

                }, function(response) {
                    //failed to load the wellbore
                    deferred.reject("Failed to load the Wellbore");
                });

                return deferred.promise;
            };

            var flattenTreeArray = function (tree) {
                var selections = [];

                for (var c = 0; c < tree.length; c++) {
                    var company = tree[c].root;
                    var fields = tree[c].children;
                    for (var f = 0; f < fields.length; f++) {
                        var field = fields[f].root;
                        var pads = fields[f].children;

                        for (var p = 0; p < pads.length; p++) {
                            var pad = pads[p].root;
                            var wells = pads[p].children;

                            for (var w = 0; w < wells.length; w++) {
                                var well = wells[w].root;

                                var wellbores = wells[w].children;
                                for (var wb = 0; wb < wellbores.length; wb++) {
                                    var wellbore = wellbores[wb].root;
                                    var selection = {
                                        companyName: company.name,
                                        companyId: company.id,
                                        fieldName: field.name,
                                        fieldId: field.id,
                                        padName: pad.name,
                                        padId: pad.id,
                                        wellName: well.name,
                                        wellId: well.id,
                                        rigName: well.rigName !== undefined && well.rigName !== null ? well.rigName : "N/A",
                                        wellboreName: wellbore.name,
                                        uniqueWellID: wellbore.uniqueWellID !== undefined && wellbore.uniqueWellID !== "" ? wellbore.uniqueWellID : "N/A",
                                        wellboreId: wellbore.id,
                                        wellboreStatus: wellbore.status,
                                        latestSurveyTime: wellbore.latestSurveyTime !== undefined && wellbore.latestSurveyTime.seconds !== undefined ?
                                            new Date(wellbore.latestSurveyTime.seconds * 1000) : null
                                    };
                                    selections.push(selection);
                                }
                            }
                        }
                    }
                }
                return selections;
            };

            return WellboreSelectionService;
        }]);
})();

