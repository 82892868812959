/**
 * This is a really simple controller with a series of radio buttons that describe the various import options we support.
 */
(function () {

    var app = angular.module("saphira");

    app.controller("ImportSurveyFileOptionsModalController", [
        /* Angular Modules  */ "$scope", "$uibModalInstance",
        /* 3rd Party Modules*/ "SweetAlert",
        /* Internal Modules */ "ClientRequisitesService",
        /* Input            */

        function (/* Angular Modules  */ $scope, $uibModalInstance,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ ClientRequisitesService
                  /* Input            */) {

            $scope.selectedOption = {
                choice: ""
            };
            $scope.options = [];
            var init = function () {
                ClientRequisitesService.init().then(function (response) {
                    $scope.options = ClientRequisitesService.data.ImportOptions;
                }, function (error) {
                    SweetAlert.swal("Error!", "Error getting the import options from the server", "error");
                });
            };
            init();

            /**
             * Simply chooses the option, and then closes the modals with the chosen option. eeeeassy.
             * @param  {Enum} option The chosen import options enum
             */
            $scope.chooseOption = function (option) {
                $uibModalInstance.close(option.choice);
            }
        }
    ]);
})();