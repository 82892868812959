/**
 * PingService:
 *
 *      This service is responsible for determining the time between the client and the server.
 *
 * Created by cperry on 6/30/2015.
 */

(function () {

    var app = angular.module('saphira');

    app.service('PingService', [
        /* Angular Modules  */ '$q', '$http', '$window', '$timeout',
        /* 3rd Party Modules*/ "SweetAlert",
        /* Internal Modules */
        /* Input            */
        function (/* Angular Modules  */ $q, $http, $window, $timeout,
                  /* 3rd Party Modules*/ SweetAlert
                  /* Internal Modules */
                  /* Input            */) {

            var response = {};

            var version = "2.10";

            response.latency = 0;
            response.status = "Unavailable";
            response.error = "";

            this.getLatency = function () {
                return response.latency;
            };

            this.getStatus = function () {
                return response.status;
            };

            this.getError = function () {
                return response.error;
            };


            /**
             * ping:
             *
             *      Tests how long it takes to get a response from the server for a simple request.
             *      Hopefully gives an idea on whether the server is up and how much time there is.
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            this.ping = function () {
                var deferred = $q.defer();
                var startTime = new Date().getTime();
                response.error = "";

                $http.get("/saphira/api/ping", null).success(function (data, status, headers, config) {

                    if(status === 200 && version !== data) {
                        SweetAlert.swal({
                            title: "New version of Saphira",
                            text: "Saphira has updated to version " + data + ".\nPlease perform a hard refresh by pressing:\n<Ctrl + Shift + R> or <Cmd + Shift + R>",
                            type: "warning",
                            showCancelButton: true,
                            showConfirmButton: false,
                            closeOnConfirm: false
                        });
                    }

                    response.error = "Available";
                    response.status = status;
                    var endTime = new Date().getTime();
                    response.latency = endTime - startTime;

                    deferred.resolve();
                }).error(function (data, status, headers, config) {

                    //console.log("Ping failure: " + status);
                    switch (status) {
                        case 401:
                            response.error = "Unauthorized";
                            var endTime = new Date().getTime();
                            response.latency = endTime - startTime;
                            break;
                        case 404:
                            response.status = status;
                            response.error = "Not Found";
                            break;
                        case 503:
                            response.status = status;
                            response.error = "Unavailable";
                            break;
                        default:
                            response.status = status;
                            response.error = "Unknown Error";
                            break;
                    }

                    //console.log("Failed status: " + response.status);
                    //console.log("Failed time: " + response.latency);
                    deferred.resolve();
                });
                return deferred.promise;
            };

        }]);
})();