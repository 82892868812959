/**
 * Data Validation controller
 * Handles the displaying of validation errors
 */

(function () {

    var app = angular.module('saphira');

    app.controller('TieInSetupModalController', [
        /* Angular Modules  */ '$scope', '$uibModal', '$timeout', '$q', '$filter',
        /* 3rd Party Modules*/ 'SweetAlert',
        /* Internal Modules */ 'HierarchyService', 'StateService', 'UIBlocker', "InputValidationService",
        /* Input            */ 'field', 'well', 'trajectory', 'surveySet', 'definitivePath', 'initialMode',

        function (/* Angular Modules  */ $scope, $uibModal, $timeout, $q, $filter,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ HierarchyService, StateService, UIBlocker, InputValidationService,
                  /* Input            */ field, well, trajectory, surveySet, definitivePath, initialMode) {

            $scope.field = field;
            $scope.well = well;
            $scope.trajectory = trajectory;
            $scope.surveySet = surveySet;

            $scope.InputValidationService = InputValidationService;

            $scope.InputValidationService = InputValidationService;

            // Accordion pane visibilities
            $scope.sections = {
                'create': initialMode === 'create',
                'select': initialMode === 'select'
            };

            // Options for the creation mode.
            $scope.create = {
                md: 0,
                tvd: 0,
                inc: 0,
                azi: 0,
                northings: 0,
                eastings: 0,
                verticalSection: 0,
                trajectoryId: trajectory.id
            };

            // Options for the selection mode.
            $scope.select = {
                'options': {
                    'Wellbores': [],
                    'Trajectory': [],
                    'SurveySet': [],
                    'Survey': []
                },
                'selected': {
                    'Wellbore': undefined,
                    'Trajectory': undefined,
                    'SurveySet': undefined,
                    'Survey': undefined
                },
                'surveyTableOptions': {
                    'multiSelect': false,
                    'displayedColumns': [
                        {
                            'field': 'mdVal',
                            'title': 'MD'
                        },
                        {
                            'field': 'parentSurveySetName',
                            'title': 'Survey Set'
                        }
                    ]
                }
            };

            $scope.$watch('sections.select', function (newValue, oldValue) {
                console.warn("sections.select changed: ", newValue, oldValue);
                if (newValue) {
                    // Set a scope variable on a time delay so the table has time to do layout before column widths are calculated.
                    $timeout(function () {
                        $scope.laidOut = true;
                    });
                } else {
                    $scope.laidOut = false;
                }
            });

            /**
             * Called to change the selected wellbore. Updates the list of available trajectories.
             *
             * @param {Object} wellbore    The newly-selected wellbore (metadata) object.
             */
            $scope.selectWellbore = function (wellbore) {
                //if we were given a wellbore
                if (typeof(wellbore) !== 'undefined') {

                    //load the wellbore
                    return HierarchyService.Wellbore.get(wellbore.id).then(function (wellbore) {

                        //select the wellbore
                        $scope.select.selected.Wellbore = wellbore;

                        // //load the children trajectories
                        // HierarchyService.Trajectory.getManyMetaByParent(wellbore.id).then(function (trajectoryMetas) {
                        //
                        //     //set the options to select trajectories from
                        //     $scope.select.options.Trajectory = trajectoryMetas;
                        //
                        //     //reset the trajectory selection
                        //     return $scope.selectTrajectory(undefined);
                        // });

                        //load all of the trajectory metas under this wellbore
                        HierarchyService.Trajectory.getManyMetaByParent(wellbore.id).then(function (trajectoryMetas) {
                            $scope.select.options.Trajectory = [];
                            $scope.select.selected.Trajectory = undefined;
                            //populate the list of trajectories (except the current one) under the wellbore
                            trajectoryMetas.forEach(function (trajectoryMeta) {
                                if (trajectoryMeta.id !== StateService.wellboreDetailView.selectedTrajectoryId) {
                                    $scope.select.options.Trajectory.push(trajectoryMeta);
                                }
                            });

                            return $scope.selectTrajectory(undefined);
                        })
                    });

                    //if we were not given a wellbore
                } else {
                    //zero out all data under the wellbore
                    $scope.select.selected.Wellbore = undefined;
                    $scope.select.options.Trajectory = [];
                    return $scope.selectTrajectory(undefined);
                }
            };

            var setSurveySetNamesOnTable = function () {
                //add a field to each survey with the name of the Survey Set (for the table)
                var surveySetIdsList = [];
                angular.forEach($scope.select.options.Survey, function (survey) {
                    if (!surveySetIdsList.includes(survey.parentSurveySetId)) {
                        surveySetIdsList.push(survey.parentSurveySetId);
                    }
                });
                HierarchyService.SurveySet.getManyMeta(surveySetIdsList).then(function (surveySetMetas) {
                    angular.forEach(surveySetMetas, function (surveySet) {
                        angular.forEach($scope.select.options.Survey, function (survey) {
                            if (!survey.parentSurveySetName && survey.parentSurveySetId === surveySet.id) {
                                survey.parentSurveySetName = surveySet.name;
                            }
                        })
                    })
                });
            };

            /**
             * Called to change the selected trajectory. Updates the list of available surveys.
             *
             * @param {Object} trajectoryMeta    The newly-selected trajectory (metadata) object.
             */
            $scope.selectTrajectory = function (trajectoryMeta) {

                //if we were given something other than a trajectory...
                if (typeof(trajectoryMeta) === 'undefined') {
                    //zero everything out!
                    $scope.select.options.Survey = [];
                    $scope.select.selected.Survey = [];
                    return;
                }

                //load the surveys on the trajectory
                HierarchyService.Trajectory.get(trajectoryMeta.id).then(function (newTrajectory) {
                    $scope.select.options.Survey = [];

                    //"flatten" out the surveys
                    angular.forEach(newTrajectory.definitiveSurveys, function (survey) {
                        survey.mdVal = survey.md.value;
                    });

                    var firstSurvey = definitivePath[0];

                    //create a list of surveys based on the lowest MD
                    if (!firstSurvey) {
                        //no surveys, so any can be the tie in
                        $scope.select.options.Survey = newTrajectory.definitiveSurveys;

                        //apply the surveyset names to the table
                        setSurveySetNamesOnTable();
                        return;
                    }

                    //filter out any surveys that don't meet md requirements
                    var surveysToChooseFrom = [];
                    angular.forEach(newTrajectory.definitiveSurveys, function (survey) {
                        if (survey.mdVal < firstSurvey.md.value) {
                            surveysToChooseFrom.push(survey);
                        }
                    });

                    //set the list of surveys to select from that meet md requirements
                    $scope.select.options.Survey = surveysToChooseFrom;

                    //apply the surveyset names to the table
                    setSurveySetNamesOnTable();


                    //un-select any selected survey
                    $scope.select.selected.Survey = [];
                });
            };

            /**
             * Called when the submit button is clicked. Finalizes the settings.
             */
            $scope.submit = function () {
                //if we are creating a new survey for the tie-in
                if ($scope.sections.create === true) {

                    //verify inc and azi are validated
                    var errors = InputValidationService.validateInputSet([
                        'tiein-inc', 'tiein-azi'
                    ]);
                    if (errors.length > 0) {
                        swal(
                            'Error validating tie-in values!',
                            errors[0],
                            'error'
                        );
                        return;
                    }

                    var firstSurvey = definitivePath[0];

                    //if we have surveys in the current survey set
                    if (firstSurvey) {

                        //if the desired tie-in md is deeper than any existing surveys
                        if (parseFloat($scope.create.md) >= firstSurvey.md.value) {
                            //format an error sweet alert
                            var unitString = $filter('distanceUnit')(surveySet.unitSet.depthUnit);
                            SweetAlert.swal("Error", "Tie-In MD (" + parseFloat($scope.create.md) + " " + unitString +
                                ") must be shallower than any existing surveys (" + firstSurvey.md.value + " " +
                                unitString + ").", "error");

                            //since there was an error, we dont want to persist anything
                            return;
                        }

                        //if the md is the same, make sure min curve values match
                        else if (parseFloat($scope.create.md) === firstSurvey.md.value) {

                            var createNorthString = $filter('distanceDecimal')(parseFloat($scope.create.northings),
                                field.unitSet.distanceUnit);
                            var createEastString = $filter('distanceDecimal')(parseFloat($scope.create.eastings),
                                field.unitSet.distanceUnit);
                            var createVsString = $filter('distanceDecimal')(parseFloat($scope.create.verticalSection),
                                well.unitSet.depthUnit);

                            var lowestNorthString = $filter('distanceDecimal')(firstSurvey.north.value,
                                field.unitSet.distanceUnit);
                            var lowestEastString = $filter('distanceDecimal')(firstSurvey.east.value,
                                field.unitSet.distanceUnit);
                            var lowestVsString = $filter('distanceDecimal')(firstSurvey.verticalSection.value,
                                well.unitSet.depthUnit);

                            if (createNorthString !== lowestNorthString ||
                                createEastString !== lowestEastString ||
                                createVsString !== lowestVsString) {

                                //format an error sweet alert
                                SweetAlert.swal("Error",
                                    "Tie-In matches MD of existing survey, but min curve values don't match!", "error");

                                //since there was an error, we dont want to persist anything
                                return;
                            }
                        }
                    }

                    trajectory.tieInOriginalSurveyId = null;
                    trajectory.tieInOriginalTrajectoryId = null;
                    trajectory.tieInSource = 'MANUAL';
                    HierarchyService.Trajectory.save(trajectory).then(function () {

                        //create the tie-in from the create input fields
                        HierarchyService.Survey.createTieIn($scope.create).then(function (createdTieIn) {

                            //save the trajectory so that it knows about the new tie in
                            return HierarchyService.Trajectory.get(trajectory.id, true).then(function () {

                                //close the modal
                                $scope.$close();
                            });

                            //if we were unable to save the tie-in
                        }, function (error) {
                            console.error(error);
                            SweetAlert.swal("Error", "An unknown error occurred. Please check your inputs.", "error");
                        });
                    });
                    //if we are selecting a pre-existing survey for the tie-in
                } else if ($scope.sections.select === true) {

                    //make sure a survey is selected
                    if ($scope.select.selected.Survey.length === 0) {
                        SweetAlert.swal("Error",
                            "You must select a survey, or create one by clicking on the 'Create New Tie-In Survey' button.'");
                        return;
                    }

                    //make sure the survey selected isn't already the one currently set as tie-in
                    if (trajectory.tieInOriginalSurveyId === $scope.select.selected.Survey[0].id && trajectory.tieInSource === $scope.select.selected.Trajectory.name) {
                        SweetAlert.swal("Error",
                            "This survey and source are already set as the tie-in survey on the current trajectory.");
                        return;
                    }

                    //set the tie-in id on the trajectory
                    trajectory.tieInOriginalSurveyId = $scope.select.selected.Survey[0].id;
                    trajectory.tieInOriginalTrajectoryId = $scope.select.selected.Trajectory.id;
                    trajectory.tieInSurvey = $scope.select.selected.Survey[0];
                    trajectory.tieInSource = $scope.select.selected.Trajectory.name;

                    //then save the trajectory
                    HierarchyService.Trajectory.save(trajectory).then(function () {
                        $scope.select.selected.Trajectory = trajectory;

                        //and close the modal
                        $scope.$close();
                    }, function (error) {
                        //if we were unable to save the reference to the tie-in
                        console.error(error);
                        SweetAlert.swal("Error", "An unknown error occurred. Please check your selection.", "error");
                    });

                    //if the modal was not set to create or select (somehow...?)
                } else {
                    SweetAlert.swal("Huh?",
                        "Well this isn't supposed to happen. Try going back and forth between creating and selecting tie-ins, then try again?",
                        "error");
                }
            };

            var init = function () {

                // Immediately prepare the list of available Wellbores and survey sets.
                UIBlocker.start();

                HierarchyService.Wellbore.getManyMetaByParent(StateService.wellboreDetailView.selectedWellId).then(function (wellbores) {
                    // set the wellbore selection options to the list of metas
                    $scope.select.options.Wellbore = wellbores;
                    UIBlocker.stop();

                }, function (error) {
                    UIBlocker.stop();
                    SweetAlert.swal("Error", "Failed to load wellbores;\n" + error, "error");
                });

            };
            init();
        }
    ])
    ;
})();
