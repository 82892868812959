(function () {
  var app = angular.module('saphira');
  app.service('CaptureAndSendAnalyticsService', [
    /* Angular Modules  */
    /* 3rd Party Modules*/
    /* Internal Modules */
    /* Input            */

    function (/* Angular Modules  */
              /* 3rd Party Modules*/
              /* Internal Modules */
              /* Input            */) {

      var CaptureAndSendAnalyticsService = {};
      const DASH_VALUE = '- - -';

      /**
       * Captures event name and data
       * @param {string} eventName Name of the event
       * @param {object} eventData Event parameters
       */
      CaptureAndSendAnalyticsService.captureAnalytics = function(eventName, eventData){
        try {
          const measurementId = window['__application_properties__']['GA4_MEASUREMENT_ID'];
          sendEventAnalytics(measurementId, eventName, eventData);
        } catch (e) {
          console.error('Error sending analytics data : ' + e.toString());
        }
      };

      /**
       * validates and sends the event data to google analytics
       * @param {string} id
       * @param {string} eventName
       * @param {object} eventData
       */
      sendEventAnalytics = function(id, eventName, eventData) {
        if (arguments.length < 3) {
          throw new TypeError('Not enough arguments; id, eventName, eventData expected');
        } else if (!id || !eventName || !eventData) {
          throw new TypeError('Invalid arguments passed');
        } else if (typeof eventData !== 'object') {
          throw new TypeError('Invalid event data passed');
        }

        const mergedEventData = {'send_to': id};
        Object.assign(mergedEventData, cleanValues(eventData));

        try {
          gtag('event', eventName, mergedEventData);
        } catch (e) {
          console.error('Error sending analytics data : ' + e.toString());
        }
      };

      /**
       * Cleans the data
       * @param {Object} data
       * @return {data} with missing values as ---
       */
      var cleanValues = function (data) {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key) && (data[key] == null || data[key] === '')) {
            data[key] = DASH_VALUE;
          }
        }
        return data;
      };

      return CaptureAndSendAnalyticsService;
    }]);
})();
