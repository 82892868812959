(function () {
    /**
     * This directive is similar to our selectable table, but in list form. Each element will have either a checkbox (for multi-selecting) or radio buttons(for a single-select).
     * The directive should have the following attributes:
     *  1) elements: A list holding all of the possible options. I'm assuming all elements must have a 'toString' member which describes how to display the item, as well as an 'id' so I can filter/sort them properly.
     *  2) selected: A link to a variable that will hold the list of selected elements. If using a radio button, this MUST have an inner member called 'choice' which represents the single-radio button choice
     *  3) type: either 'checkbox" or 'radio". If "checkbox" the values are displayed with a checkbox and multiple may be selected. If "radio", the values are displayed as a series of radio buttons and only one may be selected.
     *  4) enabled: True or false. If true, the component works as expected. If false, the component is greyed out and cannot be acted on, but will still display the current selection.
     *  5) deletion: an optional function attribute handle which allows you to add a 'delete' button to the end of each line and attach a function handle to it, to specify how you want things deleted.
     *  6) selection: an optional function attribute handle which allows you to set behaviour when an item is selected.
     *  7) id: this is the id of the entire directive, used to ensure we can get specific styling/behaviour applied to different instances of the directive.
     *
     *  The following classes are available to style this directive:
     *  1) mvRadioItem - This class is every line in the directive that has a radio button type specified
     *  2) mvRadioItem-{{item.id}} - this allows for specific styling of each individual line with a radio type. id is the id of the element passed in
     *  3) mvCheckboxItem - This class is every line in the directive that has a checkbox button type specified
     *  4) mvCheckboxItem-{{item.id}} - this allows for specific styling of each individual line with a checkbox type. id is the id of the element passed in
     *  5) mv-select-list -  This is the class of the overall div which holds the entire select list.
     *  6) mvItemLabel - This is the 'label' span which holds the text of the element (the toString part), allowing you to style the text and such. This is every label in the list
     *  7) mvItemLabel-{{item.id}} - This styles individual labels based on the ID.
     *  8) mv-select-item-delete - This styles every one of the 'delete' buttons, if they exist.
     *  9) mv-select-item-delete-{{item.id}} - styles the specific element, identified by the ID of the element associated with that line.
     *  10) mv-select-list-{{id}} - if you want to style a specific instance of this directive, you can use this along with child selectors to style it specifically.
     */
    angular.module('saphira').directive('mvSelectList', function($templateCache) {
        function link(scope, element, attrs){
            scope.selectionExists = 'selection' in attrs;
            scope.deletionExists = 'deletion' in attrs;
            function checkElementsEmpty(){
                if (scope.elements === null || scope.elements.length === 0){
                    if (scope.type == 'radio'){
                        scope.selected.choice = null;
                    }else{
                        scope.selected = null;

                    };
                };
            };
            checkElementsEmpty();

            function updateSelectedItems(){
                if (scope.elements === null || scope.selected === null || scope.selected === undefined ){
                    return;
                }
                if (scope.type == 'radio'){
                    if (scope.selected.choice === null){
                        return;
                    }
                }
                if (scope.elements.length === 0){
                     if (scope.type == 'radio'){
                        scope.selected.choice = "";
                    }else{
                        scope.selected = "";

                    };
                }
                var first = 0;
                //This entire if statement is to handle the case where the deleted item is a radio button which is currently selected. Kinda a bummer, but w/e
                if (scope.type == 'radio'){
                    //if something gets deleted, save the old choice, then clear out the choice
                    var oldChoice = scope.selected.choice;
                    scope.selected.choice = "";
                    //iterate through all of the elements that are left and see if any still exist that were selected before
                    scope.elements.forEach(function(element){
                        if (element.id === oldChoice){
                            //if something matched, it was selected and the deleted item wasn't the currently selected one, reset this, and move on with life.
                            scope.selected.choice = element;
                        };
                    });
                }else{
                    scope.selected = [];
                    scope.elements.forEach(function(element){
                        if (element.selected){
                            scope.selected.push(element.id);
                        };
                    });
                };
            };
            scope.$watch('elements', function (newTime) {
                updateSelectedItems();
            },true);
        };

        /**
         * This controller properly sets the 'selection' handler (necessary for the radio button), and calls the selection function specified by the user.
         * @param  {scope variable} $scope the injected scope for our controller
         * @return doesn't return anything, just makes an async call out to the user-defined function
         */
        function controller($scope){
            $scope.handleSelectionEvent = function(element){
                if ($scope.selectionExists){
                    $scope.selection(element);
                };
            };
        };

        return {
            link: link,
            controller: controller,
            scope: {
                elements: '=',
                id: '=',
                type: '=',
                enabled: '=',
                selected: '=',
                deletion: "&?",
                selection: "&?"
            },
            templateUrl: 'html/templates/selectList.html',
            restrict: 'E'
        };
    });
})();