/**
 * FileTemplateService:
 *
 *      Responsible for:
 */
(function () {
    var app = angular.module('saphira');

    app.factory("SurveyCorrectionTimesPlottingService", [
        /* Angular Modules  */ "$filter",
        /* 3rd Party Modules*/
        /* Internal Modules */ "PlotHelperService", "PlotStyleService",
        /* Input            */

        function (/* Angular Modules  */ $filter,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ PlotHelperService, PlotStyleService
                  /* Input            */) {

            var service = {};

            /**
             * Returns plot data for survey correction times
             * @param surveys
             * @param unitSet
             * @returns {Object}
             */
            service.plotSurveyCorrectionTimes = function (surveys, unitSet, surveySetName, startTime) {
            	surveys = PlotHelperService.getSurveysByCorrectionTime(surveys, startTime);

                var times = [];

                for(var i = 0; i < surveys.length; i++) {
                	var md = surveys[i].md.value;
                    var submittedTime = surveys[i][startTime].seconds;
                    var correctedTime = surveys[i].correctedTime.seconds;

                    var diffDuration = this.getDateDiff(submittedTime, correctedTime);
                    times.push([md, moment(diffDuration.hours + ":" + diffDuration.minutes + ":" + diffDuration.seconds, "hh:mm:ss").valueOf()]);
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle(surveySetName + ' Survey Correction Times from ' + ((startTime === 'receivedTime') ? 'Survey Submission' : 'File Submission')),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Survey Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDateTimeYAxis("Time"),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(unitSet.depthUnit, null),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDateTimeToolTip(unitSet.depthUnit, 'datetime'),

                    series: [
                        PlotStyleService.createMeasuredSeries("Correction Time", times)
                    ],
                    lang: {
                        noData: "No Timestamp Data Available"
                    },
                    noData: PlotStyleService.getDefaultNoData()
                };
            };

            /**
             * Returns pie chart data for survey correction times
             * @param surveys
             * @param unitSet
             * @returns {Object}
             */
            service.plotPieSurveyCorrectionTimes = function (surveys, unitSet, startTime) {
            	surveys = PlotHelperService.getSurveysByCorrectionTime(surveys, startTime);

                var series = [];
                var correctionTime = {
                	"<1 min": 0,
                	"1-2 min": 0,
                	"2-3 min": 0,
                	"3+ min": 0
                }

                for(var i = 0; i < surveys.length; i++) {
                    var submittedTime = surveys[i][startTime].seconds;
                    var correctedTime = surveys[i].correctedTime.seconds;

                    var diffDurationSec = correctedTime - submittedTime;

                    if(diffDurationSec < 60) {
                        correctionTime["<1 min"]++;
                    } else if(diffDurationSec < 120) {
                        correctionTime["1-2 min"]++;
                    } else if(diffDurationSec < 180) {
                        correctionTime["2-3 min"]++;
                    } else {
                        correctionTime["3+ min"]++;
                    };
                }

                for(var key in correctionTime) {
                	if(correctionTime[key] > 0) { 
                		series.push([key, correctionTime[key]]);
                	};
                }


                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Survey Corrections Times from ' + ((startTime === 'receivedTime') ? 'Survey Submission' : 'File Submission')),
                    chart: PlotStyleService.getPieChart(),
                    plotOptions: PlotStyleService.getPiePlotOptions(),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getPieToolTip(),

                    series: [
                    	PlotStyleService.createPieSeries("Total Surveys", series)
                    ],
                    lang: {
                        noData: "No Timestamp Data Available"
                    },
                    noData: PlotStyleService.getDefaultNoData()
                };
            };

            /**
             * Returns pie chart data for multiple survey correction times
             * @param surveySet
             * @returns {Object}
             */
            service.plotMultiPieSurveyCorrectionTimes = function (surveySet, startTime) {
            	var series = [];
                var correctionTime = {
                	"<1 min": 0,
                	"1-2 min": 0,
                	"2-3 min": 0,
                	"3+ min": 0
                }

            	for (var i in surveySet) {
                    if (surveySet.hasOwnProperty(i) && surveySet[0].surveys.length > 0) {
                        var surveys = PlotHelperService.getSurveysByCorrectionTime(surveySet[i].surveys, startTime);

                        for(var j = 0; j < surveys.length; j++) {
						    var submittedTime = surveys[j][startTime].seconds;
						    var correctedTime = surveys[j].correctedTime.seconds;
						    
                            var diffDurationSec = correctedTime - submittedTime;

                            if(diffDurationSec < 60) {
                                correctionTime["<1 min"]++;
                            } else if(diffDurationSec < 120) {
                                correctionTime["1-2 min"]++;
                            } else if(diffDurationSec < 180) {
                                correctionTime["2-3 min"]++;
                            } else {
                                correctionTime["3+ min"]++;
                            };
						}
                    }
                }

                for(var key in correctionTime) {
                	if(correctionTime[key] > 0) { 
                		series.push([key, correctionTime[key]]);
                	};
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Survey Corrections Times from ' + ((startTime === 'receivedTime') ? 'Survey Submission' : 'File Submission')),
                    chart: PlotStyleService.getPieChart(),
                    plotOptions: PlotStyleService.getPiePlotOptions(),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getPieToolTip(),

                    series: [
                    	PlotStyleService.createPieSeries("Total Surveys", series)
                    ],
                    lang: {
                        noData: "No Timestamp Data Available"
                    },
                    noData: PlotStyleService.getDefaultNoData()
                };
            };

            /**
             * Get difference in time from two specified timestamp.
             * @param startDate
             * @param endDate
             * @returns {Object}
             */
            service.getDateDiff = function(startDate, endDate) {
                var diffSec = endDate - startDate;
                diffSec = Number(diffSec);
                
                return {
                    "days": Math.floor(diffSec / (3600*24)),
                    "hours": Math.floor(diffSec / 3600),
                    "minutes": Math.floor(diffSec % 3600 / 60),
                    "seconds": Math.floor(diffSec % 3600 % 60)
                }
            }

            return service;

        }]);
})();
