
(function () {
    var app = angular.module("saphira");

    app.controller("MasterChatController", ["$scope", "$rootScope", "$interval",
        "DataService", "SweetAlert", "MasterChatService", "StateService", "AuthenticationService",
        "orderByFilter",
        function ($scope, $rootScope, $interval, DataService, SweetAlert, MasterChatService,
                   StateService, AuthenticationService, orderBy) {
            $scope.refreshTimer = 2000;
            $scope.refreshId = false;
            $scope.processing = false;
            $scope.newMessage = false;
            $scope.init = function () {
                AuthenticationService.checkSessionLogin().then(function (status) {
                    if (!status) {
                        $state.go("login");
                        return false;
                    }
                    $scope.chat = {
                        options: {
                            user: AuthenticationService.currentUser().displayName,
                            userColor: '#ADD8E6',
                            otherColor: '#E4EEE0'
                        },
                        messages: []
                    };
                });
            };
            $scope.init();
            $scope.currentPage = 1;
            $scope.pageSize = 10;
            $scope.totalMessages = 0;
            $scope.totalPages = 0;

            var scrollToBottom = function(){
                var el = $('.mvChatBox_Scrollable')[0];
                setTimeout(function() {
                    el.scrollTop = el.scrollHeight;
                }, 10);
            };
            $scope.jumpToFirstPage = function(){
                $scope.currentPage = 1;
                $scope.getMessages();
            };
            $scope.checkEqual = function(res){
                var sorted = orderBy(res, 'time', true);
                sorted.forEach(function(message) {
                    message.momentTime = moment.unix(message.time.seconds);
                });
                return angular.equals(angular.copy($scope.chat.messages), angular.copy(sorted));
            };
            $scope.getMessages = function(){
                //console.log('getMessages');
                $scope.processing = true;
                MasterChatService.getPageMessages($scope.currentPage, $scope.pageSize).then(function(res){
                    if (res.messages.length > 0) {

                        //if the messages are new
                        if(!$scope.checkEqual(res.messages)){
                            //re-order the messages by time and scroll down
                            $scope.chat.messages = orderBy(res.messages, 'time', true);
                            scrollToBottom();
                        }

                        //update total messages and total pages
                        $scope.totalMessages = res.totalItems;
                        $scope.totalPages = res.totalPages;

                        //if we are on the first page
                        if($scope.currentPage == 1) {
                           // console.log('latest', $scope.chat.messages[$scope.chat.messages.length - 1]);
                            $scope.latest = $scope.chat.messages[$scope.chat.messages.length - 1].time;
                        }
                    }
                    $scope.processing = false;
                    $scope.startRefresh();
                }, function (error) {
                    $scope.processing = false;
                    SweetAlert.swal("Error", "Failed to load chat messages.\n" + error.status, "error");
                });
            };

            $scope.getNewMessages = function(){
                var time1 = moment.utc($scope.latest.seconds * 1000.0);
                var time2 = moment.utc();
                MasterChatService.getMessages(time1, time2).then(function (res) {
                    if (res.messages.length > 0) {
                        $scope.newMessage = true;
                    }
                }, function (error) {
                    SweetAlert.swal("Error", "Failed to load chat messages.\n" + error.status, "error");
                });
            };
            $scope.startRefreshPages = function(){
               // console.log('startRefreshPages');
                if(!$scope.refreshId || $scope.refreshId.$$state.status != 0) {
                    $scope.newMessage = false;
                    $scope.stopGetNewMessages();
                    $scope.refreshId = $interval(function () {
                        $scope.getMessages();
                    }, $scope.refreshTimer);
                }
            };
            $scope.startGetNewMessages = function(){
                //console.log('startGetNewMessages');
                $scope.stopRefreshPages();
                $scope.newMessagesInterval = $interval(function () {
                    $scope.getNewMessages();
                }, $scope.refreshTimer);
            };
            $scope.startRefresh = function(){
                if($scope.currentPage == 1){
                    $scope.startRefreshPages();
                } else {
                    $scope.startGetNewMessages();
                }               
            };
            $scope.stopRefreshPages = function(){
                $interval.cancel($scope.refreshId);
                //console.log('stopRefreshPages',$scope.refreshId);
            };
            $scope.stopGetNewMessages = function(){
                $interval.cancel($scope.newMessagesInterval);
                //console.log('stopGetNewMessages',$scope.newMessagesInterval);
            };
            $scope.getMessages();
            $scope.$on(
                "$destroy", function(){
                    $scope.stopRefreshPages();
                    $scope.stopGetNewMessages();
                }
            );
        }
    ]);
})();