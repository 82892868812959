(function () {
    var app = angular.module('saphira');

    app.factory('InputValidationService', [
        /* Angular Modules  */ "$timeout",
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */ $timeout
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            var Service = {};
            var rangeMap = {};

            // DISTANCE UNITS -- acceptable ranges for distances in various units (ranges should be equivalent across units)

            rangeMap["METER"] = {};
            rangeMap["METER"]       ["Standard"] = {min: 0, max: 6096000};
            rangeMap["METER"]       ["md"] = {min: 0, max: 30480};
            rangeMap["METER"]       ["tvd"] = {min: 0, max: 30480};
            rangeMap["METER"]       ["local"] = {min: -60960000, max: 60960000};

            rangeMap["KILOMETER"] = {};
            rangeMap["KILOMETER"]   ["Standard"] = {min: 0, max: 6096};
            rangeMap["KILOMETER"]   ["md"] = {min: 0, max: 30.48};
            rangeMap["KILOMETER"]   ["tvd"] = {min: 0, max: 30.48};
            rangeMap["KILOMETER"]   ["local"] = {min: -60096, max: 60096};

            rangeMap["FOOT"] = {};
            rangeMap["FOOT"]        ["Standard"] = {min: 0, max: 20000000};
            rangeMap["FOOT"]        ["md"] = {min: 0, max: 100000};
            rangeMap["FOOT"]        ["tvd"] = {min: 0, max: 100000};
            rangeMap["FOOT"]        ["local"] = {min: -200000000, max: 200000000};

            rangeMap["FOOT_US"] = {};
            rangeMap["FOOT_US"]     ["Standard"] = {min: 0, max: 20000000};
            rangeMap["FOOT_US"]     ["md"] = {min: 0, max: 99999.8};
            rangeMap["FOOT_US"]     ["tvd"] = {min: 0, max: 99999.8};
            rangeMap["FOOT_US"]     ["local"] = {min: -200000000, max: 200000000};

            rangeMap["YARD"] = {};
            rangeMap["YARD"]        ["Standard"] = {min: 0, max: 6666666.6667};
            rangeMap["YARD"]        ["md"] = {min: 0, max: 33333.333};
            rangeMap["YARD"]        ["tvd"] = {min: 0, max: 33333.333};
            rangeMap["YARD"]        ["local"] = {min: -66666660.6667, max: 66666660.6667};

            rangeMap["MILE"] = {};
            rangeMap["MILE"]        ["Standard"] = {min: 0, max: 3787.8787879};
            rangeMap["MILE"]        ["md"] = {min: 0, max: 18.939393939393938};
            rangeMap["MILE"]        ["tvd"] = {min: 0, max: 18.939393939393938};
            rangeMap["MILE"]        ["local"] = {min: -37870.8787879, max: 37870.8787879};

            // ANGLE UNITS -- acceptable ranges for angles in various units (ranges should be equivalent across units)

            // NOTE: Angle units are sometimes from 0 -> 360, and sometimes from -180 -> 180. So all of the ranges for
            // angle units are from the equivalent of -180 degrees to 360 degrees to allow for all input.
            rangeMap["DEGREE"] = {};
            rangeMap["DEGREE"]      ["Standard"] = {min: -180, max: 360};
            rangeMap["DEGREE"]      ["dip"] = {min: -90, max: 90};
            rangeMap["DEGREE"]      ["dec"] = {min: -180, max: 180};
            rangeMap["DEGREE"]      ["gridConvergence"] = {min: -179.9999999999, max: 180};
            rangeMap["DEGREE"]      ["inc"] = {min: 0, max: 180};
            rangeMap["DEGREE"]      ["azi"] = {min: 0, max: 360};
            rangeMap["DEGREE"]      ["toolFace"] = {min: 0, max: 360};

            rangeMap["RADIAN"] = {};
            rangeMap["RADIAN"]      ["Standard"] = {min: -3.14159, max: 6.28319};
            rangeMap["RADIAN"]      ["dip"] = {min: -1.5708, max: 1.5708};
            rangeMap["RADIAN"]      ["dec"] = {min: -3.14159, max: 3.14159};
            rangeMap["RADIAN"]      ["gridConvergence"] = {min: -3.14159265, max: 3.14159};
            rangeMap["RADIAN"]      ["inc"] = {min: 0, max: 3.14159};
            rangeMap["RADIAN"]      ["azi"] = {min: 0, max: 6.28319};
            rangeMap["RADIAN"]      ["toolFace"] = {min: 0, max: 6.28319};

            rangeMap["GRADIAN"] = {};
            rangeMap["GRADIAN"]     ["Standard"] = {min: -200, max: 400};
            rangeMap["GRADIAN"]     ["dip"] = {min: -100, max: 100};
            rangeMap["GRADIAN"]     ["dec"] = {min: -200, max: 200};
            rangeMap["GRADIAN"]     ["gridConvergence"] = {min: -199.999999999889, max: 200};
            rangeMap["GRADIAN"]     ["inc"] = {min: 0, max: 200};
            rangeMap["GRADIAN"]     ["azi"] = {min: 0, max: 400};
            rangeMap["GRADIAN"]     ["toolFace"] = {min: 0, max: 400};

            // GRAVITY UNITS -- acceptable ranges for gravity in various units (ranges should be equivalent across units)

            rangeMap["M_PER_SEC_SQ"] = {};
            rangeMap["M_PER_SEC_SQ"]    ["Standard"] = {min: -10.2969825, max: 10.2969825};

            rangeMap["FT_PER_SEC_SQ"] = {};
            rangeMap["FT_PER_SEC_SQ"]   ["Standard"] = {min: -33.78275098425197, max: 33.78275098425197};

            rangeMap["G"] = {};
            rangeMap["G"]               ["Standard"] = {min: -1.05, max: 1.05};

            rangeMap["MILLI_G"] = {};
            rangeMap["MILLI_G"]         ["Standard"] = {min: -1050, max: 1050};

            rangeMap["G_98"] = {};
            rangeMap["G_98"]            ["Standard"] = {min: -1.0507125, max: 1.0507125};

            rangeMap["GAL"] = {};
            rangeMap["GAL"]             ["Standard"] = {min: -1029.69825, max: 1029.69825};

            rangeMap["MILLI_GAL"] = {};
            rangeMap["MILLI_GAL"]       ["Standard"] = {min: -1029698.25, max: 1029698.25};

            // MAGNETIC UNITS -- acceptable ranges for magnetic values in various units (ranges should be equivalent across units)

            rangeMap["NANOTESLA"] = {};
            rangeMap["NANOTESLA"]       ["Standard"] = {min: -200000, max: 200000};

            rangeMap["MICROTESLA"] = {};
            rangeMap["MICROTESLA"]      ["Standard"] = {min: -200, max: 200};

            rangeMap["TESLA"] = {};
            rangeMap["TESLA"]           ["Standard"] = {min: -0.0002, max: 0.0002};

            rangeMap["GAUSS"] = {};
            rangeMap["GAUSS"]           ["Standard"] = {min: -2.0, max: 2.0};

            rangeMap["GAMMA"] = {};
            rangeMap["GAMMA"]           ["Standard"] = {min: -200000.0, max: 200000};

            // TEXT COLORS

            var invalidFieldColor = "red";
            var validFieldColor = "black";

            /**
             * validateInputSet:
             *
             *      Performs validateInput() on each id in the given array of ids and returns
             *      any error codes found while validating.
             *
             * @param ids
             * @returns {Array}
             */
            Service.validateInputSet = function (ids) {

                //if nothing is passed in, we return no errors
                if (ids === undefined || ids.length === 0) {
                    return [];
                }

                var errors = [];
                for (var i = 0; i < ids.length; i++) {
                    var error = Service.validateInput(ids[i]);
                    if (error !== undefined) {
                        errors.push(error);
                    }
                }
                return errors;
            };

            /**
             * filterFloat:
             *
             *      A stricter float filter than parseFloat().
             *
             * @param value
             * @returns {*}
             */
            var filterFloat = function (value) {
                if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/
                        .test(value))
                    return Number(value);
                return NaN;
            };

            /**
             * validateInputWithTimeout
             *
             *      Wrapper function around validateInput but allows for a timeout.
             *
             * @param id
             * @param timeout
             */
            Service.validateInputWithTimeout = function (id, timeout) {
                $timeout(function () {
                    Service.validateInput(id);
                }, timeout);
            };

            /**
             * validateInput:
             *
             *      Validates the input on the element on the given id.
             *
             *      The element must have the following attributeS:
             *          fieldName: The display name of the field (used in giving back an error response)
             *          unit: The unit for the value
             *          unitType: A specific type for the value (for example, md's have a specific range)
             *              -Can be left blank (assumed standard)
             *
             * @param id
             * @returns {*}
             */
            Service.validateInput = function (id) {

                var element = document.getElementById(id);
                if (element === undefined || element === null) {
                    console.error('unable validate field with id: ' + id);
                }

                var fieldName = element.getAttribute('fieldName');
                var unit = element.getAttribute('unit');
                var type = element.getAttribute('unitType');
                if (type === undefined || type === null) type = "Standard";
                var value = filterFloat(element.value);
                var range = rangeMap[unit][type];
                if (element.value.length === 0) {
                    element.style.color = validFieldColor;
                    return "Value " + element.getAttribute('fieldname') + " has not been set.";
                }
                else if (isNaN(value)) {
                    element.style.color = invalidFieldColor;
                    return "Value \"" + element.value + "\" is invalid for field " + fieldName + ".";
                }
                else if (value < range.min || value > range.max) {
                    element.style.color = invalidFieldColor;
                    return fieldName + " " + value + " " + unit + " is outside of the range " + range.min + " " + unit + " to " + range.max + " " + unit + ".";
                }
                else {
                    element.style.color = '';
                }
            };

            Service.validateInputRaw = function(value, unit, unitType) {
                if (unitType === undefined || unitType === null) {
                        unitType = "Standard";
                }

                var value = filterFloat(value);
                var range = rangeMap[unit][unitType];

                if (value.length === 0) {
                    return true;
                }
                else if (isNaN(value)) {
                    return false;
                }
                else if (value < range.min || value > range.max) {
                    return false;
                }
                else {
                    return true;
                }
            };

            Service.getRangeWarning = function(unit, unitType, fieldName) {
                var range = rangeMap[unit][unitType];
                return fieldName + " must be in the range " + range.min + " " + unit + " to " + range.max + " " + unit + ".";
            };

            Service.validatedInputsReturnErrors = function (inputsToValidate) {
                var errors = Service.validateInputSet(inputsToValidate);
                if (errors.length > 0) {
                    var errorMsg = "";
                    for (var i = 0; i < errors.length; i++) {
                        errorMsg += errors[i] + "\n";
                    }
                    swal(
                        'Error validating input values!',
                        errorMsg,
                        'error'
                    );
                    return true;
                }
                return false;
            };

            return Service;
        }]);
})();
