(function () {

    var app = angular.module('saphira');

    app.controller('ReportModalController', [
        /* Angular Modules  */ '$scope', '$uibModal',  '$uibModalInstance', '$timeout',
        /* 3rd Party Modules*/
        /* Internal Modules */ 'HighchartsPlottingService',
        /* Input            */ 'surveySet', 'surveys', 'acSigma', 'header', 'reportParams', 'analysis',

        function (/* Angular Modules  */ $scope, $uibModal, $uibModalInstance, $timeout,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ HighchartsPlottingService,
                  /* Input            */ surveySet, surveys, acSigma, header, reportParams, analysis) {

            //Assign the messages to be displayed in the modals

            $scope.surveySet = surveySet;
            $scope.analysis = analysis;
            $scope.unitSet = surveySet.unitSet;
            $scope.header = header;
            $scope.reportParams = reportParams;

            $scope.sigmaAllGraph= null;
            $scope.bTotalAllGraph = null;
            $scope.gTotalAllGraph = null;
            $scope.dipAllGraph = null;

            $scope.incCorrGraph = null;
            $scope.aziCorrGraph = null;

            $scope.surCorrGraph = null
            $scope.surCorrPieGraph = null

            $scope.surCorrFileGraph = null
            $scope.surCorrFilePieGraph = null

            $scope.qcPlotsMade = function () {
                return ($scope.sigmaAllGraph !== null && $scope.bTotalAllGraph !== null
                && $scope.gTotalAllGraph !== null && $scope.dipAllGraph !== null);
            };

            $scope.diffPlotsMade = function () {
                return ($scope.incCorrGraph !== null && $scope.aziCorrGraph !== null);
            };

            $scope.surCorrPlotsMade = function () {
                return ($scope.surCorrGraph !== null && $scope.surCorrPieGraph !== null);
            };

            $scope.surCorrFilePlotsMade = function () {
                return ($scope.surCorrFileGraph !== null && $scope.surCorrFilePieGraph !== null);
            };

            $scope.printReport = function() {

                printElement(document.getElementById("printableSurveyReport"));

                if(reportParams.qcPlots) {
                    var sigmaPlot = getImgFromChart($scope.sigmaAllGraph.chart);
                    var bTotalPlot = getImgFromChart($scope.bTotalAllGraph.chart);
                    var gTotalPlot = getImgFromChart($scope.gTotalAllGraph.chart);
                    var dipPlot = getImgFromChart($scope.dipAllGraph.chart);
                    printElement(sigmaPlot, true, "<br/>");
                    printElement(bTotalPlot, true);
                    printElement(gTotalPlot, true);
                    printElement(dipPlot, true);
                }

                if(reportParams.correctedPlots) {
                    var incPlot = getImgFromChart($scope.incCorrGraph.chart);
                    var aziPlot = getImgFromChart($scope.aziCorrGraph.chart);
                    printElement(incPlot, true, "<br/>");
                    printElement(aziPlot, true);
                }

                if(reportParams.surveyCorrectionTimeFromSurveyCreation) {
                    var surCorrPlots = getImgFromChart($scope.surCorrGraph.chart);
                    var surCorrPiePlots = getImgFromChart($scope.surCorrPieGraph.chart);
                    printElement(surCorrPlots, true);
                    printElement(surCorrPiePlots, true);
                }

                if(reportParams.surveyCorrectionTimeFromFileUpload) {
                    var surCorrFilePlots = getImgFromChart($scope.surCorrFileGraph.chart);
                    var surCorrFilePiePlots = getImgFromChart($scope.surCorrFilePieGraph.chart);
                    printElement(surCorrFilePlots, true);
                    printElement(surCorrFilePiePlots, true);
                }


                $timeout(function() {
                    window.print();
                    clearPrint();
                }, 100);
            };

            $scope.close = function() {
                $uibModalInstance.close('close');
            };


            var printElement = function(elem, append, delimiter) {
                var domClone = elem.cloneNode(true);

                var $printSection = getPrintSection();

                if (append !== true) {
                    $printSection.innerHTML = "";
                }

                else if (append === true) {
                    if (typeof(delimiter) === "string") {
                        $printSection.innerHTML += delimiter;
                    }
                    else if (typeof(delimiter) === "object") {
                        $printSection.appendChild(delimiter);
                    }
                }

                $printSection.appendChild(domClone);
            };

            var clearPrint = function() {
                var $printSection = getPrintSection();
                    $printSection.innerHTML = "";
            };

            var getPrintSection = function() {
                var $printSection = null;
                $printSection = document.getElementById("printSection");

                if (!$printSection) {
                    $printSection = document.createElement("div");
                    $printSection.id = "printSection";
                    document.body.appendChild($printSection);
                }
                return $printSection;
            };

            var getImgFromChart = function(chart) {
                var imageWidth = 1000;
                var imageHeight = imageWidth * chart.chartHeight / chart.chartWidth;

                var svg = chart.getSVG( {
                    exporting: {
                        sourceWidth: chart.chartWidth,
                        sourceHeight: chart.chartHeight
                    }
                });

                var canvas = document.createElement('canvas');
                canvas.height = imageHeight;
                canvas.width = imageWidth;

                var image = new Image;
                image.onload = function() {
                    canvas.getContext('2d').drawImage(this, 0,0, imageWidth, imageHeight);
                };
                image.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)));
                return image;
            };

            var init = function () {

                //Make plots
                if(reportParams.qcPlots && surveys) {
                    if ($scope.sigmaAllGraph === null) {
                        $scope.sigmaAllGraph = {chart: {}, options: {}};
                        $scope.sigmaAllGraph.options = HighchartsPlottingService.Sigma.plotMultiSigma(surveys, surveySet.unitSet, acSigma);
                    }
                    if ($scope.bTotalAllGraph === null) {
                        $scope.bTotalAllGraph = {chart: {}, options: {}};
                        $scope.bTotalAllGraph.options = HighchartsPlottingService.BTotal.plotMultiBTotal(surveys, surveySet.unitSet, acSigma);
                    }
                    if ($scope.dipAllGraph === null) {
                        $scope.dipAllGraph = {chart: {}, options: {}};
                        $scope.dipAllGraph.options = HighchartsPlottingService.Dip.plotMultiDip(surveys, surveySet.unitSet, acSigma);
                    }
                    if ($scope.gTotalAllGraph === null) {
                        $scope.gTotalAllGraph = {chart: {}, options: {}};
                        $scope.gTotalAllGraph.options = HighchartsPlottingService.GTotal.plotMultiGTotal(surveys, surveySet.unitSet, acSigma);
                    }
                }

                if(reportParams.correctedPlots && surveys){
                    if ($scope.incCorrGraph === null) {
                        $scope.incCorrGraph = {chart: {}, options: {}};
                        $scope.incCorrGraph.options = HighchartsPlottingService.Inc.plotMsaInc(surveys, surveySet.unitSet, surveySet.msaDeltaInc);
                    }
                    if ($scope.aziCorrGraph === null) {
                        $scope.aziCorrGraph = {chart: {}, options: {}};
                        $scope.aziCorrGraph.options = HighchartsPlottingService.Azi.plotMsaAzi(surveys, surveySet.unitSet, surveySet.msaDeltaAzi);
                    }

                }

                if(reportParams.surveyCorrectionTimeFromSurveyCreation && surveys){
                    if ($scope.surCorrGraph === null) {
                        $scope.surCorrGraph = {chart: {}, options: {}};
                        $scope.surCorrGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotSurveyCorrectionTimes(surveys, surveySet.unitSet, surveySet.name, "receivedTime");
                    }
                    if ($scope.surCorrPieGraph === null) {
                        $scope.surCorrPieGraph = {chart: {}, options: {}};
                        $scope.surCorrPieGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotPieSurveyCorrectionTimes(surveys, surveySet.unitSet, "receivedTime");
                    }
                }

                if(reportParams.surveyCorrectionTimeFromFileUpload && surveys){
                    if ($scope.surCorrFileGraph === null) {
                        $scope.surCorrFileGraph = {chart: {}, options: {}};
                        $scope.surCorrFileGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotSurveyCorrectionTimes(surveys, surveySet.unitSet, surveySet.name, "fileUploadTime");
                    }
                    if ($scope.surCorrFilePieGraph === null) {
                        $scope.surCorrFilePieGraph = {chart: {}, options: {}};
                        $scope.surCorrFilePieGraph.options = HighchartsPlottingService.SurveyCorrectionTimes.plotPieSurveyCorrectionTimes(surveys, surveySet.unitSet, "fileUploadTime");
                    }
                }


                //Get latest corrected survey
                if(reportParams.recentSurvey){
                    $scope.currentSurvey = surveys.length > 0 ? surveys[0] : null;
                    for(var i = 0; i < surveys.length; i++) {
                        if(!surveys[i].correctedSixAxisDatas || !surveys[i].correctedSixAxisDatas.MULTISTATION) {
                            break;
                        }
                        $scope.currentSurvey = surveys[i];
                    }
                }
            };
            init();

        }
    ]);

})();
