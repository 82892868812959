(function () {

    var app = angular.module('saphira');

    app.controller('MultiReportParametersModalController', [
        /* Angular Modules  */ '$scope', '$uibModal',  '$uibModalInstance', '$filter',
        /* 3rd Party Modules*/
        /* Internal Modules */ "DataService", "HierarchyService",
        /* Input            */ 'wellbore', 'surveySet', 'acSigma', 'header',

        function (/* Angular Modules  */ $scope, $uibModal, $uibModalInstance, $filter,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ DataService, HierarchyService,
                  /* Input            */ wellbore, surveySet, acSigma, header) {

            $scope.reportParams = {
                header: false,
                recentSurvey: false,
                textReport: false,
                customComment: "",
                qcPlots: false,
                correctedPlots: false,
                surveyCorrectionTimeFromFileUpload: false,
                surveyCorrectionTimeFromSurveyCreation: false,
                reportNotes: false,
                notes: ""
            };

            $scope.surveySets = {
                available: [],
                selected: []
            };

            var getSurveySets = function() {
                HierarchyService.SurveySet.getManyMetaByParent(wellbore.id).then(
                    function(metas){
                        $scope.surveySets.available = $filter('orderBy')(metas, 'name');
                    },
                    function(error){
                        SweetAlert.swal("Error", error.data, "error");
                        console.error(error);
                    });
            };

            var openReport = function(analyses) {
                $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/MultiReportModal.html',
                    controller: 'MultiReportModalController',
                    backdrop: 'static',
                    windowClass: 'reportModal',
                    resolve: {
                        analyses: function () {
                            return analyses;
                        },
                        reportParams : function() {
                            return $scope.reportParams;
                        },
                        acSigma : function() {
                            return acSigma;
                        },
                        header : function() {
                            return header;
                        }
                    }
                });
            };

            $scope.makeReport = function() {
                var ids = [];
                for (var i in $scope.surveySets.selected) {
                    ids.push($scope.surveySets.selected[i].id);
                }

                DataService.getMultiAnalysis(ids).then(function(analyses) {
                    openReport(analyses);
                }, function(error) {
                    console.error(error);
                });
            };

            $scope.checkNoSurveySetsSelected = function() {
                return $scope.surveySets.selected.length === 0;
            };

            $scope.checkNoOptionsSelected = function() {
                var options =  $scope.reportParams;
                return !(options.header || options.recentSurvey || options.textReport || options.qcPlots || options.correctedPlots
                    || options.surveyCorrectionTimeFromFileUpload || options.surveyCorrectionTimeFromSurveyCreation || options.reportNotes);
            };

            /**
             * closeModalCancel:
             *      This dismisses the modals so that the user can go back to change values before resubmitting.
             */
            $scope.closeModalCancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            var init = function() {
                getSurveySets();
            };
            init();
        }
    ]);

})();
