/**
 * FileTemplateService:
 *
 *      Responsible for:
 */
(function () {
    var app = angular.module('saphira');

    app.factory("SigmaPlottingService", [
        /* Angular Modules  */ "$filter",
        /* 3rd Party Modules*/
        /* Internal Modules */ "PlotStyleService", "PlotHelperService",
        /* Input            */

        function (/* Angular Modules  */ $filter,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ PlotStyleService, PlotHelperService
                  /* Input            */) {

            var service = {};


            service.plotRawSigma = function (surveys, unitSet, acSigma) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);

                var redRange = [], greenRange = [], cSigma = [];
                var sigma = {
                    ALL:[],
                    STANDARD:[],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getRawMinMax(surveys, acSigma);
                var min = minMax.min, max = minMax.max;

                for(var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var meas = surveys[i].facData.sigma;

                    cSigma.push([md, acSigma]);
                    redRange.push([md,acSigma, max]);
                    greenRange.push([md, min, acSigma]);
                    sigma[surveys[i].type].push([md, meas]);
                    sigma["ALL"].push([md, meas]);
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Sigma'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("Sigma", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(unitSet.depthUnit, null),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, null),

                    series: [
                        PlotStyleService.createAllMeasuredSeries("Measured Surveys", sigma["ALL"]),
                        PlotStyleService.createMeasuredSeries("Measured Standard", sigma["STANDARD"]),
                        PlotStyleService.createPoorMeasuredSeries("Measured Poor", sigma["POOR"], unitSet.depthUnit, null),
                        PlotStyleService.createBadSeries("Measured Bad", sigma["BAD"]),
                        PlotStyleService.createCheckshotMeasuredSeries("Measured Checkshot", sigma["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerMeasuredSeries("Measured Accelerometer", sigma["ACC_CHECK"]),
                        PlotStyleService.createReferenceSeries("AC Sigma", cSigma),
                        PlotStyleService.createFailRegion(redRange),
                        PlotStyleService.createPassRegion(greenRange)
                    ]
                };
            };

            service.plotMsaSigma = function (surveys, unitSet, acSigma) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);
                surveys = PlotHelperService.getSurveysByCorrection(surveys, ['MULTISTATION']);


                var redRange = [], greenRange = [], cSigma = [];
                var sigma = {
                    ALL:[],
                    STANDARD:[],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getMSAMinMax(surveys, acSigma);
                var min = minMax.min, max = minMax.max;

                for(var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var meas = surveys[i].correctedFacDatas.MULTISTATION.sigma;

                    cSigma.push([md, acSigma]);
                    redRange.push([md,acSigma, max]);
                    greenRange.push([md, min, acSigma]);
                    sigma[surveys[i].type].push([md, meas]);
                    sigma["ALL"].push([md, meas]);
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Sigma'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("Sigma", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(unitSet.depthUnit, null),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, null),

                    series: [
                        PlotStyleService.createReferenceSeries("AC Sigma", cSigma),
                        PlotStyleService.createAllCorrectedSeries("Corrected Surveys", sigma["ALL"]),
                        PlotStyleService.createCorrectedSeries("Corrected Standard", sigma["STANDARD"]),
                        PlotStyleService.createPoorCorrectedSeries("Corrected Poor", sigma["POOR"]),
                        PlotStyleService.createCheckshotCorrectedSeries("Corrected Checkshot", sigma["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerCorrectedSeries("Corrected Accelerometer", sigma["ACC_CHECK"]),
                        PlotStyleService.createBadSeries("Corrected Bad", sigma["BAD"]),
                        PlotStyleService.createFailRegion(redRange),
                        PlotStyleService.createPassRegion(greenRange)
                    ]
                };
            };


            service.plotMultiSigma = function( surveys, unitSet, acSigma) {

                surveys = PlotHelperService.filterOutInterpolatedSurveys(surveys);

                var redRange = [], greenRange = [], cSigma = [];
                var sigma = {
                    ALL:[],
                    STANDARD:[],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };
                var msaSigma = {
                    ALL:[],
                    STANDARD:[],
                    POOR: [],
                    BAD: [],
                    CHECKSHOT: [],
                    ACC_CHECK: []
                };

                var minMax = getAllMinMax(surveys, acSigma);
                var min = minMax.min, max = minMax.max;

                for(var i = 0; i < surveys.length; i++) {
                    var md = surveys[i].md.value;
                    var meas = surveys[i].facData.sigma;

                    cSigma.push([md, acSigma]);
                    redRange.push([md,acSigma, max]);
                    greenRange.push([md, min, acSigma]);
                    sigma[surveys[i].type].push([md, meas]);
                    sigma["ALL"].push([md, meas]);

                    if(surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        var corr = surveys[i].correctedFacDatas.MULTISTATION.sigma;
                        msaSigma[surveys[i].type].push([md, corr]);
                        msaSigma["ALL"].push([md, corr]);
                    }
                }

                return {
                    exporting: PlotStyleService.getDefaultExporting(),
                    title: PlotStyleService.getDefaultTitle('Sigma'),
                    xAxis: PlotStyleService.getDefaultXAxis(
                        'Measured Depth (' + $filter('distanceUnit')(unitSet.depthUnit) + ')'),
                    yAxis: PlotStyleService.getDefaultYAxis("Sigma", min, max),
                    chart: PlotStyleService.getDefaultChart(),
                    plotOptions: PlotStyleService.getDefaultPlotOptions(unitSet.depthUnit, null),
                    credits: {enabled: false},
                    tooltip: PlotStyleService.getDefaultToolTip(unitSet.depthUnit, null),

                    series: [
                        PlotStyleService.createFailRegion(redRange),
                        PlotStyleService.createPassRegion(greenRange),
                        PlotStyleService.createReferenceSeries("AC Sigma", cSigma),
                        PlotStyleService.createAllMeasuredSeries("Measured Surveys", sigma["ALL"]),
                        PlotStyleService.createMeasuredSeries("Measured Standard", sigma["STANDARD"]),
                        PlotStyleService.createPoorMeasuredSeries("Measured Poor", sigma["POOR"]),
                        PlotStyleService.createBadSeries("Measured Bad", sigma["BAD"]),
                        PlotStyleService.createCheckshotMeasuredSeries("Measured Checkshot", sigma["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerMeasuredSeries("Measured Accelerometer", sigma["ACC_CHECK"]),
                        PlotStyleService.createAllCorrectedSeries("Corrected Surveys", msaSigma["ALL"]),
                        PlotStyleService.createCorrectedSeries("Corrected Standard", msaSigma["STANDARD"]),
                        PlotStyleService.createPoorCorrectedSeries("Corrected Poor", msaSigma["POOR"]),
                        PlotStyleService.createBadSeries("Corrected Bad", msaSigma["BAD"]),
                        PlotStyleService.createCheckshotCorrectedSeries("Corrected Checkshot", msaSigma["CHECKSHOT"]),
                        PlotStyleService.createAccelerometerCorrectedSeries("Corrected Accelerometer", msaSigma["ACC_CHECK"]),
                    ]
                };
            };

            /**
             * Returns the min and max values for the Sigma plot.
             *
             * @param surveys
             * @param acSigma
             * @returns {{}}
             */
            var getRawMinMax = function (surveys, acSigma) {
                var minMax = {min: 0, max: acSigma};

                for(var i = 0; i < surveys.length; i++) {
                    minMax.max = minMax.max < surveys[i].facData.sigma ? surveys[i].facData.sigma : minMax.max;
                }
                minMax.max += Math.abs((minMax.max - minMax.min) * 0.05);

                return minMax;
            };

            /**
             * Returns the min and max values for the Sigma plot.
             *
             * @param surveys
             * @param acSigma
             * @returns {{}}
             */
            var getMSAMinMax = function (surveys, acSigma) {
                var minMax = {min: 0, max: acSigma};

                for(var i = 0; i < surveys.length; i++) {
                    minMax.max = minMax.max < surveys[i].correctedFacDatas.MULTISTATION.sigma ? surveys[i].correctedFacDatas.MULTISTATION.sigma : minMax.max;
                }
                minMax.max += Math.abs((minMax.max - minMax.min) * 0.05);

                return minMax;
            };

            /**
             * Returns the min and max values for the Sigma plot.
             *
             * @param surveys
             * @param acSigma
             * @returns {{}}
             */
            var getAllMinMax = function (surveys, acSigma) {
                var minMax = {min: 0, max: acSigma};

                for(var i = 0; i < surveys.length; i++) {
                    minMax.max = minMax.max < surveys[i].facData.sigma ? surveys[i].facData.sigma : minMax.max;
                    if(surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        minMax.max = minMax.max < surveys[i].correctedFacDatas.MULTISTATION.sigma ? surveys[i].correctedFacDatas.MULTISTATION.sigma : minMax.max;
                    }
                }
                minMax.max += Math.abs((minMax.max - minMax.min) * 0.05);

                return minMax;
            };

            return service;

        }]);
})();