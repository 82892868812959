(function () {

    var app = angular.module("saphira");

    app.controller("MsaParametersModalController", [
        /* Angular Modules  */ '$scope', '$filter', "$uibModal", "$uibModalInstance",
        /* 3rd Party Modules*/ 'SweetAlert', 'lodash',
        /* Internal Modules */ 'CorrectionService', 'HierarchyService', 'EventService',
        /* Input            */ 'acSigma', 'surveySet', 'refresh',

        function (/* Angular Modules  */ $scope, $filter, $uibModal, $uibModalInstance,
                  /* 3rd Party Modules*/ SweetAlert, lodash,
                  /* Internal Modules */ CorrectionService, HierarchyService, EventService,
                  /* Input            */ acSigma, surveySet, refresh) {

            $scope.surveySet = surveySet.data;

            var depthUnit = $scope.surveySet.unitSet.depthUnit;

            //get the surveys and sort them
            var surveys = HierarchyService.Survey.Loaded[surveySet.id];
            $scope.surveys = $filter('orderBy')(surveys, 'md.value', false);

            //store the min and max mds
            var minMd = $scope.surveys[0].md.value;
            var maxMd = $scope.surveys[$scope.surveys.length - 1].md.value;

            $scope.params = {
                'parentSurveySetId': surveySet.id,
                'selector': 'UNCORRECTED',
                'mdMin': maxMd,
                'mdMax': maxMd
            };

            $scope.isNewFileFlagPresent = EventService.isNewFileFlagPresent;
            $scope.clearSurveySetFlags = function() {
                EventService.clearSurveySetFlags().then(function()  {
                    refresh();
                });
            };

            var getMinMdRange = function () {
                if ($scope.params.selector !== "RANGE" || !$scope.params.mdMin) {
                    return minMd;
                }
                else {
                    return $scope.params.mdMin;
                }
            };

            var getMaxMdRange = function () {
                if ($scope.params.selector !== "RANGE" || !$scope.params.mdMax) {
                    return maxMd;
                }
                else {
                    return $scope.params.mdMax;
                }
            };

            $scope.isSurveyCorrected = function (survey) {


                if (survey.type != "STANDARD" && survey.type != "POOR")
                    return false;

                switch ($scope.params.selector) {
                    case "ALL":
                        return true;
                    case "NONE":
                        return false;
                    case "RANGE":
                        return getMinMdRange() <= survey.md.value && survey.md.value <= getMaxMdRange();
                    case "UNCORRECTED":
                        return !angular.isObject(survey.correctedSixAxisDatas) || Object.keys(survey.correctedSixAxisDatas).length === 0;
                    default:
                        return false;
                }
            };

            var numberOfSurveysToCorrect = function () {
                var numSurveys = 0;
                for (var i = 0; i < $scope.surveys.length; i++) {
                    if ($scope.isSurveyCorrected($scope.surveys[i])) {
                        numSurveys++;
                    }
                }
                return numSurveys;
            };

            var isSurveyinComputeRange = function (survey) {
                var min = lodash.get($scope, "manualCorrectionValues.msaComputationRange.min.value", minMd);
                var max = lodash.get($scope, "manualCorrectionValues.msaComputationRange.max.value", maxMd);

                // If min or max is null or undefined, it will use the entire md range
                min = (min !== null && min !== undefined) ? min : minMd;
                max = (max !== null && min !== undefined) ? max : maxMd;

                return (survey.md.value >= min && survey.md.value <= max);
            };

            var numberofSurveysToCompute = function () {
                var numSurveys = 0;

                //if we are only computing msa from a range
                if ($scope.correctionResolutions.msaComputeFromRange) {

                    //count surveys based on which are in the compute range
                    for (var i = 0; i < $scope.surveys.length; i++) {
                        var survey = $scope.surveys[i];
                        if (isSurveyinComputeRange(survey)) {
                            numSurveys++;
                        }
                    }
                }
                //otherwise, all surveys are used for computing msa
                else {
                    numSurveys = $scope.surveys.length;
                }

                return numSurveys;
            };

            var isSurveyComputeType = function (survey) {
                return !(survey.type === "POOR" || survey.type === "BAD" || survey.type === "INTERPOLATED");
            };

            var isValidComputeRange = function () {
                var set = $scope.manualCorrectionValues;
                var min = set.msaComputationRange.min;
                var max = set.msaComputationRange.max;
                return !($scope.correctionResolutions.msaComputeFromRange &&
                (!min || !max || (!min.value && min.value !== 0) || (!max.value && max.value !== 0)));
            };

            $scope.isSurveyComputedFrom = function (survey) {
                return (!$scope.correctionResolutions.msaComputeFromRange || isSurveyinComputeRange(survey)) && isSurveyComputeType(survey);
            };

            $scope.correctSurveys = function () {

                CorrectionService.applyCorrection(surveySet.id, $scope.params.selector, getMinMdRange(), getMaxMdRange(), CorrectionService.correction, $scope.manualCorrectionValues.msaIncOffset,
                    $scope.manualCorrectionValues.msaAziOffset, true, $scope.correctionResolutions.msaComputeFromRange, $scope.manualCorrectionValues.msaComputationRange).then(function () {
                    $scope.modalClose();
                }, function (error) {
                    //failed to apply correction
                    SweetAlert.swal("Error", "Unknown error when applying correction to surveys.", "error");
                });
            };

            $scope.computeMSA = function () {

                var set = $scope.manualCorrectionValues;

                if (numberOfSurveysToCorrect() === 0) {
                    swal('No surveys are selected for correction!');
                    return;
                }

                if (set.msaComputeFromRange && !isValidComputeRange()) {
                    swal("Invalid range");
                    return;
                }

                if (numberofSurveysToCompute() === 0) {
                    swal("No surveys to correct from!");
                    return;
                }

                $scope.manualCorrectionValues.msaComputationRange.max.unit = depthUnit;
                $scope.manualCorrectionValues.msaComputationRange.min.unit = depthUnit;

                var call;
                call = CorrectionService.computeMSA(surveySet, $scope.params.selector, getMinMdRange(), getMaxMdRange(),
                    $scope.manualCorrectionValues, $scope.correctionResolutions, null);

                return call.then(function (correction) {

                    CorrectionService.correction = correction;
                    CorrectionService.applyCorrection(surveySet.id, $scope.params.selector, getMinMdRange(), getMaxMdRange(), CorrectionService.correction, $scope.manualCorrectionValues.msaIncOffset,
                        $scope.manualCorrectionValues.msaAziOffset, false, $scope.correctionResolutions.msaComputeFromRange, $scope.manualCorrectionValues.msaComputationRange)
                        .then(function (correctionAssessment) {
                                var validation = $uibModal.open({
                                    templateUrl: 'html/views/detailedWellbore/workflow/msa/MsaValidationModal.html',
                                    controller: 'MsaValidationModalController',
                                    windowClass: 'qc-plots-modal',
                                    backdrop: 'static',

                                    resolve: {
                                        manualCorrectionValues: function () {
                                            return $scope.manualCorrectionValues;
                                        },
                                        correctionResolutions: function () {
                                            return $scope.correctionResolutions;
                                        },
                                        rawSurveys: function () {
                                            return HierarchyService.Survey.Loaded[surveySet.id];
                                        },
                                        correctionAssessment: function () {
                                            return correctionAssessment;
                                        },
                                        surveySetId: function () {
                                            return surveySet.id;
                                        },
                                        params: function () {
                                            return $scope.params;
                                        },
                                        unitSet: function () {
                                            return $scope.surveySet.unitSet;
                                        },
                                        acSigma: function () {
                                            return acSigma;
                                        },
                                        qcDeltaInc: function () {
                                            return $scope.surveySet.qcDeltaInc;
                                        },
                                        qcDeltaAzi: function () {
                                            return $scope.surveySet.qcDeltaAzi;
                                        },
                                        msaQCDeltaInc: function () {
                                            return $scope.surveySet.msaDeltaInc;
                                        },
                                        msaQCDeltaAzi: function () {
                                            return $scope.surveySet.msaDeltaAzi;
                                        },
                                        fromAutomation: function () {
                                            return false;
                                        },
                                        refresh: function () {
                                            return refresh;
                                        }
                                    }
                                });

                                validation.result.then(function () {

                                    // Check for recorrected surveys
                                    var numSurveysRecorrected = 0;

                                    for (var i = 0; i < $scope.surveys.length; i++) {
                                        if ($scope.isSurveyCorrected($scope.surveys[i]) && Object.keys($scope.surveys[i].correctedSixAxisDatas).length > 0) {
                                            numSurveysRecorrected++;
                                        }
                                    }
                                    if (numSurveysRecorrected > 0) {
                                        // Timeout to prevent SweetAlert bug: attempting to create a second sweetalert right after closing another sweetalert
                                        // prevents second sweetalert popup from appearing. This occurs when ValidationModalController "updated from the corrected surveys"
                                        // SweetAlert is triggered. Possible fix: combine both SweetAlerts into one in the same Controller.
                                        setTimeout(function() {
                                            SweetAlert.swal({
                                                title: 'Warning:',
                                                text: 'This will permanently overwrite ' + numSurveysRecorrected + ' existing survey corrections, do you wish to continue?',
                                                type: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: 'Yes',
                                                closeOnConfirm: true
                                            }, function (isConfirm) {
                                                if (isConfirm) {
                                                    //Apply correction
                                                    $scope.correctSurveys();
                                                }
                                            });
                                        },500);
                                    }
                                    else {
                                        $scope.correctSurveys();
                                    }
                                }, function () {
                                    //Update the surveyset reference in this controller
                                    HierarchyService.SurveySet.get(surveySet.data.id).then(function () {
                                        surveySet.data = HierarchyService.SurveySet.Loaded[surveySet.data.id];
                                        $scope.surveySet = surveySet.data;
                                    }, function (error) {
                                        SweetAlert("Error", "Link to survey set broke, please reopen MSA window", "error");
                                        $scope.modalCancel();
                                    });
                                });

                                //TODO plot correction and allow for submitting of edited correction
                            },
                            function (error) {
                                SweetAlert.swal("Error", "Unknown error occurred while applying correction to surveys.", "error");
                            });
                }, function (error) {
                    if (error.status === 500) {
                        swal("Error", "Unable to find a correction solution within the set number of iterations.", "error");
                    } else {
                        var errorMessage = error && error.data && error.data.message ? error.data.message : "";
                        swal("Error", "Error while attempting to compute correction with the given correction parameters. \n\n" + errorMessage, "error");
                    }
                }, function () {
                    //finally
                });

            };

            $scope.modalClose = function () {
                $uibModalInstance.close('Exit'); //TODO should we pass something?
            };

            $scope.modalCancel = function () {
                $uibModalInstance.dismiss('Reject'); //TODO Should we pass something back?
            };

            var buildManualCorrectionValuesFromSurveySet = function (surveySet) {
                if (surveySet.data.correctionParameters) {
                    var correctionParameters = {
                        "type": "MULTISTATION",
                        "magneticScales": {
                            "data": [
                                surveySet.data.correctionParameters.bxScaleValue ? surveySet.data.correctionParameters.bxScaleValue : 1.0,
                                surveySet.data.correctionParameters.byScaleValue ? surveySet.data.correctionParameters.byScaleValue : 1.0,
                                surveySet.data.correctionParameters.bzScaleValue ? surveySet.data.correctionParameters.bzScaleValue : 1.0
                            ]
                        },
                        "magneticBias": {
                            "data": [
                                surveySet.data.correctionParameters.bxBiasValue ? surveySet.data.correctionParameters.bxBiasValue : 0.0,
                                surveySet.data.correctionParameters.byBiasValue ? surveySet.data.correctionParameters.byBiasValue : 0.0,
                                surveySet.data.correctionParameters.bzBiasValue ? surveySet.data.correctionParameters.bzBiasValue : 0.0
                            ]
                        },
                        "magneticMisalign": {
                            "q0": surveySet.data.correctionParameters.magneticMisalignQ0Value ? surveySet.data.correctionParameters.magneticMisalignQ0Value : 1.0,
                            "q1": surveySet.data.correctionParameters.magneticMisalignQ1Value ? surveySet.data.correctionParameters.magneticMisalignQ1Value : 0.0,
                            "q2": surveySet.data.correctionParameters.magneticMisalignQ2Value ? surveySet.data.correctionParameters.magneticMisalignQ2Value : 0.0,
                            "q3": surveySet.data.correctionParameters.magneticMisalignQ3Value ? surveySet.data.correctionParameters.magneticMisalignQ3Value : 0.0
                        },
                        "gravityScales": {
                            "data": [
                                surveySet.data.correctionParameters.gxScaleValue ? surveySet.data.correctionParameters.gxScaleValue : 1.0,
                                surveySet.data.correctionParameters.gyScaleValue ? surveySet.data.correctionParameters.gyScaleValue : 1.0,
                                surveySet.data.correctionParameters.gzScaleValue ? surveySet.data.correctionParameters.gzScaleValue : 1.0
                            ]
                        },
                        "gravityBias": {
                            "data": [
                                surveySet.data.correctionParameters.gxBiasValue ? surveySet.data.correctionParameters.gxBiasValue : 0.0,
                                surveySet.data.correctionParameters.gyBiasValue ? surveySet.data.correctionParameters.gyBiasValue : 0.0,
                                surveySet.data.correctionParameters.gzBiasValue ? surveySet.data.correctionParameters.gzBiasValue : 0.0
                            ]
                        },
                        "gravityMisalign": {
                            "q0": surveySet.data.correctionParameters.gravityMisalignQ0Value ? surveySet.data.correctionParameters.gravityMisalignQ0Value : 1.0,
                            "q1": surveySet.data.correctionParameters.gravityMisalignQ1Value ? surveySet.data.correctionParameters.gravityMisalignQ1Value : 0.0,
                            "q2": surveySet.data.correctionParameters.gravityMisalignQ2Value ? surveySet.data.correctionParameters.gravityMisalignQ2Value : 0.0,
                            "q3": surveySet.data.correctionParameters.gravityMisalignQ3Value ? surveySet.data.correctionParameters.gravityMisalignQ3Value : 0.0
                        },
                        "msaComputationRange": {
                            "max": {
                                "value": undefined,
                                "unit": undefined
                            },
                            "min": {
                                "value": undefined,
                                "unit": undefined
                            }
                        },
                        "gDamp": surveySet.data.correctionParameters.gDamp ? surveySet.data.correctionParameters.gDamp : 0.01,
                        "bDamp": surveySet.data.correctionParameters.bDamp ? surveySet.data.correctionParameters.bDamp : 0.01,
                        "msaIncOffset": surveySet.data.correctionParameters.msaIncOffset,
                        "msaAziOffset": surveySet.data.correctionParameters.msaAziOffset
                    };
                    if (surveySet.data.correctionParameters.msaComputationRange) {
                        if (surveySet.data.correctionParameters.msaComputationRange.min) {
                            correctionParameters.msaComputationRange.min.value = surveySet.data.correctionParameters.msaComputationRange.min.value ? surveySet.data.correctionParameters.msaComputationRange.min.value : null
                            correctionParameters.msaComputationRange.min.unit = surveySet.data.correctionParameters.msaComputationRange.min.unit ? surveySet.data.correctionParameters.msaComputationRange.min.unit : null
                        }
                        if (surveySet.data.correctionParameters.msaComputationRange.max) {
                            correctionParameters.msaComputationRange.max.value = surveySet.data.correctionParameters.msaComputationRange.max.value ? surveySet.data.correctionParameters.msaComputationRange.max.value : null
                            correctionParameters.msaComputationRange.max.unit = surveySet.data.correctionParameters.msaComputationRange.max.unit ? surveySet.data.correctionParameters.msaComputationRange.max.unit : null
                        }
                    }
                    return correctionParameters;
                } else {
                    return {
                        "type": "MULTISTATION",
                        "magneticScales": {"data": [1.0, 1.0, 1.0]},
                        "magneticBias": {"data": [0.0, 0.0, 0.0]},
                        "magneticMisalign": {
                            "q0": 1.0,
                            "q1": 0.0,
                            "q2": 0.0,
                            "q3": 0.0
                        },
                        "gravityScales": {"data": [1.0, 1.0, 1.0]},
                        "gravityBias": {"data": [0.0, 0.0, 0.0]},
                        "gravityMisalign": {
                            "q0": 1.0,
                            "q1": 0.0,
                            "q2": 0.0,
                            "q3": 0.0
                        },
                        "msaComputationRange": {
                            "max": {
                                "value": undefined,
                                "unit": undefined
                            },
                            "min": {
                                "value": undefined,
                                "unit": undefined
                            }
                        },
                        "gDamp": 0.01,
                        "bDamp": 0.01,
                        "msaIncOffset": undefined,
                        "msaAziOffset": undefined
                    };
                }
            };

            var buildCorrectionResolutionsFromSurveySet = function (surveySet) {
                var correctionResolutions = {
                    "bxBiasType": surveySet.data.correctionParameters.bxBias,
                    "byBiasType": surveySet.data.correctionParameters.byBias,
                    "bzBiasType": surveySet.data.correctionParameters.bzBias,
                    "gxBiasType": surveySet.data.correctionParameters.gxBias,
                    "gyBiasType": surveySet.data.correctionParameters.gyBias,
                    "gzBiasType": surveySet.data.correctionParameters.gzBias,
                    "bxScaleType": surveySet.data.correctionParameters.bxScale,
                    "byScaleType": surveySet.data.correctionParameters.byScale,
                    "bzScaleType": surveySet.data.correctionParameters.bzScale,
                    "gxScaleType": surveySet.data.correctionParameters.gxScale,
                    "gyScaleType": surveySet.data.correctionParameters.gyScale,
                    "gzScaleType": surveySet.data.correctionParameters.gzScale
                };

                if (surveySet.data.correctionParameters) {
                    correctionResolutions.isMagneticMisalignManual = surveySet.data.correctionParameters.isMagneticMisalignmentManual;
                    correctionResolutions.isGravityMisalignManual = surveySet.data.correctionParameters.isGravityMisalignmentManual;
                    correctionResolutions.msaComputeFromRange = surveySet.data.correctionParameters.msaComputeFromRange;
                } else {
                    correctionResolutions.isMagneticMisalignManual = false;
                    correctionResolutions.isGravityMisalignManual = false;
                    correctionResolutions.msaComputeFromRange = false;
                }
                return correctionResolutions;
            };


            var init = function () {
                $scope.manualCorrectionValues = buildManualCorrectionValuesFromSurveySet(surveySet);

                $scope.correctionResolutions = buildCorrectionResolutionsFromSurveySet(surveySet);
            };

            init();
        }]);
})();
