/**
 * The service that connects all of the hierarchy services in one place
 */

(function () {

    var app = angular.module('saphira');

    app.factory('HierarchyService', [
        /* Angular Modules  */ "$q",
        /* 3rd Party Modules*/
        /* Internal Modules */ "CompanyService", "FieldService", "PadService", "WellService", "WellboreService",
        "TrajectoryService", "SurveySetService", "SurveyService",
        /* Input            */

        function (/* Angular Modules  */ $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ CompanyService, FieldService, PadService, WellService, WellboreService,
                  TrajectoryService, SurveySetService, SurveyService
                  /* Input            */) {

            var HierarchyService = {
                Company: CompanyService,
                Field: FieldService,
                Pad: PadService,
                Well: WellService,
                Wellbore: WellboreService,
                Trajectory: TrajectoryService,
                SurveySet: SurveySetService,
                Survey: SurveyService
            };

            /**
             * Loads an object of the given type with the given id.  If the user does not have permission on the
             * object, it attempts to load the meta object.
             *
             * @param objectType    "Company", "Field", "Pad", etc...
             * @param objectId      The id of the object.
             * @returns {deferred.promise|{then, catch, finally}|{then}}
             */
            HierarchyService.loadObjectOrObjectMeta = function(objectType, objectId) {
                var deferred = $q.defer();
                //try to load the object first
                HierarchyService[objectType].get(objectId).then(function(object) {
                    deferred.resolve(object);
                }, function(response) {
                    //then try to load the object meta
                    if(response.status === 403) {
                        HierarchyService[objectType].getOneMeta(objectId).then(function(object) {
                            deferred.resolve(object);
                        }, function() {
                            deferred.reject("Failed to load object/meta of type " + objectType);
                        });
                    }
                });
                return deferred.promise;
            };

            return HierarchyService;
        }]);
})();
