(function () {
    var app = angular.module('saphira');

    app.filter('reverse', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (items) {
                return items.slice().reverse();
            };
        }]);

    app.filter('instant', [
        /* Angular Modules  */ "$filter",
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */ $filter
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (instant) {
                return instant !== null && instant !== undefined ? ($filter('date')(instant.seconds * 1000, 'medium','UTC')) : null;
            }
        }])
})();
