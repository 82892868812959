(function () {

    var app = angular.module('saphira');

    app.directive('mvParameterResolution', [
        /* Angular Modules  */
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {
            return {
                restrict : 'E',
                scope: {
                    paramModel: '=',
                    manualValueModel: '=',
                    alwaysDisplayValue: '@',
                    mvChange: '&'
                },
                replace : true,
                link : function(scope,element,attr) {
                    scope.toggleResolutionType = function(resolvedState) {
                        // If resolved is already selected, deselect it and choose unresolved.
                        if((resolvedState == "RESOLVED" && scope.paramModel == "RESOLVED") ||
                            (resolvedState == "MANUAL" && scope.paramModel == "MANUAL")) {
                            scope.paramModel = "UNRESOLVED";
                        } else {
                            scope.paramModel = resolvedState;
                        }
                    };

                    scope.$watch('paramModel', function(newValue, oldValue) {
                        if(newValue !== oldValue) {
                            scope.mvChange();
                        }
                    });
                },
                // input-group-sm
                template : '<div class="input-group">' +
                     '<span class="input-group-btn">' +
                     '  <label ng-class="paramModel == \'RESOLVED\' ? \'btn-primary active\' : \'btn-default\'" class="btn" '+
                            'ng-click="toggleResolutionType(\'RESOLVED\')">' +
                     '      <span ng-class="paramModel == \'RESOLVED\' ? \'color-white\' : \'color-dark-grey\'" class="glyphicon glyphicon-ok"></span>' +
                     '  </label>' +
                     '</span>' +
                     '<span class="input-group-btn">' +
                     '  <label ng-class="paramModel == \'MANUAL\' ? \'btn-warning active\' : \'btn-default\'" class="btn"' +
                     '          ng-click="toggleResolutionType(\'MANUAL\')">' +
                     '      <span ng-class="paramModel == \'MANUAL\' ? \'color-black\' : \'color-dark-grey\'" class="glyphicon glyphicon-pencil"></span>' +
                     '  </label>' +
                     '</span>' +
                     '<span class="manual-input">' +
                     // input-sm
                     '  <input numberinputpad type="number" ng-model="manualValueModel" class="form-control  manual-input"' +
                     '          ng-show="alwaysDisplayValue || paramModel == \'MANUAL\'" type="text" ng-change="mvChange()"/>' +
                     '</span>' +
                     '</div>'
            };

        }]);

})();
