/**
 * Plots Service
 * Handles the creation of plot data from surveys (and other variables)
 */
(function () {
    var app = angular.module('saphira');

    app.factory('PlotService', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            var PlotService = {};

            //Scalar multiples for size of regions
            var failMult = 1.5;
            var sigmaMult = 2.0;

            /**
             * Creates the plot of Reported Sigma vs AC Sigma
             * @param surveys
             * @param acSigma
             * @returns {Array}
             */
            PlotService.createRawSigmaPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = 0;
                var max = acSigma;
                for (var i = 0; i < surveys.length; i++) {
                    max = max > surveys[i].facData.sigma ? max : surveys[i].facData.sigma;
                }
                max = max * 1.2;

                for (i = 0; i < surveys.length; i++) {
                    plot.push(createSigmaEntry(
                        surveys[i].md.value,
                        surveys[i].facData.sigma,
                        acSigma,
                        min,
                        max
                    ));
                }
                return plot;
            };

            /**
             * Creates the Plot fr reported (calculated) B Total
             * @param surveys
             * @returns {Array}
             */
            PlotService.createRawBTotalQcPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = surveys[0].referenceData.bTotal.value;
                var max = surveys[0].referenceData.bTotal.value;
                for (var i = 0; i < surveys.length; i++) {
                    min = min < (surveys[i].referenceData.bTotal.value - (failMult * (surveys[i].facData.bTotalFacMax.value * acSigma))) ? min : (surveys[i].referenceData.bTotal.value - (failMult * (surveys[i].facData.bTotalFacMax.value * acSigma)));
                    min = min < surveys[i].bTotalCalc.value ? min : surveys[i].bTotalCalc.value;
                    max = max > (surveys[i].referenceData.bTotal.value + (failMult * (surveys[i].facData.bTotalFacMax.value * acSigma))) ? max : (surveys[i].referenceData.bTotal.value + (failMult * (surveys[i].facData.bTotalFacMax.value * acSigma)));
                    max = max > surveys[i].bTotalCalc.value ? max : surveys[i].bTotalCalc.value;
                }

                for (i = 0; i < surveys.length; i++) {
                    plot.push(createQCEntry(
                        surveys[i].md.value,
                        surveys[i].bTotalCalc.value,
                        surveys[i].referenceData.bTotal.value,
                        surveys[i].facData.bTotalFacMax.value * acSigma,
                        surveys[i].facData.bTotalFacMin.value * acSigma,
                        min,
                        max
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot for reported (calculated) G Total
             * @param surveys
             * @returns {Array}
             */
            PlotService.createRawGTotalQcPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = surveys[0].referenceData.gTotal.value;
                var max = surveys[0].referenceData.gTotal.value;
                for (var i = 0; i < surveys.length; i++) {
                    min = min < (surveys[i].referenceData.gTotal.value - (failMult * (surveys[i].facData.gTotalFacMax.value * acSigma))) ? min : (surveys[i].referenceData.gTotal.value - (failMult * (surveys[i].facData.gTotalFacMax.value * acSigma)));
                    min = min < surveys[i].gTotalCalc.value ? min : surveys[i].gTotalCalc.value;
                    max = max > (surveys[i].referenceData.gTotal.value + (failMult * (surveys[i].facData.gTotalFacMax.value * acSigma))) ? max : (surveys[i].referenceData.gTotal.value + (failMult * (surveys[i].facData.gTotalFacMax.value * acSigma)));
                    max = max > surveys[i].gTotalCalc.value ? max : surveys[i].gTotalCalc.value;
                }

                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createQCEntry(
                        surveys[i].md.value,
                        surveys[i].gTotalCalc.value,
                        surveys[i].referenceData.gTotal.value,
                        surveys[i].facData.gTotalFacMax.value * acSigma,
                        surveys[i].facData.gTotalFacMin.value * acSigma,
                        min,
                        max
                    ));
                }
                return plot;
            };

            /**
             * Creates the Plot data for reported (calculated) dip
             * @param surveys
             * @returns {Array}
             */
            PlotService.createRawDipQcPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = surveys[0].referenceData.dip.value;
                var max = surveys[0].referenceData.dip.value;
                for (var i = 0; i < surveys.length; i++) {
                    min = min < (surveys[i].referenceData.dip.value - (failMult * (surveys[i].facData.dipFacMax.value * acSigma))) ? min : (surveys[i].referenceData.dip.value - (failMult * (surveys[i].facData.dipFacMax.value * acSigma)));
                    min = min < surveys[i].dipCalc.value ? min : surveys[i].dipCalc.value;
                    max = max > (surveys[i].referenceData.dip.value + (failMult * (surveys[i].facData.dipFacMax.value * acSigma))) ? max : (surveys[i].referenceData.dip.value + (failMult * (surveys[i].facData.dipFacMax.value * acSigma)));
                    max = max > surveys[i].dipCalc.value ? max : surveys[i].dipCalc.value;
                }

                for (i = 0; i < surveys.length; i++) {
                    plot.push(createQCEntry(
                        surveys[i].md.value,
                        surveys[i].dipCalc.value,
                        surveys[i].referenceData.dip.value,
                        surveys[i].facData.dipFacMax.value * acSigma,
                        surveys[i].facData.dipFacMin.value * acSigma,
                        min,
                        max
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected sigma
             * @param surveys
             * @param acSigma
             * @returns {Array}
             */
            PlotService.createCorrSigmaPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = 0;
                var max = acSigma;
                for (var i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        max = max > surveys[i].correctedFacDatas.MULTISTATION.sigma ? max : surveys[i].correctedFacDatas.MULTISTATION.sigma;
                    }
                }
                max = max * 1.2;

                for (i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        plot.push(createSigmaEntry(
                            surveys[i].md.value,
                            surveys[i].correctedFacDatas.MULTISTATION.sigma,
                            acSigma,
                            min,
                            max
                        ));
                    }
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected b total
             * @param surveys
             * @returns {Array}
             */
            PlotService.createCorrBTotalQcPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = surveys[0].referenceData.bTotal.value;
                var max = surveys[0].referenceData.bTotal.value;
                for (var i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        min = min < (surveys[i].referenceData.bTotal.value - (failMult * (surveys[i].correctedFacDatas.MULTISTATION.bTotalFacMax.value * acSigma))) ? min : (surveys[i].referenceData.bTotal.value - (failMult * (surveys[i].correctedFacDatas.MULTISTATION.bTotalFacMax.value * acSigma)));
                        min = min < surveys[i].correctedDatas.MULTISTATION.bTotal.value ? min : surveys[i].correctedDatas.MULTISTATION.bTotal.value;
                        max = max > (surveys[i].referenceData.bTotal.value + (failMult * (surveys[i].correctedFacDatas.MULTISTATION.bTotalFacMax.value * acSigma))) ? max : (surveys[i].referenceData.bTotal.value + (failMult * (surveys[i].correctedFacDatas.MULTISTATION.bTotalFacMax.value * acSigma)));
                        max = max > surveys[i].correctedDatas.MULTISTATION.bTotal.value ? max : surveys[i].correctedDatas.MULTISTATION.bTotal.value;
                    }
                }

                for (i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        plot.push(createQCEntry(
                            surveys[i].md.value,
                            surveys[i].correctedDatas.MULTISTATION.bTotal.value,
                            surveys[i].referenceData.bTotal.value,
                            surveys[i].correctedFacDatas.MULTISTATION.bTotalFacMax.value * acSigma,
                            surveys[i].correctedFacDatas.MULTISTATION.bTotalFacMin.value * acSigma,
                            min,
                            max
                        ));
                    }
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected g total
             * @param surveys
             * @returns {Array}
             */
            PlotService.createCorrGTotalQcPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = surveys[0].referenceData.gTotal.value;
                var max = surveys[0].referenceData.gTotal.value;
                for (var i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        min = min < (surveys[i].referenceData.gTotal.value - (failMult * (surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value * acSigma))) ? min : (surveys[i].referenceData.gTotal.value - (failMult * (surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value * acSigma)));
                        min = min < surveys[i].correctedDatas.MULTISTATION.gTotal.value ? min : surveys[i].correctedDatas.MULTISTATION.gTotal.value;
                        max = max > (surveys[i].referenceData.gTotal.value + (failMult * (surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value * acSigma))) ? max : (surveys[i].referenceData.gTotal.value + (failMult * (surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value * acSigma)));
                        max = max > surveys[i].correctedDatas.MULTISTATION.gTotal.value ? max : surveys[i].correctedDatas.MULTISTATION.gTotal.value;
                    }
                }

                for (i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        plot.push(createQCEntry(
                            surveys[i].md.value,
                            surveys[i].correctedDatas.MULTISTATION.gTotal.value,
                            surveys[i].referenceData.gTotal.value,
                            surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value * acSigma,
                            surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMin.value * acSigma,
                            min,
                            max
                        ));
                    }
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected dip
             * @param surveys
             * @returns {Array}
             */
            PlotService.createCorrDipQcPlot = function (surveys, acSigma) {
                var plot = [];

                //Create min/max values for regions
                var min = surveys[0].referenceData.dip.value;
                var max = surveys[0].referenceData.dip.value;
                for (var i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        min = min < (surveys[i].referenceData.dip.value - (failMult * (surveys[i].correctedFacDatas.MULTISTATION.dipFacMax.value * acSigma))) ? min : (surveys[i].referenceData.dip.value - (failMult * (surveys[i].correctedFacDatas.MULTISTATION.dipFacMax.value * acSigma)));
                        min = min < surveys[i].correctedDatas.MULTISTATION.dip.value ? min : surveys[i].correctedDatas.MULTISTATION.dip.value;
                        max = max > (surveys[i].referenceData.dip.value + (failMult * (surveys[i].correctedFacDatas.MULTISTATION.dipFacMax.value * acSigma))) ? max : (surveys[i].referenceData.dip.value + (failMult * (surveys[i].correctedFacDatas.MULTISTATION.dipFacMax.value * acSigma)));
                        max = max > surveys[i].correctedDatas.MULTISTATION.dip.value ? max : surveys[i].correctedDatas.MULTISTATION.dip.value;
                    }
                }

                for (i = 0; i < surveys.length; i++) {
                    if (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) {
                        plot.push(createQCEntry(
                            surveys[i].md.value,
                            surveys[i].correctedDatas.MULTISTATION.dip.value,
                            surveys[i].referenceData.dip.value,
                            surveys[i].correctedFacDatas.MULTISTATION.dipFacMax.value * acSigma,
                            surveys[i].correctedFacDatas.MULTISTATION.dipFacMin.value * acSigma,
                            min,
                            max
                        ));
                    }
                }
                return plot;
            };

            /**
             * Creates the plot data for all sigma data
             * @param surveys
             * @param acSigma
             * @returns {Array}
             */
            PlotService.createSigmaAllPlot = function (surveys, acSigma) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createSigmaAllEntry(
                        surveys[i].md.value,
                        surveys[i].facData.sigma,
                        (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) ? surveys[i].correctedFacDatas.MULTISTATION.sigma : null,
                        acSigma
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for all b totals
             * @param surveys
             * @param acSigma
             * @returns {Array}
             */
            PlotService.createBTotalAllPlot = function (surveys, acSigma) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createQCAllEntry(
                        //(md, calc, corr, ref, facRaw, facCorr
                        surveys[i].md.value,
                        surveys[i].bTotalCalc.value,
                        (surveys[i].correctedDatas !== undefined && surveys[i].correctedDatas.MULTISTATION !== undefined) ? surveys[i].correctedDatas.MULTISTATION.bTotal.value : null,
                        surveys[i].referenceData.bTotal.value,
                        surveys[i].facData.bTotalFacMax.value * acSigma,
                        (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) ? surveys[i].correctedFacDatas.MULTISTATION.bTotalFacMax.value * acSigma : null
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for all g totals
             * @param surveys
             * @returns {Array}
             */
            PlotService.createGTotalAllPlot = function (surveys, acSigma) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createQCAllEntry(
                        surveys[i].md.value,
                        surveys[i].gTotalCalc.value,
                        (surveys[i].correctedDatas !== undefined && surveys[i].correctedDatas.MULTISTATION !== undefined) ? surveys[i].correctedDatas.MULTISTATION.gTotal.value : null,
                        surveys[i].referenceData.gTotal.value,
                        surveys[i].facData.gTotalFacMax.value * acSigma,
                        (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) ? surveys[i].correctedFacDatas.MULTISTATION.gTotalFacMax.value * acSigma : null
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for all dips
             * @param surveys
             * @returns {Array}
             */
            PlotService.createDipAllPlot = function (surveys, acSigma) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createQCAllEntry(
                        surveys[i].md.value,
                        surveys[i].dipCalc.value,
                        (surveys[i].correctedDatas !== undefined && surveys[i].correctedDatas.MULTISTATION !== undefined) ? surveys[i].correctedDatas.MULTISTATION.dip.value : null,
                        surveys[i].referenceData.dip.value,
                        surveys[i].facData.dipFacMax.value * acSigma,
                        (surveys[i].correctedFacDatas !== undefined && surveys[i].correctedFacDatas.MULTISTATION !== undefined) ? surveys[i].correctedFacDatas.MULTISTATION.dipFacMax.value * acSigma : null
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for raw inclination
             * @param surveys
             * @param qc
             * @returns {Array}
             */
            PlotService.createRawIncPlot = function (surveys, qc) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createResidualEntry(
                        surveys[i].md.value,
                        surveys[i].incCalc.value - surveys[i].incRep.value,
                        qc
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected inclination
             * @param surveys
             * @param qc
             * @returns {Array}
             */
            PlotService.createCorrIncPlot = function (surveys, qc) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createResidualEntry(
                        surveys[i].md.value,
                        (surveys[i].correctedDatas !== undefined && surveys[i].correctedDatas.MULTISTATION !== undefined) ? surveys[i].correctedDatas.MULTISTATION.inclination.value - surveys[i].incRep.value : null,
                        qc
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected inclination with post processed data for comparison
             * @param surveys
             * @param qc
             * @returns {Array}
             */
            PlotService.createCorrIncPlotWithPost = function (surveys, postProcessedSurveys, qc) {
                var plot = [];

                for (var i = 0; i < postProcessedSurveys.length; i++) {
                    var survey = null;
                    for (var j = 0; j < surveys.length; j++) {
                        if (postProcessedSurveys[i].md.value === surveys[j].md.value && postProcessedSurveys[i].md.unit === surveys[j].md.unit) {
                            survey = (surveys[j].correctedDatas !== undefined && surveys[j].correctedDatas.MULTISTATION !== undefined) ? surveys[j].correctedDatas.MULTISTATION.inclination.value - surveys[j].incRep.value : null;
                            break;
                        }
                    }
                    plot.push(createResidualEntryWithPost(
                        postProcessedSurveys[i].md.value,
                        survey,
                        (postProcessedSurveys[i].correctedDatas !== undefined && postProcessedSurveys[i].correctedDatas.MULTISTATION !== undefined) ? postProcessedSurveys[i].correctedDatas.MULTISTATION.inclination.value - postProcessedSurveys[i].incRep.value : null,
                        qc
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for raw azimuth
             * @param surveys
             * @param qc
             * @returns {Array}
             */
            PlotService.createRawAziPlot = function (surveys, qc) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createResidualEntry(
                        surveys[i].md.value,
                        surveys[i].aziCalc.value - surveys[i].aziRep.value,
                        qc
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected azimuth
             * @param surveys
             * @param qc
             * @returns {Array}
             */
            PlotService.createCorrAziPlot = function (surveys, qc) {
                var plot = [];
                for (var i = 0; i < surveys.length; i++) {
                    plot.push(createResidualEntry(
                        surveys[i].md.value,
                        (surveys[i].correctedDatas !== undefined && surveys[i].correctedDatas.MULTISTATION !== undefined) ? surveys[i].correctedDatas.MULTISTATION.azimuth.value - surveys[i].aziRep.value : null,
                        qc
                    ));
                }
                return plot;
            };

            /**
             * Creates the plot data for corrected azimuth with post processed data for comparison
             * @param surveys
             * @param qc
             * @returns {Array}
             */
            PlotService.createCorrAziPlotWithPost = function (surveys, postProcessedSurveys, qc) {
                var plot = [];

                for (var i = 0; i < postProcessedSurveys.length; i++) {
                    var survey = null;
                    for (var j = 0; j < surveys.length; j++) {
                        if (postProcessedSurveys[i].md.value === surveys[j].md.value && postProcessedSurveys[i].md.unit === surveys[j].md.unit) {
                            survey = (surveys[j].correctedDatas !== undefined && surveys[j].correctedDatas.MULTISTATION !== undefined) ? surveys[j].correctedDatas.MULTISTATION.azimuth.value - surveys[j].aziRep.value : null;
                            break;
                        }
                    }
                    plot.push(createResidualEntryWithPost(
                        postProcessedSurveys[i].md.value,
                        survey,
                        (postProcessedSurveys[i].correctedDatas !== undefined && postProcessedSurveys[i].correctedDatas.MULTISTATION !== undefined) ? postProcessedSurveys[i].correctedDatas.MULTISTATION.azimuth.value - postProcessedSurveys[i].aziRep.value : null,
                        qc
                    ));

                }
                return plot;
            };

            /**
             * Creates an entry for the sigma plots
             * @param md
             * @param val
             * @param ref
             * @returns {*[]}
             */
            var createSigmaEntry = function (md, val, ref, min, max) {
                return [
                    md,                             //Measured Depth
                    [null, ref, max],               //Fail Region
                    [min, ref, null],               //Pass Region
                    [null, ref, null],              //Reference (AC Sigma)
                    [null, val, null]               //Value
                ];
            };

            /**
             * Creates an entry for the al sigma plot
             * @param md
             * @param raw
             * @param corr
             * @param ref
             * @returns {*[]}
             */
            var createSigmaAllEntry = function (md, raw, corr, ref) {
                return [
                    md,     //Measured Depth
                    ref,    //Reference (AC Sigma)
                    raw,    //Reported Sigma
                    corr    //Corrected Sigma
                ];
            };

            /**
             * Creates an entry for the standard QC plots.
             *
             * @param md
             * @param val
             * @param ref
             * @param facMax (must be modified by sigma already)
             * @param facMin (must be modified by sigma already)
             * @returns {*[]}
             */
            var createQCEntry = function (md, val, ref, facMax, facMin, min, max) {
                return [
                    md,                                             //Measured Depth
                    [null, ref + facMax, max],                      //Top fail region
                    [null, ref + facMin, ref + facMax],             //Top warning region
                    [ref - facMin, ref, ref + facMin],              //Pass region
                    [ref - facMax, ref - facMin, null],             //Bottom warning region
                    [min, ref - facMax, null],                      //Bottom fail region
                    [null, ref, null],                              //reference
                    [null, val, null]                               //value
                ];
            };

            /**
             * Creates an entry for the all qc plots
             * @param md
             * @param raw
             * @param corr
             * @param ref
             * @param facRaw
             * @param facCorr
             * @returns {*[]}
             */
            var createQCAllEntry = function (md, raw, corr, ref, facRaw, facCorr) {
                return [
                    md,             //Measured Depth
                    ref + facRaw,   //Top Raw Fac
                    ref + facCorr,  //Top Correction Fac
                    ref - facCorr,  //Bottom Correction Fac
                    ref - facRaw,   //Bottom Raw Fac
                    ref,            //Reference
                    raw,            //Reported
                    corr            //Corrected
                ];
            };

            /**
             * Creates an entry for the risidual plots
             * @param md
             * @param calc
             * @param corr
             * @param qc
             * @returns {*[]}
             */
            var createResidualEntry = function (md, value, qc) {
                return [
                    md,         //Measured Depth
                    value,       //Calculated Residual
                    qc,         //Positive QC
                    qc * -1.0   //Negative QC
                ];
            };

            /**
             * Creates an entry for the risidual plots
             * @param md
             * @param calc
             * @param corr
             * @param qc
             * @returns {*[]}
             */
            var createResidualEntryWithPost = function (md, value, postProcessValue, qc) {
                return [
                    md,         //Measured Depth
                    postProcessValue,
                    value,       //Calculated Residual
                    qc,         //Positive QC
                    qc * -1.0   //Negative QC
                ];
            };

            return PlotService;
        }]);
})();

