(function () {
    var app = angular.module('saphira');

    app.service('SurveySetRawDataTableService', [
        /* Angular Modules  */ '$filter',
        /* 3rd Party Modules*/ 'uiGridValidateService',
        /* Internal Modules */ 'InputValidationService', "TimeService",
        /* Input            */

        function(/* Angular Modules  */ $filter,
                 /* 3rd Party Modules*/ uiGridValidateService,
                 /* Internal Modules */ InputValidationService, TimeService
                 /* Input            */ ) {

        uiGridValidateService.setValidator('rangeValidation',
            function(info) {
                return function(oldValue, newValue, rowEntity, colDef) {
                   var isValid = InputValidationService.validateInputRaw(newValue, info.unit, info.unitType);
                   return isValid;
                };
            },
            function(info) {
                return InputValidationService.getRangeWarning(info.unit, info.unitType, info.fieldName);
            }
        );

        /**
         * Combines the given table columns into one array.
         *
         * @param columnArrays  A list of arrays of columns
         *
         * @returns {Array}
         */
        var combineColumns = function (columnArrays) {

            var combinedColumns = [];

            columnArrays.forEach(function (columns) {
                columns.forEach(function (column) {
                    combinedColumns.push(column);
                });
            });
            return combinedColumns;
        };

        var service = {};

            service.prep = function (units, isCustomer, uiGridConstants) {
                service.units = units;
                service.isCustomer = isCustomer;
                service.uiGridConstants = uiGridConstants;
            };

            service.getType = function () {
                return [
                    {
                        field: 'type',
                        displayName: 'Type',
                        cellFilter: 'surveyTypeFilter',
                        cellEditableCondition: function(params){
                            return params.row.entity.type !== 'DEFINITIVE_REPORTED';
                        },
                        editableCellTemplate: 'ui-grid/dropdownEditor',
                        editDropdownOptionsArray: [
                            {id: 'STANDARD', value: 'D-S'},
                            {id: 'POOR', value: 'D-P'},
                            {id: 'DEFINITIVE_INTERPOLATED', value: 'D-I'},
                            {id: 'BAD', value: 'X-B'},
                            {id: 'ACC_CHECK', value: 'X-A'},
                            {id: 'CHECKSHOT', value: 'X-C'},
                            {id: 'INTERPOLATED', value: 'X-I'}
                        ],
                        visible: !service.isCustomer,
                        minWidth: 60,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getTypeMsaValidationModal = function () {
                return [
                    {
                        field: 'type',
                        displayName: 'Type',
                        cellFilter: 'surveyTypeFilter',
                        editableCellTemplate: 'ui-grid/dropdownEditor',
                        editDropdownOptionsArray: [
                            {id: 'STANDARD', value: 'D-S'},
                            {id: 'POOR', value: 'D-P'},
                            {id: 'BAD', value: 'X-B'},
                            {id: 'CHECKSHOT', value: 'X-C'}
                        ],
                        visible: !service.isCustomer,
                        minWidth: 60,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getDateAndTime = function () {
                return [
                    {
                        field: 'submittedTime',
                        displayName: 'Submitted Time' + " (" + TimeService.getTimeZoneAbbr().server + ")",
                        cellFilter: 'instant',
                        minWidth: 190,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getMd = function () {
                return [
                    {
                        field: 'md.value',
                        displayName: 'Md (' + $filter('distanceUnit')(service.units.depth) + ')',
                        cellFilter: 'distanceDecimal:"' + service.units.depth + '"',
                        enableSorting: true,
                        sort: {
                            direction: service.uiGridConstants.ASC
                        },
                        suppressRemoveSort: true,
                        enableHiding: false,
                        enableColumnMenu: true,
                        minWidth: 135,
                        sortingAlgorithm: function (a, b) {
                            if (a === b) {
                                return 0;
                            }
                            if (a > b)
                                return 1;
                            else {
                                return -1;
                            }
                        }
                    }
                ];
            };

            service.getMsaInc = function () {
                return [
                    {
                        field: 'correctedDatas.MULTISTATION.inclination.value',
                        displayName: 'MSA Inc (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getMsaAzi = function () {
                return [
                    {
                        field: 'correctedDatas.MULTISTATION.azimuth.value',
                        displayName: 'MSA Azi (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getMsaIncAzi = function () {
                return combineColumns([
                    service.getMsaInc(),
                    service.getMsaAzi()
                ]);
            };

            service.getRepInc = function () {
                return [
                    {
                        field: 'incRep.value',
                        displayName: 'Reported Inc (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getRepAzi = function () {
                return [
                    {
                        field: 'aziRep.value',
                        displayName: 'Reported Azi (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getRepIncAzi = function () {
                return combineColumns([
                    service.getRepInc(),
                    service.getRepAzi()
                ]);
            };

            service.getCalcInc = function () {
                return [
                    {
                        field: 'incCalc.value',
                        displayName: 'Inc Calc (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getCalcAzi = function () {
                return [
                    {
                        field: 'aziCalc.value',
                        displayName: 'Azi Calc (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getCalcIncAzi = function () {
                return combineColumns([
                    service.getCalcInc(),
                    service.getCalcAzi()
                ]);
            };

            service.getDeltaIncAzi = function () {
                return [
                    {
                        field: 'incCalcReportedDelta.value',
                        displayName: 'Inc Calculated - Reported (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'incCorrReportedDelta.value',
                        displayName: 'Inc Corrected - Reported (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'aziCalcReportedDelta.value',
                        displayName: 'Azi Calculated - Reported (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'aziCorrReportedDelta.value',
                        displayName: 'Azi Corrected - Reported (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getReferenceValues = function() {
                return [
                    {
                        field: 'referenceData.dec.value',
                        displayName: 'Ref. Dec (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'gridConvergence.value',
                        displayName: 'Grid Convergence (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle +'"',
                        minWidth: 120,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false,
                        validators: { rangeValidation: { fieldName: 'Grid Convergence', unitType:'gridConvergence', unit: service.units.angle } },
                        cellTemplate: 'ui-grid/cellTitleValidator'
                    },
                    {
                        field: 'referenceData.bTotal.value',
                        displayName: 'Ref. B Total (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'referenceData.dip.value',
                        displayName: 'Ref. Dip (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'referenceData.gTotal.value',
                        displayName: 'Ref. G Total (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getDeltaCorrectedReportedIncAzi = function () {
                return [
                    {
                        field: 'incCorrReportedDelta.value',
                        displayName: 'Inc Corrected - Reported (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'aziCorrReportedDelta.value',
                        displayName: 'Azi Corrected - Reported (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getCorrectionMisalignments = function () {
                return [
                    {
                        field: 'corrections.MULTISTATION.gravityMisalign.q0',
                        displayName: 'Gravitational Q0',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityMisalign.q1',
                        displayName: 'Gravitational Q1',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityMisalign.q2',
                        displayName: 'Gravitational Q2',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityMisalign.q3',
                        displayName: 'Gravitational Q3',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticMisalign.q0',
                        displayName: 'Magnetic Q0',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticMisalign.q1',
                        displayName: 'Magnetic Q1',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticMisalign.q2',
                        displayName: 'Magnetic Q2',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticMisalign.q3',
                        displayName: 'Magnetic Q3',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getReferenceValues = function () {
                return [
                    {
                        field: 'referenceData.gTotal.value',
                        displayName: 'Ref. G Total (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'referenceData.bTotal.value',
                        displayName: 'Ref. B Total (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'referenceData.dip.value',
                        displayName: 'Ref. Dip (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'referenceData.dec.value',
                        displayName: 'Ref. Dec (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'gridConvergence.value',
                        displayName: 'Grid Convergence (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        minWidth: 120,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getCalculatedValuesGTotal = function () {
                return [
                    {
                        field: 'gTotalCalc.value',
                        displayName: 'G Total Calc (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        visible: !service.isCustomer,
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getCalculatedValuesBTotal = function () {
                return [
                    {
                        field: 'bTotalCalc.value',
                        displayName: 'B Total Calc (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        visible: !service.isCustomer,
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getCalculatedValuesDip = function () {
                return [
                    {
                        field: 'dipCalc.value',
                        displayName: 'Dip Calc (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ]
            };

            service.getCalculatedValues = function () {
                return combineColumns([
                    service.getCalculatedValuesGTotal(),
                    service.getCalculatedValuesBTotal(),
                    service.getCalculatedValuesDip()
                ])
            };

            service.getReportedBackCalculated = function () {
                return [
                    {
                        field: 'backCalculatedSurveyInputFields.reportedBTotal.value',
                        displayName: 'Reported B Total (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'backCalculatedSurveyInputFields.reportedGTotal.value',
                        displayName: 'Reported G Total (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'backCalculatedSurveyInputFields.reportedDip.value',
                        displayName: 'Reported Dip (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'backCalculatedSurveyInputFields.reportedToolFace.value',
                        displayName: 'Reported ToolFace (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getRawSixAxis = function () {
                return [
                    {
                        field: 'rawSixAxis.orientedG.0',
                        displayName: 'Gx (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedG.1',
                        displayName: 'Gy (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedG.2',
                        displayName: 'Gz (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedB.0',
                        displayName: 'Bx (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedB.1',
                        displayName: 'By (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedB.2',
                        displayName: 'Bz (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 90,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getCalculatedSixAxis = function () {
                return [
                    {
                        field: 'rawSixAxis.orientedG.0',
                        displayName: 'Calculated Gx (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 90,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedG.1',
                        displayName: 'Calculated Gy (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 90,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedG.2',
                        displayName: 'Calculated Gz (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 90,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedB.0',
                        displayName: 'Calculated Bx (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 90,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedB.1',
                        displayName: 'Calculated By (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 90,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'rawSixAxis.orientedB.2',
                        displayName: 'Calculated Bz (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 90,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getMsaSixAxis = function () {
                return [
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.orientedG.0',
                        displayName: 'MSA Gx (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.orientedG.1',
                        displayName: 'MSA Gy (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.orientedG.2',
                        displayName: 'MSA Gz (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.orientedB.0',
                        displayName: 'MSA Bx (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.orientedB.1',
                        displayName: 'MSA By (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.orientedB.2',
                        displayName: 'MSA Bz (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getUnorientedMsaSixAxis = function () {
                return [
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.g.0',
                        displayName: 'MSA Gx (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.g.1',
                        displayName: 'MSA Gy (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.g.2',
                        displayName: 'MSA Gz (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.b.0',
                        displayName: 'MSA Bx (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.b.1',
                        displayName: 'MSA By (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedSixAxisDatas.MULTISTATION.b.2',
                        displayName: 'MSA Bz (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        minWidth: 100,
                        visible: !service.isCustomer,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getMsaCorrection = function () {
                return [
                    {
                        field: 'corrections.MULTISTATION.gravityBias.data.0',
                        displayName: 'MSA Gx Bias (' + $filter('gravityUnit')("MILLI_G") + ')',
                        visible: !service.isCustomer,
                        cellFilter: 'gravityDecimal:"MILLI_G"',
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityBias.data.1',
                        displayName: 'MSA Gy Bias (' + $filter('gravityUnit')("MILLI_G") + ')',
                        visible: !service.isCustomer,
                        cellFilter: 'gravityDecimal:"MILLI_G"',
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityBias.data.2',
                        displayName: 'MSA Gz Bias (' + $filter('gravityUnit')("MILLI_G") + ')',
                        visible: !service.isCustomer,
                        cellFilter: 'gravityDecimal:"MILLI_G"',
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityScales.data.0',
                        displayName: 'MSA Gx Scale',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellFilter: 'number:"4"'
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityScales.data.1',
                        displayName: 'MSA Gy Scale',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellFilter: 'number:"4"'
                    },
                    {
                        field: 'corrections.MULTISTATION.gravityScales.data.2',
                        displayName: 'MSA Gz Scale',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellFilter: 'number:"4"'
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticBias.data.0',
                        displayName: 'MSA Bx Bias (' + $filter('magneticUnit')("NANOTESLA") + ')',
                        visible: !service.isCustomer,
                        cellFilter: 'magneticDecimal:"NANOTESLA"',
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticBias.data.1',
                        displayName: 'MSA By Bias (' + $filter('magneticUnit')("NANOTESLA") + ')',
                        visible: !service.isCustomer,
                        cellFilter: 'magneticDecimal:"NANOTESLA"',
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticBias.data.2',
                        displayName: 'MSA Bz Bias (' + $filter('magneticUnit')("NANOTESLA") + ')',
                        visible: !service.isCustomer,
                        cellFilter: 'magneticDecimal:"NANOTESLA"',
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticScales.data.0',
                        displayName: 'MSA Bx Scale',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellFilter: 'number:"4"'
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticScales.data.1',
                        displayName: 'MSA By Scale',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellFilter: 'number:"4"'
                    },
                    {
                        field: 'corrections.MULTISTATION.magneticScales.data.2',
                        displayName: 'MSA Bz Scale',
                        visible: !service.isCustomer,
                        minWidth: 100,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellFilter: 'number:"4"'
                    }
                ];
            };

            service.getCalculatedToolFaces = function () {
                return [
                    {
                        field: 'calculatedHighSideToolFace.value',
                        displayName: 'HSTF Calc (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'calculatedMagneticToolFace.value',
                        displayName: 'MTF Calc (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getFacRanges = function () {
                return [
                    {
                        field: 'facData.sigma',
                        displayName: 'FAC Sigma',
                        cellFilter: 'number : 2',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'facData.gTotalFacMin.value',
                        displayName: 'FAC G Total Min (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'facData.gTotalFacMax.value',
                        displayName: 'FAC G Total Max (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'facData.bTotalFacMin.value',
                        displayName: 'FAC B Total Min (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'facData.bTotalFacMax.value',
                        displayName: 'FAC B Total Max (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'facData.dipFacMin.value',
                        displayName: 'FAC Dip Min (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'facData.dipFacMax.value',
                        displayName: 'FAC Dip Max (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getMsaComputeFromMdRange = function () {
                return [
                    {
                        field: 'msaComputeFromMinMd.value',
                        displayName: 'Msa Md Min (' + $filter('distanceUnit')(service.units.depth) + ')',
                        cellFilter: 'distanceDecimal:"' + service.units.depth + '"',
                        enableSorting: true,
                        visible: !service.isCustomer,
                        sort: {
                            direction: service.uiGridConstants.ASC
                        },
                        suppressRemoveSort: true,
                        enableHiding: false,
                        enableColumnMenu: true,
                        minWidth: 135,
                        sortingAlgorithm: function (a, b) {
                            if (a === b) {
                                return 0;
                            }
                            if (a > b)
                                return 1;
                            else {
                                return -1;
                            }
                        }
                    },
                    {
                        field: 'msaComputeFromMaxMd.value',
                        displayName: 'Msa Md Max (' + $filter('distanceUnit')(service.units.depth) + ')',
                        cellFilter: 'distanceDecimal:"' + service.units.depth + '"',
                        enableSorting: true,
                        visible: !service.isCustomer,
                        sort: {
                            direction: service.uiGridConstants.ASC
                        },
                        suppressRemoveSort: true,
                        enableHiding: false,
                        enableColumnMenu: true,
                        minWidth: 135,
                        sortingAlgorithm: function (a, b) {
                            if (a === b) {
                                return 0;
                            }
                            if (a > b)
                                return 1;
                            else {
                                return -1;
                            }
                        }
                    }
                ];
            };

        service.getMsaCorrection = function() {
            return [
                {
                    field: 'corrections.MULTISTATION.gravityBias.data.0',
                    displayName: 'MSA Gx Bias (' + $filter('gravityUnit')("MILLI_G") + ')',
                    visible: !service.isCustomer,
                    cellFilter: 'gravityDecimal:"MILLI_G"',
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false
                },
                {
                    field: 'corrections.MULTISTATION.gravityBias.data.1',
                    displayName: 'MSA Gy Bias (' + $filter('gravityUnit')("MILLI_G") + ')',
                    visible: !service.isCustomer,
                    cellFilter: 'gravityDecimal:"MILLI_G"',
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false
                },
                {
                    field: 'corrections.MULTISTATION.gravityBias.data.2',
                    displayName: 'MSA Gz Bias (' + $filter('gravityUnit')("MILLI_G") + ')',
                    visible: !service.isCustomer,
                    cellFilter: 'gravityDecimal:"MILLI_G"',
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false
                },
                {
                    field: 'corrections.MULTISTATION.gravityScales.data.0',
                    displayName: 'MSA Gx Scale',
                    visible: !service.isCustomer,
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellFilter: 'number:"4"'
                },
                {
                    field: 'corrections.MULTISTATION.gravityScales.data.1',
                    displayName: 'MSA Gy Scale',
                    visible: !service.isCustomer,
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellFilter: 'number:"4"'
                },
                {
                    field: 'corrections.MULTISTATION.gravityScales.data.2',
                    displayName: 'MSA Gz Scale',
                    visible: !service.isCustomer,
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellFilter: 'number:"4"'
                },
                {
                    field: 'corrections.MULTISTATION.magneticBias.data.0',
                    displayName: 'MSA Bx Bias (' + $filter('magneticUnit')("NANOTESLA") + ')',
                    visible: !service.isCustomer,
                    cellFilter: 'magneticDecimal:"NANOTESLA"',
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false
                },
                {
                    field: 'corrections.MULTISTATION.magneticBias.data.1',
                    displayName: 'MSA By Bias (' + $filter('magneticUnit')("NANOTESLA") + ')',
                    visible: !service.isCustomer,
                    cellFilter: 'magneticDecimal:"NANOTESLA"',
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false
                },
                {
                    field: 'corrections.MULTISTATION.magneticBias.data.2',
                    displayName: 'MSA Bz Bias (' + $filter('magneticUnit')("NANOTESLA") + ')',
                    visible: !service.isCustomer,
                    cellFilter: 'magneticDecimal:"NANOTESLA"',
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false
                },
                {
                    field: 'corrections.MULTISTATION.magneticScales.data.0',
                    displayName: 'MSA Bx Scale',
                    visible: !service.isCustomer,
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellFilter: 'number:"4"'
                },
                {
                    field: 'corrections.MULTISTATION.magneticScales.data.1',
                    displayName: 'MSA By Scale',
                    visible: !service.isCustomer,
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellFilter: 'number:"4"'
                },
                {
                    field: 'corrections.MULTISTATION.magneticScales.data.2',
                    displayName: 'MSA Bz Scale',
                    visible: !service.isCustomer,
                    minWidth: 100,
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellFilter: 'number:"4"'
                }
            ];
        };

            service.getMsaFacValues = function () {
                return [
                    {
                        field: 'correctedFacDatas.MULTISTATION.sigma',
                        displayName: 'MSA FAC Sigma',
                        cellFilter: 'number : 2',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedFacDatas.MULTISTATION.gTotalFacMin.value',
                        displayName: 'MSA FAC G Total Min (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedFacDatas.MULTISTATION.gTotalFacMax.value',
                        displayName: 'MSA FAC G Total Max (' + $filter('gravityUnit')(service.units.gravity) + ')',
                        cellFilter: 'gravityDecimal:"' + service.units.gravity + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedFacDatas.MULTISTATION.bTotalFacMin.value',
                        displayName: 'MSA FAC B Total Min (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedFacDatas.MULTISTATION.bTotalFacMax.value',
                        displayName: 'MSA FAC B Total Max (' + $filter('magneticUnit')(service.units.magnetic) + ')',
                        cellFilter: 'magneticDecimal:"' + service.units.magnetic + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedFacDatas.MULTISTATION.dipFacMin.value',
                        displayName: 'MSA FAC Dip Min (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedFacDatas.MULTISTATION.dipFacMax.value',
                        displayName: 'MSA FAC Dip Max (' + $filter('angleUnit')(service.units.angle) + ')',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getMinCurve = function () {
                return [
                    {
                        field: 'tvd.value',
                        displayName: 'TVD (' + $filter('distanceUnit')(service.units.depth) + ')',
                        cellFilter: 'distanceDecimal:"' + service.units.depth + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'north.value',
                        displayName: 'Northings (' + $filter('distanceUnit')(service.units.distance) + ')',
                        cellFilter: 'distanceDecimal:"' + service.units.distance + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'east.value',
                        displayName: 'Eastings (' + $filter('distanceUnit')(service.units.distance) + ')',
                        cellFilter: 'distanceDecimal:"' + service.units.distance + '"',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },

                    {
                        field: 'dogLegSeverity.value',
                        displayName: 'DLS (' + $filter('angleUnit')(service.units.angle) + ' / ' + (service.units.depth === 'METER' ? '30m' : '100ft') + ')',
                        cellFilter: 'number : 3',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            service.getChangeDataValidationModal = function () {
                return [
                    {
                        field: 'changedData',
                        visible: false
                    }
                ]
            };

            service.getMisc = function() {
                return [
                                        {
                        field: 'surveySource',
                        displayName: 'Survey Source',
                        visible: !service.isCustomer,
                        minWidth: 120,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'trackingId',
                        displayName: 'Tracking ID',
                        enableSorting: false,
                        enableHiding: false,
                        enableColumnMenu: false,
                        cellTooltip: true,
                        minWidth: 200
                    },
                    {
                        field: 'receivedTime',
                        displayName: 'Received Time' + " (" + TimeService.getTimeZoneAbbr().server + ")",
                        visible: !service.isCustomer,
                        cellFilter: 'instant',
                        minWidth: 190,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'fileUploadTime',
                        displayName: 'File Upload Time' + " (" + TimeService.getTimeZoneAbbr().server + ")",
                        visible: !service.isCustomer,
                        cellFilter: 'instant',
                        minWidth: 190,
                        enableSorting: false,
                        enableColumnMenu: false
                    },
                    {
                        field: 'correctedTime',
                        displayName: 'Corrected Time' + " (" + TimeService.getTimeZoneAbbr().server + ")",
                        visible: !service.isCustomer,
                        cellFilter: 'instant',
                        minWidth: 190,
                        enableSorting: false,
                        enableColumnMenu: false
                    }
                ];
            };

            return service;

        }]);
})();
