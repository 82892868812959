(function () {

    var app = angular.module('saphira');

    app.controller('SurveyProgramModalController', [
        /* Angular Modules  */ '$scope', '$uibModal', '$uibModalInstance', '$filter', '$timeout',
        /* 3rd Party Modules*/ 'SweetAlert',
        /* Internal Modules */ "InputValidationService", "DataService", "SurveyProgramService",
        /* Input            */ 'wellbore',

        function (/* Angular Modules  */ $scope, $uibModal, $uibModalInstance, $filter, $timeout,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ InputValidationService, DataService, SurveyProgramService,
                  /* Input            */ wellbore) {

            /* Reference input validation service */
            $scope.InputValidationService = InputValidationService;

            $scope.pickers = {
                surveyProgram: {}
            };

            $scope.surveyProgram = {
                wellInformation: {},
                geomagnetics: {},
                surveyProgramDetail: [{}]
            };
            $scope.geomagneticsDate;

            $scope.openCalender = function (e, picker) {
                e.preventDefault();
                e.stopPropagation();
                picker.open = true;
            };

            $scope.addNewItemSP = function () {
                $scope.surveyProgram.surveyProgramDetail.push({
                    startDepth: {
                        value: 0,
                        unit: $scope.surveyProgram.wellInformation.tvdReference.unit
                    },
                    endDepth: {
                        value: 0,
                        unit: $scope.surveyProgram.wellInformation.tvdReference.unit}
                });
            };

            $scope.removeItemSP = function (index) {
                $scope.surveyProgram.surveyProgramDetail.splice(index, 1);
                console.log($scope.surveyProgram.surveyProgramDetail);
            };

            $scope.onChangeTVDRefUnit = function () {
                for (var index = 0; index < $scope.surveyProgram.surveyProgramDetail.length; index++) {
                    $scope.surveyProgram.surveyProgramDetail[index].startDepth.unit = $scope.surveyProgram.wellInformation.tvdReference.unit;
                    $scope.surveyProgram.surveyProgramDetail[index].endDepth.unit = $scope.surveyProgram.wellInformation.tvdReference.unit;
                }
            };

            var inputsToValidateGridNorth = [
                'tvdReferenceInput', 'latitudeInput', 'longitudeInput',
                'northingsInput', 'eastingsInput',
                'geomagneticsTotalField', 'geomagneticsDeclination', 'geomagneticsDipAngle', 'geomagneticsGridConvergence'
            ];

            var inputsToValidateTrueNorth = [
                'tvdReferenceInput', 'latitudeInput', 'longitudeInput',
                'northingsInput', 'eastingsInput',
                'geomagneticsTotalField', 'geomagneticsDeclination', 'geomagneticsDipAngle'
            ];

            var getSurveyProgramData = function () {
                SurveyProgramService.getByWellboreId(wellbore.id).then(
                    function (result) {
                        $scope.surveyProgram = result.data;
                        if ($scope.surveyProgram.wellInformation.latitude !== null && $scope.surveyProgram.wellInformation.latitude.value !== null
                            && $scope.surveyProgram.wellInformation.latitude.value < 0) {
                            $scope.surveyProgram.wellInformation.latitude.value = (-1) * $scope.surveyProgram.wellInformation.latitude.value;
                        }
                        if ($scope.surveyProgram.wellInformation.longitude !== null && $scope.surveyProgram.wellInformation.longitude.value !== null
                            && $scope.surveyProgram.wellInformation.longitude.value < 0) {
                            $scope.surveyProgram.wellInformation.longitude.value = (-1) * $scope.surveyProgram.wellInformation.longitude.value;
                        }
                        //Truncate lat and long to 4 decimal places
                        $scope.surveyProgram.wellInformation.latitude.value = SurveyProgramService.truncate($scope.surveyProgram.wellInformation.latitude.value, 4);
                        $scope.surveyProgram.wellInformation.longitude.value = SurveyProgramService.truncate($scope.surveyProgram.wellInformation.longitude.value, 4);

                        $scope.onChangeTVDRefUnit();
                        if($scope.surveyProgram.geomagnetics.date!=null) {
                            $scope.geomagneticsDate = new Date($scope.surveyProgram.geomagnetics.date);
                        }

                        //Throw up a warning if we couldn't transform the northing and easting into the CRS lat/lon
                        if($scope.surveyProgram.wellInformation.coordinateReferenceType === "DEFAULT")  {
                            $scope.surveyProgram.wellInformation.latitude.value = null;
                            $scope.surveyProgram.wellInformation.longitude.value = null;
                            swal("Warning", "Failed to read coordinate reference system:\n<"
                                + $scope.surveyProgram.wellInformation.geodeticDatum
                                + ">\nPlease enter latitude and longitude.", "warning");
                        }
                    },
                    function (error) {
                        swal("Error", error.data.message, "error");
                        console.error(error.data);
                    });
            };


            var saveSurveyProgramReport = function () {
                $scope.surveyProgram.geomagnetics.date = $scope.geomagneticsDate;
                SurveyProgramService.addByWellboreId($scope.surveyProgram).then(
                    function (result) {
                        var surveyProgramId= result.data;
                        if(surveyProgramId!=null) {
                            $scope.surveyProgram.id = surveyProgramId;
                        }
                    },
                    function (error) {
                        SweetAlert.swal("Error", error.data, "error");
                        console.error(error);
                    });
            };

            $scope.setGeomagneticsData = function () {
                $scope.surveyProgram.geomagnetics.date = $scope.geomagneticsDate;
                SurveyProgramService.addGeomagneticsData($scope.surveyProgram).then(
                    function (result) {
                        var surveyProgramGeomagnetics = result.data;
                        if(surveyProgramGeomagnetics!=null) {
                            $scope.surveyProgram.geomagnetics.totalField = surveyProgramGeomagnetics.totalField;
                            $scope.surveyProgram.geomagnetics.declination = surveyProgramGeomagnetics.declination;
                            $scope.surveyProgram.geomagnetics.dipAngle = surveyProgramGeomagnetics.dipAngle;
                            $scope.surveyProgram.geomagnetics.gridConvergence = surveyProgramGeomagnetics.gridConvergence;
                            $scope.surveyProgram.geomagnetics.totalCorrection = surveyProgramGeomagnetics.totalCorrection;
                        }
                    },
                    function (error) {
                        SweetAlert.swal("Error", error.data, "error");
                        console.error(error);
                    });
            };

            /**
             * Print the report and save
             */
            $scope.printReport = function () {

                var inputsToValidateTemp = [];//inputsToValidate;

                for (var index = 0; index < $scope.surveyProgram.surveyProgramDetail.length; index++) {
                    inputsToValidateTemp.push('startDepth' + index);
                    inputsToValidateTemp.push('endDepth' + index);
                }

                //check input for errors
                if ($scope.surveyProgram.wellInformation.northReference === 'GRID') {
                    if (InputValidationService.validatedInputsReturnErrors(inputsToValidateGridNorth.concat(inputsToValidateTemp))) {
                        return;
                    }
                }

                if ($scope.surveyProgram.wellInformation.northReference === 'TRUE') {
                    if (InputValidationService.validatedInputsReturnErrors(inputsToValidateTrueNorth.concat(inputsToValidateTemp))) {
                        return;
                    }
                }



                saveSurveyProgramReport();

                $timeout(function () {
                    window.print();
                }, 100);
            };

            /**
             * This dismisses the modals so that the user can go back ot the previous view
             */
            $scope.closeModal = function () {
                $uibModalInstance.close('Exit');
            };

            var init = function () {
                getSurveyProgramData();
            };
            init();
        }
    ]);

})();
