/**
 *  This directive provides a chat box with the following feature set:
 *
 *  1.) When initialized, it scrolls to the bottom to show most recent messages.
 *  2.) Allows for sending a message if there is a message to send (won't send empty messages) and
 *      binding this event to a handler on the controller (for persisting the message)
 *  3.) Both the main user and any other users have different background colors
 *  4.) If a new message comes in,
 *      i) and the user is at the bottom, it scrolls down to show the new message.
 *      ii) and the user is not at the bottom, a box appears and says 'Click To View New Message'.
 *          a.) If the box is clicked, it scrolls down to the bottom to view the new message, and the box disappears.
 *          b.) If the user scrolls down, the box will disappear.
 *  5.) A message box displays the following information
 *      i) The username in the top left corner (bold, left-aligned)
 *      ii) The message (left aligned)
 *      iii) A timestamp showing relative date, 'Today at 10:10 AM' or '11/23/2015' (italicized, right-aligned)
 *      iv) A timestamp showing date and time on hover, 'Tuesday, November 24 2015, 10:10:13 am'
 *
 *
 */
(function() {

        var app = angular.module('saphira');

        app.directive('mvChatBox', [
            /* Angular Modules  */ '$document', '$window','$templateCache',
            /* 3rd Party Modules*/ 'moment',
            /* Internal Modules */  "TimeService",
            /* Input            */

            function (/* Angular Modules  */ $document, $window, $templateCache,
                      /* 3rd Party Modules*/ moment,
                      /* Internal Modules */ TimeService
                      /* Input            */) {

        return {
            restrict: 'E',      //Enforce the directive to be used as an element

            replace: true,      //Force the directive html to replace the tag

            scope: {
                messages: '=',  // (Required) The list of messages to display in the chat box

                                // Example:
                                // [
                                //     {user: bob, message: 'message 1', time: (moment object)},
                                //     {user: tim, message: 'message 2', time: (moment object)}
                                //     {user: bob, message: 'message 3', time: (moment object)}
                                //     {user: bob, message: 'message 4', time: (moment object)}
                                //     {user: tim, message: 'message 5', time: (moment object)}
                                //     {user: bob, message: 'message 6', time: (moment object)}
                                // ]

                options: '=',   // (Required) Some configuration options

                                // Example:
                                //  {
                                //      user: 'bob',            //the user displayed with the user color
                                //      userColor: '#E0EEEE'    //the background color for the user's messages
                                //      otherColor: '#E4EEE0'   //the background color for other users' messages
                                //  }

                onAddMessage: '&'   // (Required) Function called when a message is added
            },

            templateUrl: 'html/templates/chatBox.html', //The template url

            link: function(scope, elem, attrs) {

                /**
                 * createCssClass:
                 *
                 *      Creates a new css class with the given class name and gives it a css property with the given
                 *      value.
                 *
                 * @param className     Class name of the new css class
                 * @param field         The field to set
                 * @param value         The value to give that field
                 */
                var createCssClass = function(className, field, value, otherStyle) {
                    var headerStyleClass = document.createElement('style');
                    headerStyleClass.type = 'text/css';
                    headerStyleClass.innerHTML = '.' + className + ' { ' + field + ': ' + value + '; ' + otherStyle + '}';
                    document.getElementsByTagName('head')[0].appendChild(headerStyleClass);
                };

                /**
                 * createWidthCssClass:
                 *
                 *      Creates a new css class with the given class name and gives it a width property with the given
                 *      width value.
                 *
                 * @param className     Class name of the new css class
                 * @param width         Width to assign to the width property of the new css class
                 */
                var createWidthCssClass = function(className, width) {
                    createCssClass(className, 'width', width + 'px');
                };

                /**
                 * createHeightCssClass:
                 *
                 *      Creates a new css class with the given class name and gives it a height property with the given
                 *      height value.
                 *
                 * @param className     Class name of the new css class
                 * @param height        Height to assign to the height property of the new css class
                 */
                var createHeightCssClass = function(className, height) {
                    createCssClass(className, 'height', height + 'px');
                };

                //a list of the classes used in the directive
                var classNames = {
                    main: 'mvChatBox_Main',
                    scrollable: 'mvChatBox_Scrollable',
                    newMessage: 'mvChatBox_NewMessage',
                    messageBox: 'mvChatBox_MessageBox',
                    mainUserBox: 'mvChatBox_MainUserBox',
                    otherUserBox: 'mvChatBox_OtherUserBox',
                    inputTextField: 'mvChatBox_InputTextField',
                    submitTextButton: 'mvChatBox_SubmitTextButton'
                };

                //a list of the dimensions we want to pre-define
                var dimensions = {
                    main: {
                        width: elem[0].clientWidth,
                        height: elem[0].clientHeight
                    },
                    scrollable: {
                        widthBuffer: 13
                    },
                    scrollbar: {
                        width: 20
                    },
                    newMessage: {
                        widthBuffer: -12
                    },
                    messageBox: {
                        widthBuffer: -2
                    },
                    inputTextField: {
                        height: 26,
                        widthBuffer: -5
                    },
                    sendButton: {
                        width: 50,
                        height: 26
                    }
                };

                //do some math to figure out the rest of the fields
                dimensions.scrollable.width = dimensions.main.width - dimensions.scrollbar.width + dimensions.scrollable.widthBuffer;
                dimensions.scrollable.height = dimensions.main.height + dimensions.scrollable.heightBuffer;
                dimensions.messageBox.width = dimensions.scrollable.width - dimensions.scrollbar.width + dimensions.messageBox.widthBuffer;
                dimensions.newMessage.width = dimensions.messageBox.width + dimensions.newMessage.widthBuffer;
                dimensions.inputTextField.width = dimensions.messageBox.width - dimensions.sendButton.width + dimensions.scrollbar.width + dimensions.inputTextField.widthBuffer;

                //apply the dimensions to the new fields
                createWidthCssClass(classNames.main, dimensions.main.width);
                createWidthCssClass(classNames.scrollable, dimensions.scrollable.width);
                createHeightCssClass(classNames.scrollable, dimensions.scrollable.height);
                createWidthCssClass(classNames.newMessage, dimensions.newMessage.width);
                createWidthCssClass(classNames.messageBox, dimensions.messageBox.width);
                createWidthCssClass(classNames.inputTextField, dimensions.inputTextField.width);
                createWidthCssClass(classNames.submitTextButton, dimensions.sendButton.width);

                //create the css classes for the background color
                createCssClass(classNames.mainUserBox, 'background-color', scope.options.userColor, 'float: left;');
                createCssClass(classNames.otherUserBox, 'background-color', scope.options.otherColor, 'float: right;');
            },

            //The controller that handles all of the functionality after the directive has been compiled
            controller: ['$scope', '$element', function($scope, $element) {

                //The scroll element in the directive
                var scrollElement;


                /**
                 *  scrollToBottom:
                 *
                 *      This function scrolls the chatbox to the bottom to display the most
                 *      recent messages and indicates that there are no new messages (since
                 *      we see them now).
                 */
                $scope.scrollToBottom = function() {
                    scrollElement.scrollTop = scrollElement.scrollHeight;
                    $scope.newMessage = false;
                };

                /**
                 *  scrollbarIsAtBottom:
                 *
                 *      determines if the scrollbar is at the bottom
                 *
                 * @returns {boolean}   True if scrollbar is at bottom
                 */
                var scrollbarIsAtBottom = function() {
                    return scrollElement.scrollTop === (scrollElement.scrollHeight - scrollElement.offsetHeight);
                };

                /**
                 *  sendNewMessage:
                 *
                 *      Helper function for sending a new message.  Calls the onAddMessage function
                 *      with the new message text, resets the input field to empty string, and scrolls
                 *      to the bottom of the chatbox.
                 */
                $scope.sendNewMessage = function() {
                    //if we have a valid message to send (non-empty, defined)
                    if ($scope.newMessageText !== undefined && $scope.newMessageText !== '') {
                        //create the new message
                        var newMessage = {
                            sender: $scope.options.user,
                            message: $scope.newMessageText,
                            time: moment()
                        };

                        //add the message to the list of messages
                        //$scope.messages.push(newMessage);

                        //send the message off to the handler for persistence
                        $scope.onAddMessage()(newMessage);

                        //reset the text box
                        $scope.newMessageText = '';

                        //and scroll back to the bottom
                        $scope.scrollToBottom();
                    }
                };

                /**
                 * init:
                 *
                 *      An init function called when the directive is created.
                 */
                var init = function() {
                    //First, find the scroll element
                    for (var i = 0; i < $element[0].children[0].children.length; i++) {
                        if ($element[0].children[0].children[i].className === 'mvChatBox_Scrollable')
                        {
                            scrollElement = $element[0].children[0].children[i];
                        }
                    }

                    //Set up the scroll event listener
                    scrollElement.addEventListener('scroll', function() {
                        //if the scroll bar is at the bottom
                        if (scrollbarIsAtBottom()) {
                            //remove our new message notification
                            $scope.newMessage = false;
                            $scope.$apply();
                        }
                    });

                    //Set up the watch for new messages
                    $scope.$watch('messages', function(newvalue, oldValue) {
                        $scope.messages.forEach(function(message){
                            message.momentTime = moment.unix(message.time.seconds);
                            message.tz= TimeService.getTimeZoneAbbr().client;
                        });
                        //if we are at the bottom of the message list
                        if (scrollbarIsAtBottom()) {
                            //go ahead and scroll down for the new message
                            setTimeout(function() {
                                $scope.scrollToBottom();
                            }, 10);
                        }
                        //if we are in the middle of the list
                        else {
                            //display the new message message
                            //
                            $scope.newMessage = true;
                        }
                    }, true);

                    //give the controller time to bind to the messages dataset before scrolling to the bottom
                    setTimeout(function() {
                        $scope.scrollToBottom();
                    }, 10);
                };

                init();

            }]
        }

    }]);
})();