/**
 * Well Detail Controller. Sets up and controls the Well Detail view.
 */
(function () {

    var app = angular.module("saphira");

    app.controller("WellboreDetailController", [
        /* Angular Modules  */ "$scope", "$rootScope", "$interval", "$state", "$filter", "$q", "$window", "$uibModal", "$document",
        "$timeout", "$stateParams",
        /* 3rd Party Modules*/ "SweetAlert", "lodash", "uiGridConstants", "Idle",
        /* Internal Modules */ "InputValidationService", "HierarchyService", "CorrectionService", "StateService",
        "ActivityLogService", "AuthenticationService", "DataService", "UIBlocker", "SnapshotService", "ClientRequisitesService",
        "MviwIfrService", "MWDService", "TableConfigService", "ChatService", "WellboreSelectionService", "EventService",
        "CompanySnapshotService","TimeService", "CaptureAndSendAnalyticsService", "Constants",
        /* Input            */

        function (/* Angular Modules  */ $scope, $rootScope, $interval, $state, $filter, $q, $window, $uibModal, $document,
                  $timeout, $stateParams,
                  /* 3rd Party Modules*/ SweetAlert, lodash, uiGridConstants, Idle,
                  /* Internal Modules */ InputValidationService, HierarchyService, CorrectionService, StateService,
                  ActivityLogService, AuthenticationService, DataService, UIBlocker, SnapshotService, ClientRequisitesService,
                  MviwIfrService, MWDService, TableConfigService, ChatService, WellboreSelectionService, EventService,
                  CompanySnapshotService, TimeService, CaptureAndSendAnalyticsService, Constants
                  /* Input            */) {

            $scope.$on('IdleStart', function() {
                if(AuthenticationService.currentUser().isRigUser) {
                    AuthenticationService.updateDelayTimeInSession("idleStart");
                }
            });

            $scope.$on('IdleEnd', function() {
                if(AuthenticationService.currentUser().isRigUser) {
                    AuthenticationService.updateDelayTimeInSession("idleEnd");
                }
            });

            //how often the page is refreshed (ms)
            var refreshTimer = 15000;

            var newSurveySound = new Audio("sounds/notify.wav");
            var newFileSound = new Audio("sounds/notify.wav");

            var haveAckedInitialNotifications = false;

            // The survey validation error enums (not msa validation) that will stop full automation.
            var checkedSurveyValidationErrors = [6, 7, 8, 9];

            const SURVEYSET_HI_RES_SURVEYS = "Hi-Res Surveys";
            const SURVEYSET_SYNTHETIC_SURVEYS = "Synthetic Surveys";
            const SURVEYSET_CUSTOM_SURVEYS = "Custom RTX Surveys";
            const TRAJECTORY_SYNTHETIC_RTX_SURVEYS = "Synthetic RTX Surveys";
            const TRAJECTORY_CONTINUOUS_RTX_SURVEYS = "Continuous RTX Surveys";
            const TRAJECTORY_CUSTOM_RTX_SURVEYS = "Custom RTX Surveys";

            /* New survey to be entered and submitted */
            $scope.newSurvey = {};

            /* A map of tab names to indices */
            $scope.metaTabIndices = {
                information: 0,
                well: 3,
                wellbore: 4,
                trajectory: 5,
                surveySet: 6
            };

            /* A map of table names to indices */
            $scope.metaTabEditTableIndices = {
                company: 0,
                field: 1,
                pad: 2,
                well:3
            };

            var msaModal = null;

            /* Reference input validation service */
            $scope.InputValidationService = InputValidationService;

            $scope.pickers = {
                newSurvey: {}
            };

            $scope.ifrCubeModelIds = [];

            $scope.selectedMetas = {
                surveySet: null,
                trajectory: null
            };

            $scope.definitiveSurveySentinel = false;


            $scope.hasTvdService = {
                surveySet: false,
                trajectory: false
            };

            $scope.jumpToMd = {
                valueTrajectory: null,
                valueSurveySet: null,
            }

            /*
             * $scope.rigContactsEditMetadata.data holds the rigContact data from the well.  This variable is needed
             * because the UI for changing the rigContact data is on the SurveySet instead of the Well making it
             * difficult to store the rigContacts on the normal editMetaData variable which would contain the
             * SurveySet information. This needs to be two levels deep in order for scoping to work properly, which is
             * why the 'data' sub-variable exists.
             */
            $scope.rigContactsEditMetadata = {data: null};

            /**
             *  This is the data hierarchy for LOADED meta data and data for the detailed view
             */
            $scope.selected = {
                company: {},
                field: {},
                pad: {},
                well: {},
                wellbore: {},
                trajectory: {},
                surveySet: {},
                survey: {},
                rigUserSession: {}
            };
            $scope.selected.surveySet = {};

            $scope.openCalender = function (e, picker) {
                e.preventDefault();
                e.stopPropagation();
                picker.open = true;
            };

            $scope.notificationsAvailableForTrajectory = false;
            $scope.notificationsEnabledForSurveySet = false;

            $scope.isSurveyListEmpty = function () {
                if ($scope.selected.surveySet && $scope.selected.surveySet.id &&
                    HierarchyService.Survey.Loaded[$scope.selected.surveySet.id] &&
                    HierarchyService.Survey.Loaded[$scope.selected.surveySet.id]) {
                    return HierarchyService.Survey.Loaded[$scope.selected.surveySet.id].length === 0;
                }
                return false;
            };

            var pendingRefresh = false;

            $document.bind("keyup", function (event) {
                //if delete is pressed
                if (event.keyCode === 46 && !isRigUser()) {
                    $scope.deleteSurveys();
                }
            });

            /**
             * canViewOtherActions:
             *
             *      A quick boolean check to see if the "Other Actions" button should be displayed or not
             *
             * @returns {boolean}
             */
            $scope.canViewOtherActions = function () {
                return true;
                //if we have permission to view the activity log on the wellbore
                if ($scope.checkPerm($scope.selected.wellbore.data, 'ACTIVITY_LOG')) {
                    return true;
                }
                //if we are on the trajectory tab
                else if ($scope.currentMetaTab === $scope.metaTabIndices.trajectory) {
                    //always display more options (can always display the 3d trajectory)
                    return true;
                }
                //if we are on the survey set tab
                else if ($scope.currentMetaTab === $scope.metaTabIndices.surveySet) {
                    //if we have ANY of the perms listed, we should display the
                    var perms = ['AUTOMATION', 'SNAPSHOT_FULL', 'ATTACHMENT', 'UPDATE', 'DELETE'];
                    for (var i = 0; i < perms.length; i++) {
                        if ($scope.checkPerm($scope.selected.surveySet.data, perms[i]))
                            return true;
                    }
                }
                else {
                    return false;
                }
            };

            /**
             * canViewMiscActions:
             *
             *  A quick boolean check to see if the "Misc Actions" button should be displayed or not
             *
             * @returns {boolean}
             */
            $scope.canViewMiscActions = function () {
                //if we have permission to view the activity log on the wellbore
                if ($scope.checkPerm($scope.selected.wellbore.data, 'ACTIVITY_LOG')) {
                    return true;
                }
                //if we are on the trajectory tab or Survey set tab
                else if ($scope.currentMetaTab === $scope.metaTabIndices.trajectory || $scope.currentMetaTab === $scope.metaTabIndices.surveySet) {
                    //always display more options (can always display the 3d trajectory)
                    return true;
                }
                //if we are on any other tab besides Trajectory and Survey set
                else if (!$scope.isCustomer()) {
                    return true;
                }
                else {
                    return false;
                }
            };

            /**
             * canViewSurveyActions
             *
             *      A quick boolean check to see if the "Survey Actions" button should be displayed or not
             *
             * @returns {boolean}
             */
            $scope.canViewSurveyActions = function () {
                //if we are on the trajectory tab
                if ($scope.currentMetaTab === $scope.metaTabIndices.trajectory) {
                    if ($scope.checkPerm($scope.selected.trajectory.data, 'EXPORT_TRAJECTORY') && $scope.checkPerm($scope.selected.trajectory.data, 'ATTACHMENT'))
                        return true;
                }
                //if we are on the survey set tab
                else if ($scope.currentMetaTab === $scope.metaTabIndices.surveySet) {
                    //if we have ANY of the perms listed, we should display the survey actions dropdown
                    var perms = ['UPDATE', 'DELETE'];
                    for (var i = 0; i < perms.length; i++) {
                        if ($scope.checkPerm($scope.selected.surveySet.data, perms[i]))
                            return true;
                    }
                    if (($scope.checkPerm($scope.selected.surveySet.data, 'EXPORT_FULL') || $scope.checkPerm($scope.selected.surveySet.data, 'SNAPSHOT') )
                        && $scope.checkPerm($scope.selected.surveySet.data, 'ATTACHMENT'))
                        return true;
                }
                return false;
            };

            $scope.isRigUser = AuthenticationService.isRigUser;
            $scope.isCustomer = AuthenticationService.isCustomer;
            $scope.isReadOnlyUser = AuthenticationService.isReadOnlyUser;
            $scope.isSuperAdmin = AuthenticationService.isSuperAdmin;
            $scope.timeZone = TimeService.getTimeZoneAbbr();

            $scope.isNewFileFlagPresent = EventService.isNewFileFlagPresent;

            $scope.setNewFileFlag = function (flag) {
                EventService.setNewFileFlag(flag).then(function () {
                    refresh();
                });
            };

            $scope.clearFlags = function() {
                EventService.clearSurveySetFlags().then(function () {
                    refresh();
                });
            };

            /**
             * Opens a modal displaying a comparison of of the trajectory and selected survey set
             */
            $scope.open3dTraj = function () {
                // Capture analytics when open 3D Trajectory button is clicked
                const eventData = {
                    'button_name': 'Open 3d Traj',
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_BUTTON_CLICKED, eventData);
                var activityLogModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/3dTrajectoryModal.html',
                    controller: '3dTrajectoryModalController',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'activityLogModal',
                    resolve: {
                        data: function () {
                            return lodash.sortBy($scope.definitiveSurveyTable.data, function (survey) {
                                return survey.md.value;
                            });
                        },
                        rawData: function () {
                            if ($scope.readSurveyTable != undefined && $scope.readSurveyTable.data !== undefined) {
                                return lodash.sortBy($scope.readSurveyTable.data, function (survey) {
                                    return survey.md.value;
                                });
                            }
                            else {
                                return [];
                            }
                        }
                    }
                });
            };

            /**
             * Opens a modal displaying a Survey Program form
             */
            $scope.openSurveyProgram = function () {
                var surveyProgramModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/SurveyProgramModal.html',
                    controller: 'SurveyProgramModalController',
                    backdrop: 'static',
                    windowClass: 'surveyProgramReportModal',
                    resolve: {
                        wellbore: function() {
                            return $scope.selected.wellbore.data;
                        }
                    }
                });
            };

            var getSelectedSurveySet = function () {
                return HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId];
            };

            var getSurveys = function () {
                return HierarchyService.Survey.Loaded[StateService.wellboreDetailView.selectedSurveySetId];
            };

            var getDefinitiveSurveys = function () {
                return HierarchyService.Trajectory.Loaded[StateService.wellboreDetailView.selectedTrajectoryId].definitiveSurveys;
            };

            var sortSurveysByMd = function (surveys) {
                return $filter('orderBy')(surveys, 'md.value', false);
            };

            var getContextUnits = function () {
                return {
                    depth: HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId].unitSet.depthUnit,
                    distance: HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId].unitSet.distanceUnit,
                    angle: HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId].unitSet.angleUnit,
                    magnetic: HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId].unitSet.magneticUnit,
                    gravity: HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId].unitSet.gravityUnit
                }
            };

            $scope.getSurveyInputType = function () {
                if (getSelectedSurveySet()) {
                    return getSelectedSurveySet().surveyInputType;
                }
                return null;
            };

            //the index of the raw survey tab being displayed
            var activeSurveyTableTab = 'All Values';

            //the tabs that the raw survey table can be broken into for easy of use
            $scope.surveyTableTabs = TableConfigService.getRawSurveyTabs();

            //a template to build the read and edit survey tables from
            $scope.surveyTable = {
                multiSelect: true,
                modifierKeysToMultiSelect: true,
                noUnselect: false,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                editing: false,
                enableCellEditOnFocus: true,
                rowTemplate: '<div class="survey-type-{{row.entity.type}}" ng-dblclick="grid.appScope.editTable(row)"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}" ui-grid-cell></div></div>'
            };

            //the tables being displayed
            $scope.readSurveyTable = {};
            $scope.editSurveyTable = {};
            $scope.definitiveSurveyTable = {};

            var configSurveyTableReadOnly = function () {
                $scope.readSurveyTable = lodash.clone($scope.surveyTable);

                $scope.readSurveyTable.onRegisterApi = function (gridApi) {
                    $scope.readSurveyTableApi = gridApi;

                    var saveSurveySetReadTableState = function () {
                        StateService.readSurveyTableState = $scope.readSurveyTableApi.saveState.save();
                    };

                    $scope.readSurveyTableApi.core.on.sortChanged($scope, saveSurveySetReadTableState);
                    $scope.readSurveyTableApi.pinning.on.columnPinned($scope, saveSurveySetReadTableState);

                    $timeout(function () {
                        var lastRow = $scope.readSurveyTable.data[$scope.readSurveyTable.data.length - 1];
                        var firstColumn = $scope.readSurveyTable.columnDefs[0];
                        $scope.readSurveyTableApi.core.scrollTo(lastRow, firstColumn);
                    }, 0);
                };

                $timeout(function () {
                    if (StateService.readSurveyTableState !== undefined) {
                        $scope.readSurveyTableApi.saveState.restore($scope, StateService.readSurveyTableState);
                    }
                });
            };

            var configSurveyTableEdit = function () {
                $scope.editSurveyTable = lodash.cloneDeep($scope.surveyTable);
                $scope.editSurveyTable.rowEditWaitInterval = -1;
                $scope.editSurveyTable.onRegisterApi = function (gridApi) {
                    $scope.editSurveyTableApi = gridApi;

                    var saveSurveySetEditTableState = function () {
                        StateService.editSurveyTableState = $scope.editSurveyTableApi.saveState.save();
                    };

                    $scope.editSurveyTableApi.core.on.sortChanged($scope, saveSurveySetEditTableState);
                    $scope.editSurveyTableApi.pinning.on.columnPinned($scope, saveSurveySetEditTableState);
                };

                $timeout(function () {
                    if (StateService.editSurveyTableState !== undefined) {
                        $scope.editSurveyTableApi.saveState.restore($scope, StateService.editSurveyTableState);
                    }
                });
            };

            var setupSurveyTable = function () {
                var tab =  $scope.lastSelectedSurveyTableTab ?  $scope.lastSelectedSurveyTableTab.title : null;

                if (getSurveys()) {

                    $scope.surveyTable.data = sortSurveysByMd(getSurveys());

                    $scope.surveyTable.columnDefs = TableConfigService.getRawSurveyTableDefs(
                        getContextUnits(), $scope.isCustomer(), getSelectedSurveySet().surveyInputType, uiGridConstants, tab);

                    configSurveyTableReadOnly();
                    configSurveyTableEdit();
                }
            };

            /**
             * Creates and populates the definitive survey table
             */
            var setupDefinitiveDataTable = function () {

                if (getDefinitiveSurveys()) {

                    $scope.definitiveSurveyTable.data = sortSurveysByMd(getDefinitiveSurveys());

                    var units = {
                        depth: HierarchyService.Trajectory.Loaded[StateService.wellboreDetailView.selectedTrajectoryId].unitSet.depthUnit,
                        distance: HierarchyService.Trajectory.Loaded[StateService.wellboreDetailView.selectedTrajectoryId].unitSet.distanceUnit,
                        angle: HierarchyService.Trajectory.Loaded[StateService.wellboreDetailView.selectedTrajectoryId].unitSet.angleUnit
                    };

                    $scope.definitiveSurveyTable.columnDefs =
                        TableConfigService.getDefinitiveSurveyTableDefs(units, uiGridConstants);

                    $scope.definitiveSurveyTable.enableRowSelection = true;
                    $scope.definitiveSurveyTable.onRegisterApi = function (gridApi) {
                        $scope.definitiveSurveyTableApi = gridApi;
                        $scope.definitiveSurveyTableApi.core.on.sortChanged($scope, function () {
                            StateService.wellboreDetailView.definitiveSurveyTableState = $scope.definitiveSurveyTableApi.saveState.save();
                        });

                        $timeout(function () {

                            var lastRow = $scope.definitiveSurveyTable.data[$scope.definitiveSurveyTable.data.length - 1];
                            var firstColumn = $scope.definitiveSurveyTable.columnDefs[0];
                            $scope.definitiveSurveyTableApi.core.scrollTo(lastRow, firstColumn);

                        }, 0);
                    };

                    $timeout(function () {

                        if (StateService.wellboreDetailView.definitiveSurveyTableState !== undefined) {
                            $scope.wellboreDetailView.definitiveSurveyTableState.saveState.restore($scope,
                                StateService.wellboreDetailView.definitiveSurveyTableState);
                        }
                    });

                    $scope.definitiveSurveySentinel = true;
                }
            };

            /**
             * Action that occurs when a tab is selected to change which columns are displayed
             *
             * @param tabTitle  The title of the tab ('All Values', 'Survey Comparison', etc)
             * @param index     The index of the tab so we know if the tab has changed.
             */
            $scope.selectTableTab = function (tab) {
                $scope.lastSelectedSurveyTableTab = tab;

                //if we changed tabs
                if (tab.title !== activeSurveyTableTab) {

                    //mark which tab we are on
                    activeSurveyTableTab = tab.title;

                    //clear out the column defs
                    $scope.readSurveyTable.columnDefs = [];
                    $scope.editSurveyTable.columnDefs = [];

                    //assign new column defs (timeout is required for the styling to not get messed up)
                    $timeout(function () {
                        var newColumnDefs = TableConfigService.getRawSurveyTableDefs(getContextUnits(),
                            $scope.isCustomer(), getSelectedSurveySet().surveyInputType, uiGridConstants, tab.title);
                        $scope.readSurveyTable.columnDefs = newColumnDefs;
                        $scope.editSurveyTable.columnDefs = newColumnDefs;
                    }, 20); //works fine with 4ms on my machine, 20ms for slow machines...?
                }
            };

            var buildDuplicateMdMessage = function (duplicateMDMap, unitStr) {
                var initial = true;
                var msg = null;
                for (var md in duplicateMDMap) {
                    if(initial) {
                        msg = 'Multiple D-S surveys at the following md(s): ';
                        initial = false;
                    } else {
                        msg += ", ";
                    }
                    msg += "(" + duplicateMDMap[md] + "x) " + md + " " + unitStr;
                }
                return msg;
            };

            var buildDuplicateTrackingIdMessage = function (duplicateTrackingIdMap) {
                var initial = true;
                var msg = null;
                for (var trackingId in duplicateTrackingIdMap) {
                    if(initial) {
                        msg = "Multiple D-S surveys with the following trackingId's: ";
                        initial = false;
                    } else {
                        msg += ", ";
                    }
                    msg += "(" + duplicateTrackingIdMap[trackingId] + "x) " + trackingId;
                }
                return msg;
            };

            var isSurveyDefinitive = function (survey) {
                return lodash.includes($filter('surveyTypeFilter')(survey.type), 'D-');
            }

            $scope.duplicateSurveysExist = function (tab) {

                //get the surveys we are counting
                var surveys;
                if (tab === 'SurveySet') {
                    surveys = HierarchyService.Survey.Loaded[StateService.wellboreDetailView.selectedSurveySetId];
                }
                else if (tab === 'Trajectory') {
                    surveys = $scope.definitiveSurveyTable.data;
                }
                else {
                    console.error('Trying to determine surveys of same depth on something other than a survey set or trajectory.');
                    return;
                }

                var surveySet = getSelectedSurveySet();
                if (!surveySet) {
                    //this can occur when the page is still loading. returning here doesnt seem to cause any issues,
                    //but it cleans up the error log
                    return;
                }

                var hasDuplicates = false;

                var mdMap = [];
                var duplicateMDMap = [];

                var trackingIdMap = [];
                var duplicateTrackingIdMap = [];

                for (var i = 0; i < surveys.length; i++) {
                    var survey = surveys[i];
                    if(isSurveyDefinitive(survey)) {
                        // check for duplicate MD's
                        if (mdMap[survey.md.value] === undefined) {
                            mdMap[survey.md.value] = true;
                        } else {
                            if(duplicateMDMap[survey.md.value] === undefined) {
                                // this is the second time we've seen this MD
                                duplicateMDMap[survey.md.value] = 2;
                                hasDuplicates = true;
                            } else {
                                duplicateMDMap[survey.md.value] += 1;
                        }
                        }

                        // check for duplicate trackingId's
                        if(survey.trackingId !== undefined && survey.trackingId !== null) {
                            if(trackingIdMap[survey.trackingId] === undefined) {
                                trackingIdMap[survey.trackingId] = true;
                            } else {
                                if(duplicateTrackingIdMap[survey.trackingId] === undefined) {
                                    // This is the second time we've seen this trackingId
                                    duplicateTrackingIdMap[survey.trackingId] = 2;
                                    hasDuplicates = true;
                                } else {
                                    duplicateTrackingIdMap[survey.trackingId] += 1;
                    }
                }
                    }
                }
                }

                var unitStr = $filter('distanceUnit')(surveySet.unitSet.depthUnit);
                if (tab === 'SurveySet') {
                    $scope.duplicateRawMdMessage = buildDuplicateMdMessage(duplicateMDMap, unitStr);
                    $scope.duplicateTrackingIdMessage = buildDuplicateTrackingIdMessage(duplicateTrackingIdMap);
                }
                else if (tab === 'Trajectory') {
                    $scope.duplicateDefinitiveMdMessage = buildDuplicateMdMessage(duplicateMDMap, unitStr);
                }

                // true if duplicate surveys exist, false otherwise.
                return hasDuplicates;
            };

            var templates = "html/views/detailedWellbore/metaTables/";
            $scope.companyInfoTableURL = templates + "CompanyInfoTableTemplate.html";
            $scope.fieldInfoTableURL = templates + "FieldInfoTableTemplate.html";
            $scope.padInfoTableURL = templates + "PadInfoTableTemplate.html";
            $scope.wellInfoTableURL = templates + "WellInfoTableTemplate.html";
            $scope.wellLocationTableURL = templates + "WellLocationTableTemplate.html";
            $scope.rigContactsURL = templates + "RigContactsTemplate.html";
            $scope.rigUserStatus = templates + "RigUserStatusTemplate.html";
            $scope.wellboreInfoTableURL = templates + "WellboreInfoTableTemplate.html";
            $scope.wellboreMagneticModelTableURL = templates + "WellboreMagneticModelTableTemplate.html";
            $scope.wellboreAntiCollisionSettingsTableURL = templates + "WellboreAntiCollisionSettingsTableTemplate.html";
            $scope.trajectoryInfoTableURL = templates + "TrajectoryInfoTableTemplate.html";
            $scope.trajectoryTieInTableURL = templates + "TrajectoryTieInTableTemplate.html";
            $scope.surveySetTieInTableURL = templates + "SurveySetTieInTableTemplate.html";
            $scope.surveySetInfoTableURL = templates + "SurveySetInfoTableTemplate.html";
            $scope.surveySetTolerancesTableURL = templates + "SurveySetTolerancesTableTemplate.html";
            $scope.surveySetMsaValidationTolerancesTableURL = templates + "SurveySetMsaValidationTolerancesTableTemplate.html";
            $scope.surveySetAutomationParamTableURL = templates + "SurveySetAutomationParametersTableTemplate.html";
            $scope.definitiveSurveySelectionTableURL = templates + "DefinitiveSurveySelectionTableTemplate.html";

            $scope.canEditObject = true;

            $scope.isNewSurveyInterpolated = function () {
                return $scope.newSurvey.type === 'INTERPOLATED' || $scope.newSurvey.type === 'DEFINITIVE_INTERPOLATED';
            };

            $scope.copyOverMetaData = function () {
                if ($scope.currentMetaTab === $scope.metaTabIndices.information) {
                    if ($scope.tableEditType === $scope.metaTabEditTableIndices.company) {
                    $scope.editMetaData = _.cloneDeep($scope.selected.company.data);
                    } else if ($scope.tableEditType === $scope.metaTabEditTableIndices.field) {
                    $scope.editMetaData = _.cloneDeep($scope.selected.field.data);
                    } else if ($scope.tableEditType === $scope.metaTabEditTableIndices.pad) {
                    $scope.editMetaData = _.cloneDeep($scope.selected.pad.data);
                    } else if ($scope.tableEditType === $scope.metaTabEditTableIndices.well) {
                        $scope.editMetaData = _.cloneDeep($scope.selected.well.data);
                    }
                }  else if ($scope.currentMetaTab === $scope.metaTabIndices.wellbore) {
                    $scope.editMetaData = _.cloneDeep($scope.selected.wellbore.data);
                } else if ($scope.currentMetaTab === $scope.metaTabIndices.trajectory) {
                    $scope.editMetaData = _.cloneDeep($scope.selected.trajectory.data);
                } else if ($scope.currentMetaTab === $scope.metaTabIndices.surveySet) {
                    $scope.editMetaData = _.cloneDeep($scope.selected.surveySet.data);
                } else {
                    console.error('copyOverMetaData: Unknown tab: ' + JSON.stringify($scope.currentMetaTab));
                }
            };

            $scope.enableRigContactsTableEdit = function (tableEditFlag) {
                $scope.rigContactsEditMetadata.data = _.cloneDeep($scope.selected.well.data.rigContacts);
                $scope.enableTableEdit(tableEditFlag);
            };

            $scope.enableTableEdit = function (tableEditFlag) {
                $scope.copyOverMetaData();
                $scope.canEditObject = false;
                lodash.set($scope, tableEditFlag, true);
            };

            $scope.enableTableEditForMergedTab = function (tableEditFlag, tableEditType) {

                $scope.tableEditType = tableEditType;
                $scope.enableTableEdit(tableEditFlag);

            };


            $scope.disableTableEdit = function (tableEditFlag) {
                lodash.set($scope, tableEditFlag, false);
                $scope.canEditObject = true;
            };


            var swalErrorSavingObject = function (objectType, error) {

                var msg = "";

                //handle cases where the string given in error is from the client, not from a request formatted
                //as 'error.data.message'
                if (typeof error === 'string') {
                    msg = error;
                }
                else if (typeof error[0] === 'string') {
                    msg = error[0];
                }
                else if (typeof error.data.message === 'string') {
                    msg = error.data.message;
                }

                SweetAlert.swal(
                    "Error saving " + objectType + "!",
                    "There was an error saving the " + objectType + ": " + msg,
                    "error"
                );
            };

            $scope.saveMetaData = function (hierarchyLevel, tableEditFlag, inputsToValidate) {
                handleBlankPlannedTD();
                //check input for errors
                if (InputValidationService.validatedInputsReturnErrors(inputsToValidate)) {
                    return;
                }

                //save the well
                HierarchyService[hierarchyLevel].save($scope.editMetaData).then(function () {
                    $scope.disableTableEdit(tableEditFlag);
                    $scope.canEditObject = true;
                    refresh();
                }, function (error) {
                    swalErrorSavingObject(hierarchyLevel, error);
                });
            };

            /**
             * Set Planned Total Depth to 0 if blank
             */
            var handleBlankPlannedTD = function () {
                var element = document.getElementById('plannedTdInput');
                if (element === undefined || element === null) {
                    return;
                }
                if (element.value.length === 0) {
                    element.value = 0;
                    $scope.editMetaData.plannedTotalDepth.value = 0;
                }
            };

            $scope.saveRigContacts = function (rigContacts, tableEditFlag) {
                if (!rigContacts) {
                    rigContacts = "Day MWD operator: \n" +
                        "Day MWD phone number and email: \n" +
                        "Night MWD operator: \n" +
                        "Night MWD phone number and email: ";
                }
                DataService.updateRigContacts($scope.selected.well.data.id, rigContacts).then(function () {
                    $scope.disableTableEdit(tableEditFlag);
                    $scope.canEditObject = true;
                    refresh();
                }, function (error) {
                    swalErrorSavingObject("Well", error);
                });
            };

            $scope.saveMetaDataField = function (tableEditFlag) {

                var saveField = function () {
                    HierarchyService.Field.save($scope.editMetaData).then(function () {
                        refresh();
                        $scope.disableTableEdit(tableEditFlag);
                    }, function (error) {
                        swalErrorSavingObject("Field", error);
                        $scope.disableTableEdit(tableEditFlag);
                    });
                };

                //if the epsg code changed, we need to confirm
                if ($scope.editMetaData.EPSGCode !== $scope.selected.field.data.EPSGCode) {
                    var oldEPSG = $scope.selected.field.data.EPSGCode;
                    SweetAlert.swal({
                        title: 'Are you sure?',
                        text: 'You are about to change the coordinate system of this well!',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Change it!',
                        closeOnConfirm: true
                    }, function (isConfirm) {
                        //if we decide not to change the epsg code, set it back to what it was
                        if (!isConfirm) {
                            $scope.editMetaData.EPSGCode = oldEPSG;
                        }
                        setTimeout(function () {
                            saveField();
                        }, 100);

                    });
                } else {
                    saveField();
                }
            };

            $scope.getInputsForModelType = function () {
                if ($scope.editMetaData.magneticModelType === 'MANUAL') {
                    return ['wmmDipManual', 'wmmDecManual'];
                }
                else {
                    return ['wmmDipOffset', 'wmmDecOffset'];
                }
            };

            var getLowestSurvey = function (surveys) {
                var lowestMd = surveys[0].md.value;
                var lowestSurvey = surveys[0];
                for (var i = 0; i < surveys.length; i++) {
                    if (lowestMd > surveys[i].md.value) {
                        lowestSurvey = surveys[i];
                    }
                }
                return lowestSurvey;
            };

            //TODO: this can use some cleaning
            $scope.saveTrajectoryTieIn = function (trajectory, tableEditFlag, inputsToValidate) {

                //check input for errors
                if (InputValidationService.validatedInputsReturnErrors(inputsToValidate)) {
                    return;
                }

                //make sure the md is not deeper than any existing surveys
                //if we have surveys
                var surveys = $scope.selected.trajectory.data.definitiveSurveys;
                var errors = [];

                if (surveys && surveys.length > 0) {

                    //get the lowest md and the edited md
                    var lowestSurvey = getLowestSurvey(surveys);
                    var lowestMd = lowestSurvey.md.value;
                    var editedMd = parseFloat($scope.editMetaData.tieInSurvey.md.value);

                    //if the desired tie-in md is deeper than any existing surveys
                    if (editedMd > lowestMd) {
                        var unitString = $filter('distanceUnit')($scope.selected.well.data.unitSet.depthUnit);
                        errors.push("Tie-In MD (" + $scope.editMetaData.tieInSurvey.md.value + " " +
                            unitString + ") must be shallower than any existing surveys (" + lowestMd +
                            " " + unitString + ").");
                    }
                    //if the desired tie-in md is at the lowest existing survey depth
                    else if (editedMd === lowestMd) {
                        //verify that the min curve values match
                        var editTieIn = $scope.editMetaData.tieInSurvey;

                        if (editTieIn.north.value !== lowestSurvey.north.value || editTieIn.east.value !== lowestSurvey.east.value ||
                            editTieIn.verticalSection.value !== lowestSurvey.verticalSection.value) {
                            errors.push("Tie-In matches MD of existing survey, but min curve values don't match!");
                        }
                    }
                }

                if (errors.length > 0) {
                    $scope.selected.trajectory.tieInValuesTableEdit = true;
                    swalErrorSavingObject("Tie-In", errors);
                }
                else {
                    $scope.selected.trajectory.data.tieInOriginalTrajectoryId = null;
                    $scope.selected.trajectory.data.tieInOriginalSurveyId = null;
                    $scope.selected.trajectory.data.tieInSource = 'MANUAL';
                    HierarchyService.Trajectory.save($scope.selected.trajectory.data).then(function () {
                        HierarchyService.Survey.save($scope.editMetaData.tieInSurvey, null, $scope.selected.trajectory.data.id)
                            .then(function () {
                                refresh();
                                $scope.disableTableEdit(tableEditFlag);
                            }, function (error) {
                                swalErrorSavingObject("Trajectory Tie-In", error);
                        });
                    });
                }
            };

            /**
             *  goToWellboreSelection:
             *
             *      Redirects the user to the well selection view
             */
            $scope.goToWellboreSelection = function () {
                 // Capture analytics when Select Another Wellbore button is clicked in wellbore-detail view
                 const eventData = {
                    'button_name': 'Select Another Wellbore'
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_SELECTION_BUTTON_CLICKED, eventData);
                $state.go("wellboreSelection");
            };

            $scope.openActivityLog = function () {
                var activityLogModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/ActivityLogModal.html',
                    controller: 'ActivityLogModalController',
                    windowClass: 'activityLogModal',
                    backdrop: 'static',
                    resolve: {
                        wellName: function () {
                            return $scope.selected.well.data.name;
                        }
                    }
                });
            };

            $scope.openMapMarker = function () {
                var mapMarkerModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/MapMarkerModal.html',
                    controller: 'MapMarkerModalController',
                    windowClass: 'mapMarkerModal',
                    backdrop: 'static',
                    scope: $scope
                });
            };

            /**
             *  loadIfrCubeIds:
             *
             *      Loads the IFR Cube Ids for the Magnetic Model Id drop down when the IFR type is selected.
             */
            var loadIfrCubeIds = function () {
                if ($scope.selected.wellbore === undefined) {
                    return;
                }

                UIBlocker.start();

                MviwIfrService.getCubeIds().then(function (result) {
                    UIBlocker.stop();
                    $scope.ifrCubeModelIds = result.data;
                }, function (error) {
                    UIBlocker.stop();
                    console.error("Failed to load IFR Cube Ids.");
                });
            };

            /**
             * selectTrajectory:
             *
             *      Selects the given trajectory by loading it from the server by id.
             *  Since a new trajectory has been selected, we move to the trajectory tab.
             *
             * @param trajectory        The trajectory to load
             */
            $scope.selectTrajectory = function (trajectory) {
                if (typeof (trajectory) === 'undefined' || trajectory === null) {
                    if ($scope.selected.trajectory.data !== undefined && $scope.selected.trajectory.data !== null) {
                        $scope.selectedMetas.trajectory = $scope.selected.trajectory.data;
                        for (var i = 0; i < $scope.selected.wellbore.trajectoryMetas.length; i++) {
                            var meta = $scope.selected.wellbore.trajectoryMetas[i];
                            if (meta.id === $scope.selected.trajectory.data.id) {
                                $scope.selectedMetas.trajectory = meta;
                                break;
                            }
                        }
                    }
                    return $q.resolve();
                }

                $scope.currentMetaTab = $scope.metaTabIndices.trajectory;

                return HierarchyService.Trajectory.get(trajectory.id, true).then(function (newTrajectory) {
                    StateService.wellboreDetailView.selectedTrajectoryId = newTrajectory.id;
                    $scope.selectedObject = $scope.selected.trajectory.data = newTrajectory;
                    $scope.selected.trajectory.id = newTrajectory.id;
                    $scope.selected.trajectory.commonName = newTrajectory.commonName;

                    $scope.notificationsEnabledForTrajectory =
                        $scope.checkPerm($scope.selected.trajectory.data, 'NOTIFICATIONS');

                    setupDefinitiveDataTable();

                    initializeDefinitiveSurveyData().then(function () {
                        //Set the number of surveys we have in the trajectory with posted values in a stored variable
                        var surveys = newTrajectory.definitiveSurveys;
                        if (surveys.length > 0) {
                            var numPosted = 0;
                            for (var i = 0; i < surveys.length; i++) {
                                if (surveys[i].postedInc !== undefined) {
                                    numPosted++;
                                }
                            }
                            StateService.wellboreDetailView.numPostedSurveys = numPosted;
                        }
                        else {
                            StateService.wellboreDetailView.numPostedSurveys = 0;
                        }
                        return $q.resolve();
                    }, function (error) {
                        return $q.reject(error);
                    });
                }, function (error) {
                    $scope.selected.trajectory = {};
                    StateService.wellboreDetailView.selectedTrajectoryId = null;
                    console.error('Error selecting trajectory!', error); // TODO: SWAL this?
                    return $q.reject(error);
                });
            };

            var isLatestSurveyCorrected = function () {

                var surveys = HierarchyService.Survey.Loaded[StateService.wellboreDetailView.selectedSurveySetId];
                if (surveys.length > 0) {
                    var latestSurvey = surveys[surveys.length - 1];
                    if (latestSurvey.correctedSixAxisDatas.MULTISTATION) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                //if we have no surveys, consider everything corrected.
                else {
                    return true;
                }

            };

            $scope.allowMsaCorrection = function () {
                if ($scope.selected.surveySet.data.automationActive) {
                    return !$scope.selected.surveySet.data.automateCorrection;
                }
                else {
                    return true;
                }
            };

            $scope.isFullAutomationActive = function () {
                if ($scope.selected.surveySet.data.automationActive) {
                    return $scope.selected.surveySet.data.automateCorrection;
                }
                else {
                    return false;
                }
            };

            /**
             * selectSurveySet:
             *
             *      Selects the given survey set by loading it from the server by id.
             *  Since a new survey set has been selected, we move to the survey set tab.
             *
             * @param surveySet     The survey set to load
             */
            $scope.selectSurveySet = function (surveySet, forceUpdate) {
                if (typeof (surveySet) === 'undefined' || surveySet === null) {
                    if ($scope.selected.surveySet.data !== undefined && $scope.selected.surveySet.data !== null) {
                        for (var i = 0; i < $scope.selected.wellbore.surveySetMetas.length; i++) {
                            var meta = $scope.selected.wellbore.surveySetMetas[i];
                            if (meta.id === $scope.selected.surveySet.data.id) {
                                $scope.selectedMetas.surveySet = meta;
                                break;
                            }
                        }
                    }
                    return $q.resolve();
                }
                return HierarchyService.SurveySet.get(surveySet.id, forceUpdate).then(function (newSurveySet) {
                    StateService.wellboreDetailView.selectedSurveySetId = newSurveySet.id;
                    if(newSurveySet.name === SURVEYSET_SYNTHETIC_SURVEYS || newSurveySet.name === SURVEYSET_HI_RES_SURVEYS || newSurveySet.name === SURVEYSET_CUSTOM_SURVEYS) {
                        UIBlocker.rtxLoader(true);
                        UIBlocker.start();
                    }

                    return HierarchyService.SurveySet.updateIfModified(newSurveySet.id).then(function (updated) {
                        if ($scope.selected.surveySet === null || typeof ($scope.selected.surveySet) === 'undefined') {
                            $scope.selected.surveySet = {};
                        }
                        $scope.selectedObject = $scope.selected.surveySet.data = newSurveySet;
                        $scope.selected.surveySet.id = newSurveySet.id;
                        $scope.selected.surveySet.commonName = newSurveySet.commonName;

                        $scope.notificationsEnabledForSurveySet = $scope.checkPerm($scope.selected.surveySet.data, 'NOTIFICATIONS');

                        $scope.selected.survey.data = newSurveySet; // TODO: Is this correct? Seems wrong, but again, was in the original code...

                        setupSurveyTable();

                        $scope.surveyTable.editing = false;//TODO might need to do more here?

                        if (!$scope.isCustomer()) {
                            $scope.currentMetaTab = $scope.metaTabIndices.surveySet;
                            if ($scope.checkPerm($scope.selected.surveySet.data, 'MSA') && !isLatestSurveyCorrected() && $scope.allowMsaCorrection()) {
                                $scope.msaCorrection();
                            }
                            if ($scope.checkPerm($scope.selected.surveySet.data, 'QC_PLOTS') &&
                                $scope.selected.surveySet.data.hasNewSuccessfulSurvey &&
                                isLatestSurveyCorrected() && $scope.isFullAutomationActive()) {
                                $scope.selected.survey.data = HierarchyService.Survey.Loaded[StateService.wellboreDetailView.selectedSurveySetId];
                                $scope.qcPlots();
                            }
                        }

                        if (!$scope.isCustomer() && !$scope.isReadOnlyUser() && $scope.checkPerm($scope.selected.surveySet.data, 'NOTIFICATIONS')) {
                            if ($scope.selected.surveySet.data.approachingTd) {
                                notificationForNewSurveyApproachingTD();
                            }
                        }

                        refreshSurveySetReference();
                        refreshSurveyReference();

                        if(newSurveySet.name === SURVEYSET_SYNTHETIC_SURVEYS || newSurveySet.name === SURVEYSET_HI_RES_SURVEYS || newSurveySet.name === SURVEYSET_CUSTOM_SURVEYS) {
                            UIBlocker.rtxLoader(false);
                            UIBlocker.stop();
                        }
                        return handleNotifications();
                    });
                }, function (error) {
                    $scope.selected.surveySet = {};
                    StateService.wellboreDetailView.selectedSurveySetId = null;
                    console.error('Error selecting survey set!', error);
                    swal("error selecting survey set",
                        "There was a problem selecting the survey set, please try again. If problem persists, contact support",
                        "error");
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };

            /**
             * hasTieInSurvey:
             *
             *      Returns true if the survey set has a tie-in survey.
             *
             * @param surveySet     The survey set to analyze.
             *
             * @return {boolean}    True if the survey set has a tie-in survey. False if not.
             */
            $scope.hasTieInSurvey = function (trajectory) {
                return (typeof (trajectory.tieInSurveyId) === 'string') &&
                    (trajectory.tieInSurvey !== null && trajectory.tieInSurvey !== undefined);
            };

            /**
             * showTieInSetup:
             *
             *      Opens the tiein setup modals dialog.
             *
             * @param initialMode   The accordion to open at the start ('select' or 'create').
             */
            $scope.showTieInSetup = function (initialMode) {
                var instance = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/workflow/TieInSetupModal.html',
                    controller: 'TieInSetupModalController',
                    windowClass: 'tiein-modals',
                    'resolve': {
                        'initialMode': function () {
                            return initialMode;
                        },
                        'field': function () {
                            return $scope.selected.field.data
                        },
                        'well': function () {
                            return $scope.selected.well.data
                        },
                        'trajectory': function () {
                            return $scope.selected.trajectory.data;
                        },
                        'surveySet': function () {
                            return $scope.selected.surveySet.data;
                        },
                        'definitivePath': function () {
                            return $scope.selected.trajectory.data.definitiveSurveys;
                        }
                    }
                });

                instance.result.then(function (result) {
                    //make sure the tie-in editing is set to false
                    $scope.selected.trajectory.tieInValuesTableEdit = false;

                    $scope.disableTableEdit('selected.trajectory.tieInValuesTableEdit');

                    refresh();
                })
            };

            /**
             * Opens qc plots window
             */
            $scope.qcPlots = function () {

                if ($scope.selected.survey.data.length === 0) {
                    SweetAlert.swal("Error", "No surveys to plot!", "error");
                    return;
                }

                // Capture analytics when Plots button is clicked
                const eventData = {
                    'button_name': 'Plots',
                    'survey_set_name': $scope.selected.surveySet.data.name,
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_BUTTON_CLICKED, eventData);
                var plotsModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/QcPlotsModal.html',
                    controller: 'QCPlotsModalController',
                    windowClass: 'qc-plots-modal',
                    backdrop: 'static',
                    resolve: {
                        surveySet: function () {
                            return $scope.selected.surveySet.data;
                        },
                        surveys: function () {
                            return $filter('orderBy')($scope.selected.survey.data, 'md.value', false);
                        },
                        acSigma: function () {
                            return $scope.selected.company.data.sigmaLevel;
                        },
                        well: function(){
                            return $scope.selected.well.data;
                        },
                        refresh: function () {
                            return refresh;
                        }
                    }
                });
            };

            /**
             * Submits a new survey
             */
            $scope.submitSurvey = function () {
                //verify the new survey inputs are valid
                var errors = [];
                switch (getSelectedSurveySet().surveyInputType) {
                    case 'STANDARD':
                        if ($scope.isNewSurveyInterpolated()) {
                            errors = InputValidationService.validateInputSet(['manualMdInput']);
                        }
                        else {
                            errors = InputValidationService.validateInputSet([
                                'manualMdInput', 'manualIncInput', 'manualAziInput',
                                'manualB0Input', 'manualB1Input', 'manualB2Input',
                                'manualG0Input', 'manualG1Input', 'manualG2Input'
                            ]);
                        }
                        break;
                    case 'BACK_CALCULATED':
                        if ($scope.isNewSurveyInterpolated()) {
                            errors = InputValidationService.validateInputSet(['bcmanualMdInput']);
                        }
                        else {
                            errors = InputValidationService.validateInputSet([
                            'bcmanualMdInput', 'bcmanualIncInput', 'bcmanualAziInput',
                            'bcmanualBTotalInput', 'bcmanualGTotalInput',
                            'bcmanualDipInput', 'bcmanualToolfaceInput'
                        ]);
                        }
                        break;
                }

                if (errors.length > 0) {

                    var swalMessage = "";

                    for (var i = 0; i < errors.length; i++) {
                        swalMessage += errors[i];

                        if (i < errors.length - 1) {
                            swalMessage += "\n\n";
                        }
                    }

                    swal('Error validating survey values!', swalMessage, 'error');
                    return;
                }

                // Capture analytics when survey submitted manually
                const eventData = {
                    'button_name': 'Manual Survey Submit',
                    'survey_set_name': $scope.selected.surveySet.data.name,
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_BUTTON_CLICKED, eventData);

                HierarchyService.Survey.create($scope.newSurvey, StateService.wellboreDetailView.selectedSurveySetId,
                    $scope.selected.surveySet.data.toolType)
                    .then(function (success) {
                        checkValidationAndSubmitSurvey(success);
                    }, function (error) {
                        if (error.status === 500) {
                            if (lodash.includes(error.data.message, "No definitive trajectory set")) {
                                swal(
                                    'Error creating survey!',
                                    'There is no definitive trajectory set.  Please set a trajectory to definitive before submitting surveys.',
                                    'error'
                                );
                            }
                            else {
                                swal(
                                    'Error creating survey!',
                                    'The server ran into an internal error.  Please review data and try submitting again.',
                                    'error'
                                );
                            }
                        } else if (error.status === 417) {
                            swal('Error creating survey!',
                                'The survey submitted must be below the tie in point, please change the MD and resubmit the survey',
                                'error');
                        } else {
                            swal(
                                'Error creating survey!',
                                'Please correct the following error and try submitting the survey again:' + '\n' +
                                error.data,
                                'error'
                            );
                        }
                    });
            };

            /**
             * Opens chat window
             */
            $scope.chat = function () {

                if ($scope.chatOpen === undefined || $scope.chatOpen === false) {
                    $scope.chatOpen = true;

                    // Capture analytics when chat button is clicked
                    const eventData = {
                        'button_name': 'Chat',
                        'well_name': $scope.selected.well.data.name
                    };
                    CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_BUTTON_CLICKED, eventData);

                    var chatModal = $uibModal.open({
                        templateUrl: 'html/views/detailedWellbore/misc/ChatModal.html',
                        controller: 'ChatModalController',
                        size: '70-percent',
                        resolve: {
                            currentWell: function () {
                                return $scope.selected.well.data;
                            }
                        }
                    });

                    chatModal.result.then(function () {
                        //closed
                        $scope.chatOpen = false;
                        clearChatFlag();

                    }, function () {
                        //Canceled
                        $scope.chatOpen = false;
                        clearChatFlag();
                    });
                }
            };

            var clearChatFlag = function () {
                if (!$scope.isCustomer()) {
                    EventService.setNewChatFlag(false).then(function () {
                        refresh();
                    });
                }
            };

            /**
             * Opens shift notes
             */
            $scope.shiftNotes = function () {
                // Capture analytics when shift notes button is clicked
                const eventData = {
                    'button_name': 'Shift Notes',
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_BUTTON_CLICKED, eventData);
                var shiftModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/ShiftNotesModal.html',
                    controller: 'ShiftNotesModalController',
                    size: '70-percent',
                    resolve: {
                        currentWell: function () {
                            return $scope.selected.well.data;
                        }
                    }
                });
            };

            /**
             * This function creates a new snapshot of the currently selected object (whether it be a survey set, well, w/e)
             * @return {JS Promise} This returns a promise linking to the http request on the server for making the snapshot of the object.
             */
            $scope.createSnapshot = function (objectedToCreateSnapshotOf) {
                SnapshotService.createFile(StateService.wellboreDetailView.selectedSurveySetId, "SURVEYSET_SNAPSHOT", false)
                    .then(function (response) {
                        SnapshotService.downloadFile("SurveySet", StateService.wellboreDetailView.selectedSurveySetId, response.data);
                    }, function (error) {
                        SweetAlert.swal("Unable to create Snapshot!", error.data, "error");
                    });
            };

            /**
             * This function is used to view all of our snapshots that we have for the given object.
             * @param  {JS Object} objectToViewSnapshotsOf This is the object we're viewing the snapshot for. We assume that it has things like: an id, a commonName, a name, etc.
             */
            $scope.viewSnapshots = function (objectToViewSnapshotsOf) {
                var snapshotsModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/SnapshotModal.html',
                    controller: 'SnapshotModalController',
                    size: 'lg',
                    resolve: {
                        currentObject: function () {
                            return objectToViewSnapshotsOf;
                        }
                    }
                });
                snapshotsModal.result.then(function (success) {
                    if (success) {
                        SweetAlert.swal("Success!!", "Snapshot successfully imported :) ", "success");
                        $scope.selectSurveySet($scope.selected.surveySet.data);
                        refresh();
                    }
                })

            };

            $scope.fullSurveySetExport = function () {
                SnapshotService.createFile(StateService.wellboreDetailView.selectedSurveySetId, "SURVEYSET_FULL", true)
                    .then(function (response) {
                        SnapshotService.downloadFile("SurveySet", StateService.wellboreDetailView.selectedSurveySetId, response.data);
                    }, function (error) {
                        SweetAlert.swal("Unable to create Snapshot!", error.data, "error");
                    });
            };

            $scope.fullTrajectoryExport = function () {
                // Capture analytics when trajectory data is exported
                const eventData = {
                    'report_type': 'Export Trajectory',
                    'trajectory_name': $scope.selected.trajectory.data.name,
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_GENERATE_REPORT, eventData);
                SnapshotService.createFile(StateService.wellboreDetailView.selectedTrajectoryId, "TRAJECTORY_FULL", true)
                    .then(function (response) {
                        SnapshotService.downloadFile("Trajectory", StateService.wellboreDetailView.selectedTrajectoryId, response.data);
                    }, function (error) {
                        SweetAlert.swal("Unable to create Snapshot!", error.data, "error");
                    });
            };

            $scope.correctedSurveyReport = function () {
                // Capture analytics when CSR report is generated
                const eventData = {
                    'report_type': 'CSR',
                    'trajectory_name': $scope.selected.trajectory.data.name,
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_GENERATE_REPORT, eventData);
                DataService.getCorrectedSurveyReport(StateService.wellboreDetailView.selectedTrajectoryId);
            };

            $scope.makeReport = function () {
                var reportModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/ReportParametersModal.html',
                    controller: 'ReportParametersModalController',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        surveySet: function () {
                            return $scope.selected.surveySet.data;
                        },
                        surveys: function () {
                            var definitiveSurveys = $filter("definitiveSurveys")($scope.selected.survey.data, false);
                            return $filter('orderBy')(definitiveSurveys, 'md.value', false);
                        },
                        acSigma: function () {
                            return $scope.selected.company.data.sigmaLevel;
                        },
                        header: function () {
                            return $scope.selected.company.data.name + " - " + $scope.selected.well.data.name + " - "
                                + $scope.selected.well.data.rigName + " - " + new Date(new Date().getTime()).toLocaleDateString();
                        }
                    }
                });
            };

            $scope.makeMultiReport = function() {
                $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/misc/MultiReportParametersModal.html',
                    controller: 'MultiReportParametersModalController',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        wellbore: function() {
                          return $scope.selected.wellbore.data;
                        },
                        surveySet: function () {
                            return $scope.selected.surveySet.data;
                        },
                        acSigma: function () {
                            return $scope.selected.company.data.sigmaLevel;
                        },
                        header: function () {
                            return $scope.selected.company.data.name + " - " + $scope.selected.well.data.name + " - "
                                + $scope.selected.well.data.rigName + " - " + new Date(new Date().getTime()).toLocaleDateString();
                        }
                    }
                });
            };

            /**
             * jumpToSurveys
             * Jump function to jump to the md entered
             */
            $scope.jumpToSurveys = function (event) {
                if (event.key === 'Enter') {
                    let gridApi;
                    let table;
                    let value;
                    if ($scope.currentMetaTab === $scope.metaTabIndices.surveySet) {
                        gridApi = $scope.readSurveyTableApi;
                        table = $scope.readSurveyTable;
                        value = $scope.jumpToMd.valueSurveySet;
                    } else {
                        gridApi = $scope.definitiveSurveyTableApi;
                        table = $scope.definitiveSurveyTable;
                        value = $scope.jumpToMd.valueTrajectory;
                    }
                    const jumpToNodeIndex = findRowToJump(table.data, value);
                    gridApi.core.scrollTo(table.data[jumpToNodeIndex], table.columnDefs[0]);
                }
            };


            /**
             * findRowToJump
             * Finds the row index of the  md entered or the closest md
             */
            var findRowToJump = function (nodeList, value) {
                let jumpToNodeIndex = nodeList.findIndex((node) => node.md.value === value);
                if (jumpToNodeIndex < 0) {
                    const indexNode = nodeList.reduce((a, b) => {
                        return Math.abs(b.md.value - value) < Math.abs(a.md.value - value) ? b : a;
                    });
                    jumpToNodeIndex = nodeList.findIndex((node) => node.md.value === indexNode.md.value);
                }
                return jumpToNodeIndex - 1;
            }

            /**
             * Enables editing of surveys
             */
            $scope.editSurveys = function () {
                $scope.surveyTable.editing = true;
            };

            /**
             * Disables editing of surveys
             */
            $scope.disableEditSurveys = function () {
                //disable further editing fo the table
                $scope.surveyTable.editing = false;

                //flush the edits
                $scope.editSurveyTable.data = lodash.cloneDeep($scope.surveyTable.data);
                var gridRows = $scope.editSurveyTableApi.rowEdit.getDirtyRows();
                var dataRows = gridRows.map(function (gridRow) {
                    return gridRow.entity;
                });
                $scope.editSurveyTableApi.rowEdit.setRowsClean(dataRows);
            };

            /**
             * Saves the edited surveys and recomputes calculated/reference/fac values
             */
            $scope.saveEditedSurveysWithoutOverride = function () {

                var editedRows = $scope.editSurveyTableApi.rowEdit.getDirtyRows($scope.editSurveyTableApi.grid);
                var surveysToSave = editedRows.map(function (row) {
                    return row.entity;
                });

                if (surveysToSave.length > 0) {
                    SweetAlert.swal({
                        title: "Are you sure??",
                        text: "Calculated/reference values will be recomputed.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#2D9635",
                        confirmButtonText: "Yes! Recompute the surveys.",
                        closeOnConfirm: false,
                        showLoaderOnConfirm: true
                    }, function (proceed) {
                        if (proceed) {

                            UIBlocker.start();
                            $scope.createSnapshot($scope.selected.surveySet.data);
                            HierarchyService.Survey.simpleUpdates(surveysToSave,
                                $scope.selected.surveySet.data.id, $scope.selected.surveySet.data.toolType)
                                .then(function (response) {
                                        if (surveysToSave.length === 1) {
                                            SweetAlert.swal("Success!",
                                                "Survey has been updated", "success");
                                        } else {
                                            SweetAlert.swal("Success!",
                                                surveysToSave.length + " surveys have been updated", "success");
                                        }
                                        $scope.editSurveyTableApi.rowEdit.setRowsClean(surveysToSave);
                                        $scope.surveyTable.editing = false;
                                        refresh();
                                        UIBlocker.stop();
                                    }, function (error) {
                                        SweetAlert.swal("Error!", "There was a problem saving the edited surveys:" + error.data.message, "error");
                                        UIBlocker.stop();
                                    }
                                );
                        }
                    });
                }
                else {
                    $scope.surveyTable.editing = false;
                }
            };

            /**
             * Saves the edited surveys and does not recompute anything
             */
            $scope.saveEditedSurveysWithOverride = function () {

                var editedRows = $scope.editSurveyTableApi.rowEdit.getDirtyRows($scope.editSurveyTableApi.grid);
                var surveysToSave = editedRows.map(function (row) {
                    return row.entity;
                });

                if (surveysToSave.length > 0) {
                    UIBlocker.start();
                    $scope.createSnapshot($scope.selected.surveySet.data);
                    HierarchyService.Survey.overrideUpdates(surveysToSave, $scope.selected.surveySet.data.id,
                        $scope.selected.surveySet.data.toolType)
                        .then(function (response) {
                            if (surveysToSave.length === 1) {
                                SweetAlert.swal("Success!",
                                    "Survey has been updated", "success");
                            } else {
                                SweetAlert.swal("Success!",
                                    surveysToSave.length + " surveys have been updated", "success");
                            }
                            $scope.editSurveyTableApi.rowEdit.setRowsClean(surveysToSave);
                            $scope.surveyTable.editing = false;
                            refresh();
                            UIBlocker.stop();
                        }, function (error) {
                            UIBlocker.stop();
                            SweetAlert.swal("Error!", "There was a problem saving the edited surveys.",
                                "error");
                        });
                }
                else {
                    $scope.surveyTable.editing = false;
                }
            };

            /**
             * Recomputes the selected surveys calculated and reference values but does not change any corrections
             */
            $scope.recomputeSurveys = function () {
                var surveySetName = $scope.selectedMetas.surveySet.name;

                swal({
                        title: 'Are you sure?',
                        text: 'You are about to recompute all surveys on the \'' + surveySetName + '\' survey set!',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Recompute',
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {

                            HierarchyService.Survey.recompute(StateService.wellboreDetailView.selectedSurveySetId).then(
                                function (success) {
                                    refresh();
                                    swal(
                                        'Success!',
                                        'Successfully recomputed all surveys on the \'' + surveySetName + '\' survey set!'
                                    )
                                }, function (error) {
                                    if (error.status === 404) {
                                        swal('Error recomputing surveys!',
                                            'Error: The survey set you are trying to recompute, \'' + surveySetName + '\', cannot be found.',
                                            'error');
                                    } else {
                                        swal('Error recomputing surveys!', 'Error while recomputing surveys on the \'' + surveySetName
                                                + '\' survey set. Error: ' + JSON.stringify(error.data), 'error');
                                    }
                                }
                            );
                        }
                    });
            };

            /**
             * Opens up the modals that allows for deleting surveys
             */
            $scope.deleteSurveys = function () {

                var surveysToDelete = $scope.readSurveyTableApi.selection.getSelectedRows();
                var numSurveysToDelete = surveysToDelete.length;

                if (numSurveysToDelete === 0) {
                    swal(
                        'No surveys to delete!',
                        'Please select surveys to delete and try again.'
                    );
                    return;
                }

                swal({
                        title: 'Are you sure?',
                        text: 'You are about to delete ' + (numSurveysToDelete === 1 ? '1 survey!' : numSurveysToDelete + ' surveys!'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Delete',
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {

                            var ids = [];
                            for (var i = 0; i < numSurveysToDelete; i++) {
                                ids.push(surveysToDelete[i].id);
                            }

                            HierarchyService.Survey.delete(StateService.wellboreDetailView.selectedSurveySetId, ids).then(
                                function (success) {
                                    refresh();
                                    swal(
                                        'Success!',
                                        'Successfully deleted ' + (numSurveysToDelete === 1 ? '1 survey!' : numSurveysToDelete + ' surveys!')
                                    )
                                }, function (error) {
                                    console.error(error);
                                    if (error.status === 404) {
                                        swal('Error deleting surveys!',
                                            'Error: The surveys ids you are trying to delete cannot be found.',
                                            'error');
                                    } else {
                                        swal('Error deleting surveys!', 'Error: ' + JSON.stringify(error), 'error');
                                    }

                                }
                            );
                        }
                    });
            };

            /**
             *  refreshCompanyReference:
             *
             *      Re-references the locally selected company to the company stored in the service
             */
            var refreshCompanyReference = function () {
                $scope.selected.company.data = HierarchyService.Company.Loaded[StateService.wellboreDetailView.selectedCompanyId];
            };

            /**
             *  refreshFieldReference:
             *
             *      Re-references the locally selected field to the field stored in the service
             */
            var refreshFieldReference = function () {
                $scope.selected.field.data = HierarchyService.Field.Loaded[StateService.wellboreDetailView.selectedFieldId];
            };

            /**
             *  refreshPadReference:
             *
             *      Re-references the locally selected pad to the pad stored in the service
             */
            var refreshPadReference = function () {
                $scope.selected.pad.data = HierarchyService.Pad.Loaded[StateService.wellboreDetailView.selectedPadId];
            };

            /**
             *  refreshWellReference:
             *
             *      Re-references the locally selected well to the well stored in the service
             */
            var refreshWellReference = function () {
                $scope.selected.well.data = HierarchyService.Well.Loaded[StateService.wellboreDetailView.selectedWellId];
                if ($scope.selected.well.data !== undefined && $scope.selected.field.data !== undefined) {
                    $scope.selected.well.data.unitSet.distanceUnit =
                        $scope.selected.field.data.unitSet.distanceUnit;
                }
            };

            /**
             *  refreshWellboreReference:
             *
             *      Re-references the locally selected wellbore to the wellbore stored in the service
             */
            var refreshWellboreReference = function () {
                $scope.selected.wellbore.data = HierarchyService.Wellbore.Loaded[StateService.wellboreDetailView.selectedWellboreId];
            };

            /**
             *  refreshTrajectoryReference:
             *
             *      Re-references the locally selected trajectory to the trajectory stored in the service
             */
            var refreshTrajectoryReference = function () {
                $scope.selected.trajectory.data = HierarchyService.Trajectory.Loaded[StateService.wellboreDetailView.selectedTrajectoryId];

            };

            /**
             *  refreshSurveySetReference:
             *
             *      Re-references the locally selected survey set to the survey set stored in the service
             */
            var refreshSurveySetReference = function () {
                $scope.selected.surveySet.data =
                    HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId];
                $scope.selected.surveySet.data.unitSet.depthUnit = $scope.selected.well.data.unitSet.depthUnit;
            };

            /**
             *  refreshSurveySetReference:
             *
             *      Re-references the locally selected survey set to the survey set stored in the service
             */
            var refreshSurveyReference = function () {
                $scope.selected.survey.data = HierarchyService.Survey.Loaded[StateService.wellboreDetailView.selectedSurveySetId];
            };

            /**
             *  loadTrajectoryMetas:
             *
             *      Loads the trajectory metas used in the trajectory selection dropdown menu
             */
            var loadTrajectoryMetas = function () {

                var deferred = $q.defer();

                if ($scope.selected.wellbore === undefined || $scope.selected.wellbore.data === undefined) {
                    deferred.resolve();
                    return deferred.promise;
                }

                UIBlocker.start();
                HierarchyService.Trajectory.getManyMetaByParent($scope.selected.wellbore.data.id)
                    .then(function (trajectoryMetas) {
                        UIBlocker.stop();
                        $scope.selected.wellbore.trajectoryMetas = $scope.selected.wellbore.trajectoryMetasList = $filter('orderBy')(trajectoryMetas, 'name');
                        if ($scope.isCustomer() && trajectoryMetas.some(tMeta => tMeta.name === TRAJECTORY_SYNTHETIC_RTX_SURVEYS || tMeta.name === TRAJECTORY_CONTINUOUS_RTX_SURVEYS || tMeta.name === TRAJECTORY_CUSTOM_RTX_SURVEYS)) {
                            $scope.hasTvdService.trajectory = true;
                            $scope.selected.wellbore.trajectoryMetasList = trajectoryMetas.filter(function (tMeta) {
                                return tMeta.name === TRAJECTORY_SYNTHETIC_RTX_SURVEYS || tMeta.name === TRAJECTORY_CONTINUOUS_RTX_SURVEYS || tMeta.name === TRAJECTORY_CUSTOM_RTX_SURVEYS || tMeta.name === 'MSA Corrected Surveys';
                            });
                        }
                        deferred.resolve();
                    }, function (error) {
                        UIBlocker.stop();
                        if ($scope.selected.wellbore.trajectoryMetas === undefined) {
                            SweetAlert.swal("Error", "There were no Trajectories found!", "error");
                        } else {
                            SweetAlert.swal("Error", "There was a problem loading the trajectories.." + error.data,
                                "error");
                        }
                        deferred.resolve();
                    });

                return deferred.promise;
            };

            /**
             *  loadSurveySetMetas:
             *
             *      Loads the survey set metas used in the survey set selection dropdown menu
             */
            var loadSurveySetMetas = function () {

                var deferred = $q.defer();

                if ($scope.selected.wellbore === undefined || $scope.selected.wellbore.data === undefined) {
                    deferred.resolve();
                    return deferred.promise;
                }

                UIBlocker.start();
                HierarchyService.SurveySet.getManyMetaByParent($scope.selected.wellbore.data.id)
                    .then(function (surveySetMetas) {
                        UIBlocker.stop();
                        $scope.selected.wellbore.surveySetMetas = $scope.selected.wellbore.surveySetMetasList= $filter('orderBy')(surveySetMetas, 'name');
                        if ($scope.isCustomer() && surveySetMetas.some(sMeta => sMeta.name === SURVEYSET_SYNTHETIC_SURVEYS || sMeta.name === SURVEYSET_HI_RES_SURVEYS || sMeta.name === SURVEYSET_CUSTOM_SURVEYS)) {
                            $scope.hasTvdService.surveySet = true;
                            $scope.selected.wellbore.surveySetMetasList = surveySetMetas.filter(function (sMeta) {
                                return sMeta.name === SURVEYSET_SYNTHETIC_SURVEYS || sMeta.name === SURVEYSET_HI_RES_SURVEYS || sMeta.name === SURVEYSET_CUSTOM_SURVEYS || sMeta.status === 'ACTIVE';
                            });
                        }
                        deferred.resolve();
                    }, function (error) {
                        UIBlocker.stop();
                        if ($scope.selected.trajectory.surveySetMetas === undefined) {
                            SweetAlert.swal("Error", "There were no Survey Sets found!", "error");
                        } else {
                            SweetAlert.swal("Error", "There was a problem loading the survey sets.." + error.data,
                                "error");
                        }
                        deferred.resolve();
                    });

                return deferred.promise;
            };

            var autoSelect = function () {

                var trajectoryMetas = $scope.selected.wellbore.trajectoryMetas;
                var surveySetMetas = $scope.selected.wellbore.surveySetMetas;
                var requests = [];

                if (surveySetMetas !== undefined && surveySetMetas.length > 0) {
                    for (var i = 0; i < surveySetMetas.length; i++) {
                        var surveySet = surveySetMetas[i];
                        if (surveySet.status === "ACTIVE") {
                            requests.push($scope.selectSurveySet(surveySet));
                            $scope.selectedMetas.surveySet = surveySet;
                            $scope.selected.surveySet.id = surveySet.id;
                            $scope.selected.surveySet.commonName = surveySet.commonName;
                            break;
                        }
                    }
                }

                if (trajectoryMetas !== undefined && trajectoryMetas.length > 0) {
                    for (var i = 0; i < trajectoryMetas.length; i++) {
                        var trajectory = trajectoryMetas[i];
                        if (trajectory.trajectoryType === "DEFINITIVE") {
                            requests.push($scope.selectTrajectory(trajectory));
                            $scope.selectedMetas.trajectory = trajectory;
                            $scope.selected.trajectory.id = trajectory.id;
                            $scope.selected.trajectory.commonName = trajectory.commonName;
                            break;
                        }
                    }
                }

                return $q.all(requests);
            };

            /* Definitive Survey Form *****************************************************************************/

            /**
             * definitiveSurveyData
             *
             * This defines the data that is used to support the Definitive Survey table.
             * - surveySetMdData is a map of parentSurveySetId's to an array of their Md values.
             * - selectedSurveySetRangeData are the surveySet id's and range data that is set on the form.
             */
            $scope.definitiveSurveyData = {
                surveySetMdData: [],
                selectedSurveySetRangeData: []
            };

            /**
             * removeSurveysLessThanMinMD
             *
             * Given a minimum MD value and an array of measuredDepth's (MD's) this function returns a new array containing only the MD's whose value
             * is greater than (inclusively or exclusively) the minimum MD value passed in.
             *
             * @param minMdValue The minimum MD value allowed (inclusively or exclusively) in the returned array of MD's
             * @param mdArray An array of measuredDepth's to be reduced
             * @param isInclusive determines if the comparison with minMdValue is inclusive (>=, true) or exclusive (>, false).
             * @returns An array containing all of the MD values from the mdArray that have MD's greater than minMdValue.
             */
                // TODO: this doesn't consider units
            var removeSurveysLessThanMinMD = function (minMdValue, mdArray, isInclusive) {
                    var newArray = [];
                    angular.forEach(mdArray, function (measuredDepth) {
                        if (isInclusive && measuredDepth.value >= minMdValue) {
                            newArray.push(measuredDepth);
                        } else if (!isInclusive && measuredDepth.value > minMdValue) {
                            newArray.push(measuredDepth);
                        }
                    });
                    return newArray;
                };

            /**
             * This function determines the allowed MD values for the definitive survey MD range for the given index and start or end position in the range..
             *
             * For instance if the range of allowed MD values is desired for the mdStart on row 0 of the selectedSurveySetRangeData array then this function
             * should be called with isStart=true and the surveySetSelectionIndex of 0. In this example then this method will calculate the possible values
             * for the mdStart selection at index 0 in the selectedSurveySetRangeData array.  Subsequently this method will recursively call itself for the
             * next selection field in the range which would be the mdEnd on row 0 (false, 0).  This will then recursively call this method for the next
             * value until it reaches the end of the selectedSurveySetRangeData array..
             *
             * An example:
             * For the sake of simplicity we will say there is only one available surveySet with surveys having the following MD's: 1,2,3.
             * if the mdStart of row 0 is called (true, 0) this method will:
             * * set the mdStart selections to [1, 2, 3] then call this method again with values (false, 0)
             * * the recursive call will limit the mdEnd selections on index 0 to [2, 3] and then call this method again with the values (true, 1)
             * * subsequently this will limit row 1's mdStart (if it exists, and a surveySet is already selected) to the ranges [2,3] and call this same
             *    method with (false, 1), etc.
             *
             * @param isStart boolean which specifies whether the range to be set is the mdStart (true) or the mdEnd (false) in the range..
             * @param surveySetIndex The index of the row of data that needs to have it's start or end (specified by isStart) permissible MD values
             *      limited in the selectedSurveySetRangeData array.
             */
            var generateAcceptableStartSurveyRanges = function (surveySetIndex) {

                if (surveySetIndex >= $scope.definitiveSurveyData.selectedSurveySetRangeData.length) {
                    return;
                }

                var parentSurveySetId = $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].parentSurveySetId;

                if (surveySetIndex === 0) {
                    $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].startSurveyMds =
                        $scope.definitiveSurveyData.surveySetMdData[parentSurveySetId];
                    generateAcceptableEndSurveyRanges(surveySetIndex);
                } else if (surveySetIndex > 0) {
                    var selectedMdToBeGreaterThan = $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex -
                    1].mdEnd;
                    if (selectedMdToBeGreaterThan === undefined || selectedMdToBeGreaterThan === null) {
                        $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].startSurveyMds =
                            [];
                    } else {
                        var newSurveyArray = removeSurveysLessThanMinMD(selectedMdToBeGreaterThan.value,
                            $scope.definitiveSurveyData.surveySetMdData[parentSurveySetId], true);
                        $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].startSurveyMds =
                            newSurveyArray;
                    }
                    $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].startSurveyMds.push(selectedMdToBeGreaterThan);
                    generateAcceptableEndSurveyRanges(surveySetIndex);
                }
            };

            var generateAcceptableEndSurveyRanges = function (surveySetIndex) {

                if (surveySetIndex >= $scope.definitiveSurveyData.selectedSurveySetRangeData.length) {
                    return;
                }

                var parentSurveySetId = $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].parentSurveySetId;

                var selectedMdToBeGreaterThanSecond = $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].mdStart;
                //If start md is not defined, allow all surveys to be selectable
                if (selectedMdToBeGreaterThanSecond === undefined || selectedMdToBeGreaterThanSecond === null) {
                    $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].endSurveyMds =
                        $scope.definitiveSurveyData.surveySetMdData[parentSurveySetId];
                } else {
                    var newSurveyArrayAgain = removeSurveysLessThanMinMD(selectedMdToBeGreaterThanSecond.value,
                        $scope.definitiveSurveyData.surveySetMdData[parentSurveySetId], true);
                    $scope.definitiveSurveyData.selectedSurveySetRangeData[surveySetIndex].endSurveyMds =
                        newSurveyArrayAgain;
                }
                generateAcceptableStartSurveyRanges(surveySetIndex + 1);
            };

            /**
             *  This function is a shortcut to call generateAcceptableSurveyRanges when the mdEnd is selected on the given index in the
             * selectedSurveySetRangeData array.  This function will then call generateAcceptableSurveyRanges for the next index's start MD selection to
             * prevent that index, and subsequent values, from containing a value which would come before the previous index's range on the trajectory
             * in measuredDepth. See the documentation for generateAcceptableSurveyRanges for further information.
             *
             * @param The index of the value that was selected in the selectedSurveySetRangeData array.
             */
            $scope.onEndSurveySetRangeMdSelection = function (surveySetSelectionIndex) {
                // Generate the acceptable ranges for the next MD range selection field
                generateAcceptableStartSurveyRanges(surveySetSelectionIndex + 1);
            };

            /**
             * This function is a shortcut to call generateAcceptableSurveyRanges when the mdStart is selected for the given index in the
             *  selectedSurveySetRangeData array.  This function will then call generateAcceptableSurveyRanges for the current index's mdEnd selection to
             * prevent the mdEnd, and subsequent values, from containing a value which would come before the mdStart on the trajectory in measuredDepth.
             * See the documentation for generateAcceptableSurveyRanges for further information.
             *
             * @param The index of the value that was selected in the selectedSurveySetRangeData array.
             */
            $scope.onStartSurveySetRangeMdSelection = function (surveySetSelectionIndex) {
                // Generate the acceptable ranges for the next MD range selection field
                generateAcceptableEndSurveyRanges(surveySetSelectionIndex);
            };

            // TODO: this method is temporary and will eventially be replaced by a new backend service
            var getMdsForDefinitiveSurveys = function (arrayOfSaphiraSurveys) {
                return arrayOfSaphiraSurveys.filter(function (survey) {
                    return survey.type == "STANDARD" || survey.type == "POOR" || survey.type == "DEFINITIVE_INTERPOLATED";
                }).map(function (survey) {
                    return survey.md
                });
            };

            /**
             * Given a surveySetRange containing a parentSurveySetId this function retrieves the MD values contained in the related SurveySet's associated
             * surveys and then assigns these MD values, indexed by parentSurveySetId, on the surveySetMdData array.
             * It then initializes the MD selection values by calling onStartSurveySetRangeMdSelection for the given definitiveSurveyIndex.
             *
             * @param surveySetRange the surveySetRange containing a parentSurveySetId which will be used to retrieve the SurveySet MD data.
             * @param definitiveSurveyIndex The index of the selectedSurveySetRangeData array in which to populate the originalSurveyMds property with
             *      the surveys contained in the SurveySet.
             */
            $scope.getSurveySetSurveyDataForSelection = function (surveySetRange, definitiveSurveyIndex) {

                if (!surveySetRange) {
                    return;
                }

                if (surveySetRange === null || surveySetRange === undefined ||
                    surveySetRange.parentSurveySetId === null || surveySetRange.parentSurveySetId === undefined) {
                    $scope.definitiveSurveyData.surveySetMdData[surveySetRange.parentSurveySetId] = [];
                    return;
                }

                return HierarchyService.Survey.getBySurveySet(surveySetRange.parentSurveySetId,
                    null, true).then(
                    function (surveys) {
                        $scope.definitiveSurveyData.surveySetMdData[surveySetRange.parentSurveySetId] =
                            getMdsForDefinitiveSurveys(surveys);
                        generateAcceptableStartSurveyRanges(definitiveSurveyIndex);
                        return $q.resolve();
                    },
                    function (error) {
                        $scope.definitiveSurveyData.surveySetMdData[surveySetRange.parentSurveySetId] = [];

                        SweetAlert.swal("Error", "SurveySet information could not be retrieved for" +
                            " SurveySet: " + surveySetRange.parentSurveySetId, "error");
                        return $q.reject(error);
                    }
                );
            };

            /**
             * initializeDefinitiveSurveyData
             *
             * This function should be called when a Trajectory is selected, it initializes the data needed to support modifying the definitive survey data
             * for the selected trajectory.
             */
            var initializeDefinitiveSurveyData = function () {
                if ($scope.selected.trajectory === undefined || $scope.selected.trajectory.data === undefined ||
                    $scope.selected.trajectory.data.id === undefined) {
                    return $q.reject();
                }

                $scope.definitiveSurveyData = {
                    surveySetMdData: [],
                    selectedSurveySetRangeData: []
                };

                if ($scope.selected.trajectory.data.parentSurveySetRanges.length === 0) {
                    return $q.reject();
                }

                $scope.definitiveSurveyData.selectedSurveySetRangeData =
                    angular.copy($scope.selected.trajectory.data.parentSurveySetRanges);

                var reverseSelectedSurveySetRangeData = $scope.definitiveSurveyData.selectedSurveySetRangeData.slice(0);

                if(!$scope.isCustomer()) {
                  var requests = [];
                  angular.forEach(reverseSelectedSurveySetRangeData.reverse(),
                    function (parentSurveySetRange, index) {
                      requests.push($scope.getSurveySetSurveyDataForSelection(parentSurveySetRange,
                        (reverseSelectedSurveySetRangeData.length - index - 1)));
                    });

                  return $q.all(requests);
                }
                else {
                    // Customer doesn't need to load the configuraiton data for the parent survey set ranges table
                    return $q.resolve();
                }

            };

            /**
             * Resets the array's used to display and record UI definitive survey range information.  Ultimately this will reset the form to be match the
             * current values on the selected trajectory's parentSurveySetRanges information.
             */
            $scope.resetSurveySetRangeSelections = function () {
                $scope.definitiveSurveyData = {
                    surveySetMdData: [],
                    selectedSurveySetRangeData: []
                };
                return initializeDefinitiveSurveyData();
            };

            /**
             * submitDefinitiveSurveyAndUpdateSelectedTrajectory
             *
             * This function submits the definitiveSurvey data as it is entered in the form.  First a validation occurs which verifies that every
             * row of data available contains a parentSurveySetId and a mdStart and a mdEnd.  If all three of these values are not present for every row in
             * the selectedSurveySetRangeData array then the request to update the trajectory is not completed.  However, if all of this data is correct,
             * then it is sent to the backend to be updated on the currently selected trajectory.  After the current trajectory is successfully updated
             * this form will reset the form to the non-editable table view and update the currently selected trajectory to get the newly submitted
             * definitive survey data, then this method will re-initialize the definitive survey form to display the updated definitive survey data..
             */
            $scope.submitDefinitiveSurveyAndUpdateSelectedTrajectory = function () {

                //clear out any impossible range
                for(var i = 0; i < $scope.definitiveSurveyData.selectedSurveySetRangeData.length; i++ ) {
                    var surveySetRangeSelection = $scope.definitiveSurveyData.selectedSurveySetRangeData[i];
                    var allowableStartMds = $scope.definitiveSurveyData.selectedSurveySetRangeData[i].startSurveyMds;
                    var allowableEndMds = $scope.definitiveSurveyData.selectedSurveySetRangeData[i].endSurveyMds;

                    //set startMd to null if not in allowable mds
                    if(!_.some(allowableStartMds, function(md) {
                        return md && surveySetRangeSelection.mdStart && (md.value === surveySetRangeSelection.mdStart.value);
                        })
                    ){
                        console.warn(surveySetRangeSelection.mdStart + " not found in possible start values");
                        surveySetRangeSelection.mdStart = null;

                    }

                    //set endMd to null if not in allowable mds
                    if(!_.some(allowableEndMds, function(md) {
                            return md && surveySetRangeSelection.mdEnd && (md.value === surveySetRangeSelection.mdEnd.value);
                        })
                    ){
                        console.warn(surveySetRangeSelection.mdEnd + " not found in possible end values");
                        surveySetRangeSelection.mdEnd = null;

                    }

                }

                var definitiveSurveyData = {
                    parentSurveySetRanges: $scope.definitiveSurveyData.selectedSurveySetRangeData
                };
                var isSurveyDataValid = true;

                angular.forEach(definitiveSurveyData.parentSurveySetRanges, function (surveySetRangeSelection) {
                    if (!surveySetRangeSelection.parentSurveySetId || !surveySetRangeSelection.definitiveValue) {
                        isSurveyDataValid = false;
                    }
                });

                if (isSurveyDataValid) {
                    HierarchyService.Trajectory.updateDefinitiveSurveys($scope.selected.trajectory.data.id,
                        definitiveSurveyData.parentSurveySetRanges).then(
                        function (responseString) {

                            SweetAlert.swal("Success",
                                "Successfully updated Trajectory: " + $scope.selected.trajectory.data.name +
                                " with definitive survey information.", "success");
                            $scope.disableTableEdit('selected.trajectory.definitiveSurveyTableEdit');
                            $scope.selected.trajectory.definitiveSurveyTableEdit = false;
                            $scope.canEditObject = true;

                            // Update the stored Trajectory to include the newly applied definitive survey data and then reset the definitive survey table/form with the newly submitted data.
                            HierarchyService.Trajectory.get($scope.selected.trajectory.data.id, true).then(
                                function (updatedTrajectory) {
                                    refreshTrajectoryReference();
                                    $scope.resetSurveySetRangeSelections();
                                    setupDefinitiveDataTable();

                                }, function () {
                                    SweetAlert.swal("Error",
                                        "Failed to refresh Trajectory after successfully updating definitive survey.",
                                        "error");
                                }
                            );
                        },
                        function (responseString) {
                            SweetAlert.swal("Error",
                                "Failed to update Trajectory: " + $scope.selected.trajectory.data.name +
                                " with definitive survey information. Check the ranges if they overlap. See activity log for more info.", "error");
                        }
                    );
                } else {
                    SweetAlert.swal("Error",
                        "SurveySet range information is incomplete, SurveySet and Definitive Values are required for all SurveySet's.",
                        "error");
                }
            };

            $scope.refreshTrajectoryTab = function () {
                HierarchyService.Trajectory.get($scope.selected.trajectory.data.id, true).then(
                    function () {
                        refreshTrajectoryReference();
                        setupDefinitiveDataTable();
                    }
                );
            };

            /* END Definitive Survey Form *****************************************************************************/

            $scope.viewAttachments = function () {

                // Capture analytics when upload file button is clicked
                const eventData = {
                    'button_name': 'Upload File',
                    'survey_set_name': $scope.selected.surveySet.data.name,
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_BUTTON_CLICKED, eventData);
                var attachFilesModal = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/workflow/importSurveyFile/UploadSurveyFileModal.html',
                    controller: 'UploadSurveyFileModalController',
                    size: 'lg',
                    resolve: {
                        currentSurveySet: function () {
                            return $scope.selected.surveySet;
                        }
                    }
                });

                //when the modal is closed
                attachFilesModal.result.then(function (attachment) {

                    //if automation is active and attachments is a survey file
                    if ($scope.selected.surveySet.data.automationActive && attachment.data.isSurveyFile) {

                        //get a survey from the mwd reader
                        var result = MWDService.getSurveyFromMWD($scope.selected.surveySet.data, attachment.data.id,
                            $scope.selected.surveySet.data.mwdFileTemplate, false, false)   // currently don't allow the automation to guess the file format.. could change this later... but dangerous.
                            .then(function (survey) {
                                if (survey.data === true) {
                                    SweetAlert.swal("Success", "File imported successfully.", "success");
                                    init();
                                } else {
                                    checkValidationAndSubmitSurvey(survey.data);
                                }
                            }, function (response) {
                                //Don't display any error to anyone at request of RTC because the context is enough for the RTC and they dont want to risk confusing rigs.
                                refreshSurveySet();
                            });
                    }
                    refresh();
                });
            };

            /**
             * Opens MSA Correction module
             */
            $scope.msaCorrection = function () {

                if ($scope.selected.survey.data.length === 0) {
                    SweetAlert.swal("Error", "No surveys to correct!", "error");
                    return;
                }

                if (msaModal === null) {
                    msaModal = $uibModal.open({
                        templateUrl: 'html/views/detailedWellbore/workflow/msa/MsaParametersModal.html',
                        controller: 'MsaParametersModalController',
                        windowClass: 'qc-plots-modal',
                        resolve: {
                            acSigma: function () {
                                return $scope.selected.company.data.sigmaLevel;
                            },
                            surveySet: function () {
                                return $scope.selected.surveySet;
                            },
                            refresh: function () {
                                return refresh;
                            }
                        }
                    });
                    msaModal.result.then(function () {
                        //Successful msa application
                        msaModal = null;
                        refreshTrajectory();
                        refreshSurveySet();
                    }, function () {
                        //Canceled
                        msaModal = null;
                    });
                }
                else {
                    //MSA window already open.
                    console.info("Msa window already open");
                }
            };

            //Delete the unhandled error and either re-enable or disable automation
            var deleteUnhandledMsaError = function (enableAutomation) {
                return HierarchyService.SurveySet.updateIfModified(StateService.wellboreDetailView.selectedSurveySetId)
                    .then(function () {
                        refreshSurveySetReference();
                        refreshSurveyReference();

                        $scope.selected.surveySet.data.automationActive = enableAutomation;
                        $scope.selected.surveySet.data.unhandledMsaFailure = null;
                        return HierarchyService.SurveySet.save($scope.selected.surveySet.data);
                    });
            };

            /**
             * Opens the MSA validation modals so a real time officer can manage any errors that occurred during automation
             */
            $scope.handleMsaError = function () {

                var unhandledMSAError = $scope.selected.surveySet.data.unhandledMsaFailure;

                if(!unhandledMSAError) {
                    return; //Nothing to do here
                }

                if ($scope.selected.surveySet.data.automationActive && $scope.msaValidating !== true) {
                    $scope.msaValidating = true;

                    var params = {
                        'parentSurveySetId': $scope.selected.surveySet.data.id,
                        'selector': 'UNCORRECTED',
                        'mdMin': undefined,
                        'mdMax': undefined
                    };

                    //Apply the stored correction and validate it
                    CorrectionService.applyCorrection($scope.selected.surveySet.id, params.selector, params.mdMin,
                        params.mdMax, unhandledMSAError.correction,
                        $scope.selected.surveySet.data.correctionParameters.msaIncOffset,
                        $scope.selected.surveySet.data.correctionParameters.msaAziOffset, false)
                        .then(function (correctionAssessment) {
                                //Only display if there were any corrected surveys
                                if (correctionAssessment && correctionAssessment.standard &&
                                    correctionAssessment.standard.surveys &&
                                    correctionAssessment.standard.surveys.length > 0) {
                                    var validation = $uibModal.open({
                                        templateUrl: 'html/views/detailedWellbore/workflow/msa/MsaValidationModal.html',
                                        controller: 'MsaValidationModalController',
                                        windowClass: 'qc-plots-modal',
                                        backdrop: 'static',
                                        resolve: {
                                            //TODO consider consolidating these
                                            manualCorrectionValues: function () {
                                                return {
                                                    "gDamp": $scope.selected.surveySet.data.correctionParameters.gDamp,
                                                    "bDamp": $scope.selected.surveySet.data.correctionParameters.bDamp,
                                                    "msaIncOffset": $scope.selected.surveySet.data.correctionParameters.msaIncOffset,
                                                    "msaAziOffset": $scope.selected.surveySet.data.correctionParameters.msaAziOffset
                                                };
                                            },
                                            correctionResolutions: function () {
                                                if ($scope.selected.surveySet.data.correctionParameters) {
                                                    var correctionResolutions = {
                                                        "bxBiasType": $scope.selected.surveySet.data.correctionParameters.bxBias,
                                                        "byBiasType": $scope.selected.surveySet.data.correctionParameters.byBias,
                                                        "bzBiasType": $scope.selected.surveySet.data.correctionParameters.bzBias,
                                                        "gxBiasType": $scope.selected.surveySet.data.correctionParameters.gxBias,
                                                        "gyBiasType": $scope.selected.surveySet.data.correctionParameters.gyBias,
                                                        "gzBiasType": $scope.selected.surveySet.data.correctionParameters.gzBias,
                                                        "bxScaleType": $scope.selected.surveySet.data.correctionParameters.bxScale,
                                                        "byScaleType": $scope.selected.surveySet.data.correctionParameters.byScale,
                                                        "bzScaleType": $scope.selected.surveySet.data.correctionParameters.bzScale,
                                                        "gxScaleType": $scope.selected.surveySet.data.correctionParameters.gxScale,
                                                        "gyScaleType": $scope.selected.surveySet.data.correctionParameters.gyScale,
                                                        "gzScaleType": $scope.selected.surveySet.data.correctionParameters.gzScale,
                                                        "isMagneticMisalignManual": $scope.selected.surveySet.data.correctionParameters.isMagneticMisalignmentManual,
                                                        "isGravityMisalignManual": $scope.selected.surveySet.data.correctionParameters.isGravityMisalignmentManual,
                                                        "msaComputeFromRange": $scope.selected.surveySet.data.correctionParameters.msaComputeFromRange
                                                    };
                                                }
                                                return correctionResolutions;
                                            },
                                            rawSurveys: function () {
                                                return HierarchyService.Survey.Loaded[$scope.selected.surveySet.id];
                                            },
                                            correctionAssessment: function () {
                                                return correctionAssessment;
                                            },
                                            surveySetId: function () {
                                                return $scope.selected.surveySet.data.id;
                                            },
                                            params: function () {
                                                return params;
                                            },
                                            unitSet: function () {
                                                return $scope.selected.surveySet.data.unitSet;
                                            },
                                            acSigma: function () {
                                                return $scope.selected.company.data.sigmaLevel;
                                            },
                                            qcDeltaInc: function () {
                                                return $scope.selected.surveySet.data.qcDeltaInc;
                                            },
                                            qcDeltaAzi: function () {
                                                return $scope.selected.surveySet.data.qcDeltaAzi;
                                            },
                                            msaQCDeltaInc: function () {
                                                return $scope.selected.surveySet.data.msaDeltaInc;
                                            },
                                            msaQCDeltaAzi: function () {
                                                return $scope.selected.surveySet.data.msaDeltaAzi;
                                            },
                                            fromAutomation: function () {
                                                return true;
                                            },
                                            refresh: function () {
                                                return refresh;
                                            }
                                        }
                                    });
                                    validation.result.then(function () {
                                        //Ignore errors and apply correction
                                        CorrectionService.applyCorrection($scope.selected.surveySet.id, params.selector,
                                            params.mdMin, params.mdMax,
                                            unhandledMSAError.correction,
                                            $scope.selected.surveySet.data.correctionParameters.msaIncOffset,
                                            $scope.selected.surveySet.data.correctionParameters.msaAziOffset, true)
                                            .then(function () {
                                                SweetAlert.swal("Success", "MSA computed successfully.", "success");

                                                deleteUnhandledMsaError(true).then(function () {
                                                    $scope.msaValidating = false;
                                                });
                                            }, function () {
                                                //failed to apply correction
                                                SweetAlert.swal("Error",
                                                    "Unknown error when applying correction to surveys.", "error");
                                                deleteUnhandledMsaError(false).then(function () {
                                                    $scope.msaValidating = false;
                                                });
                                            });
                                    }, function () {
                                        //Investigate
                                        deleteUnhandledMsaError(false).then(function () {
                                            $scope.msaValidating = false;
                                            $scope.msaCorrection();
                                        });
                                    });
                                }
                                else {
                                    deleteUnhandledMsaError(true).then(function () {
                                        $scope.msaValidating = false;
                                    });
                                }
                            },
                            function () {
                                deleteUnhandledMsaError(false).then(function () {
                                    $scope.msaValidating = false;
                                });
                            });
                }
            };

            //Delete the unhandled error and either re-enable or disable automation
            var deleteUnhandledMwdErrors = function (enableAutomation) {
                return HierarchyService.SurveySet.updateIfModified(StateService.wellboreDetailView.selectedSurveySetId)
                    .then(function () {
                        refreshSurveySetReference();
                        refreshSurveyReference();

                        $scope.selected.surveySet.data.automationActive = enableAutomation;
                        $scope.selected.surveySet.data.unhandledMwdReaderErrors = null;
                        return HierarchyService.SurveySet.save($scope.selected.surveySet.data);
                    });
            };

            /**
             * openAutomationOptions
             */
            $scope.openAutomationModal = function () {
                var needToRefresh = false;
                $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/workflow/automation/AutomationModal.html',
                    controller: 'AutomationModalController',
                    windowClass: 'atuomation-modals',
                    backdrop: 'static'
                }).result.then(function (changesMade) {
                    if (changesMade) {
                        needToRefresh = true;
                    }
                }, function () {
                    needToRefresh = true;
                }).finally(function () {
                    if (needToRefresh) {
                        refresh();
                    }
                });
            };

            /**
             * openValidationTolerancesModal
             */
            $scope.openValidationTolerancesModal = function () {
                var needToRefresh = false;
                $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/workflow/SurveySetValidationTolerancesModal.html',
                    controller: 'SurveySetValidationTolerancesModalController',
                    backdrop: 'static'
                }).result.then(function (changesMade) {
                    if (changesMade) {
                        needToRefresh = true;
                    }
                }, function () {
                    needToRefresh = true;
                }).finally(function () {
                    if (needToRefresh) {
                        refresh();
                    }
                });
            };

            /**
             * This function opens the MWD reader, which allows the user to add surveys based on an MWD file type, selected by a user.
             * The file types are generated automatically by the server, so that the client never needs to hold an internal list of types.
             */
            $scope.mwdReader = function () {
                var mwdModalInstance = $uibModal.open({
                    templateUrl: 'html/views/detailedWellbore/workflow/MwdReaderModal.html',
                    controller: 'MwdReaderModalController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        currentSurveySet: function () {
                            return $scope.selected.surveySet;
                        }
                    }
                });

                mwdModalInstance.result.then(function (surveyOut) {
                    if (surveyOut === undefined) {
                        return;
                    }
                    if (surveyOut === true) {
                        SweetAlert.swal("Success!", "MWD File was succesfully imported. Page will refresh to reflect new surveys automatically.", "success");
                        refresh();
                        return;
                    }
                    checkValidationAndSubmitSurvey(surveyOut);
                });
            };

            $scope.checkPerm = AuthenticationService.checkPermissionMapEntry;


            /**
             * Checks for any raw validation errors. If there are none (or if the user is a customer), the survey is automatically submitted.
             * If there are validation errors (and the user is not a customer) it opens a modal displaying the errors and asks for verification.
             * @param survey
             */
            var checkValidationAndSubmitSurvey = function (survey) {
                if (!survey) {
                    return;
                }

                function addSurvey(survey) {
                    HierarchyService.Survey.add(survey).then(function (success) {
                        $scope.newSurvey = {};
                        refresh();
                    }, function (error) {
                        SweetAlert.swal("Error", "An error occurred while adding your validated survey to the database" +
                            (typeof (error.error) !== "undefined" ? ": " + error.error : "."), "error");
                    });
                }

                if ($scope.isCustomer() || survey.validationErrors.length === 0 || !$scope.selected.surveySet.data.validationErrorsEnabled) {
                    addSurvey(survey);
                } else {
                    //Open validation modals
                    var validationModal = $uibModal.open({
                        templateUrl: 'html/views/detailedWellbore/workflow/SurveyValidationModal.html',
                        controller: 'SurveyValidationModalController',
                        windowClass: 'data-validation-modals',
                        backdrop: 'static',
                        resolve: {
                            'validatedSurvey': survey
                        }
                    });
                    //Handle closing of validation modals
                    validationModal.result.then(
                        //Override
                        function (success) {
                            addSurvey(survey)
                        }
                    );
                }
            };

            /**
             * pasteSurvey:
             *
             *   Creates a manual survey from tab seperated fields on the clipboard
             * @param clipboardEvent
             */
            $scope.pasteSurvey = function (clipboardEvent) {
                // prevents the browser default, i.e. pasting all data into the selected input
                clipboardEvent.preventDefault();

                // pull the text out of the HTML5 ClipboardEvent object
                var pasteString = clipboardEvent.originalEvent.clipboardData.getData('text/plain');

                // split the values and populate the new survey fields
                var dataValues = pasteString.split('\t');

                if ($scope.selected.surveySet.data.surveyInputType == "STANDARD") {
                    pasteStandardSurvey(dataValues);
                } else if ($scope.selected.surveySet.data.surveyInputType == "BACK_CALCULATED") {
                    pasteBackCalculatedSurvey(dataValues);
                }

            };

            var pasteStandardSurvey = function (dataValues) {
                if (dataValues.length === 11) {
                    $scope.newSurvey.md = Number(dataValues[0]);
                    $scope.newSurvey.type = dataValues[1];
                    $scope.newSurvey.submittedTime = new Date(dataValues[2]);
                    $scope.newSurvey.inc = Number(dataValues[3]);
                    $scope.newSurvey.azi = Number(dataValues[4]);
                    $scope.newSurvey.g0 = Number(dataValues[5]);
                    $scope.newSurvey.g1 = Number(dataValues[6]);
                    $scope.newSurvey.g2 = Number(dataValues[7]);
                    $scope.newSurvey.b0 = Number(dataValues[8]);
                    $scope.newSurvey.b1 = Number(dataValues[9]);
                    $scope.newSurvey.b2 = Number(dataValues[10]);
                }
                else if (dataValues.length === 12) {
                    $scope.newSurvey.type = dataValues[0];
                    $scope.newSurvey.submittedTime = new Date(dataValues[1]);
                    $scope.newSurvey.md = Number(dataValues[3]);
                    $scope.newSurvey.inc = Number(dataValues[4]);
                    $scope.newSurvey.azi = Number(dataValues[5]);
                    $scope.newSurvey.g0 = Number(dataValues[6]);
                    $scope.newSurvey.g1 = Number(dataValues[7]);
                    $scope.newSurvey.g2 = Number(dataValues[8]);
                    $scope.newSurvey.b0 = Number(dataValues[9]);
                    $scope.newSurvey.b1 = Number(dataValues[10]);
                    $scope.newSurvey.b2 = Number(dataValues[11]);
                }
            };

            var pasteBackCalculatedSurvey = function (dataValues) {
                if (dataValues.length === 9) {
                    $scope.newSurvey.md = Number(dataValues[0]);
                    $scope.newSurvey.type = dataValues[1];
                    $scope.newSurvey.submittedTime = new Date(dataValues[2]);
                    $scope.newSurvey.inc = Number(dataValues[3]);
                    $scope.newSurvey.azi = Number(dataValues[4]);
                    $scope.newSurvey.totalB = Number(dataValues[5]);
                    $scope.newSurvey.totalG = Number(dataValues[6]);
                    $scope.newSurvey.dip = Number(dataValues[7]);
                    $scope.newSurvey.toolface = Number(dataValues[8]);
                }
            };

            var refreshCompany = function () {
                //check if the company was updated
                return HierarchyService.Company.updateIfModified(StateService.wellboreDetailView.selectedCompanyId).then(function () {
                    refreshCompanyReference();
                    return $q.resolve();
                }, function (error) {
                    return $q.reject(error);
                });
            };

            var refreshField = function () {
                //check if the field was updated
                return HierarchyService.Field.updateIfModified(StateService.wellboreDetailView.selectedFieldId).then(function () {
                    refreshFieldReference();
                    return $q.resolve();
                }, function (error) {
                    return $q.reject(error);
                });
            };

            var refreshPad = function () {
                //check if the pad was updated
                return HierarchyService.Pad.updateIfModified(StateService.wellboreDetailView.selectedPadId).then(function () {
                    refreshPadReference();
                    return $q.resolve();
                }, function (error) {
                    return $q.reject(error);
                });
            };

            var refreshWell = function () {
                //check if the well was updated
                return HierarchyService.Well.updateIfModified(StateService.wellboreDetailView.selectedWellId).then(function () {
                    refreshWellReference();
                    return checkForNewChat();
                }, function (error) {
                    return $q.reject(error);
                });
            };

            var refreshWellbore = function () {
                //check if the wellbore was updated
                return HierarchyService.Wellbore.updateIfModified(StateService.wellboreDetailView.selectedWellboreId)
                    .then(function (updated) {
                        refreshWellboreReference();
                        return $q.resolve();
                    }, function (error) {
                        return $q.reject(error);
                    });
            };

            var refreshTrajectory = function () {
                //check if the trajectory was updated
                if (StateService.wellboreDetailView.selectedTrajectoryId != null) {
                    //Check if the trajectory was updated
                    return HierarchyService.Trajectory.updateIfModified(StateService.wellboreDetailView.selectedTrajectoryId)
                        .then(function (updated) {
                            refreshTrajectoryReference();
                            if (updated === true) {
                                $scope.resetSurveySetRangeSelections().then(function () {
                                    setupDefinitiveDataTable();
                                    if ($scope.isCustomer()) {
                                        $scope.currentMetaTab = $scope.metaTabIndices.trajectory;
                                        checkNumPostedDefinitiveSurveys();
                                        var activityLogMessageRequisites = {
                                            "objectId": StateService.wellboreDetailView.selectedSurveySetId,
                                            "objectTypeName": "SurveySet",
                                            "level": "AUDIT",
                                            "activityType": "RIG_VIEWED_CORRECTION",
                                            "message": "Rig user " + AuthenticationService.currentUser().fullId + " (" + AuthenticationService.currentUser().displayName + ") viewed corrections"
                                        };
                                        ActivityLogService.addByObjectId(activityLogMessageRequisites);
                                    }

                                    return loadTrajectoryMetas();
                                }, function (error) {
                                    return $q.reject(error);
                                });
                            }
                            else {
                                return $q.resolve();
                            }
                        }, function (error) {
                            return $q.reject(error);
                        });
                }
                else {
                    return $q.resolve();
                }
            };

            var refreshSurveySet = function () {
                //check if the survey set was updated
                if (StateService.wellboreDetailView.selectedSurveySetId) {
                    return HierarchyService.SurveySet.updateIfModified(StateService.wellboreDetailView.selectedSurveySetId)
                        .then(function (updated) {
                            refreshSurveySetReference();
                            refreshSurveyReference();
                            if (updated === true) {
                                setupSurveyTable();
                                $scope.surveyTable.editing = false;

                                //If we have survey validation errors (that are checked) or any msa validation errors, possibly stop automation
                                if (!$scope.isCustomer() &&
                                    (_.intersection($scope.selected.surveySet.data.lastSurveyValidationErrors, checkedSurveyValidationErrors).length > 0) ||
                                    ($scope.selected.surveySet.data.unhandledMsaFailure !== null &&
                                    $scope.selected.surveySet.data.unhandledMsaFailure !== undefined)) {
                                    $scope.handleMsaError();
                                }

                                if (!$scope.isCustomer() &&
                                    $scope.selected.surveySet.data.unhandledMwdReaderErrors !== null &&
                                    $scope.selected.surveySet.data.unhandledMwdReaderErrors !== undefined) {
                                    dealWithMwdReader($scope.selected.surveySet.data.unhandledMwdReaderErrors);
                                }

                                if (!$scope.isCustomer() && !$scope.isReadOnlyUser() && $scope.checkPerm($scope.selected.surveySet.data, 'NOTIFICATIONS')) {
                                    handleNotifications();
                                }

                                //If the rig user sees the active survey set no longer is active, stop watching it
                                if ($scope.isCustomer() && $scope.selected.surveySet.data.status !== 'ACTIVE') {
                                    $scope.selectedMetas.surveySet = null;
                                    $scope.selected.surveySet.id = null;
                                    $scope.selected.surveySet.commonName = null;
                                    $scope.selected.surveySet = null;
                                    StateService.wellboreDetailView.selectedSurveySetId = null;
                                }

                                return loadSurveySetMetas();
                            }

                        }, function (error) {
                            return $q.reject(error);
                        });
                }
                else if ($scope.isCustomer()) {
                    //try and autoselect a surveyset
                    var surveySetMetas = $scope.selected.wellbore.surveySetMetas;
                    loadSurveySetMetas().then(function () {
                        if (surveySetMetas !== undefined && surveySetMetas.length > 0) {
                            var active = null;
                            for (var i = 0; i < surveySetMetas.length; i++) {
                                var surveySet = surveySetMetas[i];
                                if (surveySet.status === "ACTIVE") {
                                    active = surveySet;
                                    break;
                                }
                            }
                            if (active !== null) {
                                $scope.selectSurveySet(active).then(function () {
                                    $scope.selectedMetas.surveySet = active;
                                    $scope.selected.surveySet.id = active.id;
                                    $scope.selected.surveySet.commonName = active.commonName;
                                });
                            }
                        }

                    }, function () {
                        SweetAlert.swal("Error", "Failed to load active Survey Set.", "error");
                    });

                }
                else {
                    return $q.resolve();
                }
            };

            var checkRigUserStatus = function () {
                //check the session of the rig user if well has rigUsername set
                if ($scope.selected.well.data.rigUsername !== undefined) {
                    var username = $scope.selected.well.data.rigUsername;
                    AuthenticationService.getUserSession(username).then(function (message) {

                        var statusMessage = '';
                        var statusClass = '';
                        var idleTime = '';
                        if (message.status === 'STATUS_OFFLINE') {
                            statusMessage = 'OFFLINE';
                            statusClass = 'offline';
                        } else if (message.status === 'STATUS_ONLINE') {
                            statusMessage = 'ONLINE';
                            statusClass = 'online';
                        } else if (message.status === 'STATUS_IDLE') {
                            statusMessage = 'Idle, Last Seen at ';
                            idleTime = moment(1000.0 * (message.lastSeenTime.seconds + (message.lastSeenTime.nanos / 1000000000.0)))
                                    .format("YYYY[-]MM[-]DD hh[:]mm[:]ss A")  + ' ' + $scope.timeZone.client;
                            statusClass = 'idletime';
                        }

                        var session = {
                            "status": statusMessage,
                            "idleTime": idleTime,
                            "class": statusClass
                        };
                        $scope.selected.rigUserSession = session;
                        return $q.resolve();
                    }, function (error) {
                        return $q.reject(error);
                    });

                }
            };

            /**
             *  startRefreshLoop:
             *
             *      This starts the fresh loop that calls the refresh function based
             *  on the refresh rate.
             */
            var startRefreshLoop = function () {
                $rootScope.refreshId = $interval(function () {
                    if (!pendingRefresh) {
                        refreshLoop();
                    }
                }, refreshTimer);
            };

            var refreshLoop = function () {
                pendingRefresh = true;
                refresh().finally(function () {
                    pendingRefresh = false;
                });
            };
            /**
             * refresh:
             *      The refresh function that is called to make sure that the page is kept
             *      up to date.
             */
            var refresh = function () {
                var requests = [];

                if (!$scope.isCustomer()) {
                    requests.push(refreshCompany());
                    requests.push(refreshField());
                    requests.push(refreshPad());
                }
                requests.push(refreshWell());
                requests.push(refreshWellbore());
                requests.push(refreshTrajectory());
                requests.push(refreshSurveySet());
                requests.push(checkRigUserStatus());

                return $q.all(requests);
            };

            var checkNumPostedDefinitiveSurveys = function () {

                var surveys = HierarchyService.Trajectory.Loaded[StateService.wellboreDetailView.selectedTrajectoryId].definitiveSurveys;

                if (surveys.length > 0) {
                    var numPosted = 0;
                    for (var i = 0; i < surveys.length; i++) {
                        if (surveys[i].postedInc !== undefined) {
                            numPosted++;
                        }
                    }

                    if (StateService.wellboreDetailView.numPostedSurveys === undefined || numPosted > StateService.wellboreDetailView.numPostedSurveys) {
                        SweetAlert.swal("New definitive survey.");

                    }
                    StateService.wellboreDetailView.numPostedSurveys = numPosted;
                }
            };

            /**
             * notificationForNewSurvey:
             *
             * The action that occurs when a new survey comes in.
             *  1.) New survey sound is played
             *  2.) Tab title flashes to "New Survey!"
             *  3.) Correction modal opens if the user has MSA permission, otherwise there is a popup saying
             *  "New survey submitted."
             */
            var notificationForNewSurvey = function (handleNotificationForNewSurveyApproachingTD) {
                newSurveySound.play();
                tabAlert("New Survey!");
                if ($scope.checkPerm($scope.selected.surveySet.data, 'MSA') && $scope.allowMsaCorrection()) {
                    $scope.msaCorrection();
                    if(handleNotificationForNewSurveyApproachingTD){
                        notificationForNewSurveyApproachingTD();
                    }
                }
                else {
                    SweetAlert.swal({
                        title: 'New survey submitted.',
                        text: '',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        closeOnConfirm: true
                    }, function (isConfirm) {
                        if(handleNotificationForNewSurveyApproachingTD){
                            setTimeout(function() {
                                notificationForNewSurveyApproachingTD();
                            },100);
                }
                    });
                }

            };

            /**
             * notificationForNewSurveyApproachingTD:
             *
             * The action that occurs when a new survey comes in.
             *  1.) there is a popup saying
             *  "Wellbore is Approaching Total Depth."
             */
            var notificationForNewSurveyApproachingTD = function () {
                var msg = $scope.selected.surveySet.data.approachingTdMessage;
                SweetAlert.swal({
                    title:msg,
                    text:'',
                    showCancelButton: false,
                    confirmButtonText: "Continue",
                    closeOnConfirm: true
                });
            };

            /**
             * notificationForFileUpload:
             *
             * The action that occurs when a new file comes in.
             * 1.) New file sound is played
             * 2.) Tab title flashes to "New file!"
             * 3.) Popup says "New file uploaded".
             */
            var notificationForFileUpload = function () {
                newFileSound.play();
                tabAlert("New file!");
                SweetAlert.swal("New file uploaded.");
            };

            /**
             * handleNotifications:
             *
             * Handles all notifications on the survey set.
             *
             */
            var handleNotifications = function () {

                //read only users shouldn't deal with notifications
                if ($scope.isReadOnlyUser()) {
                    return;
                }

                //config
                var notificationsToCheckFor = [
                    'SURVEY_SUBMIT_SUCCESS',
                    'SURVEY_SUBMIT_APPROACHING_TD',
                    'FILE_UPLOAD_SUCCESS'
                ];

                var notificationsOnSurveySet = $scope.selected.surveySet.data.notifications;
                var userId = AuthenticationService.currentUser().fullId;

                //get the list of flags we need to acknowledge
                var notificationsToAck = getListOfNotificationsToAck(notificationsOnSurveySet, userId, notificationsToCheckFor);

                //acknowledge each individual flag
                return acknowledgeNotifications(userId, notificationsToAck);
            };


            /**
             * getListOfNotificationsToAck:
             *
             * Returns a list of notifications that the user needs to acknowledge.
             *
             * @param notifications         The flags as they are stored on the survey set
             * @param userId                The id of the user
             * @param handledNotifications    The notifications that we handle on the client so far
             *
             * @returns {Array}         A list of flags the user needs to acknowledge.
             */
            var getListOfNotificationsToAck = function (notifications, userId, handledNotifications) {

                var notificationsToAck = [];

                for (var notification in notifications) {
                    var notificationDetails = notifications[notification];

                    //if no users have acked yet, we need to
                    if (notificationDetails.usersAcknowledged.length === 0) {
                        notificationsToAck.push(notification);
                    }
                    //if the current user has not acknowledged the notification they need to
                    else {
                        var userAcknowledged = false;
                        var usersAcknowledged = notificationDetails.usersAcknowledged;
                        for (var i = 0; i < usersAcknowledged.length; i++) {
                            var user = usersAcknowledged[i];
                            if (user == userId) {
                                userAcknowledged = true;
                                break;
                            }
                        }
                        if (!userAcknowledged) {
                            notificationsToAck.push(notification);
                        }
                    }
                }

                //return the list of flags that the user needs to acknowledge
                return notificationsToAck;
            };

            /**
             * AcknowledgeNotifications:
             *
             * Sends a request to the server to acknowledge notifications that were previously unacknowledged, as well as
             * performs some client responses based on the unacknowledged notifications.
             *
             * @param userId        The id of the user acknowledging the flags
             * @param flagsToAck    The list of flags to acknowledge
             */
            var acknowledgeNotifications = function (userId, notificationsToAck) {
                //tell the server that the user has acknowledged the flags
                if (notificationsToAck.length > 0) {
                    return HierarchyService.SurveySet.ackNotifications($scope.selected.surveySet.data.id, userId, notificationsToAck)
                        .then(function (response) {

                            var handleNotificationForNewSurveyApproachingTD = false;
                            for (var i = 0; i < notificationsToAck.length; i++) {
                                var notification = notificationsToAck[i];
                                //if it was for survey approaching TD
                                if (notification == 'SURVEY_SUBMIT_APPROACHING_TD') {
                                    //create the notification for survey approaching TD
                                    if (haveAckedInitialNotifications) {
                                        handleNotificationForNewSurveyApproachingTD = true;
                                    }
                                    $scope.selected.surveySet.data.notifications.SURVEY_SUBMIT_APPROACHING_TD.usersAcknowledged.push(AuthenticationService.currentUser().fullId);
                                }
                            }
                            //then, for all flags we are acknowledging
                            for (var i = 0; i < notificationsToAck.length; i++) {
                                var notification = notificationsToAck[i];

                                //if it was for submitting a survey
                                if (notification == 'SURVEY_SUBMIT_SUCCESS') {
                                    //create the notification for submitting a survey
                                    if (haveAckedInitialNotifications) {
                                        notificationForNewSurvey(handleNotificationForNewSurveyApproachingTD);
                                    }
                                    $scope.selected.surveySet.data.notifications.SURVEY_SUBMIT_SUCCESS.usersAcknowledged.push(AuthenticationService.currentUser().fullId);
                                }

                                //if it was for uploading a file
                                if (notification == 'FILE_UPLOAD_SUCCESS') {
                                    //create the notification for uploading a file
                                    if (haveAckedInitialNotifications) {
                                        notificationForFileUpload();


                                    }
                                    $scope.selected.surveySet.data.notifications.FILE_UPLOAD_SUCCESS.usersAcknowledged.push(AuthenticationService.currentUser().fullId);
                                }
                            }

                            haveAckedInitialNotifications = true;

                            return $q.resolve;
                        }, function (error) {
                            haveAckedInitialNotifications = true;
                            return $q.reject(error);
                        });
                }
                else {
                    haveAckedInitialNotifications = true;
                    return $q.resolve();
                }
            };


            /**
             * tabAlert:
             *
             * Flashes a message in the tab title.
             *
             * @param msg   The message to flash on the tab title.
             * @param ti    How often the message should flash
             */
            var tabAlert = function (msg, ti) {
                if ($scope.hasFocus === false) {
                    $interval.cancel($scope.flashId);
                    $scope.flashId = $interval(function () {
                        document.title = document.title == msg ? StateService.wellboreDetailView.tabTitle : msg;
                    }, (ti !== undefined && ti !== null) ? ti : 1500);
                }
            };

            angular.element($window).bind('blur', function () {
                $scope.hasFocus = false;
            });

            angular.element($window).bind('focus', function () {
                if ($scope.hasFocus === false && StateService.wellboreDetailView.tabTitle !== undefined) {
                    $interval.cancel($scope.flashId);
                    document.title = StateService.wellboreDetailView.tabTitle;
                }
                $scope.hasFocus = true;
            });

            var dealWithMwdReader = function (message) {
                SweetAlert.swal({
                        title: "Error",
                        text: message,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "ok",
                        closeOnConfirm: true
                    }
                );
                deleteUnhandledMwdErrors(false);
            };

            /**
             * magneticModelTypeChange
             *
             *     This reloads the IFR Cube Id's in the event that the magnetic model type changes to IFR.
             */
            $scope.magneticModelTypeChange = function () {
                if ($scope.editMetaData.magneticModelType == "IFR" ||
                    $scope.selected.wellbore.data.magneticModelType == "IFR") {
                    loadIfrCubeIds();
                }
            };

            var checkForNewChat = function () {

                if ($scope.isReadOnlyUser() || !$scope.checkPerm($scope.selected.well.data, 'CHAT')) {
                    return $q.resolve();
                }

                return ChatService.getLatestMessageTime(StateService.wellboreDetailView.selectedWellId).then(function (latest) {

                    //if we have no concept of messages yet
                    if (StateService.wellboreDetailView.latestChatTime === undefined) {
                        StateService.wellboreDetailView.latestChatTime = (latest !== null ? latest.time : null);
                    }
                    else {
                        //if we have a newer message or we have the first message
                        if ((StateService.wellboreDetailView.latestChatTime !== null &&
                                latest.time.isAfter(StateService.wellboreDetailView.latestChatTime)) ||
                            StateService.wellboreDetailView.latestChatTime === null && latest !== null) {
                            StateService.wellboreDetailView.latestChatTime = latest.time;

                            if (AuthenticationService.currentUser().displayName != latest.sender) {
                                $scope.chat();
                            }
                        }
                    }
                    return $q.resolve();
                }, function (error) {
                    return $q.reject(error);
                });
            };

            $scope.createCompanySnapshot = function() {
                CompanySnapshotService.getCompanySnapshot($scope.selected.company.data.id);
            }

            var wellColumnForPad = {
                field: 'wellName',
                enableColumnMenu :false,
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []},
                sort: {
                    direction: uiGridConstants.ASC
                }
            };

            var wellboreColumnForPad = {
                field: 'wellboreName',
                enableColumnMenu :false,
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };



            var wellColumnForWell = {
                field: 'wellName',
                enableColumnMenu :false,
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []},
                sort: {
                    direction: uiGridConstants.ASC
                }
            };

            var wellboreColumnForWell = {
                field: 'wellboreName',
                enableColumnMenu :false,
                filter: {type: uiGridConstants.filter.INPUT, selectOptions: []}
            };

            var loadSelectionsByPad = function (padId) {
                var getColumnFilters = WellboreSelectionService.getColumnFilters;
                padId = padId === undefined ? null : padId;
                $scope.selectedRowInPad = null;
                UIBlocker.start();
                return WellboreSelectionService.getSelectionsByPad(padId).then(function (data) {
                    $scope.gridOptionsForPad.data = data;
                    wellColumnForPad.filter.selectOptions = getColumnFilters($scope.gridOptionsForPad.data, 'well');
                    WellboreSelectionService.reconstructFilterOptions($scope.gridOptionsForPad.data, wellColumnForPad, wellboreColumnForPad);
                    UIBlocker.stop();
                    StateService.loggedIn = true;
                }, function (status) {
                    UIBlocker.stop();
                    SweetAlert.swal("Error loading wellbores", "I'm sorry, there was an error loading the" +
                        " wellbores: " + status, "error");
                });
            };

            var loadSelectionsByWell = function (wellId) {
                var getColumnFilters = WellboreSelectionService.getColumnFilters;
                wellId = wellId === undefined ? null : wellId;
                $scope.selectedRowInWell = null;
                UIBlocker.start();
                return WellboreSelectionService.getSelectionsByWell(wellId).then(function (data) {
                    $scope.gridOptionsForWell.data = data;
                    wellColumnForWell.filter.selectOptions = getColumnFilters($scope.gridOptionsForWell.data, 'well');
                    WellboreSelectionService.reconstructFilterOptions($scope.gridOptionsForWell.data, wellColumnForWell, wellboreColumnForWell);
                    UIBlocker.stop();
                    StateService.loggedIn = true;
                }, function (status) {
                    UIBlocker.stop();
                    SweetAlert.swal("Error loading wellbores", "I'm sorry, there was an error loading the" +
                        " wellbores: " + status, "error");
                });
            };

            /**
             *  init:
             *
             *      Some setup for the controller/view required when moving to this view.
             */
            var init = function () {

                //check if we have a wellbore id
                if ($stateParams.wellboreId !== undefined && $stateParams.wellboreId !== "") {

                    AuthenticationService.getSessionStatus().then(function (loggedIn) {
                        //check if the user is logged in
                        if (!loggedIn) {
                            //if not, set a pending wellbore and send them to log in
                            StateService.routing.pendingPage = "wellboreDetail";
                            StateService.routing.pendingWellboreId = $stateParams.wellboreId;
                            $state.go("login");
                        }
                        else {
                            var qcPlotsPermission = false;
                            WellboreSelectionService.loadAndSelectWellboreAndParents($stateParams.wellboreId, qcPlotsPermission).finally(function () {
                                //refresh the company, field, pad, and well references
                                refreshCompanyReference();
                                refreshFieldReference();
                                refreshPadReference();
                                refreshWellReference();
                                refreshWellboreReference();
                                checkRigUserStatus();

                                var requests = [];

                                StateService.wellboreDetailView.tabTitle = $scope.selected.well.data.name;
                                document.title = StateService.wellboreDetailView.tabTitle;
                                $scope.msaValidating = false;

                                //load the enums
                                requests.push(loadTrajectoryMetas());
                                requests.push(loadSurveySetMetas());
                                requests.push(checkForNewChat());
                                requests.push(ClientRequisitesService.init());
                                if ($scope.selected.wellbore.data.magneticModelType == "IFR") {
                                    requests.push(loadIfrCubeIds());
                                }

                                $q.all(requests).then(function (responses) {
                                    $scope.loadedEnums = ClientRequisitesService.data;

                                    //disable the tabs for trajectory, and survey set
                                    StateService.wellboreDetailView.selectedTrajectoryId = StateService.wellboreDetailView.selectedSurveySetId = null;
                                    //start at the well tab
                                    $scope.currentMetaTab = $scope.metaTabIndices.wellbore;

                                    autoSelect().then(function () {
                                        qcPlotsPermission = $scope.checkPerm(HierarchyService.SurveySet.Loaded[StateService.wellboreDetailView.selectedSurveySetId], 'QC_PLOTS');

                                        if(AuthenticationService.currentUser().isRigUser && qcPlotsPermission){
                                        WellboreSelectionService.loadAndSelectWellboreAndParents($stateParams.wellboreId, qcPlotsPermission).finally(function () {
                                            //refresh the company
                                            refreshCompanyReference();
                                        });}

                                        if (StateService.wellboreDetailView.selectedTrajectoryId !== null && $scope.isCustomer()) {
                                            $scope.currentMetaTab = $scope.metaTabIndices.trajectory;
                                        }
                                        else if (StateService.wellboreDetailView.selectedSurveySetId !== null) {
                                            $scope.currentMetaTab = $scope.metaTabIndices.surveySet;

                                            //immediately check if there's an msa error to handle
                                            if (!$scope.isCustomer() &&
                                                (_.intersection($scope.selected.surveySet.data.lastSurveyValidationErrors, checkedSurveyValidationErrors).length > 0) ||
                                                ($scope.selected.surveySet.data.unhandledMsaFailure !== null &&
                                                    $scope.selected.surveySet.data.unhandledMsaFailure !== undefined)) {
                                                $scope.handleMsaError();
                                            }

                                        }

                                        if($scope.selected.pad.data) {
                                            loadSelectionsByPad($scope.selected.pad.data.id).then(function () {
                                                UIBlocker.stop();
                                            }, function (error) {
                                                UIBlocker.stop();
                                            });
                                        }
                                        
                                        loadSelectionsByWell($scope.selected.well.data.id).then(function () {
                                            UIBlocker.stop();
                                        }, function (error) {
                                            UIBlocker.stop();
                                        });

                                        startRefreshLoop();

                                    }, function () {
                                        startRefreshLoop();
                                    });
                                }, function (error) {
                                    console.error(error);
                                    $scope.goToWellboreSelection();
                                });
                            });
                        }
                    }, function () {
                        SweetAlert.swal("Error", "Failed to authenticate", "error");
                        $state.go("login");
                    });


                }
                else {
                    //We don't have a wellbore, go choose one
                    $scope.goToWellboreSelection();
                }
            };
            init();

            $scope.$on("$destroy", function (event) {

                StateService.wellboreDetailView = {};

                $interval.cancel($rootScope.refreshId);
                $interval.cancel($scope.flashId);
            });

            $scope.gridOptionsForPad = {
                enableFiltering: true,
                enableGridMenu: false,
                columnDefs: [wellColumnForPad, wellboreColumnForPad],
                data: null,
                multiSelect: false,
                modifierKeysToMultiSelect: false,
                noUnselect: true,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                appScopeProvider: $scope,
                rowTemplate: "<div ng-dblclick=\"grid.appScope.viewWellbore(row.entity)\" " +
                "ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\" class=\"ui-grid-cell\" " +
                "ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader }\" ui-grid-cell></div>",
                onRegisterApi: function (gridApi) {
                    //set gridApi on scope
                    $scope.gridApi = gridApi;

                    $scope.gridApi.core.on.filterChanged($scope, function () {
                        WellboreSelectionService.reconstructFilterOptions($scope.gridOptionsForPad.data, wellColumnForPad, wellboreColumnForPad);
                    });

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        $scope.selectedRowInPad = row.entity;
                    });

                }
            };

            $scope.gridOptionsForWell = {
                    enableFiltering: true,
                    enableGridMenu: false,
                    columnDefs: [wellColumnForWell, wellboreColumnForWell],
                    data: null,
                    multiSelect: false,
                    modifierKeysToMultiSelect: false,
                    noUnselect: true,
                    enableRowSelection: true,
                    enableRowHeaderSelection: false,
                    appScopeProvider: $scope,
                    rowTemplate: "<div ng-dblclick=\"grid.appScope.viewWellbore(row.entity)\" " +
                    "ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\" class=\"ui-grid-cell\" " +
                    "ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader }\" ui-grid-cell></div>",
                    onRegisterApi: function (gridApi) {
                        //set gridApi on scope
                        $scope.gridApi = gridApi;

                        $scope.gridApi.core.on.filterChanged($scope, function () {
                            WellboreSelectionService.reconstructFilterOptions($scope.gridOptionsForWell.data, wellColumnForWell, wellboreColumnForWell);
                        });

                        gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                            $scope.selectedRowInWell = row.entity;
                        });


                    }
            };


            $scope.viewWellbore = function (sel) {
                 // Capture analytics when View Wellbore button is clicked by selecting a well in infrmation tab
                 const eventData = {
                    'well_name': sel.wellName,
                    'button_name': 'View Wellbore'
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_BUTTON_CLICKED, eventData);
                //view
                $window.open("/#/wellboreDetail/" + sel.wellboreId);
            };

            /**
             * Capture event analytics when any meta tab selected in detail view
             * @param {string} tabName Selected tab name
             */
            $scope.captureAnalyticsOnTabSelection = function(tabName) {
                // Capture analytics when any meta tab selected in wellbore detail
                const eventData = {
                    'tab_clicked_name': tabName,
                    'well_name': $scope.selected.well.data.name
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.WELLBORE_DETAIL_TAB_CLICKED, eventData);
            }


        }
    ]);
})();
