(function () {

    var app = angular.module("saphira");

    app.factory("CorrectionService", [
        /* Angular Modules  */ "$http", "$filter", "$q",
        /* 3rd Party Modules*/  "lodash",
        /* Internal Modules */  "UIBlocker",
        /* Input            */

        function (/* Angular Modules  */ $http, $filter, $q,
                  /* 3rd Party Modules*/ lodash,
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var CorrectionService = {};

            CorrectionService.correction = {};

            var getSurveyTypeFromString = function (string) {
                if (string === "POOR") return "D-P";
                if (string === "BAD") return "D-B";
                if (string === "STANDARD") return "D-S";
                if (string === "ACC_CHECK") return "X-A";
                if (string === "CHECKSHOT") return "X-C";
                if (string === "INTERPOLATED") return "X-I";
                return "Unknown";
            };

            var buildModifiedCorrectionFromModifiedValues = function (correction, modifiedValues) {
                var modifiedCorrection = angular.copy(correction);

                modifiedCorrection.bxBiasResolution = modifiedValues.bxBiasType;
                modifiedCorrection.byBiasResolution = modifiedValues.byBiasType;
                modifiedCorrection.bzBiasResolution = modifiedValues.bzBiasType;
                modifiedCorrection.bxScaleResolution = modifiedValues.bxScaleType;
                modifiedCorrection.byScaleResolution = modifiedValues.byScaleType;
                modifiedCorrection.bzScaleResolution = modifiedValues.bzScaleType;
                modifiedCorrection.gxBiasResolution = modifiedValues.gxBiasType;
                modifiedCorrection.gyBiasResolution = modifiedValues.gyBiasType;
                modifiedCorrection.gzBiasResolution = modifiedValues.gzBiasType;
                modifiedCorrection.gxScaleResolution = modifiedValues.gxScaleType;
                modifiedCorrection.gyScaleResolution = modifiedValues.gyScaleType;
                modifiedCorrection.gzScaleResolution = modifiedValues.gzScaleType;
                modifiedCorrection.isMagneticMisalignSet = modifiedValues.isMagneticMisalignManual;
                modifiedCorrection.isGravitationalMisalignSet = modifiedValues.isGravityMisalignManual;
                modifiedCorrection.msaComputeFromRange = modifiedValues.msaComputeFromRange;

                return modifiedCorrection;
            };

            /**
             * This is necessary because a non-null distance measure of null value evaluates to 0 since it uses a primitive double
             * @param msaComputationRange distance range
             * @returns {{}} distance range with endpoints with null values removed.
             */
            var getCleanMsaComputeRange = function(msaComputationRange) {

                var cleanRange = {};

                if(msaComputationRange) {
                    if (msaComputationRange.min && msaComputationRange.min.value) {
                        cleanRange.min = msaComputationRange.min;
                    }
                    else {
                        cleanRange.min = null;
                    }

                    if (msaComputationRange.max && msaComputationRange.max.value) {
                        cleanRange.max = msaComputationRange.max;
                    }
                    else {
                        cleanRange.max = null;
                    }
                }

                return cleanRange;
            };

            CorrectionService.computeMSA = function (surveySet, correctionSelector, minMd, maxMd, correction, modifiedValues, surveysToUpdate) {

                var modifiedCorrection;
                if (correction) {
                    modifiedCorrection = buildModifiedCorrectionFromModifiedValues(correction, modifiedValues);
                }



                var json = {
                    "parentSurveySetId": surveySet.id,
                    "correctionSet": correctionSelector,
                    "startMd": minMd, // Will default to undefined if parameters are ommitted?
                    "endMd": maxMd,
                    "intermediateMultiStationCorrection": modifiedCorrection,
                    "surveysToUpdate": surveysToUpdate,
                    "msaComputeFromRange" : (modifiedCorrection ? modifiedCorrection.msaComputeFromRange : false),
                    "msaComputationRange" : (modifiedCorrection ? getCleanMsaComputeRange(modifiedCorrection.msaComputationRange) : {})
                };

                var req = {
                    method: 'POST',
                    url: '/saphira/api/msa/computeCorrection',
                    headers: {
                        'Content-Type': "application/json"
                    },
                    data: json
                };

                UIBlocker.start();
                return $http(req).then(function (response) {
                    UIBlocker.stop();
                    return response.data;
                }, function (response) {
                    UIBlocker.stop();
                    return $q.reject(response);
                });
            };


            CorrectionService.applyCorrection = function (surveySetId, correctionSelector, minMd, maxMd, biasesAndScales, msaIncOffset, msaAziOffset, persistChanges, msaComputeFromRange, msaComputationRange) {

                var json = {
                    "parentSurveySetId": surveySetId,
                    "correctionSet": correctionSelector,
                    "startMd": minMd, // Will default to undefined if parameters are ommitted?
                    "endMd": maxMd,
                    "multiStationCorrection": biasesAndScales,
                    "msaIncOffset": msaIncOffset,
                    "msaAziOffset": msaAziOffset,
                    "persist": persistChanges,
                    "msaComputeFromRange": msaComputeFromRange,
                    "msaComputationRange": getCleanMsaComputeRange(msaComputationRange)
                };

                UIBlocker.start();
                var deferred = $q.defer();
                $http.post("/saphira/api/msa/applyCorrection", json).then(function (response) {
                    if (response.data === undefined || response.data === "" || response.data.standard.surveys === undefined) {
                        UIBlocker.stop();
                    }
                    else {
                        response.data.standard.surveys = $filter('orderSurveysByMd')(response.data.standard.surveys);
                        response.data.postProcessed.surveys = $filter('orderSurveysByMd')(response.data.postProcessed.surveys);
                        UIBlocker.stop();
                        deferred.resolve(response.data);
                    }
                    deferred.resolve();
                }, function (error) {
                    UIBlocker.stop();
                    deferred.reject(error);
                });
                return deferred.promise;
            };

            return CorrectionService;

        }]);
})();
