(function () {

    var app = angular.module('saphira');

    app.directive('mvMisalignmentResolution', [
        /* Angular Modules  */
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {
            return {
                restrict : 'E',
                scope: {
                    modeModel: '=',
                    q0Model: '=',
                    q1Model: '=',
                    q2Model: '=',
                    q3Model: '=',
                    mvChange: '&'
                },
                replace : true,
                link : function(scope,element,attr) {
                    scope.toggleManualResolutionType = function() {
                        scope.modeModel = !scope.modeModel;
                    };

                    scope.$watch('modeModel', function(newValue, oldValue) {
                        if(newValue !== oldValue) {
                            scope.mvChange();
                        }
                    });
                },
                // input-group-sm
                template : '<div class="input-group">' +
                     '<span class="input-group-btn">' +
                     '  <label ng-class="modeModel ? \'btn-warning\' : \'btn-default\'" class="btn"' +
                     '          ng-click="toggleManualResolutionType()" ng-class="{\'active\' : modeModel}">' +
                     '      <span ng-class="modeModel ? \'color-black\' : \'color-dark-grey\'" class="glyphicon glyphicon-pencil"></span>' +
                     '  </label>' +
                     '</span>' +
                     '<span class="input-group-addon" tooltip-append-to-body="true"' +
                     '      uib-tooltip="The scalar part of the quaternion.">Q0</span>' +
                     '<input numberinputpad type="number" ng-show="modeModel" placeholder="1.0" ng-model="q0Model" ng-readonly="!modeModel" class="form-control manual-input" type="text" ng-change="mvChange()" />' +
                     '<input numberinputpad type="number" ng-hide="modeModel" value="1.0" disabled="true" class="form-control manual-input" type="text" />' +
                     '<span class="input-group-addon" tooltip-append-to-body="true"' +
                     '      uib-tooltip="The first coordinate of the vectorial part of the quaternion.">Q1</span>' +
                     '<input numberinputpad type="number" ng-show="modeModel" placeholder="0.0" ng-model="q1Model" ng-readonly="!modeModel" class="form-control manual-input" type="text"  ng-change="mvChange()"/>' +
                     '<input numberinputpad type="number" ng-hide="modeModel" value="0.0" disabled="true" class="form-control manual-input" type="text" />' +
                     '<span class="input-group-addon" tooltip-append-to-body="true"' +
                     '      uib-tooltip="The second coordinate of the vectorial part of the quaternion.">Q2</span>' +
                     '<input numberinputpad type="number" ng-show="modeModel" placeholder="0.0" ng-model="q2Model" ng-readonly="!modeModel" class="form-control manual-input" type="text"  ng-change="mvChange()"/>' +
                     '<input numberinputpad type="number" ng-hide="modeModel" value="0.0" disabled="true" class="form-control manual-input" type="text" />' +
                     '<span class="input-group-addon" tooltip-append-to-body="true"' +
                     '      uib-tooltip="The third coordinate of the vectorial part of the quaternion.">Q3</span>' +
                     '<input numberinputpad type="number" ng-show="modeModel" placeholder="0.0" ng-model="q3Model" ng-readonly="!modeModel" class="form-control manual-input" type="text"  ng-change="mvChange()"/>' +
                     '<input numberinputpad type="number" ng-hide="modeModel" value="0.0" disabled="true" class="form-control manual-input" type="text" />' +
                     '</div>'
            };

        }]);

})();
