(function () {

    // NOTE: This is a hack to prevent SweetAlert from preventing tabbing from working.
    // Code from comments on: https://github.com/t4t5/sweetalert/issues/127
    var swalCloseFunction = window.swal.close;
    var previousWindowKeyDown = window.onkeydown;
    window.swal.close = function() {
        swalCloseFunction();
        window.onkeydown = previousWindowKeyDown;
    };

    // create our angular module
    var app = angular.module('saphira',
        ['ui.router', 'ui.select', 'ngSanitize', 'ui.bootstrap', "ui.bootstrap.datetimepicker", 'ngLodash',
            'angularjs-dropdown-multiselect', 'ngCookies', 'oitozero.ngSweetAlert', 'ngFileUpload', 'angularSpinner',
            'ngProgress', 'blockUI', 'treeControl', 'ui.bootstrap.contextMenu', 'ui.grid', 'ui.grid.selection',
            'ui.grid.edit', 'ui.grid.rowEdit', 'ui.grid.cellNav', 'ui.grid.autoResize', 'ui.grid.pagination',
            'ui.grid.pinning', 'ui.grid.saveState', 'ui.grid.validate', 'color.picker', 'angularMoment',
            'infinite-scroll', 'color.picker', 'ngTextareaEnter', 'ngIdle']);


    //configure routing
    app.config(function ($stateProvider, $urlRouterProvider, $httpProvider, usSpinnerConfigProvider, blockUIConfig, IdleProvider) {

        blockUIConfig.autoBlock = false;
        IdleProvider.idle(1800); // The time in seconds after which user is considered as idle
        IdleProvider.timeout(0); // Set to 0 to disable the feature of timeout

        $urlRouterProvider.otherwise('/');
        usSpinnerConfigProvider.setDefaults({color: '#323085'});
        $stateProvider
            .state('login', {
                url: '/',
                templateUrl: 'html/views/login/LoginView.html',
                params: {'error': null, 'reason': null},
                controller: 'LoginController'
            })
            .state('wellboreSelection', {
                url: '/wellboreSelection',
                templateUrl: 'html/views/wellboreSelection/WellboreSelectionView.html',
                controller: 'WellboreSelectionController'
            })
            .state('wellboreDetail', {
                url: '/wellboreDetail/{wellboreId}',
                templateUrl: 'html/views/detailedWellbore/WellboreDetailView.html',
                controller: 'WellboreDetailController'
            })
            .state('permissions', {
                url: '/admin/permissions',
                templateUrl: 'html/views/admin/AdminView.html',
                controller: 'AdminController'
            })
            .state('account', {
                url: '/account',
                templateUrl: 'html/views/account/AccountView.html',
                controller: 'AccountController'
            })
            .state('wellboreStatus', {
                url: '/wellboreStatus',
                templateUrl: 'html/views/wellboreStatus/WellboreStatusView.html',
                controller: 'WellboreStatusController'
            })
            .state('diagnostics', {
                url: '/diagnostics',
                templateUrl: 'html/views/diagnostics/DiagnosticsView.html',
                controller: 'DiagnosticsController'
            });
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    });

    app.run(['Idle', '$rootScope', 'AuthenticationService', '$state', function(Idle, $rootScope, AuthenticationService, $state) {
        Idle.watch();

        // Subscribe to State change start event
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
            // Proceed only for login state
            if(toState.name === 'login') {
                AuthenticationService.getSessionStatus().then(function(loggedIn) {
                    //check if the user is logged in
                    if (loggedIn) {
                        if(fromState.name !== "") {
                            $state.go(fromState.name);
                        } else {
                            $state.go("wellboreSelection");
                        };
                    };
                });
            };
        });
    }]);

    /**
     * Add function to Highcharts to allow us to get multiple charts as a single svg.
     * @param charts
     * @returns {string}
     */
    Highcharts.getSVG = function (charts, width, scale) {
        var svgArr = [],
            top = 0,
            scale = scale || 2,
            width = width || 0;

        Highcharts.each(charts, function (chart) {
            var svg = chart.getSVG();
            svg = svg.replace(
                '<svg',
                '<g transform="translate(0,' + top + ') scale(' + scale + ')"'
            );
            svg = svg.replace('</svg>', '</g>');

            top += chart.chartHeight * scale;
            width = Math.max(width, chart.chartWidth);
            svgArr.push(svg);
        });
        return '<svg height="' + top + '" width="' + width * scale +
            '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
            svgArr.join('') + '</svg>';
    };

})();
