/**
 * This service is responsible for pulling data from Springboot's Actuator endpoints (metrics, health, auditevents, logfile)
 */
(function () {

    var app = angular.module('saphira');

    app.factory('HealthService', [
        /* Angular Modules  */ '$http',
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */ $http
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            var HealthService = {};
            var URLS = {
                metrics: "saphira/api/actuator/metrics",
                health: "saphira/api/actuator/health",
                auditEvents: "saphira/api/actuator/auditevents",
                logFile: "saphira/api/actuator/logfile"
            };

            /**
             * This function simply returns our Springboot actuator metrics endpoint.
             */
            HealthService.getMetrics = function () {
                return $http.get(URLS.metrics).then(function (response) {
                    return response.data;
                });
            };

            /**
             * This function simply returns our Springboot actuator health endpoint.
             */
            HealthService.getHealth = function () {
                return $http.get(URLS.health).then(function (response) {
                    return response.data;
                });
            };

            /**
             * This function simply returns our Springboot actuator auditevents endpoint.
             */
            HealthService.getAuditEvents = function () {
                return $http.get(URLS.auditEvents).then(function (response) {
                    return response.data;
                });
            };

            /**
             * This function uses the Springboot actuator logfile endpoint and returns the last ~200KB of the Saphira logfile.
             */
            HealthService.getLogFile = function () {
                var fileSize;
                var returnSize = 200000; //Sets the size in bytes of logfile data to return from the endpoint. Kept low as logfiles can be up to 10MB.

                return $http.head(URLS.logFile).then(function (response) {
                    fileSize = response.headers("content-length");
                    if (fileSize < returnSize) {
                        returnSize = fileSize;
                    }

                    return $http.get(URLS.logFile, {
                        // Only return the last ~200KB of data
                        headers: {'Range': 'bytes=' + (fileSize-returnSize) + '-' + fileSize}
                    })
                        .then(function (response) {
                            return response.data;
                        });
                });
            };

            return HealthService;
        }]);
})();