/**
 * This controller is responsible for authenticating and redirecting the user
 *
 * Since all other pages are views within the index, the index controller is constantly running.
 */
(function () {

    var app = angular.module('saphira');

    app.controller("IndexController", [
        /* Angular Modules  */ "$scope", "$rootScope", "$state", "$q", "$interval", "$cookies",
        /* Internal Modules */  "AuthenticationService", "PingService", "StateService", "ThemeService",
        "UIBlocker", 'CaptureAndSendAnalyticsService', 'Constants',
        function (/* Angular Modules  */ $scope, $rootScope, $state, $q, $interval, $cookies,
                  /* Internal Modules */ AuthenticationService, PingService, StateService,
                  ThemeService, UIBlocker, CaptureAndSendAnalyticsService, Constants) {

            var pendingPing =false;

            $scope.acct = AuthenticationService;
            $scope.State = StateService;
            $scope.uiBlocker = UIBlocker;

            $scope.version = '2.10';

            /**
             * Redirects the user to the wellbore selection page (our 'home' page)
             */
            $scope.goHome = function() {
                $state.go('wellboreSelection');
            };

            var initColors = function () {
                ThemeService.initColors();
            };
            initColors();

            var timeout = 15000;
            $rootScope.ping = 0;
            /**
             * ping:
             *
             *      Tests the latency between the client and server
             *
             * @returns {*|Document.promise|k.promise|{then, catch, finally}|r.promise|Function}
             */
            var ping = function () {
                //console.log("Pinging the server");
                return PingService.ping().then(function () {
                    $scope.serverStatus = PingService.getStatus();
                    $scope.serverLatency = PingService.getLatency();
                    $scope.serverError = PingService.getError();

                    return $q.resolve();
                }, function(error) {
                    return $q.reject();
                });
            };

            /**
             * Initialize google analytics script
             * @description appends gtag to document
             */
            var initGoogleAnalytics = function() {
                try {
                    // we need to add these 2 script tags dynamically for GA4 gtag.js to run
                    const measurementId = window['__application_properties__']['GA4_MEASUREMENT_ID'];
                    // script Tag 1
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    script.src =
                    'https://www.googletagmanager.com/gtag/js?id=' + measurementId;
                    document.head.appendChild(script);

                    // script tag 2
                    const script2 = document.createElement('script');
                    script2.innerHTML = `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}gtag('js', new Date());
                    gtag('config', '${measurementId}');`;
                    document.head.appendChild(script2);
                    console.log('Setting GTAG_ID to :' + measurementId);
                } catch(e) {
                    console.log('Error setting up analytics' + e.toString());
                }
            };

            /**
             * Sets the Content-Security-Policy to the document
             * @description Dynamically adds a CSP meta tag to the document based on
             * environment-specific settings.
             */
            const setContentSecurityPolicy = function () {
                const appProperties = window.__application_properties__;
                if (appProperties) {
                    // Create a new meta tag element for Content-Security-Policy.
                    const cspMetaTag = document.createElement('meta');
                    cspMetaTag.setAttribute('http-equiv', 'Content-Security-Policy');

                    // Set the 'content' attribute with the actual CSP rules.
                    // These rules are constructed dynamically using the values from appProperties.
                    cspMetaTag.setAttribute('content', `
                      script-src 'self' 'unsafe-inline' 'unsafe-eval' ${appProperties.APP_CSP_SCRIPT_SRC};
                      style-src 'self' 'unsafe-inline';
                      img-src 'self' ${appProperties.APP_CSP_IMG_SRC};
                      connect-src 'self' ${appProperties.APP_CSP_CONNECT_SRC};
                      font-src 'self' ${appProperties.APP_CSP_FONT_SRC};
                      object-src 'none';`);

                    // Append the newly created meta tag to the document's <head> section.
                    document.head.appendChild(cspMetaTag);
                }
            };


            /**
             * startTimer:
             *
             *      The heartbeat of the client that continually pings the server
             */
            var startTimer = function () {
                $rootScope.indexRefreshID = $interval(function () {
                    if(!pendingPing) {
                        pingLoop();
                    }
                }, timeout);
            };

            var pingLoop = function() {
                pendingPing = true;
                ping().finally(function() {
                    pendingPing =false;
                });
            };

            /**
             * This code starts the pinging
             */
            ping().finally(function () {
                startTimer();
            });

            // Initializes the google analytics scripts
            initGoogleAnalytics();

            //  Set the Content Security Policy (CSP)
            setContentSecurityPolicy();

            /**
             * Clear timer on scope destruction event
             */
            $scope.$on("$destroy", function (event) {
                $interval.cancel($rootScope.indexRefreshID);
            });
            /**
             * Checks whether any of the menu items in the named category should be visible.
             *
             * @param {String} category     Name of the category to check.
             * @return {Boolean}            True if the user should see any of those options.
             */
            $scope.menuAny = function (category) {
                if (category === "any") {
                    return false;
                }
                var can = false;
                angular.forEach($scope.menu[category], function (value, index) {
                    can = can || value.visible();
                });
                return can;
            };

            var captureEvent = function(pageSelected) {
                 // Capture analytics when navigated to Account/Wellbore Selection page from header
                 const eventData = {
                    'page_clicked_name': pageSelected
                };
                CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.HEADER_PAGE_CLICKED, eventData);
            };

            /*
             * Contains the dropdown menu. Categories are fluff, but similar functionality should be grouped
             * so they are shown between divider lines. On any menu item, "href" will default to "#", so only
             * one of href or action need be set.
             */

            $scope.menu = {
                "common": {
                    // This isn't a page that should be exposed to normal users. In the future we'll put an actual account management page up.
                    /*                "Account": {
                     "visible": function () { return true; },
                     "href": "#/main"
                     },*/
                    "Logout": {
                        "visible": function () {
                            return true;
                        },
                        "action": AuthenticationService.logout
                    },
                    "Account": {
                        "visible": function () {
                            return true;
                        },
                        "href": "#/account",
                        "action": captureEvent
                    },
                     "Admin View": {
                        "visible": function () {
                            if (AuthenticationService.currentUser() !== null &&
                                (!AuthenticationService.currentUser().isCustomer ||
                                AuthenticationService.currentUser().isSuperAdmin)) {
                                return true;
                            }
                            return false;
                        },
                        "href": "#/admin/permissions"
                    }


                },
                "admin": {

                   "Wellbore Selection": {
                        "visible": function () {
                            return true;
                        },
                        "href": "#/wellboreSelection",
                        "action": captureEvent
                    },

                    "Data View": {
                        "visible": function () {
                            if (AuthenticationService.currentUser() !== null &&
                                (!AuthenticationService.currentUser().isCustomer ||
                                AuthenticationService.currentUser().isSuperAdmin)) {
                                return false;
                            }
                            return false;
                        },
                        "href": "#/data"
                    },
                    "Wellbore Status": {
                        "visible": function () {
                            if (AuthenticationService.currentUser() !== null &&
                                (!AuthenticationService.currentUser().isCustomer ||
                                AuthenticationService.currentUser().isSuperAdmin)) {
                                return true;
                            }
                            return false;
                        },
                        "href": "#/wellboreStatus"
                    }
                }
            };
        }
    ]);
})();
