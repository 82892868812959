(function () {
    var app = angular.module('saphira');

    app.factory("CompanySnapshotService", [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/ 'Upload',
        /* Internal Modules */ 'DataService', 'UIBlocker',
        /* Input            */

        function (/* Angular Modules   */ $http, $q,
                  /* 3rd Party Modules */ Upload,
                  /* Internal Modules  */ DataService, UIBlocker
                  /* Input             */) {

            var service = {};

            var URLS = {
                getCompanySnapshot: '/saphira/api/utils/getCompanySnapshot',
                restoreFromCompanySnapshot: '/saphira/api/utils/restoreFromCompanySnapshot'
            };

            /**
             * getCompanySnapshot:
             *
             *      Tells the server to generate a snapshot for the company and returns it to the client.
             *
             * @param companyId The id of the company to create a snapshot for
             */
            service.getCompanySnapshot = function (companyId) {

                UIBlocker.start();

                //get the id of the snapshot
                return $http.get(URLS.getCompanySnapshot, {"params": {"companyId": companyId}}).then(function (response) {

                    //use the id to download the snapshot
                    DataService.downloadAttachment("Company", companyId, response.data).then(function () {
                        UIBlocker.stop();
                    });

                }, function (error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };

            /**
             * restoreFromCompanySnapshot:
             *
             *      Given a company snapshot file, sends it to the server to create it.
             *
             * @param companyFile   The company snapshot file
             * @returns {Promise}
             */
            service.restoreFromCompanySnapshot = function (companyFile) {

                var deferred = $q.defer();

                //make sure the file exists
                if (companyFile === undefined || companyFile === null) {
                    deferred.reject("File reference not found.")
                }

                UIBlocker.start();
                Upload.upload({
                    url: URLS.restoreFromCompanySnapshot,
                    method: 'POST',
                    file: companyFile,
                    fileFormDataName: 'file'
                }).then(function (response) {
                    UIBlocker.stop();
                    swal("Success!", "Upload of Company Snapshot: " + companyFile.name + " succeeded!", "success");
                    deferred.resolve(response);
                }, function (response) {
                    UIBlocker.stop();
                    deferred.reject(response);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;

            };

            return service;

        }]);
})();