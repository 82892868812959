/**
 * FileTemplateService:
 *
 *      Responsible for:
 */
(function () {
    var app = angular.module('saphira');

    app.factory("FileTemplateService", [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules   */
                  /* 3rd Party Modules */
                  /* Internal Modules  */
                  /* Input             */) {

            var service = {};

            return service;

        }]);
})();