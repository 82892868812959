/*
 * Created by Luke on 29 December 2015
 */

(function () {

    var app = angular.module("saphira");

    app.controller("LoginController", [
        /* Angular Modules  */ "$scope", "$state", "$stateParams",
        /* 3rd Party Modules*/ "SweetAlert",
        /* Internal Modules */ "AuthenticationService", "UIBlocker", "StateService", "ClientRequisitesService",
                                "CaptureAndSendAnalyticsService", "Constants",
        /* Input            */

        function (/* Angular Modules  */ $scope, $state, $stateParams,
                  /* 3rd Party Modules*/ SweetAlert,
                  /* Internal Modules */ AuthenticationService, UIBlocker, StateService, ClientRequisitesService,
                                        CaptureAndSendAnalyticsService, Constants
                  /* Input            */) {

            $scope.login = {
                username: "",
                password: ""
            };

            //This checks the params passed into the state. If this is the first time we hit the login page, the state params should be null.
            //The only other way we can get here is if we logged out somehow, in which case, we expect there to be both an error param and a reason param
            //From these, we display a certain type of error using the SweetAlert library. cooL!
            if ($stateParams.reason !== null) {
                if ($stateParams.error) {
                    SweetAlert.swal("Logout Unsuccessful", $stateParams.reason, "error");
                } else {
                    SweetAlert.swal("Logout Successful", $stateParams.reason, "success");
                }
            }

            $scope.loginError = "";

            $scope.handleLogin = function () {
                UIBlocker.start();
                $scope.loginError = "";
                AuthenticationService.login($scope.login).then(function (user) {
                    UIBlocker.stop();
                    // Capture analytics when User logs in
                    const eventData = {
                        'login_user_SaphiraDirectory': Constants.saphiraDirectoryId[user.principal.directoryId],
                        'login_user_SaphiraGroup': user.roles[0].replace(/ROLE_/, '')
                    };
                    CaptureAndSendAnalyticsService.captureAnalytics(Constants.eventNames.LOGIN_ROLE, eventData);

                    ClientRequisitesService.init().then(function() {

                        if(StateService.routing.pendingPage !== null) {
                            var routing = StateService.routing;
                            StateService.resetRouting();
                            switch(routing.pendingPage) {

                                case "wellboreDetail":
                                    $state.go("wellboreDetail", {wellboreId: routing.pendingWellboreId});
                                    break;
                                case "permissions" :
                                    $state.go("permissions");
                                    break;
                                case "wellboreSelection" :
                                    $state.go("wellboreSelection");
                                    break;
                                default:
                                    $state.go("wellboreSelection");
                            }
                        }
                        else {
                            //else go to wellbore selection
                            $state.go("wellboreSelection");
                        }
                    });

                }, function (error) {
                    UIBlocker.stop();
                    $scope.loginError = error;
                });
            };

            var init = function() {
                document.title = "Saphira";
            }
            init();
        }]);

})();