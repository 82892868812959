var app = angular.module('saphira');
app.constant('Constants', {
    // Event names
    eventNames: {
        LOGIN_ROLE: 'LOGIN_role',
        HEADER_PAGE_CLICKED: 'HEADER_pageClicked',
        WELLBORE_SELECTION_TAB_CLICKED: 'WELLBORE_SELECTION_tabClicked',
        WELLBORE_SELECTION_BUTTON_CLICKED: 'WELLBORE_SELECTION_buttonClicked',
        WELLBORE_DETAIL_TAB_CLICKED: 'WELLBORE_DETAIL_tabClicked',
        WELLBORE_DETAIL_GENERATE_REPORT: 'WELLBORE_DETAIL_generateReport',
        WELLBORE_DETAIL_BUTTON_CLICKED: 'WELLBORE_DETAIL_buttonClicked',
    },
    // Saphira user group based on SaphiraDirectoryId
    saphiraDirectoryId: {
        655367: 'CONTRACTOR',
        655365: 'CUSTOMERS',
        2883585: 'EXT_TEST_SSAPI',
        655363: 'MAGVAR',
        32769: 'MAGVAR_ADMIN',
        655364: 'SURCON',
        655362: 'SURCON_ADMIN',
        655361: 'TEST_DIRECTORY'
    }
});