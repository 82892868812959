/**
 *  The service responsible for submitting surveys
 */
(function () {

    var app = angular.module('saphira');

    app.factory('SurveyService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */ 'UIBlocker', 'ClientRequisitesService',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker, ClientRequisitesService
                  /* Input            */) {

            var SurveyService = {};

            //a list of urls used by the service
            var URLS = {
                save: '/saphira/api/repositories/survey/save',
                add: '/saphira/api/repositories/survey/add',
                simpleUpdates: '/saphira/api/repositories/survey/simpleUpdates',
                overrideUpdates: '/saphira/api/repositories/survey/overrideUpdates',
                recompute: '/saphira/api/repositories/survey/recompute',
                create: '/saphira/api/repositories/survey/create',
                createTieIn: '/saphira/api/repositories/survey/createTieIn',
                getBySurveySet: '/saphira/api/repositories/survey/getBySurveySet',
                delete: '/saphira/api/repositories/survey/delete'
            };

            var TYPE1_ORIENTATION = "TYPE1";

            //the loaded surveys (map indexed 'usually' by parent survey set)
            SurveyService.Loaded = {};

            var reorientTypeOneSixAxisDataArray = function(sixAxisData, newOrientation) {
                if(newOrientation.name == TYPE1_ORIENTATION) {
                    sixAxisData.orientedB = sixAxisData.b;
                    sixAxisData.orientedG = sixAxisData.g;
                } else {
                    sixAxisData.orientedB = [Number(newOrientation.bxSign * sixAxisData.b[newOrientation.bxIndex]),
                                newOrientation.bySign * sixAxisData.b[newOrientation.byIndex],
                                newOrientation.bzSign * sixAxisData.b[newOrientation.bzIndex]];
                    sixAxisData.orientedG = [newOrientation.gxSign * sixAxisData.g[newOrientation.gxIndex],
                                newOrientation.gySign * sixAxisData.g[newOrientation.gyIndex],
                                newOrientation.gzSign * sixAxisData.g[newOrientation.gzIndex]];
                }
                return sixAxisData;
            };

            var deorientSixAxisDataArrayToTypeOne = function(sixAxisData, currentOrientation) {
                // Prevents an MSA corrected survey from losing sixAxis data on saving via overrideUpdates()
                if (sixAxisData.orientedB == null || sixAxisData.orientedG == null) {
                    return sixAxisData;
                }

                if(currentOrientation.name == TYPE1_ORIENTATION) {
                    sixAxisData.b = sixAxisData.orientedB;
                    sixAxisData.g = sixAxisData.orientedG;
                } else {
                    sixAxisData.b[currentOrientation.bxIndex] = currentOrientation.bxSign * sixAxisData.orientedB[0];
                    sixAxisData.b[currentOrientation.byIndex] = currentOrientation.bySign * sixAxisData.orientedB[1];
                    sixAxisData.b[currentOrientation.bzIndex] = currentOrientation.bzSign * sixAxisData.orientedB[2];
                    sixAxisData.g[currentOrientation.gxIndex] = currentOrientation.gxSign * sixAxisData.orientedG[0];
                    sixAxisData.g[currentOrientation.gyIndex] = currentOrientation.gySign * sixAxisData.orientedG[1];
                    sixAxisData.g[currentOrientation.gzIndex] = currentOrientation.gzSign * sixAxisData.orientedG[2];
                }

                return sixAxisData;
            };

            var deorientSixAxisSurveys = function(surveys, currentOrientation) {
                // This expect's the user to be inputting values based on the SurveySet's displayed toolType/Sensor Orientation
                return getOrientationByName(currentOrientation).then(function(orientation) {
                    var updatedSurveys = [];
                    surveys.forEach(function(survey, index){
                        if(survey.rawSixAxis !== null && survey.rawSixAxis !== undefined) {
                            survey.rawSixAxis = deorientSixAxisDataArrayToTypeOne(survey.rawSixAxis, orientation);
                        }
                        if(survey.correctedSixAxisDatas.MULTISTATION !== null && survey.correctedSixAxisDatas.MULTISTATION !== undefined) {
                            survey.correctedSixAxisDatas.MULTISTATION = deorientSixAxisDataArrayToTypeOne(survey.correctedSixAxisDatas.MULTISTATION, orientation);
                        }

                        updatedSurveys.push(survey);
                    });
                    return $q.resolve(updatedSurveys);
                }, function(error) {
                    var errorMessage = "Failed to deorient surveys from orientation type: " + currentOrientation + ".";
                    console.error(errorMessage);
                    return $q.reject(error);
                });
            };

            var getOrientationByName = function(orientationName) {
                var orientations = ClientRequisitesService.data.ExpandedSensorOrientations;
                if(orientations[orientationName] !== null && orientations[orientationName] !== undefined) {
                    return $q.resolve(orientations[orientationName]);
                } else {
                    var error = {};
                    error.data = "Failed to locate expanded orientation by name: " + orientationName;
                    console.error(error.data);
                    return $q.reject(error);
                }
            };

            /**
             * save:
             *
             *      Saves the changes to the given survey to the database.
             *
             * @param saphiraSurvey     The survey to save.
             *
             * @param parentSurveySetId
             * @param parentTrajectoryId
             * @returns {Promise}
             */
            SurveyService.save = function (saphiraSurvey, parentSurveySetId, parentTrajectoryId) {
                UIBlocker.start();

                return $http.post(URLS.save, saphiraSurvey, {params: {parentSurveySetId: parentSurveySetId, parentTrajectoryId: parentTrajectoryId}}).then(function (result) {
                    UIBlocker.stop();
                    return saphiraSurvey;
                }, (function(error) {
                    UIBlocker.stop();
                    return error;
                }));
            };

            /**
             * add:
             *
             *      Adds the given survey to the database
             *
             * @param saphiraSurvey     The survey to add
             *
             * @returns {*}
             */
            SurveyService.add = function (saphiraSurvey) {
                // This method will not need de-orienting beause it's input is expected to be the type1 output of the 'create()' method.
                var deferred = $q.defer();
                UIBlocker.start();
                $http.post(URLS.add, saphiraSurvey).then(function (result) {
                    UIBlocker.stop();
                    deferred.resolve(saphiraSurvey);
                }, function(error) {
                    UIBlocker.stop();
                    deferred.reject(error);
                });
                return deferred.promise;
            };

            SurveyService.recompute = function(parentSurveySetId) {
                UIBlocker.start();
                return $http.post(URLS.recompute + "/" + parentSurveySetId).then(function() {
                    UIBlocker.stop();
                    return $q.resolve();
                }, function(error) {
                    UIBlocker.stop();
                    return $q.reject(error);
                });
            };


            /**
             * This function is a wrapper around our 'simple update' functionality where the user can submit changes to the basic data (md, inc, azi, 6-axis)
             * @param  {JS Array} surveys      The survey's we're updating
             * @param  {String} parentSurveySetId the ID of the parent survey set.
             */
            SurveyService.simpleUpdates = function (surveys, parentSurveySetId, currentOrientation) {
                var params = {
                    parentSurveySetID: parentSurveySetId
                };

                return deorientSixAxisSurveys(surveys, currentOrientation).then(function(deorientedSurveys) {
                    return $http.put(URLS.simpleUpdates, deorientedSurveys, {params: params});
                }, function(error) {
                    console.error("Failed to deorient surveys from orientation: " + currentOrientation + ". Cannot update surveys from SurveyService.simpleUpdates()!");
                    return $q.reject(error);
                });
            };

            /**
             * This function is a wrapper around our 'override update' functionality where the user can submit changes even to the calculated values.
             * @param  {JS Array} surveys    The surveys the server needs
             * @param  {String} parentSurveySetId the ID of the parent survey set.
             */
            SurveyService.overrideUpdates = function (surveys, parentSurveySetId, currentOrientation) {
                var params = {
                    parentSurveySetID: parentSurveySetId
                };

                return deorientSixAxisSurveys(surveys, currentOrientation).then(function(deorientedSurveys) {
                    return $http.put(URLS.overrideUpdates, deorientedSurveys, {params: params});
                }, function(error) {
                    console.error("Failed to deorient surveys from orientation: " + currentOrientation + ". Cannot update surveys from SurveyService.overrideUpdates()!");
                    return $q.reject(error);
                });
            };

            /**
             * create:
             *
             *      Creates the survey on the server, along with getting validated and filling in reference values.
             *
             * @param validatableSurvey
             *
             * @returns {*}
             */
            SurveyService.create = function (validatableSurvey, surveySetId, surveySetOrientation) {
                var survey = validatableSurvey;
                survey.surveySetId = surveySetId;

                if (survey.submittedTime === undefined) {
                    survey.submittedTime = null;
                }

                UIBlocker.start();
                return $http.post(URLS.create, survey).then(function (result) {
                    UIBlocker.stop();
                    return result.data;
                }, function (result) {
                    UIBlocker.stop();
                    return $q.reject(result); //Reject the promise
                });
            };

            /**
             * delete:
             *
             *      Deletes the surveys on the given parent survey set
             *
             * @param parentSurveySetId
             * @param surveysToDelete
             *
             * @returns {HttpPromise}
             */
            SurveyService.delete = function (parentSurveySetId, surveysToDelete) {
                return $http.post(URLS.delete, {
                    parentSurveySetId: parentSurveySetId,
                    surveysToDelete: surveysToDelete
                });
            };

            /**
             * createTieIn:
             *
             *      Creates the survey tie-in on the server, with no validation or grabbing of reference values.
             *
             * @param validatableSurvey
             * @returns {*}
             */
            SurveyService.createTieIn = function (validatableSurvey) {
                UIBlocker.start();
                return $http.post(URLS.createTieIn, validatableSurvey).then(function (result) {
                    UIBlocker.stop();
                    return result.data;
                }, function (response) {
                    UIBlocker.stop();
                    return $q.reject(response);
                });
            };

            var computeDeltas = function (survey) {

                //reported / calculated
                survey.incCalcReportedDelta = subtractMeasures(survey.incCalc, survey.incRep);
                survey.aziCalcReportedDelta = subtractMeasures(survey.aziCalc, survey.aziRep);

                //corrected
                if (survey.correctedDatas.MULTISTATION) {
                    survey.incCorrReportedDelta = subtractMeasures(survey.correctedDatas.MULTISTATION.inclination, survey.incRep);
                    survey.aziCorrReportedDelta = subtractMeasures(survey.correctedDatas.MULTISTATION.azimuth, survey.aziRep);
                }
            };

            var subtractMeasures = function (m1, m2) {

                //validate inputs
                if (m1 === undefined || m2 === undefined || m1.unit !== m2.unit || isNaN(m1.value) || isNaN(m2.value)) {
                    if(m1 !== undefined || m2 !== undefined) {
                        console.error('Failed to subtract two measures: ', m1, m2);
                    }
                    return {
                        value: 'n/a',
                        unit: 'n/a'
                    };
                }

                return {
                    value: m1.value - m2.value,
                    unit: m1.unit
                };
            };

            /**
             * getBySurveySet:
             *
             *      Gets the surveys that belong to a survey set by the survey set's id.
             *
             * @param surveySetId
             * @param forceUpdate
             *
             * @returns {*}
             */
            SurveyService.getBySurveySet = function (surveySetId, orientationName, forceUpdate) {
                if (orientationName === undefined) {
                    orientationName = "TYPE1";
                }
                UIBlocker.start();
                if (forceUpdate !== true && surveySetId in SurveyService.Loaded) {
                    UIBlocker.stop();
                    return $q.resolve(SurveyService.Loaded[surveySetId]);
                } else {
                    return $http.get(URLS.getBySurveySet, {'params': {'surveySetId': surveySetId}}).then(function (response) {

                        if(orientationName !== null) {
                            return getOrientationByName(orientationName).then(
                                function (orientation) {
                                    response.data.forEach(function (survey, index) {
                                        if (survey.rawSixAxis !== null && survey.rawSixAxis !== undefined) {
                                            survey.rawSixAxis =
                                                reorientTypeOneSixAxisDataArray(survey.rawSixAxis, orientation);
                                        }
                                        if (survey.correctedSixAxisDatas.MULTISTATION !== null &&
                                            survey.correctedSixAxisDatas.MULTISTATION !== undefined) {
                                            survey.correctedSixAxisDatas.MULTISTATION = reorientTypeOneSixAxisDataArray(
                                                survey.correctedSixAxisDatas.MULTISTATION, orientation);
                                        }

                                        computeDeltas(survey);
                                    });
                                    UIBlocker.stop();
                                    SurveyService.Loaded[surveySetId] = response.data;
                                    return $q.resolve(response.data);
                                }, function (error) {
                                    UIBlocker.stop();
                                    SurveyService.Loaded[surveySetId] = {};
                                    response.data = {};
                                    var errorMessage = "Failed to retrieve orientation by name: " + orientationName +
                                        ". Cannot orient surveys for SurveySet with Id: " + surveySetId;
                                    console.error(errorMessage);
                                    return $q.reject(errorMessage);
                                }
                            );
                        }
                        else {
                            UIBlocker.stop();
                            return $q.resolve(response.data);
                        }
                    }, function (response) {
                        UIBlocker.stop();
                        return $q.reject(response.status + " " + response.status.message);
                    });
                }
            };

            return SurveyService;

        }]);
})();
