(function () {

    var app = angular.module('saphira');

    app.service('WellboreStatusService', [
        /* Angular Modules  */ '$q', '$http', '$filter',
        /* 3rd Party Modules*/ 'uiGridConstants',
        /* Internal Modules */ 'TimeService',
        /* Input            */
        function (/* Angular Modules  */ $q, $http, $filter,
                  /* 3rd Party Modules*/ uiGridConstants,
                  /* Internal Modules */ TimeService
                  /* Input            */) {

            var service = {};

            /**
             * Dropdown select options for the automation column
             */
            var automationActiveOptions = [
                {value: 'On', label: 'On'},
                {value: 'Off', label: 'Off'}
            ];

            var hasNewFileOptions = [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'}
            ];

            var hasNewChatOptions = [
                {value: true, label: "Yes"},
                {value: false, label: "No"}
            ];

            /**
             * Helper function for parsing survey validation errors.
             *
             * @param validationErrors
             * @returns {*}
             */
            var formatSurveyErrors = function (validationErrors) {

                if (validationErrors && validationErrors.length > 0) {
                    var surveyErrors = "";
                    for (var j = 0; j < validationErrors.length; j++) {
                        surveyErrors += validationErrors[j];
                        if (j < validationErrors.length - 1) {
                            surveyErrors += ", ";
                        }
                    }
                    return surveyErrors;
                }
                else {
                    return "None";
                }
            };

            /**
             * Helper function to treat a chat message as a notification
             *
             * @param statusPojo
             */
            var addChatNotification = function (statusPojo) {
                if (!statusPojo.notifications) {
                    statusPojo.notifications = {};
                }

                statusPojo.notifications.NEW_CHAT_MESSAGE = {
                    timeCreated: statusPojo.chat.time
                };
            };

            var backgroundCellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
              
                if (((row.entity.hasNewFile  || row.entity.hasUncorrectedSurvey) && !row.entity.hasFullAutomationNotification)
                    || row.entity.hasMsaError) {
                    return 'redBackground';
                }
                else if (row.entity.hasNewChat) {
                    return 'yellowBackground';
                }
                else if(row.entity.hasNewSuccessfulSurvey) {
                    return 'greenBackground'
                }
            };

            /**
             * getColumns:
             *
             *      Returns the column defs used by uigrid to display the table.
             */
            service.getColumns = function () {
                return [
                    {
                        field: 'name',
                        enableHiding : false,
                        filter: {type: uiGridConstants.filter.INPUT, selectOptions: []},
                        width: "25%",
                        cellTemplate: 'html/views/wellboreStatus/NameCellTemplate.html',
                        cellClass: backgroundCellClass
                    },
                    {
                        field: 'hasNewFile',
                        filter: {type: uiGridConstants.filter.SELECT, selectOptions: hasNewFileOptions},
                        width: "7%",
                        cellTemplate: 'html/views/wellboreStatus/HasNewFileCellTemplate.html',
                        cellClass: backgroundCellClass
                    },
                    {
                        field: 'hasNewChat',
                        filter: {type: uiGridConstants.filter.SELECT, selectOptions: hasNewChatOptions},
                        width: "7%",
                        cellTemplate: 'html/views/wellboreStatus/HasNewChatCellTemplate.html',
                        cellClass: backgroundCellClass
                    },
                    {
                        field: 'automation',
                        sort: {
                            direction: uiGridConstants.DSC
                        },
                        filter: {type: uiGridConstants.filter.SELECT, selectOptions: automationActiveOptions},
                        width: "7%",
                        cellTemplate: 'html/views/wellboreStatus/AutomationCellTemplate.html',
                        cellClass: backgroundCellClass
                    },
                    {
                        field: 'surveyErrors',
                        width: "8%",
                        cellClass: backgroundCellClass
                    },
                    {
                        field: 'latestActivity',
                        sort: {
                            direction: uiGridConstants.ASC
                        },
                        cellFilter: 'TimeAgoFilter',
                        cellClass: backgroundCellClass,
                        enableFiltering: false,
                        width: "9%",
                        sortingAlgorithm: TimeService.SortByMoment()
                    },
                    {
                        field: 'lastFile',
                        sort: {
                            direction: uiGridConstants.ASC
                        },
                        cellFilter: 'TimeAgoFilter',
                        cellClass: backgroundCellClass,
                        enableFiltering: false,
                        width: "7%",
                        sortingAlgorithm: TimeService.SortByMoment()
                    },
                    {
                        field: 'lastCorr',
                        sort: {
                            direction: uiGridConstants.ASC
                        },
                        cellFilter: 'TimeAgoFilter',
                        cellClass: backgroundCellClass,
                        enableFiltering: false,
                        width: "7%",
                        sortingAlgorithm: TimeService.SortByMoment()
                    },
                    {
                        field: 'lastChat',
                        sort: {
                            direction: uiGridConstants.ASC
                        },
                        cellFilter: 'TimeAgoFilter',
                        cellClass: backgroundCellClass,
                        enableFiltering: false,
                        width: "7%",
                        sortingAlgorithm: TimeService.SortByMoment()
                    },
                    {
                        field: 'lastChatMessage',
                        enableSorting: false,
                        cellTemplate: 'html/views/wellboreStatus/ChatMessageCellTemplate.html',
                        cellClass: backgroundCellClass
                    }
                ]
            };

            /**
             * formatDataForTable:
             *
             *      Converts the data from the format given by the server to the format expected by uigrid
             *
             * @param data      The data from the rest endpoint containing an array of wellbore statuses
             * @returns {Array} The data in a format usable by uigrid
             */
            service.formatDataForTable = function (data) {

                var formattedData = [];

                //iterate over the status data from the server
                for (var i = 0; i < data.length; i++) {

                    //get the server-formatted status
                    var statusPojo = data[i];
                    statusPojo.hasFlag = function(flag) {
                        return this.flags && this.flags.includes(flag);
                    };

                    //start created the ui-grid friendly status
                    var formatted = {
                        name: statusPojo.name,
                        automation: $filter('BooleanOnOffFilter')(statusPojo.automation),
                        wellboreId: statusPojo.wellboreId
                    };

                    formatted.hasNewFile = statusPojo.hasFlag("NEW_FILE");
                    formatted.hasNewChat = statusPojo.hasFlag("NEW_CHAT");
                    formatted.hasMsaError = statusPojo.hasFlag("NEW_MSA_VALIDATION_ERROR");
                    formatted.hasUncorrectedSurvey = statusPojo.hasFlag("NEW_UNCORRECTED_SURVEY");
                    formatted.hasNewSuccessfulSurvey = statusPojo.hasFlag("NEW_SUCCESSFUL_SURVEY");
                    formatted.hasFullAutomationNotification = statusPojo.hasFlag("NEW_SURVEY_AUTOMATION_NOTIFICATION_ACTIVE");

                    //if there are validation errors, properly format them and display them
                    formatted.surveyErrors = formatSurveyErrors(statusPojo.surveyValidationErrors);

                    if (statusPojo.automation && statusPojo.automateCorrection) {
                        formatted.automateCondition = true;
                        formatted.automateConditionText = 'Corrections are being applied.';
                        formatted.automationConditionText = 'Full automation is active.';
                    }

                    if (statusPojo.automation && !statusPojo.automateCorrection) {
                        formatted.automationConditionText = 'Partial automation is active.';
                    }

                    if (!statusPojo.automation && statusPojo.automateCorrection) {
                        formatted.automateCondition = true;
                        formatted.automateConditionText = 'Corrections are being applied.';
                        formatted.automationConditionText = 'Full automation is inactive.';
                    }

                    if (!statusPojo.automation && !statusPojo.automateCorrection) {
                        formatted.automationConditionText = 'Partial automation is inactive.';
                    }

                    if (statusPojo.manualCorrectionParameterActive) {
                        formatted.manualCondition = true;
                        formatted.manualConditionText = 'A manual correction parameter is being applied.';
                    }


                    //if there was a chat message
                    if (statusPojo.chat) {
                        //prepend the message with the sender and get a proper moment timestamp
                        formatted.lastChatMessage = statusPojo.chat.sender + ": " + statusPojo.chat.message;
                        formatted.lastChat = TimeService.GetMomentFromInstant(statusPojo.chat.time);

                        //add chat notification
                        addChatNotification(statusPojo);
                    }

                    //if we have notifications
                    if (statusPojo.notifications && Object.keys(statusPojo.notifications).length > 0) {

                        //format the last file and last correction
                        if (statusPojo.notifications.FILE_UPLOAD_SUCCESS) {
                            formatted.lastFile = TimeService.GetMomentFromInstant(statusPojo.notifications.FILE_UPLOAD_SUCCESS.timeCreated);
                        }
                        if (statusPojo.notifications.MSA_PROCESSING_SUCCESS) {
                            formatted.lastCorr = TimeService.GetMomentFromInstant(statusPojo.notifications.MSA_PROCESSING_SUCCESS.timeCreated);
                        }

                        //format the latest activity
                        formatted.latestActivity = TimeService.GetMostRecentNotification(statusPojo.notifications).time;
                    }

                    //add the formatted data to the list
                    formattedData.push(formatted);
                }

                //return the list of ui-grid friendly data
                return formattedData;
            };

            return service;

        }]);
})();