/**
 * This service is for handling various data transmissions to and from the server. Generally this is more general things like CRUD operations on a file, or
 * w/e
 * @return {[type]} [description]
 */
(function () {
    var app = angular.module('saphira');

    app.factory('MWDService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */ 'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker
                  /* Input            */) {

            var MWDService = {};


            /**
             * The function that obtains all the well metadata for all the
             * wells associated with the current account.
             *
             * @param wellIds
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            MWDService.getSurveyFromMWD = function (parentObject, fileId, templateName, manualMWD) {
                var deferred = $q.defer();
                var params = {
                    surveySetId: parentObject.id,
                    mwdFileId: fileId,
                    templateName: templateName
                };
                UIBlocker.start();
                $http.get("/saphira/api/utils/getSurveyFromMWDReader", {params: params}).then(
                    function (data, status, headers, config) {
                        UIBlocker.stop();
                        deferred.resolve(data);
                    },
                    function (data, status, headers, config) {
                        UIBlocker.stop();
                        deferred.reject(data);

                    },
                    function (progressEvent) {
                        deferred.notify(progressEvent.loaded / progressEvent.total);
                    }
                );
                return deferred.promise;
            };

            MWDService.getAllSurveysFromMWD = function (parentObject, fileId, templateName, overwriteExisting) {
                var deferred = $q.defer();
                var params = {
                    surveySetId: parentObject.id,
                    mwdFileId: fileId,
                    templateName: templateName,
                    overwriteExisting: overwriteExisting
                };
                UIBlocker.start();
                $http.get("/saphira/api/utils/importSurveysFromMWDFile", {params: params}).then(
                    function (data, status, headers, config) {
                        UIBlocker.stop();
                        deferred.resolve(data);
                    },
                    function (data, status, headers, config) {
                        UIBlocker.stop();
                        deferred.reject(data);

                    },
                    function (progressEvent) {
                        deferred.notify(progressEvent.loaded / progressEvent.total);
                    }
                );
                return deferred.promise;
            };
            return MWDService;
        }]);
})();