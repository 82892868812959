/**
 * PlotHelperService:
 *
 *      Responsible for: Various utility functions for creating Highchart plots
 */
(function () {
    var app = angular.module('saphira');

    app.factory("PlotHelperService", [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules   */
                  /* 3rd Party Modules */
                  /* Internal Modules  */
                  /* Input             */) {

            var service = {};

            var failMult = 1.5;

            service.filterOutInterpolatedSurveys = function (surveys) {
                var filteredSurveys = [];

                for (var i = 0; i < surveys.length; i++) {
                    if (surveys[i].type !== 'INTERPOLATED' && surveys[i].type !== 'DEFINITIVE_INTERPOLATED') {
                        filteredSurveys.push(surveys[i]);
                    }
                }

                return filteredSurveys;
            };

            /**
             * Returns the min and max values for the a set of reference, calc/corrected/ and fac values
             *
             * @param refVals
             * @param calcVals
             * @param facVals
             * @param acSigma
             * @returns {{}}
             */
            service.getMinMax = function (refVals, calcVals, facVals, acSigma) {
                var minMax = {};
                var min = 0;
                var max = 0;

                if (refVals.length != 0) {
                    min = refVals[0];
                    max = refVals[0];
                }
                for (var i = 0; i < refVals.length; i++) {
                    var meas = calcVals[i];
                    var ref = refVals[i];
                    var facMax = (ref + (failMult * (facVals[i] * acSigma)));
                    var facMin = (ref - (failMult * (facVals[i] * acSigma)));

                    min = min < facMin ? min : facMin;
                    min = min < meas ? min : meas;
                    max = max > facMax ? max : facMax;
                    max = max > meas ? max : meas;
                }

                max += Math.abs((max - min) * 0.05);
                min -= Math.abs((max - min) * 0.05);

                minMax.min = min;
                minMax.max = max;
                return minMax
            };

            /**
             * Finds the difference between two angles with signed wrapping
             * @param calculated/corrected angle
             * @param reported angle
             * @returns {number} between [-180, 180)
             */
            service.angleDif = function (calculated, reported) {
                var temp = calculated - reported;
                temp = mod(temp + 180.,360) - 180.;
                return temp;
            };

            var mod = function(a, n) {
                return a - Math.floor(a/n) * n;
            };

            /**
             * Returns the first and last md value in a list of surveys
             * @param surveys
             * @returns {{first: *, last: *}}
             */
            service.getFirstLast = function (surveys) {
                var firstLast = {first: null, last: null};
                if (surveys.length > 0) {
                    firstLast.first = surveys[0].md.value;
                    firstLast.last = surveys[surveys.length - 1].md.value;
                }
                return firstLast;
            };

            /**
             * Get a filtered list of surveys based on survey type(s)
             * @param surveys
             * @param types
             * @returns {Array}
             */
            service.getSurveysByType = function (surveys, types) {
                var temp = [];
                for (var i = 0; i < surveys.length; i++) {
                    if (service.isSurveyOfType(surveys[i], types)) {
                        temp.push(surveys[i]);
                    }
                }
                return temp;
            };

            /**
             * Get a filtered list of surveys based on correction type(s)
             * @param surveys
             * @param corrections
             * @returns {Array}
             */
            service.getSurveysByCorrection = function (surveys, corrections) {
                var temp = [];
                for (var i = 0; i < surveys.length; i++) {
                    if (service.surveyHasCorrection(surveys[i], corrections)) {
                        temp.push(surveys[i]);
                    }
                }
                return temp;
            };

            /**
             * Check if the survey's type matches any of the types given
             * @param survey
             * @param types
             * @returns {boolean}
             */
            service.isSurveyOfType = function (survey, types) {
                for (var i = 0; i < types.length; i++) {
                    if (survey.type == types[i]) {
                        return true;
                    }
                }
                return false;
            };

            /**
             * Check if the survey has a correction type matching any of the correction types given
             * @param survey
             * @param corrections
             * @returns {boolean}
             */
            service.surveyHasCorrection = function (survey, corrections) {
                for (var i = 0; i < corrections.length; i++) {
                    if (survey.correctedSixAxisDatas[corrections[i]] !== undefined) {
                        return true;
                    }
                }
                return false;
            };

            /**
             * Check if the survey has a correction time
             * @param surveys
             * @returns {Array}
             */
            service.getSurveysByCorrectionTime = function (surveys, startTime) {
                var correctedSurveys = [];

                for (var i = 0; i < surveys.length; i++) {
                    if ((startTime === "receivedTime" && surveys[i].correctedTime && surveys[i].correctedTime !== null) || (startTime === "fileUploadTime" && surveys[i].fileUploadTime && surveys[i].correctedTime)) {
                        correctedSurveys.push(surveys[i]);
                    }
                }

                return correctedSurveys;
            };

            return service;

        }]);
})();
