/**
 * ActivityLogModalController:
 *
 *      Responsible for:
 */
(function () {

    var app = angular.module('saphira');

    app.controller('MapMarkerModalController', [
        /* Angular Modules  */ '$scope', '$http', '$q', '$uibModalInstance',
        /* 3rd Party Modules*/
        /* Internal Modules */'StateService',
        /* Input            */

        function (/* Angular Modules  */ $scope, $http, $q, $uibModalInstance,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ StateService
                  /* Input            */) {

            //the action for closing the modals
            $scope.closeModalCancel = function () {
                $uibModalInstance.dismiss('cancel');
            };


        }]);
})();