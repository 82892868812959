/**
 * 'Unique' filter. Filters an array and returns an array free of duplicates. Works on object arrays too.
 *
 * Copied from https://github.com/angular-ui/angular-ui-OLDREPO/blob/master/modules/filters/unique/unique.js
 */
(function () {

    var app = angular.module('saphira');
    /**
     * Filters out all duplicate items from an array by checking the specified key
     * @param [key] {string} the name of the attribute of each object to compare for uniqueness
     * if the key is empty, the entire object will be compared
     * if the key === false then no filtering will be performed
     * @return {array}
     */
    app.filter('unique', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (items, filterOn) {

                /**
                 * If filterOn is set to false, don't filter the array and instead return everything
                 */
                if (filterOn === false) {
                    return items;
                }

                /**
                 * if filterOn is true or a string, being filtering.
                 */
                if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
                    var newItems = []; //List of items to be returned


                    /**
                     * If filterOn is not a string, we return the whole item. Otherwise we return the attribute
                     * filterOn references of the item.
                     * @param item
                     * @returns {*}
                     */
                    var extractValueToCompare = function (item) {
                        if (angular.isObject(item) && angular.isString(filterOn)) {
                            return item[filterOn];
                        } else {
                            return item;
                        }
                    };

                    /**
                     * Compares each item to each other item. If it is unique it is added to the returned list, otherwise
                     * we move on to the next item to compare.
                     */
                    angular.forEach(items, function (item) {
                        var isDuplicate = false;

                        for (var i = 0; i < newItems.length; i++) {
                            if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                                isDuplicate = true;
                                break; //Move on to next item
                            }
                        }
                        if (!isDuplicate) {
                            newItems.push(item); //Add item to unique list
                        }

                    });
                    items = newItems;
                }
                return items;
            };
        }]);
})();
