/**
 * A filter for converting our enum format to something human readable.  Well, more human readable.
 */
(function () {

    var app = angular.module('saphira');

    app.filter('SurveySetStateFilter', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return function (string) {
                switch(string) {
                    case "WAITING_SURVEY_SUBMIT":
                        return "Waiting for a new survey";
                    case "WAITING_CORRECTION":
                        return "Waiting for a correction";
                    case "WAITING_VALIDATION":
                        return "Waiting for validation";
                    default:
                        return "Unknown";
                }
            }
        }]);
})();