/**
 * This directive is used for displaying a highchart
 *
 */

(function () {

    var app = angular.module('saphira');
    app.directive('mvHighcharts', ["$timeout", "lodash", function ($timeout, lodash) {
        return {
            restrict: 'E',      // Enforce the directive to be used as an element.
            replace: true,
            scope: {
                chart: '=',
                options: '='
            },
            template: '<div><div style="width:100%; height: 100%"></div></div>',

            link: function (scope, element, attrs) {
                var chartDiv = element[0].children[0];
                var chartMade = false;
                var realMinY = null, realMaxY = null;

                var expandToMax = function(){
                    scope.chart.setSize(null, null, false);
                };

                var updateYAxis = function() {
                    var minY, maxY;

                    for (var i = 0; i < scope.options.series.length; i++) {
                        var series = scope.options.series[i];

                        if (scope.scalableSeries[series.name]) {
                            for (var j = 0; j < series.data.length; j++) {
                                var point = series.data[j];
                                var y = point[1];
                                if (minY === undefined || y < minY) {
                                    minY = y;
                                }
                                if (maxY === undefined || y > maxY) {
                                    maxY = y;
                                }
                            }
                        }
                    }
                    scope.chart.yAxis[0].setExtremes(minY, maxY);
                    scope.chart.showResetZoom();
                };

                var configureYScaling = function () {
                    populateScalableSeries();

                    scope.options.plotOptions.series.events = {
                        legendItemClick: function (event) {
                            angular.element(document).ready(function () {

                                //update the scaled series map
                                scope.scalableSeries[event.target.name] = event.target.visible;

                                if(lodash.includes(scope.scalableSeries, false)) {
                                    //update the y axis scaling
                                    updateYAxis();
                                }
                                else {
                                    //scope.chart.yAxis[0].setExtremes(realMinY, realMaxY);
                                    // Leaving this here for now, might want to tie it to some option later
                                }


                            });
                        }
                    };
                };

                var populateScalableSeries = function () {
                    scope.scalableSeries = {};
                    for (var i = 0; i < scope.options.series.length; i++) {
                        var series = scope.options.series[i];
                        if (series.ignoreScale === undefined || series.ignoreScale === false) {
                            scope.scalableSeries[series.name] = (scope.options.series[i].visible !== false);
                        }
                    }
                };
              

                var init = function () {
                    if (scope.options !== undefined) {
                        configureYScaling();
                        scope.chart = Highcharts.chart(chartDiv, scope.options);
                        angular.element(document).ready(function () {
                            //Waits for any containing div to render properly so the charts size isn't weird
                            expandToMax();
                            realMinY = scope.chart.yAxis[0].getExtremes().min;
                            realMaxY = scope.chart.yAxis[0].getExtremes().max;

                        });
                        chartMade = true;
                    }
                };
                init();

                //Watch for any changes to the parent div width and height.  Whenever changes are detected, the Dygraph
                //is resized to fit its new space.
                scope.$watch(function () {
                    return {
                        'h': element[0].parentNode.clientHeight,
                        'w': element[0].parentNode.clientWidth
                    };
                }, function (newValue, oldValue) {
                    if(newValue !== oldValue) {
                        //expandToMax();
                        // console.log(newValue);
                    }
                }, true);

                //Watch for any changes to the parent div width and height.  Whenever changes are detected, the Dygraph
                //is resized to fit its new space.
                scope.$watch(function () {
                    return {
                        'options': scope.options
                    };
                }, function (newValue, oldValue) {
                    if(newValue !== oldValue && chartMade === false) {
                        init();
                    }
                }, true);
            }
        };
    }]);
})();