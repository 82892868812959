/**
 * This service is for handling everything to do with our chat messages.
 * @return {[type]} [description]
 */
(function () {

    var app = angular.module('saphira');

    app.factory('ChatService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */ 'UIBlocker', 'TimeService',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ UIBlocker, TimeService
                  /* Input            */) {

            var ChatService = {};
            var URLS = {
                chatRepo: "/saphira/api/repositories/chat/",
                getAll: "/saphira/api/repositories/chat/well/all",
                getAllAfter: "/saphira/api/repositories/chat/well/afterTime",
                getLatestMessageTime : "/saphira/api/repositories/chat/well/latestTime",
                addChatMessage: "/saphira/api/repositories/chat/well"
            };

            /**
             * Gets all the chat messages for the given well id
             * @param wellId
             * @returns {Promise<U>|*|IPromise<U>|webdriver.promise.Promise}
             */
            ChatService.getAllMessages = function (wellId, block) {
                if (block) {
                    UIBlocker.start();
                }
                return $http.get(URLS.getAll, {
                    'params': {
                        'wellId': wellId,
                        'messageTypes':'USER'
                    }
                }).then(function (response) {
                    if (block) {
                        UIBlocker.stop();
                    }
                    return response.data;
                }, function (response) {
                    if (block) {
                        UIBlocker.stop();
                    }
                    return $q.reject(response);
                });
            };

            ChatService.getAllMessagesAfter = function (wellId, time) {
                return $http.get(URLS.getAllAfter, {
                    'params': {
                        'wellId': wellId,
                        'messageTypes':'USER',
                        'time': TimeService.GetUTCTimestampFromInstant(time)
                    }
                }).then(function (response) {
                    return response.data;
                }, function (response) {
                    return $q.reject(response);
                });
            };

            ChatService.getLatestMessageTime = function(wellId) {
                return $http.get(URLS.getLatestMessageTime, {
                    "params": {
                        'wellId': wellId,
                        'messageTypes':'USER'
                    }
                }).then(function (response) {
                    if(response.data !== null && response.data != "") {
                        response.data.time = moment.utc(response.data.time.seconds * 1000.0);
                        return response.data;
                    }
                    else {
                        return null;
                    }
                }, function (response) {
                    return $q.reject(response);
                });
            };

            /**
             * This just adds a new chat to the currently selected well.
             * @param {String} wellID  The ID of the well we have currently selected
             * @param {String} message The actual string-valued message.
             */
            ChatService.addNewChat = function (wellID, message) {
                var temp = {
                    message: message.message,
                    sender: message.sender,
                    parentId: message.parentId,
                    messageType: "USER",
                    time: {
                        seconds: message.time.unix()
                    }
                };
                return $http.post(URLS.addChatMessage + "/" + wellID, temp);
            };
            return ChatService;
        }]);
})();
