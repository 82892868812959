(function () {

    var app = angular.module('saphira');

    app.factory('DefaultThemesService', [

        function () {

            var DefaultThemesService = {};
            DefaultThemesService.defaultColors = {
                mainBackgroundColor: '#FFFFFF',
                mainTextColor: '#000000',
                headerBackgroundColor: '#FFFFFF',
                headerTextColor: '#5e5e5e',
                tableHeaderBackgroundColor: '#f5f5f5',
                tableHeaderTextColor: '#000000',
                btnColor: '#FFFFFF',
                btnBgColor: '#265a88',
                tableRowColor: '#fdfdfd',
                surveyTypeDP: '#fdfdfd',
                surveyTypeXB: "#fdfdfd",
                surveyTypeDS: "#fdfdfd",
                surveyTypeXA: "#fdfdfd",
                surveyTypeXC: "#fdfdfd",
                surveyTypeXI: "#fdfdfd",
                surveyTypeDI: "#fdfdfd",
                tableTextColor: '#000000',
                inputsBg: '#FFFFFF',
                inputsTextColor:"#555555",
                iconsColor: '#23527c',
                defaultBtnBg: '#e0e0e0',
                defaultActiveBtnBg: '#337ab7',
                linksColor: '#337ab7',
                editIconColor: "#23527c",
                saveIconColor: "#008000",
                discardIconColor: "#A10000",
                tableEditedColor:"#ece27e",
                btnSuccessBgColor:"#419641",
                btnWarningBgColor: "#eb9316",
                btnDangerBgColor: "#c12e2a",
                autoInactive: "#FF0000",
                autoActive: "#008000",
                connectDown:"#e22d00",
                connectPoor:"#faff00",
                connectBad:"#ff9600",
                connectGood:"#00c900",
                currentUserBg: "#ADD8E6",
                otherUserBg: "#E4EEE0",
                footerTextColor:"#A9A9A9",
                footerBg:"rgba(255, 255, 255, 0.2)",
                applicationBg: "#1f4e79"

            };
            DefaultThemesService.themes = [{
                name: "Default",
                colors:  DefaultThemesService.defaultColors,
                isLastSelected: true
            }, {
                name: "Spooky Forest (Will's Theme)",
                colors: {
                    mainBackgroundColor: '#553C08',
                    mainTextColor: '#1B970F',
                    headerBackgroundColor: '#051306',
                    headerTextColor: '#EE6E6E',
                    tableHeaderBackgroundColor: '#17244E',
                    tableHeaderTextColor: '#DFDFDF',
                    btnColor: '#FFFFFF',
                    btnBgColor: '#265a88',
                    tableRowColor: '#fdfdfd',
                    surveyTypeDP: '#fdfdfd',
                    surveyTypeXB: "#fdfdfd",
                    surveyTypeDS: "#fdfdfd",
                    surveyTypeXA: "#fdfdfd",
                    surveyTypeXC: "#fdfdfd",
                    surveyTypeXI: "#fdfdfd",
                    surveyTypeDI: "#fdfdfd",
                    tableTextColor: '#000000',
                    inputsBg: '#FFFFFF',
                    inputsTextColor:"#555555",
                    iconsColor: '#23527c',
                    defaultBtnBg: '#e0e0e0',
                    defaultActiveBtnBg: '#337ab7',
                    linksColor: '#337ab7',
                    editIconColor: "#23527c",
                    saveIconColor: "#008000",
                    discardIconColor: "#A10000",
                    tableEditedColor:"#ece27e",
                    btnSuccessBgColor:"#419641",
                    btnWarningBgColor: "#eb9316",
                    btnDangerBgColor: "#c12e2a",
                    autoInactive: "#FF0000",
                    autoActive: "#008000",
                    connectDown:"#e22d00",
                    connectPoor:"#faff00",
                    connectBad:"#ff9600",
                    connectGood:"#00c900",
                    currentUserBg: "#ADD8E6",
                    otherUserBg: "#E4EEE0",
                    footerTextColor:"#A9A9A9",
                    footerBg:"rgba(255, 255, 255, 0.2)",
                    applicationBg: "#1f4e79"

                },
                isLastSelected: false
            }, {
                name: "Terminal (Josh's Theme)",
                colors: {
                    mainBackgroundColor: '#383838',
                    mainTextColor: '#53884B',
                    headerBackgroundColor: '#383838',
                    headerTextColor: '#53884B',
                    tableHeaderBackgroundColor: '#53884B',
                    tableHeaderTextColor: '#000000',
                    btnColor: '#FFFFFF',
                    btnBgColor: '#265a88',
                    tableRowColor: '#fdfdfd',
                    surveyTypeDP: '#fdfdfd',
                    surveyTypeXB: "#fdfdfd",
                    surveyTypeDS: "#fdfdfd",
                    surveyTypeXA: "#fdfdfd",
                    surveyTypeXC: "#fdfdfd",
                    surveyTypeXI: "#fdfdfd",
                    surveyTypeDI: "#fdfdfd",
                    tableTextColor: '#000000',
                    inputsBg: '#FFFFFF',
                    inputsTextColor:"#555555",
                    iconsColor: '#23527c',
                    defaultBtnBg: '#e0e0e0',
                    defaultActiveBtnBg: '#337ab7',
                    linksColor: '#337ab7',
                    editIconColor: "#23527c",
                    saveIconColor: "#008000",
                    discardIconColor: "#A10000",
                    tableEditedColor:"#ece27e",
                    btnSuccessBgColor:"#419641",
                    btnWarningBgColor: "#eb9316",
                    btnDangerBgColor: "#c12e2a",
                    autoInactive: "#FF0000",
                    autoActive: "#008000",
                    connectDown:"#e22d00",
                    connectPoor:"#faff00",
                    connectBad:"#ff9600",
                    connectGood:"#00c900",
                    currentUserBg: "#ADD8E6",
                    otherUserBg: "#E4EEE0",
                    footerTextColor:"#A9A9A9",
                    footerBg:"rgba(255, 255, 255, 0.2)",
                    applicationBg: "#1f4e79"
                },
                isLastSelected: false
            }, {
                name: "'Slate' (Rahul's Theme)",
                colors: {
                    mainBackgroundColor: "#686B5E",
                    mainTextColor: "#FFFFFF",
                    headerBackgroundColor: "#B4B4B4",
                    headerTextColor: "#000000",
                    tableHeaderBackgroundColor: "#B4B99D",
                    tableHeaderTextColor: "#130202",
                    btnColor: '#FFFFFF',
                    btnBgColor: '#265a88',
                    tableRowColor: '#fdfdfd',
                    surveyTypeDP: '#fdfdfd',
                    surveyTypeXB: "#fdfdfd",
                    surveyTypeDS: "#fdfdfd",
                    surveyTypeXA: "#fdfdfd",
                    surveyTypeXC: "#fdfdfd",
                    surveyTypeXI: "#fdfdfd",
                    surveyTypeDI: "#fdfdfd",
                    tableTextColor: '#000000',
                    inputsBg: '#FFFFFF',
                    inputsTextColor: "#555555",
                    iconsColor: '#23527c',
                    defaultBtnBg: '#e0e0e0',
                    defaultActiveBtnBg: '#337ab7',
                    linksColor: '#337ab7',
                    editIconColor: "#23527c",
                    saveIconColor: "#008000",
                    discardIconColor: "#A10000",
                    tableEditedColor: "#ece27e",
                    btnSuccessBgColor: "#419641",
                    btnWarningBgColor: "#eb9316",
                    btnDangerBgColor: "#c12e2a",
                    autoInactive: "#FF0000",
                    autoActive: "#008000",
                    connectDown: "#e22d00",
                    connectPoor: "#faff00",
                    connectBad: "#ff9600",
                    connectGood: "#00c900",
                    currentUserBg: "#ADD8E6",
                    otherUserBg: "#E4EEE0",
                    footerTextColor: "#A9A9A9",
                    footerBg: "rgba(255, 255, 255, 0.2)",
                    applicationBg: "#1f4e79"
                },
                isLastSelected: false
            },  {
                    name: "'Winter' (Richard's Theme)",
                    colors: {
                        mainBackgroundColor: "#799BBE",
                        mainTextColor: "#000000",
                        headerBackgroundColor: "#A9B7C0",
                        headerTextColor: "#000000",
                        tableHeaderBackgroundColor: "#60789C",
                        tableHeaderTextColor: "#000000",
                        btnColor: '#000000',
                        btnBgColor: '#C7D8C6',
                        tableRowColor: '#BBCAFC',
                        surveyTypeDP: '#BBCAFC',
                        surveyTypeXB: "#BBCAFC",
                        surveyTypeDS: "#BBCAFC",
                        surveyTypeXA: "#BBCAFC",
                        surveyTypeXC: "#BBCAFC",
                        surveyTypeXI: "#BBCAFC",
                        surveyTypeDI: "#BBCAFC",
                        tableTextColor: '#000000',
                        inputsBg: '#FFF8F8',
                        inputsTextColor:"#555555",
                        iconsColor: '#23527c',
                        defaultBtnBg: '#e0e0e0',
                        defaultActiveBtnBg: '#337ab7',
                        linksColor: '#373737',
                        editIconColor: "#23527c",
                        saveIconColor: "#008000",
                        discardIconColor: "#A10000",
                        tableEditedColor:"#ece27e",
                        btnSuccessBgColor:"#419641",
                        btnWarningBgColor: "#eb9316",
                        btnDangerBgColor: "#c12e2a",
                        autoInactive: "#FF0000",
                        autoActive: "#008000",
                        connectDown:"#e22d00",
                        connectPoor:"#faff00",
                        connectBad:"#ff9600",
                        connectGood:"#00c900",
                        currentUserBg: "#ADD8E6",
                        otherUserBg: "#E4EEE0",
                        footerTextColor:"#8B8A8A",
                        footerBg:"rgb(169, 183, 192)",
                        applicationBg: "#CBF3EB"
                    },
                    isLastSelected: false
            }];

            return DefaultThemesService;
        }]);
})();