(function () {
    var app = angular.module('saphira');

    app.service('DefinitiveSurveyTableService', [
        /* Angular Modules  */ '$filter',
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */ $filter
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {


            var service = {};

            service.prep = function(units, uiGridConstants) {
                service.units = units;
                service.uiGridConstants = uiGridConstants;
            };

            service.getMd = function() {
                return [
                    {
                        field: 'md.value',
                        cellFilter: 'distanceDecimal:"' + service.units.depth + '"',
                        enableSorting: true,
                        displayName: 'Md (' + $filter('distanceUnit')(service.units.depth) + ')',
                        sort: {
                            direction: service.uiGridConstants.ASC
                        },
                        suppressRemoveSort: true,
                        enableHiding: false,
                        sortingAlgorithm: function (a, b) {
                            if (a === b) {
                                return 0;
                            }
                            if (a > b)
                                return 1;
                            else {
                                return -1;
                            }
                        }
                    }
                ];
            };

            service.getPostedIncAzi = function() {
                return [
                    {
                        field: 'postedInc.value',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'Inc (' + $filter('angleUnit')(service.units.angle) + ')'
                    },
                    {
                        field: 'postedAzi.value',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'Azi (' + $filter('angleUnit')(service.units.angle) + ')'
                    }
                ];
            };

            service.getMinCurve = function() {
                return [
                    {
                        field: 'tvd.value',
                        cellFilter: 'distanceDecimal:"' + service.units.depth + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'TVD (' + $filter('distanceUnit')(service.units.depth) + ')'
                    },
                    {
                        field: 'north.value',
                        cellFilter: 'distanceDecimal:"' + service.units.distance + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'Northings (' + $filter('distanceUnit')(service.units.distance) + ')'
                    },
                    {
                        field: 'east.value',
                        cellFilter: 'distanceDecimal:"' + service.units.distance + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'Eastings (' + $filter('distanceUnit')(service.units.distance) + ')'
                    },
                    {
                        field: 'verticalSection.value',
                        cellFilter: 'distanceDecimal:"' + service.units.depth + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'Vertical Section (' + $filter('distanceUnit')(service.units.depth) + ')'
                    }, {
                        field: 'dogLegSeverity.value',
                        cellFilter: 'angleDecimal:"' + service.units.angle + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'DLS (' + $filter('angleUnit')(service.units.angle) + ' / ' + (service.units.depth === 'METER' ? '30m' : '100ft') + ')',
                    }, {
                        field: 'courseLength.value',
                        cellFilter: 'distanceDecimal:"' + service.units.distance + '"',
                        enableSorting: false,
                        enableColumnMenu: false,
                        displayName: 'CL (' + $filter('distanceUnit')(service.units.depth) + ')'
                    }
                ];
            };


            return service;
        }]);
})();