/**
 *  This directive provides a smart table with the following feature set:
 *
 *  1.) Only enabled fields are displayed.
 *  2.) Fields are displayed in the order they are listed in the displayedColumns field of options.
 *  3.) Header row is fixed while the rows below it are scrollable.
 *  4.) The following classes are available for styling:
 *          -mvSmartTable_Main:     The main div at the top of the directive hierarchy
 *          -mvSmartTable_Table:    The table div
 *          -mvSmartTable_thead:    The thead div in the table
 *          -mvSmartTable_th:       The header cells
 *          -mvSmartTable_tbody:    The tbody div in the table
 *          -mvSmartTable_td:       The cells below the header
 *  5.) AngularJS Filters can be applied to columns.
 *  6.) rows are selectable if you include the 'selected' attribute. The attribute is expecting an array to be passed to it.
 *
 *  The following features are planned:
 *  1.) Edit mode will allow for edits to be made to all cells.
 *
 * notes:
 *  -filters support up to 5 arguments, but its easy to add more
 *  -any 'extra' width is added to the first column.  this is a side effect of making sure everything fits pixel perfect
 */
(function() {
    var app = angular.module('saphira');
    app.directive('mvSmartTable', ['$filter', '$timeout', 'lodash','$window','$templateCache', function($filter, $timeout, lodash, $window, $templateCache) {
        /**
         * This is the controller for the mvSmartTable directive. This makes it so that (in theory) you could share the 'selectableness' with other items. (lists, dropdowns, w/e)
         * not that we're actually going to do that, but it could be done. better here than link in this case.
         * @param  {[type]} $scope [description]
         * @return {[type]}        [description]
         */
        function controller($scope, $element, $attrs) {
            $scope.lastSelectedElement = null; // keeps tracks of the last selected element so things like 'shift-click' work, you'll see later.

            var highlightCSSStyle = "background-color: #E0EEEE !important;"; //This is the style inside of the CSS class we're going to create. makes a nice light blue highlight. also overrides the 'active' style, which we want.

            /**
             * This function creates the actual class for making something 'highlighted' (selected). It uses the class' .mv-highlighted' and just adds a <style> block to the DOM
             * since there isn't any really good way to dynamically actually alter the .css files we have. I know there is a good argument to just put this in our CSS, but then we
             * have to have a separate CSS file for the directive for it to work properly, and that's not great, sooooooooooooo yea.
             * @return nothing! just modifies the DOM
             */
            var createHighlightCSSClass = function() {
                var headerStyleClass = document.createElement('style'); // create the <style> block
                headerStyleClass.type = 'text/css'; //set the type...
                headerStyleClass.innerHTML = '.mv-highlighted { ' + highlightCSSStyle + '}'; //This is the actual CSS being used.
                document.getElementsByTagName('head')[0].appendChild(headerStyleClass); //aaand we add the element right after the 'head' html element.
            };

            createHighlightCSSClass(); //call the function to actually add the element to the DOM

            /**
             * This is actually the 'magic' that makes the selected items be highlighted. It assumes a few things:
             * 1) there is an array on the scope called 'dataset' which contains items which are being displayed on the dom, and someone is choosing from
             * 2) The selectedElement is an item from the aforementioned dataset
             * 3) you want the 'selection' style to be that of choosing from a list of items as in most common OS's
             * 4) there is an array on the scope called 'selected' which represents an array that will be filled with the selected elements
             * So, what does point 3 mean? it means the following:
             * 1) just clicking an element selects a single element, and replaces all previous selections with just that one element
             * 2) clicking while holding 'crtl' will allow single click multi-selection of element, clicking on the same element will deselect it, and only it.
             * 3) clicking an element while holding 'shift' will select all the elements from the previously selected element in a contiguous block. If no previous
             *    element was selected, then it will just select the element. If you shift+click an element already selected, nothing will happen. This should work both up and down
             *
             * Also it's worth mentioning that to be 'selected' means that it will 1) be added to the $scope.selected array and 2) it will be highlighted with the color defined
             * in the 'highlightCSSStyle' variable up above (currently E0EEEE  ... sounds like a dolphin picked the color).
             *
             * @param  {JS Object} selectedElement This is the selected element that we are dealing with
             * @param  {click-event]} $event          This is the entire click event, which lets us know if the user is holding shift or ctrl.
             * @return no return from this function! just modification of the scope.
             */
            $scope.processTableSelect = function(selectedElement, $event) {
                var selectedPresent = 'selected' in $attrs;
                if (!selectedPresent){ //if the user didn't use the 'selected' attribute, the rows won't be selectable.
                    return;
                }
                //If no selected element is given, return and do nothing. because.... nothing was selected?
                if (selectedElement == null || selectedElement == 'undefined'){
                    return;
                }

                clearSelection(); //This is strictly for making it so that the shift-click doesn't highlight all the text in the table, which looked goofy just clears all selected text on the DOM

                /*
                 * So, this little piece of logic just says the following: if the table doesn't allow multi-selection or the user hasn't pressed ctrl or shift, then clear all the selected items so far. easy!
                 */
                if (!$scope.options.multiSelect || (!$event.ctrlKey && !$event.shiftKey)) {
                    $scope.selected = [];
                };

                //If the user has the shift key pressed while clicking an item, and the multiselect option is enabled.. time for fancy shift-behaviour!
                if ($event.shiftKey && $scope.options.multiSelect) {
                    //This handles the case where nothing has been selected before clicking shift, this then makes the assumption you want everything from the top > selectedItem selected
                    if ($scope.lastSelectedElement === null){
                        $scope.lastSelectedElement = $scope.dataset[0]; //set the 'last selected item'
                        $scope.selected.push($scope.dataset[0]); //actually 'select' that item.
                    }else{
                        //some variables so we can keep track of where we are in figuring out if we're shift-selecting 'up' or 'down'
                        var selection = 0;
                        var last = 0;
                        var i = 0;
                        /*
                         * This for-each loop is entirely for determining if the selected element is 'before' or 'after' the previously selected element
                         * it iterates through the whole dataset and finds the locations of both the previously selected element, and the currently selected one.
                         */
                        $scope.dataset.forEach(function(element) {
                            if (lodash.isEqual(element, selectedElement)) {
                                selection = i;
                            };
                            if (lodash.isEqual(element, $scope.lastSelectedElement)) {
                                last = i;
                            };
                            i++;
                        });
                        //if we're going 'up' or 'backwards (depending on how you look at it)' we simply swap the last selected item and the last selected item, so
                        //we don't have to make multiple loops, one for each case.
                        if (selection < last) {
                            var temp = $scope.lastSelectedElement;
                            $scope.lastSelectedElement = $scope.dataset[selection - 1];
                            selectedElement = temp;
                        };
                    };
                    //Some variables to tell us if we
                    var foundStart = false;
                    var foundFinish = false;
                    /*
                     * This for each loop does the following:
                     * 1) finds the 'last selected element' and marks it as 'first'
                     * 2) iterates through and pushed every subsequent element into the 'selected' scope variable until the selected element is found
                     * 3) pushes the last (the selection) element onto the array, and then marks a variable signifying it's done
                     * 4) ???
                     * 5) profit
                     */
                    $scope.dataset.forEach(function(element) {
                        if (foundFinish) {
                            return; // if we're here, we've already found the end of where we should be pushing to the array.
                        };
                        if (foundStart) { //alright, if we find the beginning, then we get to start adding elements!
                            if (!lodash.includes($scope.selected, element)) { // if the element was already in the list, don't want to double-add it
                                $scope.selected.push(element); //add it!
                            }
                        };
                        if (!foundStart && lodash.isEqual(element, $scope.lastSelectedElement)) { // If we haven't found the starting element yet, and the current iteration matches the 'last selected element' set our 'start variable'
                            foundStart = true; //we found it, yay!
                        };
                        if (lodash.isEqual(selectedElement, element)) { //if the selected element = the current element iteration, we found the end! yay! mark everything as done, set the last selected element to the current one.
                            $scope.lastSelectedElement = element;
                            foundFinish = true;
                        };
                    });
                } else { //This is the case where the user is using ctrl or just a regular click
                    var foundExisting = false;
                    $scope.selected.forEach(function(item) { //This will only happen if the user holds down 'ctrl' and clicks.
                        if (lodash.isEqual(item, selectedElement)) {
                            lodash.remove($scope.selected, item); // if the element already existed and was there, remove it! (aka: deselect)
                            foundExisting = true;
                            $scope.lastSelectedElement = null; //the last selected was deselected.. so there was no last selected!
                        };
                    });
                    if (!foundExisting) {
                        $scope.lastSelectedElement = selectedElement;
                        $scope.selected.push(selectedElement);
                    };
                };
            };
            /**
             * This is just to clear all of the currently highlighted (selected) text on the browser window. Used in conjunction with the
             * selectable table element, it prevents the text being select when users are shift-clicking, which looks goofy.
             * @return doesn't return anything, just modifies the DOM.
             */
            var clearSelection = function() {
                if (document.selection && document.selection.empty) {
                    document.selection.empty();
                } else if (window.getSelection) {
                    var sel = window.getSelection();
                    sel.removeAllRanges();
                };
            };
            /**
             *
             * THis function exists because we can't use lodash inside of the ng-class lazy expression unfortunately. This is how the dom knows to add the highlighted class or not.
             * @param  {JS Object} element The element we're checking to see if our currently selected list contains it.
             * @return {boolean}         true if the $scope.selected array contains the element, false otherwise.
             */
            $scope.checkIfContains = function(element) {
                return lodash.includes($scope.selected, element);
            };

            /**
             * Marks which entry has been edited
             * @param index
             */
            $scope.entryChange = function(index) {
                //Only add the index if it hasn't been added already
                if(!lodash.includes($scope.editedEntries,index))
                    $scope.editedEntries.push(index);
            };



            /**
             * syncScrollbars:
             *
             *      Syncs the standard and edit table scrollbars.
             */
            var syncScrollbars = function() {

                //Grab both table body elements
                var elements = angular.element($element.find('tbody'));
                var normal = elements[0];
                var editable = elements[1];
                editable.scrollTop = normal.scrollTop;

                //Make the scroll event for each element update the scrollbar for the other

                //});
            };

            /**
             * Initialization for the directive controller
             */
            var init = function() {
                //Sync the scrollbars
                syncScrollbars();
            };
            init();
            $scope.$watch("editMode", function(){
                syncScrollbars();
            });


        };
        return {
            restrict: 'E', //Enforce the directive to be used as an element.
            replace: true, //Force the directive html to replace the tag
            scope: { //Isolate scope.
                dataset: '=', // (Required) The dataset to be displayed in the table.  This should be in the form
                                // of an array of objects.
                                // Example:
                                //  [
                                //      {'id': 0, 'runNumber': 0, 'depth':  5, 'bx': 0.346, 'by': 0.349, 'bz': '6.938', 'btot': 13.339},
                                //      {'id': 1, 'runNumber': 1, 'depth': 10, 'bx': 0.347, 'by': 0.342, 'bz': '8.583', 'btot': 15.573},
                                //      {'id': 2, 'runNumber': 2, 'depth': 15, 'bx': 0.348, 'by': 0.335, 'bz': '9.227', 'btot': 16.669}
                                //  ]
                options: '=',   // (Required) The set of options to configure/customize the table.
                                // Example:
                                //  {
                                //      'multiSelect': {true or false},  //Tells whether the table should allow multi-select or not.
                                //      'displayedColumns': [
                                //          {
                                //              field: 'runNumber',     //look for field runNumber
                                //              title: 'Run Number',    //use the header Run Number
                                //              height: 20              //set the column to 20% width
                                //          },
                                //          {
                                //              field: 'depth',
                                //              title: 'Depth (ft)',
                                //              format: {
                                //                  filter: 'number',   //use a number filter
                                //                  args: [2]           //pass in 2 as the first argument
                                //              }
                                //          },
                                //          {
                                //              field: 'btot',
                                //              title: 'B Total (nT)',
                                //              format: {
                                //                  filter: 'number',
                                //                  args: [3]
                                //              }
                                //          }
                                //      ]
                                //
                                //  }
                selected: '=?', // (not required) the variable which will be populated with a list of selected elements (row-wise)
                                //
                                // Example:
                                // {
                                //      ''
                                // }
                editMode: '=?', // (not required) the boolean variable which determines whether the table is
                                // currently editable or not.
                                //
                editColor: '=?',// (not required) the color of the table stripes when in edit mode
                                //
                                // Example:
                                //      'lightgoldenrodyellow'
                                //
                edited: '=?'    // (not required) The variable which will be populated with a list of which entries
                                // were edited.  It is important to note that it is up to the user of the table to define
                                // a unique identifier (index?) for each item in the dataset to keep track of what was
                                // edited.  Using the index in the array will not work due to the fact that when a dataset
                                // gets sorted, the index stops making sense and gets changed.
                                //
                                // Example:
                                // [
                                //      {'id': 0, 'runNumber': 0, 'depth':  5, 'bx': 0.346, 'by': 0.349, 'bz': '6.938', 'btot': 13.339},
                                //      {'id': 2, 'runNumber': 2, 'depth': 15, 'bx': 0.348, 'by': 0.335, 'bz': '9.227', 'btot': 16.669}
                                // ]

            },
            templateUrl: 'html/templates/smartTable.html', //The template url
            controller: controller,
            link: function(scope, elem, attrs) {
                /**
                 * applyFilters:
                 *
                 *      This goes through the column headers to find any filters that should be applied to any of the
                 *      data in the dataset and applies it.
                 */
                var applyFilters = function() {
                    for (var i = 0; i < scope.options.displayedColumns.length; i++) {
                        var field = scope.options.displayedColumns[i].field;
                        var format = scope.options.displayedColumns[i].format;
                        if (format !== undefined) {
                            for (var j = 0; j < scope.dataset.length; j++) {
                                //apply the filter with as many arguments are passed in (up to 5)
                                switch (format.args.length) {
                                    case 1:
                                        scope.dataset[j][field] = $filter(format.filter)(scope.dataset[j][field], format.args[0]);
                                        break;
                                    case 2:
                                        scope.dataset[j][field] = $filter(format.filter)(scope.dataset[j][field], format.args[0], format.args[1]);
                                        break;
                                    case 3:
                                        scope.dataset[j][field] = $filter(format.filter)(scope.dataset[j][field], format.args[0], format.args[1], format.args[2]);
                                        break;
                                    case 4:
                                        scope.dataset[j][field] = $filter(format.filter)(scope.dataset[j][field], format.args[0], format.args[1], format.args[2], format.args[3]);
                                        break;
                                    case 5:
                                        scope.dataset[j][field] = $filter(format.filter)(scope.dataset[j][field], format.args[0], format.args[1], format.args[2], format.args[3], format.args[4]);
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }
                };
                /**
                 * determinePixelWidthOfColumns:
                 *
                 *      This is a helper function that calculates the pixel width of each column.  It assumes that the
                 *      scrollbar width is 20 pixels.  It basically just checks for a given width in the options and
                 *      adds everything up.  If the number is less than 100, there are 2 possible outcomes.  If there
                 *      are any columns with no assigned width, the remaining width is dispersed amongst them. If all
                 *      columns have an assigned width, extra width is added to the first column (This is a side effect
                 *      of making the table pixel perfect).
                 */
                var determinePixelWidthOfColumns = function() {
                    //some helper numbers
                    var totalColumns = scope.options.displayedColumns.length;
                    var tableWidth = elem[0].clientWidth;
                    var scrollbarWidth = 20;
                    var workableWidth = tableWidth - scrollbarWidth;
                    //determine widths of columns that dont have any assigned
                    var widthAccountedFor = 0;
                    var columnsAccountedFor = 0;
                    for (var i = 0; i < totalColumns; i++) {
                        var column = scope.options.displayedColumns[i];
                        if (column.width !== undefined) {
                            widthAccountedFor += column.width;
                            columnsAccountedFor++;
                        }
                    }
                    //determine how many columns weren't accounted for and the remaining width
                    var columnsUnaccountedFor = totalColumns - columnsAccountedFor;
                    var widthOfUnaccountedForColumns = (100.0 - widthAccountedFor) / (columnsUnaccountedFor);
                    var newTotalWidth = 0;
                    for (var i = 0; i < totalColumns; i++) {
                        var column = scope.options.displayedColumns[i];
                        if (column.width === undefined) {
                            column.width = widthOfUnaccountedForColumns;
                        }
                        column.pixelWidth = parseInt(workableWidth * column.width / 100);
                        newTotalWidth += column.pixelWidth;
                    }
                    //check if we are off by a pixel (due to truncating)
                    if (workableWidth > newTotalWidth) {
                        //find the difference we are missing
                        var difference = workableWidth - newTotalWidth;
                        //add the difference to the first column
                        scope.options.displayedColumns[0].pixelWidth += difference;
                    }
                };
                /**
                 * createWidthCssClass:
                 *
                 *      Creates a new css class with the given class name and gives it a width property with the given
                 *      width value.
                 *
                 * @param className     Class name of the new css class
                 * @param width         Width to assign to the width property of the new css class
                 */
                var createWidthCssClass = function(className, width) {
                    var headerStyleClass = document.createElement('style');
                    headerStyleClass.type = 'text/css';
                    headerStyleClass.innerHTML = '.' + className + ' { width: ' + width + 'px; }';
                    document.getElementsByTagName('head')[0].appendChild(headerStyleClass);
                };
                /**
                 * createHeightCssClass:
                 *
                 *      Creates a new css class with the given class name and gives it a height property with the given
                 *      height value.
                 *
                 * @param className     Class name of the new css class
                 * @param height        Height to assign to the height property of the new css class
                 */
                var createHeightCssClass = function(className, height) {
                    var headerStyleClass = document.createElement('style');
                    headerStyleClass.type = 'text/css';
                    headerStyleClass.innerHTML = '.' + className + ' { height: ' + height + 'px; }';
                    document.getElementsByTagName('head')[0].appendChild(headerStyleClass);
                };
                /**
                 * resizeHeight:
                 *
                 *      This function ensures that the height on the directive div is the height of the full div.
                 *      Basically, fills the table to fill the space assigned for height.
                 */
                var resizeHeight = function() {
                    //helper numbers
                    var heightOfHeader = 34;
                    //get the height on the div
                    var requestedHeightStr = elem[0].style.height;
                    var requestedHeightPx = requestedHeightStr.replace('px', '');
                    var newHeight = requestedHeightPx - heightOfHeader;
                    //get the class name for this specific tbody
                    var className = 'mvstinternal_tbody_' + scope.id;
                    //create the new class to set the height
                    createHeightCssClass(className, newHeight);
                };
                /**
                 * applyColumnPixelWidths:
                 *
                 *      Creates new css classes for the header and cells in a given column to assign their width.
                 *      Must be run after 'determinePixelWidthOfColumns'.
                 */
                var applyColumnPixelWidths = function() {
                    for (var i = 0; i < scope.options.displayedColumns.length; i++) {
                        var field = scope.options.displayedColumns[i].field;
                        var width = scope.options.displayedColumns[i].pixelWidth;
                        var headerClassName = 'mvstinternal_columnHeader_' + scope.id + '_' + field;
                        var columnClassName = 'mvstinternal_columnCell_' + scope.id + '_' + field;
                        createWidthCssClass(headerClassName, width);
                        createWidthCssClass(columnClassName, width);
                    }
                };

                /**
                 * createEditColor
                 *
                 *      Creates a new css class for the table that defines the color fo the stripes
                 *      when in edit mode
                 */
                var createEditColor = function() {
                    var headerStyleClass = document.createElement('style');
                    headerStyleClass.type = 'text/css';

                    //Set default color to 'lightgoldenrodyellow' and replace it if we have a different color name
                    var color = 'lightgoldenrodyellow';
                    if(typeof scope.editColor === 'string' || scope.editColor instanceof String)
                        color = scope.editColor;

                    //Add the alternative table striping color to the style
                    headerStyleClass.innerHTML = '.table-striped-mvSmartTable-edit>tbody>tr:nth-of-type(odd)'
                        + ' {  background-color: ' + color + '; }';
                    document.getElementsByTagName('head')[0].appendChild(headerStyleClass);
                };


                /**
                 * init:
                 *
                 *      The entry point to the initialization of the directive instance.  The only real task that it has
                 *      is to make sure everything is properly sized.
                 */
                var init = function() {
                    //put the div id on the scope for the directive to use as a unique id
                    scope.id = attrs.id;
                    //Make a copy of the dataset for editing
                    scope.editableDataset = lodash.cloneDeep(scope.dataset); //Make sure editable table is copy of normal


                    //resize the columns
                    determinePixelWidthOfColumns();
                    applyColumnPixelWidths();
                    //Apply edit color
                    createEditColor();
                    //resize the height of the table
                    resizeHeight();

                    if(scope.editMode === true) {
                        scope.editableDataset = lodash.cloneDeep(scope.dataset);
                        scope.edited = [];
                        scope.editedEntries = [];
                    }
                };
                init();

                scope.$watchGroup(['dataset', 'options'], function(newValue, oldValue) {
                    applyFilters();
                });

                /**
                 * Watch function to generate an editable copy of the dataset every time the editMode
                 * attribute is switched to true. When switched to false, the list of edited entries is updated.
                 */
                scope.$watch('editMode', function(newValue, oldValue) {
                    //entering edit mode
                    if(newValue === true && oldValue === false) {
                        //copy the dataset to the editable dataset
                        scope.editableDataset = lodash.cloneDeep(scope.dataset);
                        scope.edited = [];
                        scope.editedEntries = [];
                    }
                    //leaving edit mode
                    else if(newValue === false && oldValue === true) {
                        //keep track of any edited rows
                        for(var i= 0; i < scope.editedEntries.length; i++) {
                            scope.edited.push(scope.editableDataset[scope.editedEntries[i]]);
                        }
                    }
                });
            }
        };
    }]);
})();
