/**
 * ClientRequisitesService:
 *
 *      Responsible for retrieving all data that the client needs from the server to function.
 *      Mostly stuff like enums.
 */
(function () {
    var app = angular.module('saphira');

    app.factory("ClientRequisitesService", [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules   */ $http, $q
                  /* 3rd Party Modules */
                  /* Internal Modules  */
                  /* Input             */) {

            var service = {};

            var URLS = {
                getClientRequisites: '/saphira/api/required'
            };

            // Whether or not init() has run before.  Mostly so we don't run it again if we already have.
            var setup = false;

            /**
             * getClientRequisites:
             *
             *      Makes an HTTP GET to retrieve all data required for the client.
             *
             * @returns {HttpPromise}
             */
            service.getClientRequisites = function() {
                return $http.get(URLS.getClientRequisites);
            };

            /**
             * init:
             *
             *      Basically a wrapper around getClientRequisites so we can call init as many times as we want
             *      from as many places as we want and ensure that we only ever make the request once.
             *
             * @param force
             * @returns {deferred.promise|{then}|{then, catch, finally}}
             */
            service.init = function(force) {
                var deferred = $q.defer();

                //if we are forcing init or we haven't done init before
                if ((force !== undefined && force === true) || setup === false) {

                    //make the call to get required client stuff
                    service.getClientRequisites().then(function(result) {

                        //store it in the service
                        service.data = result.data;

                        //setup is completed once
                        setup = true;

                        //resolve the promise
                        deferred.resolve();
                    });
                }
                //if we have already done init before
                else {
                    //resolve the promise immediately
                    deferred.resolve();
                }

                //return the promise
                return deferred.promise;
            };

            return service;

        }]);
})();