/**
 * PlotStyleService:
 *
 *      Responsible for: Various helper functions for creating Highchart plots
 */
(function () {
    var app = angular.module('saphira');

    app.factory("PlotStyleService", [
        /* Angular Modules  */ "$filter",
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules   */ $filter
                  /* 3rd Party Modules */
                  /* Internal Modules  */
                  /* Input             */) {

            var service = {};

            // Define a custom symbol path
            Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
                return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
            };
            if (Highcharts.VMLRenderer) {
                Highcharts.VMLRenderer.prototype.symbols.cross = Highcharts.SVGRenderer.prototype.symbols.cross;
            }

            //Styles rules for common series
            var style = {
                shading: {},
                color: {
                    fail: {
                        color: "#db0000",
                        regionOpacity: 0.23
                    },
                    warn: {
                        color: "#dd9e00",
                        regionOpacity: 0.35
                    },
                    safe: {
                        color: "#1f7f00",
                        regionOpacity: 0.23
                    },
                    reference: "black",
                    raw: "blue",
                    corrected: "firebrick",
                    bad: "black",
                    tooltipBorder: "black",
                    pointFillColor: "white"

                },
                line: {},
                marker: {
                    standard: {
                        symbol: "circle",
                        radius: 0,
                        lineWidth: 1,
                        lineColor: null
                    },
                    poor: {
                        symbol: "cross",
                        radius: 5,
                        lineColor: null,
                        lineWidth: 2
                    },
                    bad: {
                        symbol: "cross",
                        radius: 5,
                        lineColor: null,
                        lineWidth: 2
                    },
                    checkshot: {
                        symbol: "circle",
                        fillColor: 'transparent',
                        radius: 5,
                        lineColor: null,
                        lineWidth: 2
                    },
                    accelerometer: {
                        symbol: 'square',
                        fillColor: 'transparent',
                        radius: 5,
                        lineColor: null,
                        lineWidth: 2
                    }
                }
            };

            /**
             * Create default title object for Highcharts
             * @param title
             * @returns {{text: *}}
             */
            service.getDefaultTitle = function (title) {
                return {
                    text: title,
                    style: {
                        'font-weight': 'bold',
                        'font-size': '24px'
                    }
                };
            };

            /**
             * Create default x axis object for Highcharts
             *  //TODO make extend to the edges
             *
             * @param labelText
             * @param min
             * @param max
             * @returns {{title: {text: *, style: {font-weight: string, font-size: number}}, tickmarkPlacement: string, gridLineWidth: number, min: *, max: *}}
             */
            service.getDefaultXAxis = function (labelText, min, max) {
                return {
                    title: {
                        text: labelText,
                        style: {
                            'font-weight': 'bold',
                            'font-size': '16px'
                        }
                    },
                    tickmarkPlacement: 'on',
                    gridLineWidth: 1,
                    startOnTick: false,
                    endOnTick: false
                };
            };

            /**
             * Create default y axis object for Highcharts
             * @param labelText
             * @param min
             * @param max
             * @returns {{title: {text: *, style: {font-weight: string, font-size: number}}, min: *, max: *}}
             */
            service.getDefaultYAxis = function (labelText, min, max) {
                return {
                    title: {
                        text: labelText,
                        style: {
                            'font-weight': 'bold',
                            'font-size': '16px',
                            'text-align': 'center',
                        },
                        align: "middle"
                    },
                    startOnTick: false,
                    endOnTick: false,
                    min: min,
                    max: max

                };
            };

            /**
             * Gets default chart of Highcharts
             * @returns {{zoomType: string, panning: boolean, panKey: string, resetZoomButton: {position: {align: string, x: number, y: number}, relativeTo: string}}}
             */
            service.getDefaultChart = function () {
                return {
                    zoomType: 'xy',             //enable zooming on x and y axis
                    panning: true,              //enable panning
                    panKey: 'shift',            //pan key is shift
                    resetZoomButton: {          //move the zoom out key to the left side
                        position: {
                            align: 'left',
                            x: 40,
                            y: 10
                        },
                        relativeTo: 'chart'
                    }
                };
            };

            /**
             * Gets the default plot options for Highcharts
             * @returns {{series: {states: {hover: {lineWidthPlus: number}}}}}
             */
            service.getDefaultPlotOptions = function (xUnit, yUnit) {
                return {
                    series: {
                        states: {
                            hover: {
                                lineWidthPlus: 0
                            }
                        },
                        tooltip: service.getDefaultToolTip(xUnit, yUnit),
                        marker: {
                            enabled: true
                        }
                    },
                    scatter: {
                        tooltip: service.getDefaultToolTip(xUnit, yUnit)
                    }
                };
            };

            service.getDefaultExporting = function () {
                return {
                    sourceWidth: 1437,
                    sourceHeight: 430,
                    // scale: 2 (default)
                    chartOptions: {
                        subtitle: null
                    },
                    fallbackToExportServer: true
                };
            };

            service.getDefaultNoData = function () {
                return {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px'
                    }
                };
            };

            /**
             * Gets the default tooltip for Highcharts
             * @param unit
             * @returns {{borderColor: string, crosshairs: boolean[], positioner: PlotStyleService.positioner, shared: boolean, formatter: PlotStyleService.formatter}}
             */
            service.getDefaultToolTip = function (xUnit, yUnit) {
                var formatter = null;

                if (yUnit !== null) {
                    formatter = function () {
                        //for series of points
                        if (this.points != undefined) {
                            var s = [];
                            var md = this.x;
                            s.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                            for (var i = 0; i < this.points.length; i++) {
                                s.push('<span style="color:' + this.points[i].color + ';font-weight: bold">' +
                                    this.points[i].series.name + ':</span>  ' + $filter('measure')({
                                        value: this.points[i].y,
                                        unit: yUnit
                                    }, yUnit));
                            }
                            return s.join('<br/>');
                        }
                        //for a single point
                        else {
                            var s = [];
                            var md = this.x;
                            s.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                            s.push('<span style="color:' + this.point.color + ';font-weight: bold">' +
                                this.point.series.name + ':</span>  ' + $filter('measure')({
                                    value: this.point.y,
                                    unit: yUnit
                                }, yUnit));
                            return s.join('<br/>');
                        }
                    };
                }
                else {
                    formatter = function () {
                        //for series of points
                        if (this.points != undefined) {
                            var s = [];
                            var md = this.x;
                            s.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                            for (var i = 0; i < this.points.length; i++) {
                                s.push('<span style="color:' + this.points[i].color + ';font-weight: bold">' +
                                    this.points[i].series.name + ':</span>  ' + this.points[i].y.toFixed(2))
                            }
                            return s.join('<br/>');
                        }
                        //for a single point
                        else {
                            var s = [];
                            var md = this.x;
                            s.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                                s.push('<span style="color:' + this.point.color + ';font-weight: bold">' +
                                    this.point.series.name + ':</span>  ' + this.point.y.toFixed(2))
                            return s.join('<br/>');
                        }

                    };
                }

                return {
                    borderColor: style.color.tooltipBorder,
                    crosshairs: [true, false],
                    shared: true,
                    positioner: function () {    //position the tooltip at the top right corner
                        return {x: 1170, y: 0}
                    },
                    formatter: formatter
                };
            };

            /**
             * Create the style for failure region of a FAC plot
             * @param data
             * @returns {{data, type, lineWidth, fillOpacity, zIndex, color, showInLegend, enableMouseTracking}}
             */
            service.createFailRegion = function (data) {
                var failRegion = createRegion(
                    data,
                    style.color.fail.color,
                    style.color.fail.regionOpacity
                );
                failRegion.ignoreScale = true;
                return failRegion;
            };

            /**
             * Create the style for warning region of a FAC plot
             * @param data
             * @returns {{data, type, lineWidth, fillOpacity, zIndex, color, showInLegend, enableMouseTracking}}
             */
            service.createWarnRegion = function (data) {
                var warnRegion = createRegion(
                    data,
                    style.color.warn.color,
                    style.color.warn.regionOpacity
                );
                warnRegion.ignoreScale = true;
                return warnRegion;
            };

            /**
             * Create the style for pass region of a FAC plot
             * @param data
             * @returns {{data, type, lineWidth, fillOpacity, zIndex, color, showInLegend, enableMouseTracking}}
             */
            service.createPassRegion = function (data) {
                var passRegion = createRegion(
                    data,
                    style.color.safe.color,
                    style.color.safe.regionOpacity
                );
                passRegion.ignoreScale = true;
                return passRegion;
            };

            /**
             * Create the style for measured/calculated series for all surveys of a FAC plot
             * @param name
             * @param data
             * @returns {{name: *, data: *, zIndex: number, color: string, marker: (style.marker.standard|{symbol, radius, lineWidth, lineColor})}}
             */
            service.createAllMeasuredSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.raw,
                    marker: style.marker.standard,
                    visible: false
                };
            };

            /**
             * Create the style for corrected series for all surveys of a FAC plot
             * @param name
             * @param data
             * @returns {{name: *, data: *, zIndex: number, color: string, marker: (style.marker.standard|{symbol, radius, lineWidth, lineColor})}}
             */
            service.createAllCorrectedSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.corrected,
                    marker: style.marker.standard,
                    visible: false
                };
            };

            /**
             * Create the style for a measured/calculated series of a FAC plot
             * @param name
             * @param data
             * @returns {{name: *, data: *, zIndex: number, color: string, marker: (style.marker.standard|{symbol, radius, lineWidth, lineColor})}}
             */
            service.createMeasuredSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.raw,
                    marker: style.marker.standard
                };
            };

            /**
             * Create the style for a corrected series of a FAC plot
             * @param name
             * @param data
             * @returns {{name: *, data: *, zIndex: number, color: string, marker: (style.marker.standard|{symbol, radius, lineWidth, lineColor})}}
             */
            service.createCorrectedSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.corrected,
                    marker: style.marker.standard
                };
            };

            /**
             * Create the style for a reference series of a FAC plot
             * @param name
             * @param data
             * @returns {{name: *, data: *, zIndex: number, color: string, marker: (style.marker.standard|{symbol, radius, lineWidth, lineColor})}}
             */
            service.createReferenceSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.reference,
                    marker: style.marker.standard,
                    showInLegend: false,
                    enableMouseTracking: false
                }
            };

            service.createPoorMeasuredSeries = function (name, data, xUnit, yUnit) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.raw,
                    marker: style.marker.poor,
                    showInLegend: true,
                    enableMouseTracking: true,
                    lineWidth: 0
                }
            };

            service.createPoorCorrectedSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.corrected,
                    marker: style.marker.poor,
                    showInLegend: true,
                    enableMouseTracking: true,
                    lineWidth: 0
                }
            };

            service.createCheckshotMeasuredSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.raw,
                    marker: style.marker.checkshot,
                    showInLegend: true,
                    enableMouseTracking: true,
                    lineWidth: 0
                }
            };

            service.createCheckshotCorrectedSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.corrected,
                    marker: style.marker.checkshot,
                    showInLegend: true,
                    enableMouseTracking: true,
                    lineWidth: 0
                }
            };

            service.createAccelerometerMeasuredSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.raw,
                    marker: style.marker.accelerometer,
                    showInLegend: true,
                    enableMouseTracking: true,
                    lineWidth: 0
                }
            };

            service.createAccelerometerCorrectedSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.corrected,
                    marker: style.marker.accelerometer,
                    showInLegend: true,
                    enableMouseTracking: true,
                    lineWidth: 0
                }
            };

            /**
             * Create the style for a bad survey series of a FAC plot
             * @param name
             * @param data
             * @returns {{name: *, data: *, zIndex: number, color: string, marker: (style.marker.standard|{symbol, radius, lineWidth, lineColor})}}
             */
            service.createBadSeries = function (name, data) {
                return {
                    name: name,
                    data: data,
                    zIndex: 1,
                    color: style.color.bad,
                    marker: style.marker.bad,
                    showInLegend: true,
                    enableMouseTracking: true,
                    lineWidth: 0
                }
            };

            /**
             * Generic function to create the style for a region
             * @param data
             * @param color
             * @param opacity
             * @returns {{data: *, type: string, lineWidth: number, fillOpacity: *, zIndex: number, color: *, showInLegend: boolean, enableMouseTracking: boolean}}
             */
            var createRegion = function (data, color, opacity) {
                return {
                    data: data,
                    type: 'arearange',
                    lineWidth: 0,
                    fillOpacity: opacity,
                    zIndex: 0,
                    color: color,
                    showInLegend: false,
                    enableMouseTracking: false
                };
            };

            /**
             * Gets the dateTime plot options for Highcharts
             * @returns {{series: {states: {hover: {lineWidthPlus: number}}}}}
             */
            service.getDateTimePlotOptions = function (xUnit, yUnit) {
                return {
                    series: {
                        states: {
                            hover: {
                                lineWidthPlus: 0
                            }
                        },
                        tooltip: service.getDateTimeToolTip(xUnit, yUnit)
                    },
                    scatter: {
                        tooltip: service.getDateTimeToolTip(xUnit, yUnit)
                    }
                };
            };

            /**
             * Create dateTime y axis object for Highcharts
             * @param labelText
             * @param min
             * @param max
             * @returns {{type: 'datetime', dateTimeLabelFormats: { millisecond: '%H:%M:%S', second: '%H:%M:%S', minute: '%H:%M:%S', hour: '%H:%M:%S', day: '%H:%M:%S', week: '%H:%M:%S', month: '%H:%M:%S', year: '%H:%M:%S' }, title: {text: *, style: {font-weight: string, font-size: number}}, min: *, max: *}}
             */
            service.getDateTimeYAxis = function (labelText, min, max) {
                return {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S',
                        second: '%H:%M:%S',
                        minute: '%H:%M:%S',
                        hour: '%H:%M:%S',
                        day: '%H:%M:%S',
                        week: '%H:%M:%S',
                        month: '%H:%M:%S',
                        year: '%H:%M:%S'
                    },
                    title: {
                        text: labelText,
                        style: {
                            'font-weight': 'bold',
                            'font-size': '16px',
                            'text-align': 'center',
                        },
                        align: "middle"
                    },
                    startOnTick: false,
                    endOnTick: false,
                    min: min,
                    max: max,
                    labels: {
                        formatter: function () {
                            return moment(this.value).format("HH:mm:ss");
                        }
                    }

                };
            };

            /**
             * Gets the dateTime tooltip for Highcharts
             * @param unit
             * @returns {{borderColor: string, crosshairs: boolean[], positioner: PlotStyleService.positioner, shared: boolean, formatter: PlotStyleService.formatter}}
             */
            service.getDateTimeToolTip = function (xUnit, yUnit) {
                var formatter = null;

                if (yUnit !== null) {
                    formatter = function () {
                        //for series of points
                        if (this.points !== undefined) {
                            var series = [];
                            var md = this.x;
                            series.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                            for (var i = 0; i < this.points.length; i++) {
                                series.push('<span style="color:' + this.points[i].color + ';font-weight: bold">' +
                                    this.points[i].series.name + ':</span>  ' + moment(this.points[i].y).format("HH:mm:ss"));
                            }
                            return series.join('<br/>');
                        }
                        //for a single point
                        else {
                            var series = [];
                            var md = this.x;
                            series.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                            series.push('<span style="color:' + this.point.color + ';font-weight: bold">' +
                                this.point.series.name + ':</span>  ' + $filter('measure')({
                                    value: this.point.y,
                                    unit: yUnit
                                }, yUnit));
                            return series.join('<br/>');
                        }
                    };
                }
                else {
                    formatter = function () {
                        //for series of points
                        if (this.points !== undefined) {
                            var series = [];
                            var md = this.x;
                            series.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                            for (var i = 0; i < this.points.length; i++) {
                                series.push('<span style="color:' + this.points[i].color + ';font-weight: bold">' +
                                    this.points[i].series.name + ':</span>  ' + this.points[i].y.toFixed(2))
                            }
                            return series.join('<br/>');
                        }
                        //for a single point
                        else {
                            var series = [];
                            var md = this.x;
                            series.push('<span style="color: black; font-weight: bold">' +
                                'MD' + ':</span>  ' + $filter('measure')({
                                    value: md,
                                    unit: xUnit
                                }, xUnit));
                                series.push('<span style="color:' + this.point.color + ';font-weight: bold">' +
                                    this.point.series.name + ':</span>  ' + this.point.y.toFixed(2))
                            return series.join('<br/>');
                        }

                    };
                }

                return {
                    borderColor: style.color.tooltipBorder,
                    crosshairs: [true, false],
                    shared: true,
                    positioner: function () {    //position the tooltip at the top right corner
                        return {x: 1170, y: 0}
                    },
                    formatter: formatter
                };
            };


            /**
             * Gets 3d pie chart of Highcharts
             * @returns { type: 'pie', options3d: { enabled: true, alpha: 50, beta: 0 }
             */
            service.getPieChart = function () {
                return {
                    type: 'pie',
                    options3d: {
                        enabled: true,
                        alpha: 50,
                        beta: 0
                    }
                };
            };

            /**
             * Gets the 3d pie chart plot options for Highcharts
             * @returns {{pie: { allowPointSelect: true, cursor: 'pointer', depth: 60, dataLabels: { enabled: true, format: '{point.name}' }}, series: {states: {hover: {lineWidthPlus: number}}}}}
             */
            service.getPiePlotOptions = function () {
                return {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        depth: 60,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    },
                    series: {
                        states: {
                            hover: {
                                lineWidthPlus: 0
                            }
                        }
                    }
                };
            };

            /**
             * Create the style for a 3d pie series of a survey corrections times plot
             * @param name
             * @param data
             * @returns {{name: *, data: *, zIndex: number, color: string, marker: (style.marker.standard|{symbol, radius, lineWidth, lineColor})}}
             */
            service.createPieSeries = function (name, data) {
                var seriesData = []
                for (var i = 0; i < data.length; i++) {
                    seriesData.push({
                        name: data[i][0],
                        y: data[i][1],
                        color: (data[i][0] === '<1 min') ? '#83ab4f' : (data[i][0] === '1-2 min') ? "#7298cf" : (data[i][0] === '2-3 min') ? '#ebbf2f' : '#ab0202'
                    });
                }
                return {
                    name: name,
                    data: seriesData,
                    type: 'pie'
                };
            };

            /**
             * Gets the tooltip for 3d pie chart in Highcharts
             * @returns {{borderColor: string, crosshairs: boolean[], positioner: PlotStyleService.positioner, shared: boolean, formatter: PlotStyleService.formatter}}
             */
            service.getPieToolTip = function () {
                var formatter = null;

                formatter = function () {
                    //for series of points
                    if (this.points !== undefined) {
                        var series = [];
                        var md = this.x;
                        series.push('<span style="color: black; font-weight: bold">' +
                            'Correction Time' + ':</span>  ' + this.point[i].name);
                        for (var i = 0; i < this.points.length; i++) {
                            series.push('<span style="color:' + this.points[i].color + ';font-weight: bold">' +
                                'Corrected Surveys:</span>  ' + this.points[i].percentage + '%');
                        }
                        return series.join('<br/>');
                    }
                    //for a single point
                    else {
                        var series = [];
                        var md = this.x;
                        series.push('<span style="color: black; font-weight: bold">' +
                            'Correction Time' + ':</span>  ' + this.point.name);
                        series.push('<span style="color:' + this.point.color + ';font-weight: bold">' +
                            'Corrected Surveys:</span>  ' + this.point.percentage.toFixed(2) + '%');
                        return series.join('<br/>');
                    }
                };

                return {
                    borderColor: style.color.tooltipBorder,
                    crosshairs: [true, false],
                    shared: true,
                    positioner: function () {    //position the tooltip at the top right corner
                        return {x: 1170, y: 0}
                    },
                    formatter: formatter
                };
            };

            return service;

        }
    ])
    ;
})
();
