/**
 * This service is for handling everything to do with snapshot interactions and the server. This includes creating them, getting them, importing them, etc. etc.
 */
(function () {
    var app = angular.module('saphira');
    app.factory('SnapshotService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */  'DataService',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ DataService
                  /* Input            */) {

            var SnapshotService = {};
            var URLS = {
                surveySetSnapshot: '/saphira/api/utils/createExport',
                restoreSurveySetFromSnapshot: '/saphira/api/utils/surveySetImportRestore',
            };

            /**
             * This function simply wraps the DataServices 'getAllAttachmentsByparentID' method with a specific object type. Namely, we know that all of the snapshots are
             * stored with a convention of:
             * CommonName_SNAPSHOTS . However, there is no reason that the rest of the client should know this, thus the wrapper
             * (and why the rest of the client doesn't interact directly with the DataService for this purpose)
             *
             * @param  {String} parentId   the id of the parent object we're getting the snapshots of
             * @param  {String} objectType the actual type of the object, this is synonymous with the common name of the object.
             * @return {JS Promise}            a promise to the HTTP request.
             */
            SnapshotService.getSnapshotsForObject = function (parentId, objectType) {
                return DataService.getAllAttachmentsByParentID(parentId, "SURVEYSET" + "_SNAPSHOTS");
            };

            /**
             *
             * This function simply wraps the DataServices 'deleteAttachmentById' method with a specific object type. Namely, we know that all of the snapshots are
             * stored with a convention of:
             * CommonName_SNAPSHOTS . However, there is no reason that the rest of the client should know this, thus the wrapper
             * (and why the rest of the client doesn't interact directly with the DataService for this purpose)
             *
             * @param  {String} fileId   the id of the snapshot we want to delete
             * @param  {String} objectType the actual type of the object, this is synonymous with the common name of the object.
             * @return {JS Promise}            a promise to the HTTP request.
             */
            SnapshotService.deleteSnapshotForObject = function (objectType, objectId, fileId) {
                return DataService.deleteAttachmentById(objectType + "_SNAPSHOTS", objectId, fileId);
            };

            /**
             * This function allows you to download a snapshot.
             * @param  {String} parentObject This is the parent object common name (SurveySet, Trajectory, etc.)
             * @param  {String} parentId     This is the ID of the parent object
             * @param  {String} fileId       The actual file ID of the attachment which corresponds to the snapshot, so we can download it.
             * @return {JS Promise}              A JS promise corresponding to the http XHR request to download the file.
             */
            SnapshotService.downloadSnapshot = function (parentObject, parentId, fileId) {
                return DataService.downloadAttachment(parentObject + "_SNAPSHOTS", parentId, fileId);
            };

            /**
             * This function actually processes a restore from a snapshot for a survey set. Takes in a survey set ID, an import option, and a snapshot ID. The import options
             * should be obtained from the server
             * @param  {String} surveySetId  the string-valued ID of the survey set we're importing to
             * @param  {Enum} importOption An enum representing an import option for importing
             * @param  {String} snapshotId   The string-valued ID of the actual snapshot we're importing from
             * @return {JS Promise}              A promise that will resolve along with the HTTP XHR request.
             */
            SnapshotService.restoreSurveySetFromSnapshot = function (surveySetId, importOption, snapshotId) {
                var deferred = $q.defer();
                var params = {
                    surveySetId: surveySetId,
                    snapshotId: snapshotId,
                    importOption: importOption
                };

                $http.get(URLS.restoreSurveySetFromSnapshot, {params: params}).then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (error) {

                    //Quick fix to replace Apache timeout error with something human readable. Going forward we'll want to prevent this entirely.
                    if(error && error.status === 502) {
                        error.data = "Request Time Out: The process took longer than expected - Ensure that your data imported to your destination (this may take several minutes).";
                    }

                    deferred.reject(error);
                });
                return deferred.promise;
            };

            /**
             * This function is super basic, all it does is upload a brand new snapshot from the client machine to the server. This operation will set a 'destroy on read' flag
             * on the attachment itself, so if it's downloaded/imported it's auto deleted.
             * @param  {String} parentObject The common name of the parent object (E.g. SurveySet, Trajectory, etc.)
             * @param  {Strign} parentId     The string-valued ID of the parent object to attach the file to
             * @param  {File} snapshot     An actual MultiPart File that we are uploading to the server
             * @return {JS Promise}              A promise that resolves with the HTTP XHR request to upload the file.
             */
            SnapshotService.uploadSnapshotFromFile = function (parentObject, parentId, snapshot) {
                return DataService.uploadNewFile(snapshot, parentId, parentObject + "_SNAPSHOTS");
            };

            /**
             * This function actually creates a full snapshot for a survey set. we have a distinction between a 'full' snapshot and a 'minimal' snapshot, where a full snapshot is
             * everything we can possibly extract from a survey set, whereas a minimal one will only have some basic fields (for less privelaged users) eventually it might
             * be worth it to come up with a scheme where MagVar can manually choose who can export what from where, but that's a good bit of work.
             * @param  {String} surveySetID This is the id of the survey set that we are creating a snapshot of
             * @return {JS Promise} A promise is returned linking to the Http request being sent to the server to create a snapshot. it will eventually return a string ID of the
             * created snapshot on success.
             */
            SnapshotService.createFullSnapshotForSurveySet = function (surveySetID) {
                var params = {
                    id: surveySetID,
                    exportType: "SURVEYSET_SNAPSHOT",
                    deleteOnDownload: false
                };
                var deferred = $q.defer();

                $http.get(URLS.surveySetSnapshot, {
                    params: params
                }).then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };

            SnapshotService.createFile = function (id, exportType, deleteOnDownload) {
                var params = {
                    id: id,
                    exportType: exportType,
                    deleteOnDownload: deleteOnDownload
                };
                var deferred = $q.defer();

                $http.get(URLS.surveySetSnapshot, {
                    params: params
                }).then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                }, function (progressEvent) {
                    deferred.notify(progressEvent.loaded / progressEvent.total);
                });
                return deferred.promise;
            };

            SnapshotService.downloadFile = function (parentObject, parentId, fileId) {
                return DataService.downloadAttachment(parentObject + "_SNAPSHOTS", parentId, fileId);
            };

            /**
             * This is an interesting function, because it allows the calling class to essentially create a snapshot of anything they want. Now, it's worth mentioning that the
             * choice of 'what' the user can snapshot (full or minimal) falls to the server. the server checks the permissions, and then based upon what they are allowed to do,
             * does it. It always will snapshot the greatest amount of data available to it (ex: it will snapshot full over minimal every time if the user has enough permissions).
             * Now unfortunately, we have to do some string comparison here, but I think that the benefit we gain (the ability to funnel all requests through a single function)
             * is totally worth it.
             * @param  {JSObject} arbitraryObject This is the object that the person wants a snapshot of
             * @return {JS promise}                 returns a javascript promise linking to the http request creating the snapshot.
             */
            SnapshotService.createSnapshot = function (arbitraryObject) {
                switch (arbitraryObject.commonName) {
                    case "SurveySet":
                        return SnapshotService.createFullSnapshotForSurveySet(arbitraryObject.id);
                }
                return null;
            };
            return SnapshotService;
        }]);
})();
