/**
 * QC Plots controller
 * Handles the creation of the plots
 */

(function () {

    var app = angular.module("saphira");

    app.controller("QCPlotsModalController", [
        /* Angular Modules  */ "$scope", "$uibModalInstance", "$filter",
        /* 3rd Party Modules*/
        /* Internal Modules */ "InputValidationService", "AuthenticationService", "HighchartsPlottingService", "EventService", "ClientRequisitesService",
        /* Input            */ "surveySet", "surveys", "acSigma", "well", "refresh",

        function (/* Angular Modules  */ $scope, $uibModalInstance, $filter,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ PlotService, AuthenticationService, HighchartsPlottingService, EventService, ClientRequisitesService,
                  /* Input            */ surveySet, surveys, acSigma, well, refresh) {

            $scope.helpTooltip = "<div>Zoom: click-drag&#013;" +
                "Pan: shift-click-drag&#013;" +
                "Restore zoom level: double-click</div>";

            var unitSet = surveySet.unitSet;
            //console.log(surveySet);

            var qcDeltaAzi = surveySet.msaDeltaAzi;
            var qcDeltaInc = surveySet.msaDeltaAzi;

            $scope.checkPerm = AuthenticationService.checkPermissionMapEntry;

            //For some reason we can't just ng-if these function in the html
            $scope.rawPermission = $scope.checkPerm(surveySet, "QC_RAW");
            $scope.correctionPermission = $scope.checkPerm(surveySet, "QC_CORR");
            $scope.tolPermission = $scope.checkPerm(surveySet, "QC_TOL");
            $scope.incAziCalcPermission = $scope.checkPerm(surveySet, "QC_INCAZI_CALC");
            $scope.incAziCorrPermission = $scope.checkPerm(surveySet, "QC_INCAZI_CORR");
            $scope.validationErrorPermission = $scope.checkPerm(surveySet, "QC_VALIDATION_ERRORS");
            $scope.hasCorrection = false;

            /* Colors */
            var colors = {
                reference: 'black',
                reference2: 'green',
                measured: 'blue',
                corrected: 'firebrick',
                pass: 'green',
                warning: 'orange',
                fail: 'red',
                qc: 'green',
                qcRaw: 'blue',
                qcCorrected: 'red'
            };

            var highchartsDefaults = {
                colors: {
                    reference: '#000000',
                    raw: '#1f4e79',
                    corrected: '#c64141',
                    tooltipBorder: '#000000',
                    pointFillColor: '#FFFFFF'
                },
                marker: {
                    symbol: 'circle',
                    radius: 1,
                    lineWidth: 1,
                    lineColor: null
                },
                style: {
                    dashtype: 'ShortDot'
                }
            };

            //Construct Reported/Calc plots
            var sigmaData = {raw: [], corrected: [], all: []}, bTotalData = {raw: [], corrected: [], all: []},
                gTotalData = {raw: [], corrected: [], all: []}, dipData = {raw: [], corrected: [], all: []},
                incData = {raw: [], corrected: [], all: []}, aziData = {raw: [], corrected: [], all: []};

            /* Graphs */
            $scope.sigmaRawGraph = null;
            $scope.bTotalRawGraph = null;
            $scope.gTotalRawGraph = null;
            $scope.dipRawGraph = null;

            $scope.sigmaMsaGraph = null;
            $scope.bTotalMsaGraph = null;
            $scope.gTotalMsaGraph = null;
            $scope.dipMsaGraph = null;

            $scope.sigmaAllGraph = null;
            $scope.bTotalAllGraph = null;
            $scope.gTotalAllGraph = null;
            $scope.dipAllGraph = null;

            $scope.incCalcGraph = null;
            $scope.aziCalcGraph = null;

            $scope.incCorrGraph = null;
            $scope.aziCorrGraph = null;

            /**
             * Calls the necessary functions to redraw the graphs whenever they're displayed in the tab content
             * @param tab
             */
            $scope.tabSelect = function (tab) {
                switch (tab) {
                    case 'qcRaw':
                        break;
                    case 'qcCorr':
                        if (!$scope.hasCorrection)
                            break;
                        constructCorrectedPlots();
                        break;
                    case 'qcAll':
                        if (!$scope.hasCorrection)
                            break;
                        constructAllPlots();
                        break;
                    case 'incAziCalc':
                        constructIncAziCalcPlots();
                        break;
                    case 'incAziCorr':
                        if (!$scope.hasCorrection)
                            break;
                        constructIncAziCorrPlots();
                        break;
                    default:
                        break;
                }
            };

            var constructRawPlots = function () {
                if ($scope.sigmaRawGraph === null) {
                    $scope.sigmaRawGraph = {chart: {}, options: {}};
                    $scope.sigmaRawGraph.options = HighchartsPlottingService.Sigma.plotRawSigma(surveys, unitSet, acSigma);
                    $scope.sigmaRawGraph.options.exporting.filename = well.name + " - QC Tolerances Raw - Sigma";
                }
                if ($scope.bTotalRawGraph === null) {
                    $scope.bTotalRawGraph = {chart: {}, options: {}};
                    $scope.bTotalRawGraph.options = HighchartsPlottingService.BTotal.plotRawBTotal(surveys, unitSet, acSigma);
                    $scope.bTotalRawGraph.options.exporting.filename = well.name + " - QC Tolerances Raw - Total Magnetic Field";
                }
                if ($scope.gTotalRawGraph === null) {
                    $scope.gTotalRawGraph = {chart: {}, options: {}};
                    $scope.gTotalRawGraph.options = HighchartsPlottingService.GTotal.plotRawGTotal(surveys, unitSet, acSigma);
                    $scope.gTotalRawGraph.options.exporting.filename = well.name + " - QC Tolerances Raw - Total Gravity Field";
                }
                if ($scope.dipRawGraph === null) {
                    $scope.dipRawGraph = {chart: {}, options: {}};
                    $scope.dipRawGraph.options = HighchartsPlottingService.Dip.plotRawDip(surveys, unitSet, acSigma);
                    $scope.dipRawGraph.options.exporting.filename = well.name + " - QC Tolerances Raw - Magnetic Dip Angle";
                }
            };

            var constructCorrectedPlots = function () {
                if ($scope.sigmaMsaGraph === null) {
                    $scope.sigmaMsaGraph = {chart: {}, options: {}};
                    $scope.sigmaMsaGraph.options = HighchartsPlottingService.Sigma.plotMsaSigma(surveys, unitSet, acSigma);
                    $scope.sigmaMsaGraph.options.exporting.filename = well.name + " - QC Tolerances Corrected - Sigma";
                }
                if ($scope.bTotalMsaGraph === null) {
                    $scope.bTotalMsaGraph = {chart: {}, options: {}};
                    $scope.bTotalMsaGraph.options = HighchartsPlottingService.BTotal.plotMsaBTotal(surveys, unitSet, acSigma);
                    $scope.bTotalMsaGraph.options.exporting.filename = well.name + " - QC Tolerances Corrected - Total Magnetic Field";
                }
                if ($scope.gTotalMsaGraph === null) {
                    $scope.gTotalMsaGraph = {chart: {}, options: {}};
                    $scope.gTotalMsaGraph.options = HighchartsPlottingService.GTotal.plotMsaGTotal(surveys, unitSet, acSigma);
                    $scope.gTotalMsaGraph.options.exporting.filename = well.name + " - QC Tolerances Corrected - Total Gravity Field";
                }
                if ($scope.dipMsaGraph === null) {
                    $scope.dipMsaGraph = {chart: {}, options: {}};
                    $scope.dipMsaGraph.options = HighchartsPlottingService.Dip.plotMsaDip(surveys, unitSet, acSigma);
                    $scope.dipMsaGraph.options.exporting.filename = well.name + " - QC Tolerances Corrected - Magnetic Dip Angle";
                }
            };

            var constructAllPlots = function () {

                if ($scope.sigmaAllGraph === null) {
                    $scope.sigmaAllGraph = {chart: {}, options: {}};
                    $scope.sigmaAllGraph.options = HighchartsPlottingService.Sigma.plotMultiSigma(surveys, unitSet, acSigma);
                    $scope.sigmaAllGraph.options.exporting.filename = well.name + " - QC Tolerances All - Sigma";
                }
                if ($scope.bTotalAllGraph === null) {
                    $scope.bTotalAllGraph = {chart: {}, options: {}};
                    $scope.bTotalAllGraph.options = HighchartsPlottingService.BTotal.plotMultiBTotal(surveys, unitSet, acSigma);
                    $scope.bTotalAllGraph.options.exporting.filename = well.name + " - QC Tolerances All - Total Magnetic Field";
                }
                if ($scope.dipAllGraph === null) {
                    $scope.dipAllGraph = {chart: {}, options: {}};
                    $scope.dipAllGraph.options = HighchartsPlottingService.Dip.plotMultiDip(surveys, unitSet, acSigma);
                    $scope.dipAllGraph.options.exporting.filename = well.name + " - QC Tolerances All - Magnetic Dip Angle";
                }
                if ($scope.gTotalAllGraph === null) {
                    $scope.gTotalAllGraph = {chart: {}, options: {}};
                    $scope.gTotalAllGraph.options = HighchartsPlottingService.GTotal.plotMultiGTotal(surveys, unitSet, acSigma);
                    $scope.gTotalAllGraph.options.exporting.filename = well.name + " - QC Tolerances All - Total Gravity Field";
                }
            };

            var constructIncAziCalcPlots = function () {
                if ($scope.aziCalcGraph === null) {
                    $scope.aziCalcGraph = {chart: {}, options: {}};
                    $scope.aziCalcGraph.options =
                        HighchartsPlottingService.Azi.plotRawAzi(surveys, unitSet, surveySet.qcDeltaAzi.value);
                    $scope.aziCalcGraph.options.exporting.filename = well.name + " - Inclination/Azimuth Calculated - Azimuth (Calculated - Reported)";
                }
                if ($scope.incCalcGraph === null) {
                    $scope.incCalcGraph = {chart: {}, options: {}};
                    $scope.incCalcGraph.options = HighchartsPlottingService.Inc.plotRawInc(surveys, unitSet, surveySet.qcDeltaInc.value);
                    $scope.incCalcGraph.options.exporting.filename = well.name + " - Inclination/Azimuth Calculated - Inclination (Calculated - Reported)";
                }
            };

            var constructIncAziCorrPlots = function () {
                if ($scope.incCorrGraph === null) {
                    $scope.incCorrGraph = {chart: {}, options: {}};
                    $scope.incCorrGraph.options = HighchartsPlottingService.Inc.plotMsaInc(surveys, unitSet, surveySet.msaDeltaInc);
                    $scope.incCorrGraph.options.exporting.filename = well.name + " - Inclination/Azimuth Corrected - Inclination (Corrected - Reported)";
                }
                if ($scope.aziCorrGraph === null) {
                    $scope.aziCorrGraph = {chart: {}, options: {}};
                    $scope.aziCorrGraph.options = HighchartsPlottingService.Azi.plotMsaAzi(surveys, unitSet, surveySet.msaDeltaAzi);
                    $scope.aziCorrGraph.options.exporting.filename = well.name + " - Inclination/Azimuth Corrected - Azimuth (Corrected - Reported)";
                }
            };

            /**
             * exportGraphs:
             *
             *      Exports all graphs as a png
             *
             * @param type
             */
            $scope.exportGraphs = function (type) {
                var options = Highcharts.getOptions().exporting;
                var svg;
                var name;

                switch (type) {
                    case 'raw':
                        name = well.name +" - Reported Graphs";
                        svg = Highcharts.getSVG([$scope.sigmaRawGraph.chart, $scope.bTotalRawGraph.chart, $scope.dipRawGraph.chart, $scope.gTotalRawGraph.chart], 1450);
                        break;
                    case 'corrected':
                        name = well.name +" - Corrected Graphs";
                        svg = Highcharts.getSVG([$scope.sigmaMsaGraph.chart, $scope.bTotalMsaGraph.chart, $scope.dipMsaGraph.chart, $scope.gTotalMsaGraph.chart], 1450);
                        break;
                    case 'all':
                        name = well.name +" - Reported vs Corrected Graphs";
                        svg = Highcharts.getSVG([$scope.sigmaAllGraph.chart, $scope.bTotalAllGraph.chart, $scope.dipAllGraph.chart, $scope.gTotalAllGraph.chart], 1450);
                        break;
                    case 'calcIncAzi':
                        name = well.name +" - Calculated Delta Graphs";
                        svg = Highcharts.getSVG([$scope.aziCalcGraph.chart, $scope.incCalcGraph.chart], 1450);
                        break;
                    case 'corrIncAzi':
                        name = well.name +" - Corrected Delta Graphs";
                        svg = Highcharts.getSVG([$scope.aziCorrGraph.chart, $scope.incCorrGraph.chart], 1450);
                        break;
                    default:
                        console.error("Unknown type for export all");
                        return;
                }

                // Post to export server
                Highcharts.post(options.url, {
                    filename: name,
                    type: options.type,
                    width: options.width,
                    svg: svg
                });
            };

            var surveyHasCorrection = function(survey) {
                return survey.correctedSixAxisDatas !== null && survey.correctedSixAxisDatas !== undefined
                    && survey.correctedSixAxisDatas.MULTISTATION !== null && survey.correctedSixAxisDatas.MULTISTATION !== undefined
            };

            var checkIfHasCorrection = function () {
                if (surveys != null && surveys.length > 0) {
                    for (var i = 0; i < surveys.length; i++) {
                        if (surveyHasCorrection(surveys[i]))
                            return true;
                    }
                }
                return false;
            };

            var init = function () {
                constructRawPlots();
                $scope.hasCorrection = checkIfHasCorrection();

                //add survey validation errors for latest survey
                $scope.surveyValidationErrors = [];
                angular.forEach(surveys[surveys.length - 1].validationErrors, function (errorCode) {
                    $scope.surveyValidationErrors.push("Check " + errorCode + ": " + ClientRequisitesService.data.ValidationErrors[errorCode]);
                });

                //add validation errors for msa correction
                $scope.msaErrors = [];
                angular.forEach(surveySet.msaValidationErrors, function (errorCode) {
                    $scope.msaErrors.push("Check " + errorCode + ": " + ClientRequisitesService.data.ValidationErrors[errorCode]);
                });
            };
            init();

            $scope.rawPlotsMade = function () {
                return ($scope.sigmaRawGraph !== null && $scope.bTotalRawGraph !== null
                && $scope.gTotalRawGraph !== null && $scope.dipRawGraph !== null);
            };

            $scope.corrPlotsMade = function () {
                return ($scope.sigmaCorrGraph !== null && $scope.bTotalCorrGraph !== null
                && $scope.gTotalCorrGraph !== null && $scope.dipCorrGraph !== null);
            };

            $scope.allPlotsMade = function () {
                return ($scope.sigmaAllGraph !== null && $scope.bTotalAllGraph !== null
                && $scope.gTotalAllGraph !== null && $scope.dipAllGraph !== null);
            };

            $scope.incAziCalcPlotsMade = function () {
                return ($scope.incCalcGraph !== null && $scope.aziCalcGraph !== null);
            };

            $scope.incAziCorrPlotsMade = function () {
                return ($scope.incCorrGraph !== null & $scope.aziCorrGraph !== null);
            };

            /**
             * This dismisses the modals so that the user can go back ot the previous view
             */
            $scope.closeModal = function () {
                $uibModalInstance.close('Exit');
            };

            $scope.isNewFileFlagPresent = EventService.isNewFileFlagPresent;
            $scope.clearSurveySetFlags = function() {
                EventService.clearSurveySetFlags().then(function () {
                    refresh();
                });
            };

        }]);

})();
