/**
 * Simple service for managing the UI Blocker
 *
 * @author: cperry
 */
(function () {
    var app = angular.module('saphira');

    app.factory('UIBlocker', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            var UIBlocker = {};

            var blocks = 0; //Internal count of number of blockers up
            UIBlocker.enabled = false; //Simple boolean to see whether blocker is enabled or not
            UIBlocker.isRtxLoader = false; //Simple boolean to see whether blocker is enabled or not for RTX surveyset

            /**
             * Enables the UI blocker and increments the count of blockers
             */
            UIBlocker.start = function () {
                blocks = blocks + 1;
                UIBlocker.enabled = true;
            };

            /**
             * Decrements the number of blocks and disables the blocker if the count reaches 0
             */
            UIBlocker.stop = function () {
                blocks = (blocks - 1 < 0 ? 0 : blocks - 1);
                if (blocks === 0) {
                    UIBlocker.enabled = false;
                }
            };

            /**
             * Resets number of blockers to 0 and disables blocker
             */
            UIBlocker.reset = function () {
                blocks = 0;
                UIBlocker.enabled = false;
            };

            UIBlocker.rtxLoader = function (isRtxLoader) {
                UIBlocker.isRtxLoader = isRtxLoader;
            };

            return UIBlocker;
        }]);
})();

