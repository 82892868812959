/**
 * This controller is for handling everything to do about our 'chat' window. yay.
 */
(function () {
    var app = angular.module("saphira");

    app.controller("ChatModalController", [
        /* Angular Modules  */ "$scope", "$rootScope", "$interval", "$uibModalInstance",
        /* 3rd Party Modules*/
        /* Internal Modules */ "DataService", "SweetAlert", "ChatService", "HierarchyService", "StateService", "AuthenticationService",
        /* Input            */

        function (/* Angular Modules  */ $scope, $rootScope, $interval, $uibModalInstance,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ DataService, SweetAlert, ChatService, HierarchyService, StateService, AuthenticationService
                  /* Input            */) {

            var refreshTimer = 2000;
            var refreshId;

            var latest = null;

            /**
             * This is our object that holds everything for our chat directive.
             * @type {Object}
             */
            $scope.chat = {
                options: {
                    user: AuthenticationService.currentUser().displayName,
                    userColor: '#ADD8E6',
                    otherColor: '#E4EEE0'
                },
                messages: [],
                addNewMessage: function (message) {
                    message.parentId = StateService.wellboreDetailView.selectedWellId;
                    ChatService.addNewChat(StateService.wellboreDetailView.selectedWellId, message).then(function (success) {
                        refresh();
                    }, function (error) {
                        SweetAlert.swal("error", "There was a problem submitting the chat message: " + error.status,
                            "error");
                    });
                }
            };

            /**
             * refresh:
             *
             *      The refresh function that is called to make sure that the page is kept
             *  up to date.
             */
            var refresh = function () {

                if (latest !== null) {
                    ChatService.getAllMessagesAfter(StateService.wellboreDetailView.selectedWellId, latest).then(function (response) {
                        var messages = response.messages;
                        if (messages.length > 0) {
                            $scope.chat.messages = $scope.chat.messages.concat(messages);
                            latest = messages[messages.length - 1].time;
                        }

                    }, function (error) {
                        SweetAlert.swal("Error", "Failed to load chat messages.\n" + error.status, "error");
                    });
                }
                else {
                    ChatService.getAllMessages(StateService.wellboreDetailView.selectedWellId).then(function (response) {
                        var messages = response.messages;
                        $scope.chat.messages = messages;

                        if (messages.length > 0) {
                            latest = messages[messages.length - 1].time;
                        }
                    }, function (error) {
                        SweetAlert.swal("Error", "Failed to load chat messages.\n" + error.status, "error");
                    });
                }
            };

            /**
             *  init:
             *
             *      Some setup for the controller/view required when moving to this view.
             */
            var init = function () {
                ChatService.getAllMessages(StateService.wellboreDetailView.selectedWellId).then(function (response) {
                    var messages = response.messages;
                    $scope.chat.messages = messages;

                    if (messages.length > 0) {
                        latest = messages[messages.length - 1].time;
                    }

                    //start the refresh loop
                    refreshId = $interval(function () {
                        refresh();
                    }, refreshTimer);
                }, function (error) {
                    SweetAlert.swal("Error", "Failed to load chat messages.\n" + error.status, "error");
                });
            };
            init();

            /**
             * This cleans up our refresh timer, to make sure it destroys itself.
             */
            $scope.$on(
                "$destroy",
                function (event) {
                    $interval.cancel(refreshId);
                }
            );
        }
    ]);
})();