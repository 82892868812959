(function () {

    var app = angular.module('saphira');

    app.directive('mvMagnetismUnitSelect', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return {

                restrict: 'E',      //Enforce the directive to be used as an element

                replace: true,      //Force the directive html to replace the tag

                template: '<select>' +                
                '<option value="GAMMA">Gamma</option>' +
                '<option value="GAUSS">Gauss</option>' +
                '<option value="NANOTESLA">nT</option>' +
                '<option value="MILLITESLA">mT</option>' +
                '<option value="TESLA">T</option>' +
                '<option value="MICROTESLA">uT</option>' +
                '</select>'

            };

        }]);

})();