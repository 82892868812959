(function () {

    var app = angular.module('saphira');

    app.directive('mvEditGlyph', [
        /* Angular Modules  */
        /* 3rd Party Modules*/
        /* Internal Modules */
        /* Input            */

        function (/* Angular Modules  */
                  /* 3rd Party Modules*/
                  /* Internal Modules */
                  /* Input            */) {

            return {

                restrict: 'E',      //Enforce the directive to be used as an element

                replace: true,      //Force the directive html to replace the tag

                template: '<span style="margin: 4px; cursor: pointer; color: #23527c;" class="glyphicon glyphicon-pencil"></span>'

            };

        }]);

})();