/**
 * The service responsible for basic operations on a surveySet
 */
(function () {

    var app = angular.module('saphira');

    app.factory('SurveySetService', [
        /* Angular Modules  */ '$http', '$q',
        /* 3rd Party Modules*/
        /* Internal Modules */  'SurveyService', 'UIBlocker',
        /* Input            */

        function (/* Angular Modules  */ $http, $q,
                  /* 3rd Party Modules*/
                  /* Internal Modules */ SurveyService, UIBlocker
                  /* Input            */) {

            var SurveySetService = {};

            //a list of urls used by the service
            var URLS = {
                getOne: '/saphira/api/repositories/surveySet/getOne',
                update: '/saphira/api/repositories/surveySet/update',
                getManyMeta: '/saphira/api/repositories/surveySet/getManyMeta',
                getManyMetaByParent: '/saphira/api/repositories/surveySet/getManyMetaByParent',
                lastModifiedDate: '/saphira/api/repositories/surveySet/lastModifiedDate',
                modifyNotifications: '/saphira/api/repositories/surveySet/modifyNotifications',
                ackNotifications: '/saphira/api/repositories/surveySet/ackNotifications',
                createSurveySets: '/saphira/api/repositories/surveySet/createNewSurveySet',
                setNewFileFlag: '/saphira/api/repositories/surveySet/setNewFileFlag',
                clearFlags: '/saphira/api/repositories/surveySet/clearFlags'
            };

            //the loaded surveySets
            SurveySetService.Loaded = {};

            /**
             * getManyMeta:
             *
             *      Returns the metas for the survey sets with the corresponding
             *  ids.  Standard authentication rules apply.
             *
             * @param surveySetIds   An array of survey set ids
             * @param forceUpdate    If true, all survey metadata will be redownloaded.
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            SurveySetService.getManyMeta = function (surveySetIds, forceUpdate) {
                return $http.get(URLS.getManyMeta, {'params': {'id': surveySetIds}}).then(function (response) {
                    return response.data;
                });
            };

            /**
             * getManyMetaByParent:
             *
             *      Returns the metas for the survey sets with the corresponding
             *  parent id.  Standard authentication rules apply.
             *
             * @param parentId   id of the parent
             * @param forceUpdate    If true, all survey metadata will be redownloaded.
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            SurveySetService.getManyMetaByParent = function (parentId, forceUpdate) {
                return $http.get(URLS.getManyMetaByParent, {'params': {'parentId': parentId}})
                    .then(function (response) {
                        return response.data;
                    });
            };

            /**
             * get:
             *
             *      Returns the surveySet with the corresponding id.  If
             *  the surveySet is already loaded, it returns it.  If the
             *  surveySet is not yet loaded, it pulls it from the server
             *  and then stores a reference to it and returns it.
             *  Standard authentication rules apply.
             *
             * @param surveySetId     The id of the surveySet to return
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            SurveySetService.get = function (surveySetId, forceUpdate) {
                if (forceUpdate !== true && surveySetId in SurveySetService.Loaded) {
                    return $q.resolve(SurveySetService.Loaded[surveySetId]);
                } else {
                    UIBlocker.start();
                    return $http.get(URLS.getOne, {'params': {'id': surveySetId}}).then(function (response) {
                        SurveySetService.Loaded[response.data.id] = response.data;
                        return SurveyService.getBySurveySet(response.data.id, response.data.toolType, true).then(function (surveys) {
                            SurveySetService.Loaded[response.data.id].tieIn =
                                SurveyService.Loaded[response.data.id].tieIn;
                            UIBlocker.stop();
                            return response.data;
                        }, function (response) {
                            UIBlocker.stop();
                            return $q.reject(response.status);
                        });
                    }, function (response) {
                        UIBlocker.stop();
                        return $q.reject(response.status);
                    });
                }
            };

            SurveySetService.setNewFileFlag = function (surveySetId, flag) {
                var params = {
                    surveySetId: surveySetId,
                    flag: flag
                };

                UIBlocker.start();
                return $http.post(URLS.setNewFileFlag, null, {params: params}).then(function (response) {
                    UIBlocker.stop();
                    return response;
                }, function (response) {
                    UIBlocker.stop();
                    return response;
                });
            };

            SurveySetService.clearFlags = function (surveySetId) {
                var params = {
                    surveySetId: surveySetId
                };

                UIBlocker.start();
                return $http.post(URLS.clearFlags, null, {params: params}).then(function (response) {
                    UIBlocker.stop();
                    return response;
                }, function (response) {
                    UIBlocker.stop();
                    return response;
                });
            };

            /**
             * save:
             *
             *      Saves the given surveySet to the server.  If the surveySet is
             *  successfully saved, it updates the service's reference to
             *  the surveySet.
             *
             * @param surveySet       The surveySet object to save
             *
             * @returns {Document.promise|*|k.promise|{then, catch, finally}}
             */
            SurveySetService.save = function (surveySet, updatingTieIn, updatingParameters) {
                var params = {
                    updatingTieIn: updatingTieIn,
                    updatingParameters: updatingParameters
                };

                UIBlocker.start();
                return $http.post(URLS.update, surveySet, {params: params}).then(function (response) {
                    SurveySetService.Loaded[surveySet.id] = surveySet;

                    return SurveyService.getBySurveySet(surveySet.id, surveySet.toolType, true).then(function (surveys) {
                        SurveySetService.Loaded[surveySet.id].tieIn = SurveyService.Loaded[surveySet.id].tieIn;
                        UIBlocker.stop();
                        return surveySet;
                    }, function () {
                        UIBlocker.stop();
                    });
                }, function (response) {
                    UIBlocker.stop();
                    return $q.reject(response);
                });
            };

            /**
             * getLastModifiedDate:
             *
             *      Gets the last modified date for the surveySet with the given
             *  surveySet id.
             *
             * @param surveySetId     The surveySet id
             *
             * @returns {*}
             */
            SurveySetService.getLastModifiedDate = function (surveySetId) {
                return $http.get(URLS.lastModifiedDate, {'params': {'id': surveySetId}}).then(function (response) {
                    return response.data;
                });
            };

            /**
             * updateIfModified:
             *
             *      Updates the surveySet corresponding to the given surveySet id if
             *  the server has a more recent version.
             *
             * @param surveySetId     The surveySet id of the surveySet to check for updates on
             * @returns {*}
             */
            SurveySetService.updateIfModified = function (surveySetId) {
                if (!(surveySetId in SurveySetService.Loaded)) {
                    console.error('Attempted to check for updates on a surveySet that isn\'t loaded');
                    return $q.reject('SurveySet needs to be loaded before checking for updates');
                } else {
                    return SurveySetService.getLastModifiedDate(surveySetId).then(function (date) {
                        var local = SurveySetService.Loaded[surveySetId];

                        if (date.seconds !== local.lastModifiedDate.seconds ||
                            date.nanos !== local.lastModifiedDate.nanos) {
                            return SurveySetService.get(surveySetId, true).then(function () {
                                return true;
                            });
                        } else {
                            return false;
                        }
                    });
                }
            };

            SurveySetService.modifyNotifications = function (surveySetId, notifications) {
                return $http.post(URLS.modifyNotifications + "?id=" + surveySetId, notifications).then(function (response) {
                    return response;
                });
            };

            SurveySetService.ackNotifications = function(surveySetId, userId, notifications) {
                return $http.post(URLS.ackNotifications + "?ssId=" + surveySetId + "&ackedUserId=" + userId, notifications).then(function(response) {
                    return response;
                });
            };

            SurveySetService.createSurveySets = function (name, surveySetsIds) {
                UIBlocker.start();
                var params = {
                    name: name,
                    surveySetsIds: surveySetsIds
                };
                return $http.post(URLS.createSurveySets, params).then(function (response) {
                    return response;
                }, function (response) {
                    UIBlocker.stop();
                    return $q.reject(response);
                });
            };

            return SurveySetService;
        }]);
})();
